.ant-alert-info {
  background-color: var(--color-primary-container);
  border-color: var(--color-primary-container);
}

.ant-alert-info .ant-alert-message {
  color: var(--color-on-primary-container);
}

.ant-alert-error {
  background-color: var(--color-error-container);
  border-color: var(--color-error-container);
}

.ant-alert-error .ant-alert-message {
  color: var(--color-on-error-container);
}

.ant-alert-success {
  background-color: var(--color-tertiary-container);
  border-color: var(--color-tertiary-container);
}

.ant-alert-success .ant-alert-message {
  color: var(--color-on-tertiary-container);
}

/* .ant-input - begin */
.ant-input {
  background-color: var(--color-input-background);
  border: none;
  border-radius: var(--dimension-input-border-radius);
  color: var(--color-input-value);
  height: auto;
  line-height: var(--dimension-input-line-height);
  padding: var(--dimension-input-padding-vertical)
    var(--dimension-input-padding-horizontal);
}

.ant-input::placeholder {
  color: var(--color-on-input-background);
}

.ant-input:hover {
  background-color: var(--color-input-background-hover);
  color: var(--color-input-value);
}

.ant-input:focus {
  background-color: var(--color-input-background-focus);
  color: var(--color-input-value);
}

.ant-input:active {
  background-color: var(--color-input-background-press);
  color: var(--color-input-value);
}
/* .ant-input - end */

/* .ant-textarea - begin */
textarea.ant-input {
  height: auto;
  line-height: var(--dimension-input-line-height);
  min-height: calc(
    var(--dimension-input-line-height) + var(--dimension-input-padding-vertical) +
      var(--dimension-input-padding-vertical)
  );
  padding: var(--dimension-input-padding-vertical)
    var(--dimension-input-padding-horizontal);
}
/* .ant-textarea - end */

/* .ant-checkbox - begin */
.ant-checkbox-wrapper {
  color: var(--color-on-background);
}

.ant-checkbox-wrapper-disabled {
  opacity: 0.32;
}

.ant-checkbox .ant-checkbox-inner {
  background-color: var(--color-input-background);
  border-color: var(--color-input-background);
}

.ant-checkbox-disabled + span {
  color: var(--color-on-background);
}

.ant-checkbox-checked:after {
  border-color: var(--color-input-active);
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--color-input-active);
  border-color: var(--color-input-active);
}

.ant-checkbox .ant-checkbox-inner:after {
  border-color: var(--color-on-input-active);
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner {
  background-color: var(--color-input-active-hover);
  border-color: var(--color-input-active-hover);
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
  border-color: var(--color-input-active-hover);
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  background-color: var(--color-input-background-hover);
  border-color: var(--color-input-background-hover);
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):focus
  .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):focus .ant-checkbox-inner {
  background-color: var(--color-input-background-focus);
  border-color: var(--color-input-background-focus);
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):active
  .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):active .ant-checkbox-inner {
  background-color: var(--color-input-background-press);
  border-color: var(--color-input-background-press);
}
/* .ant-checkbox - end */

/* .ant-radio - begin */
.ant-radio-wrapper {
  color: var(--color-on-background);
}

.ant-radio-wrapper-disabled {
  opacity: 0.32;
}

.ant-radio .ant-radio-inner {
  background-color: var(--color-input-background);
  border-color: var(--color-input-background);
}

.ant-radio-wrapper .ant-radio-disabled .ant-radio-inner {
  background-color: var(--color-input-background);
  border-color: var(--color-input-background);
}

.ant-radio-wrapper .ant-radio-disabled .ant-radio-inner::after {
  background-color: var(--color-input-background);
  border-color: var(--color-input-background);
}

.ant-radio-wrapper:hover .ant-radio:not(.ant-radio-disabled) .ant-radio-wrapper,
.ant-radio-wrapper:hover .ant-radio:not(.ant-radio-disabled) .ant-radio-inner {
  background-color: var(--color-input-background-hover);
  border-color: var(--color-input-background-hover);
}

.ant-radio-wrapper .ant-radio.ant-radio-checked .ant-radio-wrapper,
.ant-radio-wrapper .ant-radio.ant-radio-checked .ant-radio-inner {
  background-color: var(--color-input-active);
  border-color: var(--color-input-active);
}

.ant-radio-wrapper:hover .ant-radio.ant-radio-checked .ant-radio-wrapper,
.ant-radio-wrapper:hover .ant-radio.ant-radio-checked .ant-radio-inner {
  background-color: var(--color-input-active-hover);
  border-color: var(--color-input-active-hover);
}

.ant-radio-wrapper:active .ant-radio.ant-radio-checked .ant-radio-wrapper,
.ant-radio-wrapper:active .ant-radio.ant-radio-checked .ant-radio-inner {
  background-color: var(--color-input-active-press);
  border-color: var(--color-input-active-press);
}

.ant-radio-wrapper .ant-radio-inner::after {
  background-color: var(--color-on-input-active);
}
/* .ant-radio - end */

/* .ant-rate-star - begin */
.ant-rate {
  color: var(--color-input-active);
}

.ant-rate-star .ant-rate-star-first,
.ant-rate-star .ant-rate-star-second {
  color: var(--color-input-background);
}
/* .ant-rate-star - end */

/* .ant-switch - begin */
.ant-switch {
  background-color: var(--color-input-background) !important;
}

.ant-switch-handle::before {
  background-color: var(--color-on-input-background) !important;
}

.ant-switch-checked {
  background-color: var(--color-input-active) !important;
}

.ant-switch-checked .ant-switch-handle::before {
  background-color: var(--color-on-input-active) !important;
}

.ant-switch.ant-switch-loading,
.ant-switch.ant-switch-disabled {
  opacity: 0.32;
}
/* .ant-switch - end */

/* .ant-input-number - begin */
.ant-input-number {
  background-color: var(--color-input-background);
  border: none;
  border-radius: var(--dimension-input-border-radius);
}

.ant-input-number .ant-input-number-input {
  color: var(--color-input-value);
  height: auto;
  line-height: var(--dimension-input-line-height);
  padding: var(--dimension-input-padding-vertical)
    var(--dimension-input-padding-horizontal);
  padding-right: calc(var(--dimension-input-padding-horizontal) + 16px);
}

.ant-input-number .ant-input-number-input::placeholder {
  color: var(--color-on-input-background);
}

.ant-input-number .ant-input-number-handler-wrap {
  background-color: var(--color-input-background);
}

.ant-input-number
  .ant-input-number-handler-wrap
  .ant-input-number-handler:hover {
  background-color: var(--color-input-background-hover);
}

.ant-input-number
  .ant-input-number-handler-wrap
  .ant-input-number-handler:focus {
  background-color: var(--color-input-background-focus);
}

.ant-input-number
  .ant-input-number-handler-wrap
  .ant-input-number-handler:active {
  background-color: var(--color-input-background-press);
}

.ant-input-number .ant-input-number-handler {
  border: none;
}

.ant-input-number .ant-input-number-handler .ant-input-number-handler-up-inner,
.ant-input-number
  .ant-input-number-handler
  .ant-input-number-handler-down-inner {
  color: var(--color-input-value);
}

.ant-input-number
  .ant-input-number-handler:hover
  .ant-input-number-handler-up-inner,
.ant-input-number
  .ant-input-number-handler:hover
  .ant-input-number-handler-down-inner {
  color: var(--color-input-value-hover);
}

.ant-input-number
  .ant-input-number-handler:focus
  .ant-input-number-handler-up-inner,
.ant-input-number
  .ant-input-number-handler:focus
  .ant-input-number-handler-down-inner {
  color: var(--color-input-value-focus);
}

.ant-input-number
  .ant-input-number-handler:active
  .ant-input-number-handler-up-inner,
.ant-input-number
  .ant-input-number-handler:active
  .ant-input-number-handler-down-inner {
  color: var(--color-input-value-press);
}
/* .ant-input-number - end */

/* .ant-picker - begin */
.ant-picker {
  background-color: var(--color-input-background);
  border: none;
  border-radius: var(--dimension-input-border-radius);
  padding: 0 var(--dimension-input-padding-horizontal) 0 0;
}

.ant-picker:hover {
  background-color: var(--color-input-background-hover);
  color: var(--color-input-value);
}

.ant-picker:focus {
  background-color: var(--color-input-background-focus);
  color: var(--color-input-value);
}

.ant-picker:active {
  background-color: var(--color-input-background-press);
  color: var(--color-input-value);
}

.ant-picker .ant-picker-input > input {
  color: var(--color-input-value);
  height: auto;
  line-height: var(--dimension-input-line-height);
  padding: var(--dimension-input-padding-vertical)
    var(--dimension-input-padding-horizontal);
}

.ant-picker .ant-picker-input > input::placeholder {
  color: var(--color-on-input-background);
}

.ant-picker .ant-picker-suffix {
  color: var(--color-on-input-background);
}

.ant-picker .ant-picker-clear {
  background-color: var(--color-input-background);
  color: var(--color-on-input-background);
}

.ant-picker .ant-picker-clear:hover {
  color: var(--color-on-input-background-hover);
}

.ant-picker .ant-picker-clear:focus {
  color: var(--color-on-input-background-focus);
}

.ant-picker .ant-picker-clear:active {
  color: var(--color-on-input-background-press);
}

.ant-picker:hover .ant-picker-clear {
  background-color: var(--color-input-background-hover);
}

.ant-picker:focus .ant-picker-clear {
  background-color: var(--color-input-background-focus);
}

.ant-picker:active .ant-picker-clear {
  background-color: var(--color-input-background-press);
}

.ant-picker-dropdown .ant-picker-panel-container {
  background-color: var(--color-input-background);
  border-radius: var(--dimension-input-border-radius);
  padding: calc(var(--dimension-input-border-radius) / 2)
    var(--dimension-input-border-radius);
}

.ant-picker-dropdown .ant-picker-header {
  color: var(--color-input-value);
  padding: 0;
}

.ant-picker-dropdown .ant-picker-header button {
  color: var(--color-input-value);
}

.ant-picker-dropdown .ant-picker-header button:hover {
  color: var(--color-input-active-hover);
}

.ant-picker-dropdown .ant-picker-header button:focus {
  color: var(--color-input-active-focus);
}

.ant-picker-dropdown .ant-picker-header button:active {
  color: var(--color-input-active-press);
}

.ant-picker-dropdown .ant-picker-date-panel .ant-picker-body {
  padding: 0;
}

.ant-picker-dropdown .ant-picker-content th {
  color: var(--color-input-value);
}

.ant-picker-dropdown .ant-picker-cell .ant-picker-cell-inner {
  border-radius: var(--dimension-input-border-radius);
  color: var(--color-input-value);
  line-height: var(--dimension-input-line-height);
}

.ant-picker-dropdown
  .ant-picker-cell:hover:not(.ant-picker-dropdown .ant-picker-cell-in-view)
  .ant-picker-cell-inner,
.ant-picker-dropdown
  .ant-picker-cell:hover:not(
    .ant-picker-dropdown .ant-picker-cell-selected
  ):not(.ant-picker-dropdown .ant-picker-cell-range-start):not(
    .ant-picker-dropdown .ant-picker-cell-range-end
  ):not(.ant-picker-dropdown .ant-picker-cell-range-hover-start):not(
    .ant-picker-dropdown .ant-picker-cell-range-hover-end
  )
  .ant-picker-cell-inner {
  background-color: var(--color-input-background-hover);
  color: var(--color-input-value-hover);
}

.ant-picker-dropdown
  .ant-picker-cell:focus:not(.ant-picker-dropdown .ant-picker-cell-in-view)
  .ant-picker-cell-inner,
.ant-picker-dropdown
  .ant-picker-cell:focus:not(
    .ant-picker-dropdown .ant-picker-cell-selected
  ):not(.ant-picker-dropdown .ant-picker-cell-range-start):not(
    .ant-picker-dropdown .ant-picker-cell-range-end
  ):not(.ant-picker-dropdown .ant-picker-cell-range-focus-start):not(
    .ant-picker-dropdown .ant-picker-cell-range-focus-end
  )
  .ant-picker-cell-inner {
  background-color: var(--color-input-background-focus);
  color: var(--color-input-value-focus);
}

.ant-picker-dropdown
  .ant-picker-cell:active:not(.ant-picker-dropdown .ant-picker-cell-in-view)
  .ant-picker-cell-inner,
.ant-picker-dropdown
  .ant-picker-cell:active:not(
    .ant-picker-dropdown .ant-picker-cell-selected
  ):not(.ant-picker-dropdown .ant-picker-cell-range-start):not(
    .ant-picker-dropdown .ant-picker-cell-range-end
  ):not(.ant-picker-dropdown .ant-picker-cell-range-active-start):not(
    .ant-picker-dropdown .ant-picker-cell-range-active-end
  )
  .ant-picker-cell-inner {
  background-color: var(--color-input-background-press);
  color: var(--color-input-value-press);
}

.ant-picker-dropdown
  .ant-picker-cell-in-view:is(.ant-picker-dropdown .ant-picker-cell-today):not(
    .ant-picker-dropdown .ant-picker-cell-selected
  ):not(.ant-picker-dropdown .ant-picker-cell-range-start):not(
    .ant-picker-dropdown .ant-picker-cell-range-end
  ):not(.ant-picker-dropdown .ant-picker-cell-range-active-start):not(
    .ant-picker-dropdown .ant-picker-cell-range-active-end
  )
  .ant-picker-cell-inner::before {
  border-color: var(--color-on-input-background);
  border-radius: var(--dimension-input-border-radius);
}

.ant-picker-dropdown
  .ant-picker-cell-in-view:is(.ant-picker-dropdown .ant-picker-cell-today)
  .ant-picker-cell-inner::before {
  border-color: var(--color-input-active);
  border-radius: var(--dimension-input-border-radius);
}

.ant-picker-dropdown
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell
  .ant-picker-time-panel-cell-inner {
  border-radius: var(--dimension-input-border-radius);
  color: var(--color-input-value);
}

.ant-picker-dropdown
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell
  .ant-picker-time-panel-cell-inner:hover {
  background-color: var(--color-input-background-hover);
}

.ant-picker-dropdown
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell
  .ant-picker-time-panel-cell-inner:focus {
  background-color: var(--color-input-background-focus);
}

.ant-picker-dropdown
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell
  .ant-picker-time-panel-cell-inner:active {
  background-color: var(--color-input-background-press);
}

.ant-picker-dropdown
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background-color: var(--color-input-active);
  color: var(--color-on-input-active);
}

.ant-picker-dropdown
  .ant-picker-cell-in-view:is(
    .ant-picker-dropdown .ant-picker-cell-selected
  ):hover
  .ant-picker-cell-inner,
.ant-picker-dropdown
  .ant-picker-cell-in-view:is(
    .ant-picker-dropdown .ant-picker-cell-range-start
  ):hover
  .ant-picker-cell-inner,
.ant-picker-dropdown
  .ant-picker-cell-in-view:is(
    .ant-picker-dropdown .ant-picker-cell-range-end
  ):hover
  .ant-picker-cell-inner {
  background-color: var(--color-input-active-hover);
  color: var(--color-on-input-active-hover);
}

.ant-picker-dropdown
  .ant-picker-cell-in-view:is(
    .ant-picker-dropdown .ant-picker-cell-selected
  ):focus
  .ant-picker-cell-inner,
.ant-picker-dropdown
  .ant-picker-cell-in-view:is(
    .ant-picker-dropdown .ant-picker-cell-range-start
  ):focus
  .ant-picker-cell-inner,
.ant-picker-dropdown
  .ant-picker-cell-in-view:is(
    .ant-picker-dropdown .ant-picker-cell-range-end
  ):focus
  .ant-picker-cell-inner {
  background-color: var(--color-input-active-focus);
  color: var(--color-on-input-active-focus);
}

.ant-picker-dropdown
  .ant-picker-cell-in-view:is(
    .ant-picker-dropdown .ant-picker-cell-selected
  ):active
  .ant-picker-cell-inner,
.ant-picker-dropdown
  .ant-picker-cell-in-view:is(
    .ant-picker-dropdown .ant-picker-cell-range-start
  ):active
  .ant-picker-cell-inner,
.ant-picker-dropdown
  .ant-picker-cell-in-view:is(
    .ant-picker-dropdown .ant-picker-cell-range-end
  ):active
  .ant-picker-cell-inner {
  background-color: var(--color-input-active-press);
  color: var(--color-on-input-active-press);
}

.ant-picker-dropdown
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner:hover {
  background-color: var(--color-input-active-hover);
  color: var(--color-on-input-active-hover);
}

.ant-picker-dropdown
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner:focus {
  background-color: var(--color-input-active-focus);
  color: var(--color-on-input-active-focus);
}

.ant-picker-dropdown
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner:active {
  background-color: var(--color-input-active-press);
  color: var(--color-on-input-active-press);
}

.ant-picker-dropdown
  .ant-picker-cell-in-view:is(.ant-picker-dropdown .ant-picker-cell-selected)
  .ant-picker-cell-inner,
.ant-picker-dropdown
  .ant-picker-cell-in-view:is(.ant-picker-dropdown .ant-picker-cell-range-start)
  .ant-picker-cell-inner,
.ant-picker-dropdown
  .ant-picker-cell-in-view:is(.ant-picker-dropdown .ant-picker-cell-range-end)
  .ant-picker-cell-inner {
  background-color: var(--color-input-active);
  color: var(--color-on-input-active);
}

.ant-picker-dropdown .ant-picker-today-btn {
  color: var(--color-primary);
}

.ant-picker-dropdown .ant-picker-today-btn:hover {
  color: var(--color-primary-hover);
}

.ant-picker-dropdown .ant-picker-today-btn:focus {
  color: var(--color-primary-focus);
}

.ant-picker-dropdown .ant-picker-today-btn:active {
  color: var(--color-primary-press);
}
/* .ant-picker - end */

/* .ant-select - begin */
.ant-select {
  background-color: var(--color-input-background);
  border: none;
  border-radius: var(--dimension-input-border-radius);
  color: var(--color-input-value);
}

.ant-select:hover {
  background-color: var(--color-input-background-hover);
  color: var(--color-input-value);
}

.ant-select:focus {
  background-color: var(--color-input-background-focus);
  color: var(--color-input-value);
}

.ant-select:active {
  background-color: var(--color-input-background-press);
  color: var(--color-input-value);
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent;
  border: none;
  color: inherit;
  height: auto;
  min-height: calc(
    var(--dimension-input-line-height) + var(--dimension-input-padding-vertical) +
      var(--dimension-input-padding-vertical)
  );
  padding: 0;
}

.ant-select .ant-select-arrow {
  color: var(--color-on-input-background);
  right: var(--dimension-input-padding-horizontal);
}

.ant-select .ant-select-clear {
  background-color: var(--color-input-background);
  color: var(--color-on-input-background);
  right: var(--dimension-input-padding-horizontal);
}

.ant-select .ant-select-clear:hover {
  color: var(--color-on-input-background-hover);
}

.ant-select .ant-select-clear:focus {
  color: var(--color-on-input-background-focus);
}

.ant-select .ant-select-clear:active {
  color: var(--color-on-input-background-press);
}

.ant-select:hover .ant-select-clear {
  background-color: var(--color-input-background-hover);
}

.ant-select:focus .ant-select-clear {
  background-color: var(--color-input-background-focus);
}

.ant-select:active .ant-select-clear {
  background-color: var(--color-input-background-press);
}
/* .ant-picker - end */

/* .ant-select-dropdown - begin */
.ant-select-dropdown {
  background-color: var(--color-input-background);
  border-radius: var(--dimension-input-border-radius);
  color: var(--color-input-value);
  padding: calc(var(--dimension-input-border-radius) / 2)
    var(--dimension-input-border-radius);
}

.ant-select-dropdown .ant-select-item-option {
  background-color: var(--color-input-background);
  border-radius: var(--dimension-input-border-radius);
  color: var(--color-input-value);
  line-height: var(--dimension-input-line-height);
  margin: 2px 0;
  padding: calc(var(--dimension-input-padding-vertical) / 2)
    var(--dimension-input-padding-horizontal);
}

.ant-select-dropdown
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: var(--color-input-background-hover);
  color: var(--color-input-value-hover);
}

.ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: var(--color-input-active);
  color: var(--color-on-input-active);
}

.ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  .ant-select-item-option-state {
  color: inherit;
}

.ant-select-dropdown .ant-select-item-option-disabled {
  color: var(--color-input-value-disabled);
}
/* .ant-select-dropdown - end */

/* .ant-select-single - begin */
.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: auto;
  line-height: var(--dimension-input-line-height);
  padding: var(--dimension-input-padding-vertical) 0;
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
  inset-inline-start: var(--dimension-input-padding-horizontal);
  inset-inline-end: var(--dimension-input-padding-horizontal);
  padding-right: 16px;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  color: inherit;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  height: auto;
  line-height: var(--dimension-input-line-height);
  padding: var(--dimension-input-padding-vertical)
    var(--dimension-input-padding-horizontal);
  padding-right: calc(var(--dimension-input-padding-horizontal) + 16px);
}

.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  color: var(--color-on-input-background);
}
/* .ant-select-single - end */

/* .ant-select-multiple - begin */
.ant-select-multiple .ant-select-selection-overflow {
  padding: calc(var(--dimension-input-padding-vertical) / 2)
    calc(var(--dimension-input-padding-horizontal) / 2);
  padding-right: calc(var(--dimension-input-padding-horizontal) + 16px);
}

.ant-select-multiple .ant-select-selection-overflow-item {
  margin-inline-end: calc(var(--dimension-input-padding-horizontal) / 4);
}

.ant-select-multiple .ant-select-selection-placeholder {
  color: var(--color-on-input-background);
  padding-right: calc(var(--dimension-input-padding-horizontal) + 16px);
}

.ant-select-multiple .ant-select-selection-item {
  background-color: var(--color-input-background-press);
  border-color: var(--color-outline);
  border-radius: var(--dimension-input-border-radius);
  height: auto;
  margin: 0;
  padding: calc(var(--dimension-input-padding-vertical) / 2)
    var(--dimension-input-padding-horizontal);
}

.ant-select-multiple .ant-select-selection-item-remove {
  color: var(--color-on-input-background);
  right: var(--dimension-input-padding-horizontal);
}

.ant-select-multiple .ant-select-selection-item-remove:hover {
  color: var(--color-on-input-background-hover);
}

.ant-select-multiple .ant-select-selection-item-remove:focus {
  color: var(--color-on-input-background-focus);
}

.ant-select-multiple .ant-select-selection-item-remove:active {
  color: var(--color-on-input-background-press);
}
/* .ant-select-multiple - end */

/* .ant-upload - begin */
.ant-upload {
  color: var(--color-on-input-background);
}

.ant-upload.ant-upload-drag {
  align-items: center;
  background-color: var(--color-input-background);
  border: none;
  border-radius: var(--dimension-input-border-radius);
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 0;
  transition: all 0.2s;
}

.ant-upload.ant-upload-drag:hover {
  background-color: var(--color-input-background-hover);
}

.ant-upload.ant-upload-drag:focus {
  background-color: var(--color-input-background-focus);
}

.ant-upload.ant-upload-drag:active {
  background-color: var(--color-input-background-press);
}

.ant-upload.ant-upload-drag p.ant-upload-hint {
  color: var(--color-on-input-background);
}

.ant-upload.ant-upload-drag:hover {
  color: var(--color-on-input-background-hover);
}

.ant-upload.ant-upload-drag:focus {
  color: var(--color-on-input-background-focus);
}

.ant-upload.ant-upload-drag:active {
  color: var(--color-on-input-background-press);
}

.ant-upload.ant-upload-drag .ant-upload {
  padding: 0;
}

.ant-upload.ant-upload-drag .ant-upload-drag-icon {
  margin-bottom: var(--space-3x);
}
/* .ant-upload - end */

/* .ant-form-item-explain-error - begin */
.ant-form-item-explain-error {
  color: var(--color-error);
}
/* .ant-form-item-explain-error - end */

/* .ant-slider - begin */
.ant-slider .ant-slider-rail {
  background-color: var(--color-input-background);
}

.ant-slider .ant-slider-track {
  background-color: var(--color-input-active);
}

.ant-slider .ant-slider-handle::after {
  background-color: var(--color-input-active);
  box-shadow: 0 0 0 2px var(--color-on-input-active);
}

.ant-slider:hover .ant-slider-rail {
  background-color: var(--color-input-background);
}

.ant-slider:hover .ant-slider-track {
  background-color: var(--color-input-active-hover);
}

.ant-slider:hover .ant-slider-handle::after {
  background-color: var(--color-input-active-hover);
  box-shadow: 0 0 0 2px var(--color-on-input-active);
}

.ant-slider:focus .ant-slider-track {
  background-color: var(--color-input-active-focus);
}

.ant-slider:focus .ant-slider-handle::after {
  background-color: var(--color-input-active-focus);
  box-shadow: 0 0 0 2px var(--color-on-input-active);
}

.ant-slider:active .ant-slider-track {
  background-color: var(--color-input-active-press);
}

.ant-slider:active .ant-slider-handle::after {
  background-color: var(--color-input-active-press);
  box-shadow: 0 0 0 2px var(--color-on-input-active);
}

.ant-slider .ant-slider-handle:hover::after,
.ant-slider .ant-slider-handle:active::after,
.ant-slider .ant-slider-handle:focus::after {
  box-shadow: 0 0 0 2px var(--color-on-input-active);
}
/* .ant-slider - end */

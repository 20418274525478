.root {
  align-items: center;
  border-color: #d9d9d9;
  border-radius: 24px;
  border-style: solid;
  border-width: 1px;
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
  max-width: 100%;
  padding: 8px 16px;
  width: 384px;
}

.iconInputDevices {
  font-size: 16px;
}

.iconPermissionDenied {
  color: darkred;
  font-size: 12px;
}

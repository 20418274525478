.clickable {
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
}

.button {
  all: unset;
  width: 100%;
}

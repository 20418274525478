.wrapper {
  display: contents;
}

.root {
  margin: var(--dimension-input-padding-vertical) 4px;
}

.inputFullWidth {
  width: calc(100% - 8px);
}

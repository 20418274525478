.root {
  align-items: center;
  background-color: #000000;
  display: flex;
  height: 128px;
  justify-content: center;
  width: 128px;
}

.loading {
  color: #ffffff;
}

.video {
  height: 100%;
  width: 100%;
}

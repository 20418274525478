.root {
  align-items: center;
  display: flex !important;
  flex-direction: row;
  gap: var(--space-gap);
  padding: var(--padding-list-item);
}

.iconContainer {
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: var(--size-icon-button-medium-icon);
  justify-content: center;
  margin: 0 var(--padding-list-item-horizontal);
  width: var(--size-icon-button-medium-icon);
}

.icon {
  border-radius: 50%;
}

.teamContainer {
  flex: 1;
}

.challengeContainer {
  flex: 1;
}

.tagContainer {
  flex: 0 0 128px;
}

.scoreContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  flex: 0 0 96px;
}

.score {
  width: 48px;
}

.iconButton {
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  padding: 0 !important;
  border-radius: 50% !important;
}

.iconButton:not(.small):not(.large) {
  border-radius: var(--shape-icon-button-medium-border-radius);
  height: var(--size-icon-button-medium);
  line-height: var(--size-icon-button-medium);
  min-width: var(--size-icon-button-medium);
}

.iconButton:not(.small):not(.large) > span {
  font-size: var(--size-icon-button-medium-icon);
  height: var(--size-icon-button-medium-icon);
  line-height: var(--size-icon-button-medium-icon);
  min-width: var(--size-icon-button-medium-icon);
}

.small {
  border-radius: var(--shape-icon-button-small-border-radius);
  height: var(--size-icon-button-small);
  line-height: var(--size-icon-button-small);
  min-width: var(--size-icon-button-small);
}

.small > span {
  font-size: var(--size-icon-button-small-icon);
  height: var(--size-icon-button-small-icon);
  line-height: var(--size-icon-button-small-icon);
  min-width: var(--size-icon-button-small-icon);
}

.large {
  border-radius: var(--shape-icon-button-large-border-radius);
  height: var(--size-icon-button-large);
  line-height: var(--size-icon-button-large);
  min-width: var(--size-icon-button-large);
}

.large > span {
  font-size: var(--size-icon-button-large-icon);
  height: var(--size-icon-button-large-icon);
  line-height: var(--size-icon-button-large-icon);
  min-width: var(--size-icon-button-large-icon);
}

.disabled {
  opacity: var(--opacity-disabled) !important;
}

.link {
  background-color: transparent !important;
}

.link:hover {
  background-color: transparent !important;
}

.link:focus {
  background-color: transparent !important;
}

.link:active {
  background-color: transparent !important;
}

.text {
  background-color: transparent !important;
}

.text:hover {
  background-color: transparent !important;
}

.text:focus {
  background-color: transparent !important;
}

.text:active {
  background-color: transparent !important;
}

.link.disabled,
.text.disabled {
  color: var(--color-on-surface-variant) !important;
  opacity: var(--opacity-disabled) !important;
}

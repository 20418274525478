.root {
  display: flex;
  flex-direction: column;
}

.imageContainer {
  position: relative;
}

.image {
  width: 100%;
}

.imageTag {
  bottom: var(--space-gap);
  position: absolute;
  right: var(--space-gap);
}

:root {
  --color-dark-primary: #1d94fa;
  --color-dark-primary-hover: #41a5fb;
  --color-dark-primary-focus: #53aefb;
  --color-dark-primary-press: #53aefb;
  --color-dark-primary-drag: #65b6fc;
  --color-dark-on-primary: #ffffff;
  --color-dark-on-primary-hover: #dbeefe;
  --color-dark-on-primary-focus: #c9e5fe;
  --color-dark-on-primary-press: #c9e5fe;
  --color-dark-on-primary-drag: #b7ddfd;
  --color-dark-primary-container: #c3e7ff;
  --color-dark-primary-container-hover: #a4c7dd;
  --color-dark-primary-container-focus: #94b7cd;
  --color-dark-primary-container-press: #94b7cd;
  --color-dark-primary-container-drag: #85a7bc;
  --color-dark-on-primary-container: #001e2d;
  --color-dark-on-primary-container-hover: #1f3e4f;
  --color-dark-on-primary-container-focus: #2f4e5f;
  --color-dark-on-primary-container-press: #2f4e5f;
  --color-dark-on-primary-container-drag: #3e5e70;
  --color-dark-secondary: #d9308a;
  --color-dark-secondary-hover: #df519d;
  --color-dark-secondary-focus: #e262a6;
  --color-dark-secondary-press: #e262a6;
  --color-dark-secondary-drag: #e572af;
  --color-dark-on-secondary: #ffffff;
  --color-dark-on-secondary-hover: #f9deec;
  --color-dark-on-secondary-focus: #f6cde3;
  --color-dark-on-secondary-press: #f6cde3;
  --color-dark-on-secondary-drag: #f3bdda;
  --color-dark-secondary-container: #ffd8e6;
  --color-dark-secondary-container-hover: #e0b5c6;
  --color-dark-secondary-container-focus: #d1a4b7;
  --color-dark-secondary-container-press: #d1a4b7;
  --color-dark-secondary-container-drag: #c193a7;
  --color-dark-on-secondary-container: #3e0021;
  --color-dark-on-secondary-container-hover: #5d2341;
  --color-dark-on-secondary-container-focus: #6c3450;
  --color-dark-on-secondary-container-press: #6c3450;
  --color-dark-on-secondary-container-drag: #7c4560;
  --color-dark-tertiary: #006b55;
  --color-dark-tertiary-hover: #298370;
  --color-dark-tertiary-focus: #3d8f7e;
  --color-dark-tertiary-press: #3d8f7e;
  --color-dark-tertiary-drag: #529a8b;
  --color-dark-on-tertiary: #ffffff;
  --color-dark-on-tertiary-hover: #d6e7e4;
  --color-dark-on-tertiary-focus: #c2dbd6;
  --color-dark-on-tertiary-press: #c2dbd6;
  --color-dark-on-tertiary-drag: #add0c9;
  --color-dark-tertiary-container: #47fdd3;
  --color-dark-tertiary-container-hover: #3cdab5;
  --color-dark-tertiary-container-focus: #36c8a6;
  --color-dark-tertiary-container-press: #36c8a6;
  --color-dark-tertiary-container-drag: #30b697;
  --color-dark-on-tertiary-container: #002019;
  --color-dark-on-tertiary-container-hover: #0b4337;
  --color-dark-on-tertiary-container-focus: #115546;
  --color-dark-on-tertiary-container-press: #115546;
  --color-dark-on-tertiary-container-drag: #176755;
  --color-dark-error: #b3261e;
  --color-dark-error-hover: #bf4942;
  --color-dark-error-focus: #c55a54;
  --color-dark-error-press: #c55a54;
  --color-dark-error-drag: #cb6b66;
  --color-dark-on-error: #ffffff;
  --color-dark-on-error-hover: #f3dcdb;
  --color-dark-on-error-focus: #edcbc9;
  --color-dark-on-error-press: #edcbc9;
  --color-dark-on-error-drag: #e7bab7;
  --color-dark-error-container: #f9dedc;
  --color-dark-error-container-hover: #dcbdbb;
  --color-dark-error-container-focus: #cdacaa;
  --color-dark-error-container-press: #cdacaa;
  --color-dark-error-container-drag: #be9b99;
  --color-dark-on-error-container: #410e0b;
  --color-dark-on-error-container-hover: #5e2f2c;
  --color-dark-on-error-container-focus: #6d403d;
  --color-dark-on-error-container-press: #6d403d;
  --color-dark-on-error-container-drag: #7c514e;
  --color-dark-background: #171717;
  --color-dark-background-hover: #373838;
  --color-dark-background-focus: #474848;
  --color-dark-background-press: #474848;
  --color-dark-background-drag: #585859;
  --color-dark-on-background: #e1e3e5;
  --color-dark-on-background-hover: #c1c2c4;
  --color-dark-on-background-focus: #b1b2b4;
  --color-dark-on-background-press: #b1b2b4;
  --color-dark-on-background-drag: #a0a2a3;
  --color-dark-outline: #938f99;
  --color-dark-outline-hover: #7f7c84;
  --color-dark-outline-focus: #75727a;
  --color-dark-outline-press: #75727a;
  --color-dark-outline-drag: #6b696f;
  --color-dark-surface: #171717;
  --color-dark-surface-hover: #373838;
  --color-dark-surface-focus: #474848;
  --color-dark-surface-press: #474848;
  --color-dark-surface-drag: #585859;
  --color-dark-on-surface: #e1e3e5;
  --color-dark-on-surface-hover: #c1c2c4;
  --color-dark-on-surface-focus: #b1b2b4;
  --color-dark-on-surface-press: #b1b2b4;
  --color-dark-on-surface-drag: #a0a2a3;
  --color-dark-surface-variant: #49454f;
  --color-dark-surface-variant-hover: #5e5964;
  --color-dark-surface-variant-focus: #68636e;
  --color-dark-surface-variant-press: #68636e;
  --color-dark-surface-variant-drag: #726e78;
  --color-dark-on-surface-variant: #cac4d0;
  --color-dark-on-surface-variant-hover: #b5b0bb;
  --color-dark-on-surface-variant-focus: #aba6b1;
  --color-dark-on-surface-variant-press: #aba6b1;
  --color-dark-on-surface-variant-drag: #a19ba7;
  --color-dark-surface-1: #212121;
  --color-dark-surface-1-hover: #404040;
  --color-dark-surface-1-focus: #4f5050;
  --color-dark-surface-1-press: #4f5050;
  --color-dark-surface-1-drag: #5e5f60;
  --color-dark-surface-2: #272727;
  --color-dark-surface-2-hover: #454545;
  --color-dark-surface-2-focus: #545455;
  --color-dark-surface-2-press: #545455;
  --color-dark-surface-2-drag: #636364;
  --color-dark-surface-3: #2d2d2e;
  --color-dark-surface-3-hover: #4a4a4b;
  --color-dark-surface-3-focus: #58595a;
  --color-dark-surface-3-press: #58595a;
  --color-dark-surface-3-drag: #676769;
  --color-dark-surface-4: #2f2f30;
  --color-dark-surface-4-hover: #4b4c4d;
  --color-dark-surface-4-focus: #5a5a5b;
  --color-dark-surface-4-press: #5a5a5b;
  --color-dark-surface-4-drag: #68696a;
  --color-dark-surface-5: #333434;
  --color-dark-surface-5-hover: #4f5050;
  --color-dark-surface-5-focus: #5d5e5e;
  --color-dark-surface-5-press: #5d5e5e;
  --color-dark-surface-5-drag: #6b6c6d;
  --color-dark-category-success: #3bb157;
  --color-dark-category-success-hover: #5abd72;
  --color-dark-category-success-focus: #6ac47f;
  --color-dark-category-success-press: #6ac47f;
  --color-dark-category-success-drag: #7aca8d;
  --color-dark-on-category-success: #ffffff;
  --color-dark-on-category-success-hover: #e0f3e4;
  --color-dark-on-category-success-focus: #d0ecd7;
  --color-dark-on-category-success-press: #d0ecd7;
  --color-dark-on-category-success-drag: #c0e6c9;
  --color-dark-category-info: #3b9ab1;
  --color-dark-category-info-hover: #5aaabd;
  --color-dark-category-info-focus: #6ab2c4;
  --color-dark-category-info-press: #6ab2c4;
  --color-dark-category-info-drag: #7abaca;
  --color-dark-on-category-info: #ffffff;
  --color-dark-on-category-info-hover: #e0eff3;
  --color-dark-on-category-info-focus: #d0e7ec;
  --color-dark-on-category-info-press: #d0e7ec;
  --color-dark-on-category-info-drag: #c0dfe6;
  --color-dark-category-warning: #b1963b;
  --color-dark-category-warning-hover: #bda75a;
  --color-dark-category-warning-focus: #c4af6a;
  --color-dark-category-warning-press: #c4af6a;
  --color-dark-category-warning-drag: #cab87a;
  --color-dark-on-category-warning: #ffffff;
  --color-dark-on-category-warning-hover: #f3eee0;
  --color-dark-on-category-warning-focus: #ece6d0;
  --color-dark-on-category-warning-press: #ece6d0;
  --color-dark-on-category-warning-drag: #e6ddc0;
  --color-dark-category-error: #b3261e;
  --color-dark-category-error-hover: #bf4942;
  --color-dark-category-error-focus: #c55a54;
  --color-dark-category-error-press: #c55a54;
  --color-dark-category-error-drag: #cb6b66;
  --color-dark-on-category-error: #ffffff;
  --color-dark-on-category-error-hover: #f3dcdb;
  --color-dark-on-category-error-focus: #edcbc9;
  --color-dark-on-category-error-press: #edcbc9;
  --color-dark-on-category-error-drag: #e7bab7;
  --color-dark-category-a: #000000;
  --color-dark-category-a-hover: #292929;
  --color-dark-category-a-focus: #3d3d3d;
  --color-dark-category-a-press: #3d3d3d;
  --color-dark-category-a-drag: #525252;
  --color-dark-on-category-a: #ffffff;
  --color-dark-on-category-a-hover: #d6d6d6;
  --color-dark-on-category-a-focus: #c2c2c2;
  --color-dark-on-category-a-press: #c2c2c2;
  --color-dark-on-category-a-drag: #adadad;
  --color-dark-category-b: #777777;
  --color-dark-category-b-hover: #8d8d8d;
  --color-dark-category-b-focus: #989898;
  --color-dark-category-b-press: #989898;
  --color-dark-category-b-drag: #a3a3a3;
  --color-dark-on-category-b: #ffffff;
  --color-dark-on-category-b-hover: #e9e9e9;
  --color-dark-on-category-b-focus: #dedede;
  --color-dark-on-category-b-press: #dedede;
  --color-dark-on-category-b-drag: #d3d3d3;
  --color-dark-category-c: #852c72;
  --color-dark-category-c-hover: #994e89;
  --color-dark-category-c-focus: #a25f94;
  --color-dark-category-c-press: #a25f94;
  --color-dark-category-c-drag: #ac709f;
  --color-dark-on-category-c: #ffffff;
  --color-dark-on-category-c-hover: #ebdde8;
  --color-dark-on-category-c-focus: #e2ccdd;
  --color-dark-on-category-c-press: #e2ccdd;
  --color-dark-on-category-c-drag: #d8bbd2;
  --color-dark-category-d: #8834b0;
  --color-dark-category-d-hover: #9b54bd;
  --color-dark-category-d-focus: #a565c3;
  --color-dark-category-d-press: #a565c3;
  --color-dark-category-d-drag: #ae75c9;
  --color-dark-on-category-d: #ffffff;
  --color-dark-on-category-d-hover: #ecdff2;
  --color-dark-on-category-d-focus: #e2ceec;
  --color-dark-on-category-d-press: #e2ceec;
  --color-dark-on-category-d-drag: #d9bee6;
  --color-dark-category-e: #4357b1;
  --color-dark-category-e-hover: #6172bd;
  --color-dark-category-e-focus: #707fc4;
  --color-dark-category-e-press: #707fc4;
  --color-dark-category-e-drag: #7f8dca;
  --color-dark-on-category-e: #ffffff;
  --color-dark-on-category-e-hover: #e1e4f3;
  --color-dark-on-category-e-focus: #d2d7ec;
  --color-dark-on-category-e-press: #d2d7ec;
  --color-dark-on-category-e-drag: #c3c9e6;
  --color-dark-category-f: #b13e3a;
  --color-dark-category-f-hover: #bd5d5a;
  --color-dark-category-f-focus: #c46c69;
  --color-dark-category-f-press: #c46c69;
  --color-dark-category-f-drag: #ca7c79;
  --color-dark-on-category-f: #ffffff;
  --color-dark-on-category-f-hover: #f3e0df;
  --color-dark-on-category-f-focus: #ecd1d0;
  --color-dark-on-category-f-press: #ecd1d0;
  --color-dark-on-category-f-drag: #e6c1c0;
  --color-dark-category-g: #3ca357;
  --color-dark-category-g-hover: #5bb272;
  --color-dark-category-g-focus: #6bb97f;
  --color-dark-category-g-press: #6bb97f;
  --color-dark-category-g-drag: #7ac08d;
  --color-dark-on-category-g: #ffffff;
  --color-dark-on-category-g-hover: #e0f0e4;
  --color-dark-on-category-g-focus: #d0e9d7;
  --color-dark-on-category-g-press: #d0e9d7;
  --color-dark-on-category-g-drag: #c1e2c9;
  --color-dark-category-h: #fd953e;
  --color-dark-category-h-hover: #fda65d;
  --color-dark-category-h-focus: #fdae6c;
  --color-dark-category-h-press: #fdae6c;
  --color-dark-category-h-drag: #feb77c;
  --color-dark-on-category-h: #ffffff;
  --color-dark-on-category-h-hover: #ffeee0;
  --color-dark-on-category-h-focus: #ffe6d1;
  --color-dark-on-category-h-press: #ffe6d1;
  --color-dark-on-category-h-drag: #feddc1;
  --color-dark-input-background: #404040;
  --color-dark-input-background-hover: #4b4b4b;
  --color-dark-input-background-focus: #515151;
  --color-dark-input-background-press: #515151;
  --color-dark-input-background-drag: #565656;
  --color-dark-on-input-background: #868686;
  --color-dark-on-input-background-hover: #7b7b7b;
  --color-dark-on-input-background-focus: #757575;
  --color-dark-on-input-background-press: #757575;
  --color-dark-on-input-background-drag: #707070;
  --color-dark-input-active: #c3e7ff;
  --color-dark-input-active-hover: #a4c7dd;
  --color-dark-input-active-focus: #94b7cd;
  --color-dark-input-active-press: #94b7cd;
  --color-dark-input-active-drag: #85a7bc;
  --color-dark-on-input-active: #001e2d;
  --color-dark-on-input-active-hover: #1f3e4f;
  --color-dark-on-input-active-focus: #2f4e5f;
  --color-dark-on-input-active-press: #2f4e5f;
  --color-dark-on-input-active-drag: #3e5e70;
  --color-dark-input-value: #ffffff;
  --color-dark-input-value-hover: #ffffff;
  --color-dark-input-value-focus: #ffffff;
  --color-dark-input-value-press: #ffffff;
  --color-dark-input-value-drag: #ffffff;
  --color-dark-input-value-disabled: #212121;
  --color-dark-input-value-disabled-hover: #212121;
  --color-dark-input-value-disabled-focus: #212121;
  --color-dark-input-value-disabled-press: #212121;
  --color-dark-input-value-disabled-drag: #212121;
  --color-light-primary: #1d94fa;
  --color-light-primary-hover: #41a5fb;
  --color-light-primary-focus: #53aefb;
  --color-light-primary-press: #53aefb;
  --color-light-primary-drag: #65b6fc;
  --color-light-on-primary: #ffffff;
  --color-light-on-primary-hover: #dbeefe;
  --color-light-on-primary-focus: #c9e5fe;
  --color-light-on-primary-press: #c9e5fe;
  --color-light-on-primary-drag: #b7ddfd;
  --color-light-primary-container: #c3e7ff;
  --color-light-primary-container-hover: #a4c7dd;
  --color-light-primary-container-focus: #94b7cd;
  --color-light-primary-container-press: #94b7cd;
  --color-light-primary-container-drag: #85a7bc;
  --color-light-on-primary-container: #001e2d;
  --color-light-on-primary-container-hover: #1f3e4f;
  --color-light-on-primary-container-focus: #2f4e5f;
  --color-light-on-primary-container-press: #2f4e5f;
  --color-light-on-primary-container-drag: #3e5e70;
  --color-light-secondary: #d9308a;
  --color-light-secondary-hover: #df519d;
  --color-light-secondary-focus: #e262a6;
  --color-light-secondary-press: #e262a6;
  --color-light-secondary-drag: #e572af;
  --color-light-on-secondary: #ffffff;
  --color-light-on-secondary-hover: #f9deec;
  --color-light-on-secondary-focus: #f6cde3;
  --color-light-on-secondary-press: #f6cde3;
  --color-light-on-secondary-drag: #f3bdda;
  --color-light-secondary-container: #ffd8e6;
  --color-light-secondary-container-hover: #e0b5c6;
  --color-light-secondary-container-focus: #d1a4b7;
  --color-light-secondary-container-press: #d1a4b7;
  --color-light-secondary-container-drag: #c193a7;
  --color-light-on-secondary-container: #3e0021;
  --color-light-on-secondary-container-hover: #5d2341;
  --color-light-on-secondary-container-focus: #6c3450;
  --color-light-on-secondary-container-press: #6c3450;
  --color-light-on-secondary-container-drag: #7c4560;
  --color-light-tertiary: #006b55;
  --color-light-tertiary-hover: #298370;
  --color-light-tertiary-focus: #3d8f7e;
  --color-light-tertiary-press: #3d8f7e;
  --color-light-tertiary-drag: #529a8b;
  --color-light-on-tertiary: #ffffff;
  --color-light-on-tertiary-hover: #d6e7e4;
  --color-light-on-tertiary-focus: #c2dbd6;
  --color-light-on-tertiary-press: #c2dbd6;
  --color-light-on-tertiary-drag: #add0c9;
  --color-light-tertiary-container: #47fdd3;
  --color-light-tertiary-container-hover: #3cdab5;
  --color-light-tertiary-container-focus: #36c8a6;
  --color-light-tertiary-container-press: #36c8a6;
  --color-light-tertiary-container-drag: #30b697;
  --color-light-on-tertiary-container: #002019;
  --color-light-on-tertiary-container-hover: #0b4337;
  --color-light-on-tertiary-container-focus: #115546;
  --color-light-on-tertiary-container-press: #115546;
  --color-light-on-tertiary-container-drag: #176755;
  --color-light-error: #b3261e;
  --color-light-error-hover: #bf4942;
  --color-light-error-focus: #c55a54;
  --color-light-error-press: #c55a54;
  --color-light-error-drag: #cb6b66;
  --color-light-on-error: #ffffff;
  --color-light-on-error-hover: #f3dcdb;
  --color-light-on-error-focus: #edcbc9;
  --color-light-on-error-press: #edcbc9;
  --color-light-on-error-drag: #e7bab7;
  --color-light-error-container: #f9dedc;
  --color-light-error-container-hover: #dcbdbb;
  --color-light-error-container-focus: #cdacaa;
  --color-light-error-container-press: #cdacaa;
  --color-light-error-container-drag: #be9b99;
  --color-light-on-error-container: #410e0b;
  --color-light-on-error-container-hover: #5e2f2c;
  --color-light-on-error-container-focus: #6d403d;
  --color-light-on-error-container-press: #6d403d;
  --color-light-on-error-container-drag: #7c514e;
  --color-light-background: #171717;
  --color-light-background-hover: #373838;
  --color-light-background-focus: #474848;
  --color-light-background-press: #474848;
  --color-light-background-drag: #585859;
  --color-light-on-background: #e1e3e5;
  --color-light-on-background-hover: #c1c2c4;
  --color-light-on-background-focus: #b1b2b4;
  --color-light-on-background-press: #b1b2b4;
  --color-light-on-background-drag: #a0a2a3;
  --color-light-outline: #938f99;
  --color-light-outline-hover: #7f7c84;
  --color-light-outline-focus: #75727a;
  --color-light-outline-press: #75727a;
  --color-light-outline-drag: #6b696f;
  --color-light-surface: #171717;
  --color-light-surface-hover: #373838;
  --color-light-surface-focus: #474848;
  --color-light-surface-press: #474848;
  --color-light-surface-drag: #585859;
  --color-light-on-surface: #e1e3e5;
  --color-light-on-surface-hover: #c1c2c4;
  --color-light-on-surface-focus: #b1b2b4;
  --color-light-on-surface-press: #b1b2b4;
  --color-light-on-surface-drag: #a0a2a3;
  --color-light-surface-variant: #49454f;
  --color-light-surface-variant-hover: #5e5964;
  --color-light-surface-variant-focus: #68636e;
  --color-light-surface-variant-press: #68636e;
  --color-light-surface-variant-drag: #726e78;
  --color-light-on-surface-variant: #cac4d0;
  --color-light-on-surface-variant-hover: #b5b0bb;
  --color-light-on-surface-variant-focus: #aba6b1;
  --color-light-on-surface-variant-press: #aba6b1;
  --color-light-on-surface-variant-drag: #a19ba7;
  --color-light-surface-1: #212121;
  --color-light-surface-1-hover: #404040;
  --color-light-surface-1-focus: #4f5050;
  --color-light-surface-1-press: #4f5050;
  --color-light-surface-1-drag: #5e5f60;
  --color-light-surface-2: #272727;
  --color-light-surface-2-hover: #454545;
  --color-light-surface-2-focus: #545455;
  --color-light-surface-2-press: #545455;
  --color-light-surface-2-drag: #636364;
  --color-light-surface-3: #2d2d2e;
  --color-light-surface-3-hover: #4a4a4b;
  --color-light-surface-3-focus: #58595a;
  --color-light-surface-3-press: #58595a;
  --color-light-surface-3-drag: #676769;
  --color-light-surface-4: #2f2f30;
  --color-light-surface-4-hover: #4b4c4d;
  --color-light-surface-4-focus: #5a5a5b;
  --color-light-surface-4-press: #5a5a5b;
  --color-light-surface-4-drag: #68696a;
  --color-light-surface-5: #333434;
  --color-light-surface-5-hover: #4f5050;
  --color-light-surface-5-focus: #5d5e5e;
  --color-light-surface-5-press: #5d5e5e;
  --color-light-surface-5-drag: #6b6c6d;
  --color-light-category-success: #3bb157;
  --color-light-category-success-hover: #5abd72;
  --color-light-category-success-focus: #6ac47f;
  --color-light-category-success-press: #6ac47f;
  --color-light-category-success-drag: #7aca8d;
  --color-light-on-category-success: #ffffff;
  --color-light-on-category-success-hover: #e0f3e4;
  --color-light-on-category-success-focus: #d0ecd7;
  --color-light-on-category-success-press: #d0ecd7;
  --color-light-on-category-success-drag: #c0e6c9;
  --color-light-category-info: #3b9ab1;
  --color-light-category-info-hover: #5aaabd;
  --color-light-category-info-focus: #6ab2c4;
  --color-light-category-info-press: #6ab2c4;
  --color-light-category-info-drag: #7abaca;
  --color-light-on-category-info: #ffffff;
  --color-light-on-category-info-hover: #e0eff3;
  --color-light-on-category-info-focus: #d0e7ec;
  --color-light-on-category-info-press: #d0e7ec;
  --color-light-on-category-info-drag: #c0dfe6;
  --color-light-category-warning: #b1963b;
  --color-light-category-warning-hover: #bda75a;
  --color-light-category-warning-focus: #c4af6a;
  --color-light-category-warning-press: #c4af6a;
  --color-light-category-warning-drag: #cab87a;
  --color-light-on-category-warning: #ffffff;
  --color-light-on-category-warning-hover: #f3eee0;
  --color-light-on-category-warning-focus: #ece6d0;
  --color-light-on-category-warning-press: #ece6d0;
  --color-light-on-category-warning-drag: #e6ddc0;
  --color-light-category-error: #b3261e;
  --color-light-category-error-hover: #bf4942;
  --color-light-category-error-focus: #c55a54;
  --color-light-category-error-press: #c55a54;
  --color-light-category-error-drag: #cb6b66;
  --color-light-on-category-error: #ffffff;
  --color-light-on-category-error-hover: #f3dcdb;
  --color-light-on-category-error-focus: #edcbc9;
  --color-light-on-category-error-press: #edcbc9;
  --color-light-on-category-error-drag: #e7bab7;
  --color-light-category-a: #000000;
  --color-light-category-a-hover: #292929;
  --color-light-category-a-focus: #3d3d3d;
  --color-light-category-a-press: #3d3d3d;
  --color-light-category-a-drag: #525252;
  --color-light-on-category-a: #ffffff;
  --color-light-on-category-a-hover: #d6d6d6;
  --color-light-on-category-a-focus: #c2c2c2;
  --color-light-on-category-a-press: #c2c2c2;
  --color-light-on-category-a-drag: #adadad;
  --color-light-category-b: #777777;
  --color-light-category-b-hover: #8d8d8d;
  --color-light-category-b-focus: #989898;
  --color-light-category-b-press: #989898;
  --color-light-category-b-drag: #a3a3a3;
  --color-light-on-category-b: #ffffff;
  --color-light-on-category-b-hover: #e9e9e9;
  --color-light-on-category-b-focus: #dedede;
  --color-light-on-category-b-press: #dedede;
  --color-light-on-category-b-drag: #d3d3d3;
  --color-light-category-c: #852c72;
  --color-light-category-c-hover: #994e89;
  --color-light-category-c-focus: #a25f94;
  --color-light-category-c-press: #a25f94;
  --color-light-category-c-drag: #ac709f;
  --color-light-on-category-c: #ffffff;
  --color-light-on-category-c-hover: #ebdde8;
  --color-light-on-category-c-focus: #e2ccdd;
  --color-light-on-category-c-press: #e2ccdd;
  --color-light-on-category-c-drag: #d8bbd2;
  --color-light-category-d: #8834b0;
  --color-light-category-d-hover: #9b54bd;
  --color-light-category-d-focus: #a565c3;
  --color-light-category-d-press: #a565c3;
  --color-light-category-d-drag: #ae75c9;
  --color-light-on-category-d: #ffffff;
  --color-light-on-category-d-hover: #ecdff2;
  --color-light-on-category-d-focus: #e2ceec;
  --color-light-on-category-d-press: #e2ceec;
  --color-light-on-category-d-drag: #d9bee6;
  --color-light-category-e: #4357b1;
  --color-light-category-e-hover: #6172bd;
  --color-light-category-e-focus: #707fc4;
  --color-light-category-e-press: #707fc4;
  --color-light-category-e-drag: #7f8dca;
  --color-light-on-category-e: #ffffff;
  --color-light-on-category-e-hover: #e1e4f3;
  --color-light-on-category-e-focus: #d2d7ec;
  --color-light-on-category-e-press: #d2d7ec;
  --color-light-on-category-e-drag: #c3c9e6;
  --color-light-category-f: #b13e3a;
  --color-light-category-f-hover: #bd5d5a;
  --color-light-category-f-focus: #c46c69;
  --color-light-category-f-press: #c46c69;
  --color-light-category-f-drag: #ca7c79;
  --color-light-on-category-f: #ffffff;
  --color-light-on-category-f-hover: #f3e0df;
  --color-light-on-category-f-focus: #ecd1d0;
  --color-light-on-category-f-press: #ecd1d0;
  --color-light-on-category-f-drag: #e6c1c0;
  --color-light-category-g: #3ca357;
  --color-light-category-g-hover: #5bb272;
  --color-light-category-g-focus: #6bb97f;
  --color-light-category-g-press: #6bb97f;
  --color-light-category-g-drag: #7ac08d;
  --color-light-on-category-g: #ffffff;
  --color-light-on-category-g-hover: #e0f0e4;
  --color-light-on-category-g-focus: #d0e9d7;
  --color-light-on-category-g-press: #d0e9d7;
  --color-light-on-category-g-drag: #c1e2c9;
  --color-light-category-h: #fd953e;
  --color-light-category-h-hover: #fda65d;
  --color-light-category-h-focus: #fdae6c;
  --color-light-category-h-press: #fdae6c;
  --color-light-category-h-drag: #feb77c;
  --color-light-on-category-h: #ffffff;
  --color-light-on-category-h-hover: #ffeee0;
  --color-light-on-category-h-focus: #ffe6d1;
  --color-light-on-category-h-press: #ffe6d1;
  --color-light-on-category-h-drag: #feddc1;
  --color-light-input-background: #404040;
  --color-light-input-background-hover: #4b4b4b;
  --color-light-input-background-focus: #515151;
  --color-light-input-background-press: #515151;
  --color-light-input-background-drag: #565656;
  --color-light-on-input-background: #868686;
  --color-light-on-input-background-hover: #7b7b7b;
  --color-light-on-input-background-focus: #757575;
  --color-light-on-input-background-press: #757575;
  --color-light-on-input-background-drag: #707070;
  --color-light-input-active: #c3e7ff;
  --color-light-input-active-hover: #a4c7dd;
  --color-light-input-active-focus: #94b7cd;
  --color-light-input-active-press: #94b7cd;
  --color-light-input-active-drag: #85a7bc;
  --color-light-on-input-active: #001e2d;
  --color-light-on-input-active-hover: #1f3e4f;
  --color-light-on-input-active-focus: #2f4e5f;
  --color-light-on-input-active-press: #2f4e5f;
  --color-light-on-input-active-drag: #3e5e70;
  --color-light-input-value: #ffffff;
  --color-light-input-value-hover: #ffffff;
  --color-light-input-value-focus: #ffffff;
  --color-light-input-value-press: #ffffff;
  --color-light-input-value-drag: #ffffff;
  --color-light-input-value-disabled: #212121;
  --color-light-input-value-disabled-hover: #212121;
  --color-light-input-value-disabled-focus: #212121;
  --color-light-input-value-disabled-press: #212121;
  --color-light-input-value-disabled-drag: #212121;
  --dimension-input-border-radius: 8px;
  --dimension-input-line-height: 24px;
  --dimension-input-padding-horizontal: 16px;
  --dimension-input-padding-vertical: 12px;
  --dimension-surface-border-radius: 4px;
  --dimension-surface-padding-horizontal: 16px;
  --dimension-surface-padding-vertical: 12px;
  --typography-display1-font-family: Roboto, sans-serif;
  --typography-display1-height: 76px;
  --typography-display1-weight: 400;
  --typography-display1-size: 64px;
  --typography-display-large-font-family: Roboto, sans-serif;
  --typography-display-large-line-height: 64px;
  --typography-display-large-weight: 400;
  --typography-display-large-size: 57px;
  --typography-display-medium-font-family: Roboto, sans-serif;
  --typography-display-medium-line-height: 52px;
  --typography-display-medium-weight: 400;
  --typography-display-medium-size: 45px;
  --typography-display-small-font-family: Roboto, sans-serif;
  --typography-display-small-line-height: 44px;
  --typography-display-small-weight: 400;
  --typography-display-small-size: 36px;
  --typography-headline-large-font-family: Roboto, sans-serif;
  --typography-headline-large-line-height: 40px;
  --typography-headline-large-weight: 400;
  --typography-headline-large-size: 32px;
  --typography-headline-medium-font-family: Roboto, sans-serif;
  --typography-headline-medium-line-height: 36px;
  --typography-headline-medium-weight: 400;
  --typography-headline-medium-size: 28px;
  --typography-headline-small-font-family: Roboto, sans-serif;
  --typography-headline-small-line-height: 32px;
  --typography-headline-small-weight: 400;
  --typography-headline-small-size: 24px;
  --typography-title-large-font-family: Roboto, sans-serif;
  --typography-title-large-line-height: 28px;
  --typography-title-large-weight: 400;
  --typography-title-large-size: 22px;
  --typography-headline6-font-family: Roboto, sans-serif;
  --typography-headline6-height: 24px;
  --typography-headline6-weight: 400;
  --typography-headline6-size: 18px;
  --typography-title-medium-font-family: Roboto, sans-serif;
  --typography-title-medium-line-height: 24px;
  --typography-title-medium-weight: 500;
  --typography-title-medium-size: 16px;
  --typography-title-small-font-family: Roboto, sans-serif;
  --typography-title-small-line-height: 20px;
  --typography-title-small-weight: 500;
  --typography-title-small-size: 14px;
  --typography-label-large-font-family: Roboto, sans-serif;
  --typography-label-large-line-height: 20px;
  --typography-label-large-weight: 500;
  --typography-label-large-size: 16px;
  --typography-body-large-font-family: Roboto, sans-serif;
  --typography-body-large-line-height: 24px;
  --typography-body-large-weight: 400;
  --typography-body-large-size: 16px;
  --typography-body-medium-font-family: Roboto, sans-serif;
  --typography-body-medium-line-height: 20px;
  --typography-body-medium-weight: 400;
  --typography-body-medium-size: 14px;
  --typography-body-small-font-family: Roboto, sans-serif;
  --typography-body-small-line-height: 16px;
  --typography-body-small-weight: 400;
  --typography-body-small-size: 12px;
  --typography-label-medium-font-family: Roboto, sans-serif;
  --typography-label-medium-line-height: 16px;
  --typography-label-medium-weight: 500;
  --typography-label-medium-size: 14px;
  --typography-label-small-font-family: Roboto, sans-serif;
  --typography-label-small-line-height: 16px;
  --typography-label-small-weight: 500;
  --typography-label-small-size: 12px;
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-primary: var(--color-dark-primary);
    --color-primary-hover: var(--color-dark-primary-hover);
    --color-primary-focus: var(--color-dark-primary-focus);
    --color-primary-press: var(--color-dark-primary-press);
    --color-primary-drag: var(--color-dark-primary-drag);
    --color-on-primary: var(--color-dark-on-primary);
    --color-on-primary-hover: var(--color-dark-on-primary-hover);
    --color-on-primary-focus: var(--color-dark-on-primary-focus);
    --color-on-primary-press: var(--color-dark-on-primary-press);
    --color-on-primary-drag: var(--color-dark-on-primary-drag);
    --color-primary-container: var(--color-dark-primary-container);
    --color-primary-container-hover: var(--color-dark-primary-container-hover);
    --color-primary-container-focus: var(--color-dark-primary-container-focus);
    --color-primary-container-press: var(--color-dark-primary-container-press);
    --color-primary-container-drag: var(--color-dark-primary-container-drag);
    --color-on-primary-container: var(--color-dark-on-primary-container);
    --color-on-primary-container-hover: var(
      --color-dark-on-primary-container-hover
    );
    --color-on-primary-container-focus: var(
      --color-dark-on-primary-container-focus
    );
    --color-on-primary-container-press: var(
      --color-dark-on-primary-container-press
    );
    --color-on-primary-container-drag: var(
      --color-dark-on-primary-container-drag
    );
    --color-secondary: var(--color-dark-secondary);
    --color-secondary-hover: var(--color-dark-secondary-hover);
    --color-secondary-focus: var(--color-dark-secondary-focus);
    --color-secondary-press: var(--color-dark-secondary-press);
    --color-secondary-drag: var(--color-dark-secondary-drag);
    --color-on-secondary: var(--color-dark-on-secondary);
    --color-on-secondary-hover: var(--color-dark-on-secondary-hover);
    --color-on-secondary-focus: var(--color-dark-on-secondary-focus);
    --color-on-secondary-press: var(--color-dark-on-secondary-press);
    --color-on-secondary-drag: var(--color-dark-on-secondary-drag);
    --color-secondary-container: var(--color-dark-secondary-container);
    --color-secondary-container-hover: var(
      --color-dark-secondary-container-hover
    );
    --color-secondary-container-focus: var(
      --color-dark-secondary-container-focus
    );
    --color-secondary-container-press: var(
      --color-dark-secondary-container-press
    );
    --color-secondary-container-drag: var(
      --color-dark-secondary-container-drag
    );
    --color-on-secondary-container: var(--color-dark-on-secondary-container);
    --color-on-secondary-container-hover: var(
      --color-dark-on-secondary-container-hover
    );
    --color-on-secondary-container-focus: var(
      --color-dark-on-secondary-container-focus
    );
    --color-on-secondary-container-press: var(
      --color-dark-on-secondary-container-press
    );
    --color-on-secondary-container-drag: var(
      --color-dark-on-secondary-container-drag
    );
    --color-tertiary: var(--color-dark-tertiary);
    --color-tertiary-hover: var(--color-dark-tertiary-hover);
    --color-tertiary-focus: var(--color-dark-tertiary-focus);
    --color-tertiary-press: var(--color-dark-tertiary-press);
    --color-tertiary-drag: var(--color-dark-tertiary-drag);
    --color-on-tertiary: var(--color-dark-on-tertiary);
    --color-on-tertiary-hover: var(--color-dark-on-tertiary-hover);
    --color-on-tertiary-focus: var(--color-dark-on-tertiary-focus);
    --color-on-tertiary-press: var(--color-dark-on-tertiary-press);
    --color-on-tertiary-drag: var(--color-dark-on-tertiary-drag);
    --color-tertiary-container: var(--color-dark-tertiary-container);
    --color-tertiary-container-hover: var(
      --color-dark-tertiary-container-hover
    );
    --color-tertiary-container-focus: var(
      --color-dark-tertiary-container-focus
    );
    --color-tertiary-container-press: var(
      --color-dark-tertiary-container-press
    );
    --color-tertiary-container-drag: var(--color-dark-tertiary-container-drag);
    --color-on-tertiary-container: var(--color-dark-on-tertiary-container);
    --color-on-tertiary-container-hover: var(
      --color-dark-on-tertiary-container-hover
    );
    --color-on-tertiary-container-focus: var(
      --color-dark-on-tertiary-container-focus
    );
    --color-on-tertiary-container-press: var(
      --color-dark-on-tertiary-container-press
    );
    --color-on-tertiary-container-drag: var(
      --color-dark-on-tertiary-container-drag
    );
    --color-error: var(--color-dark-error);
    --color-error-hover: var(--color-dark-error-hover);
    --color-error-focus: var(--color-dark-error-focus);
    --color-error-press: var(--color-dark-error-press);
    --color-error-drag: var(--color-dark-error-drag);
    --color-on-error: var(--color-dark-on-error);
    --color-on-error-hover: var(--color-dark-on-error-hover);
    --color-on-error-focus: var(--color-dark-on-error-focus);
    --color-on-error-press: var(--color-dark-on-error-press);
    --color-on-error-drag: var(--color-dark-on-error-drag);
    --color-error-container: var(--color-dark-error-container);
    --color-error-container-hover: var(--color-dark-error-container-hover);
    --color-error-container-focus: var(--color-dark-error-container-focus);
    --color-error-container-press: var(--color-dark-error-container-press);
    --color-error-container-drag: var(--color-dark-error-container-drag);
    --color-on-error-container: var(--color-dark-on-error-container);
    --color-on-error-container-hover: var(
      --color-dark-on-error-container-hover
    );
    --color-on-error-container-focus: var(
      --color-dark-on-error-container-focus
    );
    --color-on-error-container-press: var(
      --color-dark-on-error-container-press
    );
    --color-on-error-container-drag: var(--color-dark-on-error-container-drag);
    --color-background: var(--color-dark-background);
    --color-background-hover: var(--color-dark-background-hover);
    --color-background-focus: var(--color-dark-background-focus);
    --color-background-press: var(--color-dark-background-press);
    --color-background-drag: var(--color-dark-background-drag);
    --color-on-background: var(--color-dark-on-background);
    --color-on-background-hover: var(--color-dark-on-background-hover);
    --color-on-background-focus: var(--color-dark-on-background-focus);
    --color-on-background-press: var(--color-dark-on-background-press);
    --color-on-background-drag: var(--color-dark-on-background-drag);
    --color-outline: var(--color-dark-outline);
    --color-outline-hover: var(--color-dark-outline-hover);
    --color-outline-focus: var(--color-dark-outline-focus);
    --color-outline-press: var(--color-dark-outline-press);
    --color-outline-drag: var(--color-dark-outline-drag);
    --color-surface: var(--color-dark-surface);
    --color-surface-hover: var(--color-dark-surface-hover);
    --color-surface-focus: var(--color-dark-surface-focus);
    --color-surface-press: var(--color-dark-surface-press);
    --color-surface-drag: var(--color-dark-surface-drag);
    --color-on-surface: var(--color-dark-on-surface);
    --color-on-surface-hover: var(--color-dark-on-surface-hover);
    --color-on-surface-focus: var(--color-dark-on-surface-focus);
    --color-on-surface-press: var(--color-dark-on-surface-press);
    --color-on-surface-drag: var(--color-dark-on-surface-drag);
    --color-surface-variant: var(--color-dark-surface-variant);
    --color-surface-variant-hover: var(--color-dark-surface-variant-hover);
    --color-surface-variant-focus: var(--color-dark-surface-variant-focus);
    --color-surface-variant-press: var(--color-dark-surface-variant-press);
    --color-surface-variant-drag: var(--color-dark-surface-variant-drag);
    --color-on-surface-variant: var(--color-dark-on-surface-variant);
    --color-on-surface-variant-hover: var(
      --color-dark-on-surface-variant-hover
    );
    --color-on-surface-variant-focus: var(
      --color-dark-on-surface-variant-focus
    );
    --color-on-surface-variant-press: var(
      --color-dark-on-surface-variant-press
    );
    --color-on-surface-variant-drag: var(--color-dark-on-surface-variant-drag);
    --color-surface-1: var(--color-dark-surface-1);
    --color-surface-1-hover: var(--color-dark-surface-1-hover);
    --color-surface-1-focus: var(--color-dark-surface-1-focus);
    --color-surface-1-press: var(--color-dark-surface-1-press);
    --color-surface-1-drag: var(--color-dark-surface-1-drag);
    --color-surface-2: var(--color-dark-surface-2);
    --color-surface-2-hover: var(--color-dark-surface-2-hover);
    --color-surface-2-focus: var(--color-dark-surface-2-focus);
    --color-surface-2-press: var(--color-dark-surface-2-press);
    --color-surface-2-drag: var(--color-dark-surface-2-drag);
    --color-surface-3: var(--color-dark-surface-3);
    --color-surface-3-hover: var(--color-dark-surface-3-hover);
    --color-surface-3-focus: var(--color-dark-surface-3-focus);
    --color-surface-3-press: var(--color-dark-surface-3-press);
    --color-surface-3-drag: var(--color-dark-surface-3-drag);
    --color-surface-4: var(--color-dark-surface-4);
    --color-surface-4-hover: var(--color-dark-surface-4-hover);
    --color-surface-4-focus: var(--color-dark-surface-4-focus);
    --color-surface-4-press: var(--color-dark-surface-4-press);
    --color-surface-4-drag: var(--color-dark-surface-4-drag);
    --color-surface-5: var(--color-dark-surface-5);
    --color-surface-5-hover: var(--color-dark-surface-5-hover);
    --color-surface-5-focus: var(--color-dark-surface-5-focus);
    --color-surface-5-press: var(--color-dark-surface-5-press);
    --color-surface-5-drag: var(--color-dark-surface-5-drag);
    --color-category-success: var(--color-dark-category-success);
    --color-category-success-hover: var(--color-dark-category-success-hover);
    --color-category-success-focus: var(--color-dark-category-success-focus);
    --color-category-success-press: var(--color-dark-category-success-press);
    --color-category-success-drag: var(--color-dark-category-success-drag);
    --color-on-category-success: var(--color-dark-on-category-success);
    --color-on-category-success-hover: var(
      --color-dark-on-category-success-hover
    );
    --color-on-category-success-focus: var(
      --color-dark-on-category-success-focus
    );
    --color-on-category-success-press: var(
      --color-dark-on-category-success-press
    );
    --color-on-category-success-drag: var(
      --color-dark-on-category-success-drag
    );
    --color-category-info: var(--color-dark-category-info);
    --color-category-info-hover: var(--color-dark-category-info-hover);
    --color-category-info-focus: var(--color-dark-category-info-focus);
    --color-category-info-press: var(--color-dark-category-info-press);
    --color-category-info-drag: var(--color-dark-category-info-drag);
    --color-on-category-info: var(--color-dark-on-category-info);
    --color-on-category-info-hover: var(--color-dark-on-category-info-hover);
    --color-on-category-info-focus: var(--color-dark-on-category-info-focus);
    --color-on-category-info-press: var(--color-dark-on-category-info-press);
    --color-on-category-info-drag: var(--color-dark-on-category-info-drag);
    --color-category-warning: var(--color-dark-category-warning);
    --color-category-warning-hover: var(--color-dark-category-warning-hover);
    --color-category-warning-focus: var(--color-dark-category-warning-focus);
    --color-category-warning-press: var(--color-dark-category-warning-press);
    --color-category-warning-drag: var(--color-dark-category-warning-drag);
    --color-on-category-warning: var(--color-dark-on-category-warning);
    --color-on-category-warning-hover: var(
      --color-dark-on-category-warning-hover
    );
    --color-on-category-warning-focus: var(
      --color-dark-on-category-warning-focus
    );
    --color-on-category-warning-press: var(
      --color-dark-on-category-warning-press
    );
    --color-on-category-warning-drag: var(
      --color-dark-on-category-warning-drag
    );
    --color-category-error: var(--color-dark-category-error);
    --color-category-error-hover: var(--color-dark-category-error-hover);
    --color-category-error-focus: var(--color-dark-category-error-focus);
    --color-category-error-press: var(--color-dark-category-error-press);
    --color-category-error-drag: var(--color-dark-category-error-drag);
    --color-on-category-error: var(--color-dark-on-category-error);
    --color-on-category-error-hover: var(--color-dark-on-category-error-hover);
    --color-on-category-error-focus: var(--color-dark-on-category-error-focus);
    --color-on-category-error-press: var(--color-dark-on-category-error-press);
    --color-on-category-error-drag: var(--color-dark-on-category-error-drag);
    --color-category-a: var(--color-dark-category-a);
    --color-category-a-hover: var(--color-dark-category-a-hover);
    --color-category-a-focus: var(--color-dark-category-a-focus);
    --color-category-a-press: var(--color-dark-category-a-press);
    --color-category-a-drag: var(--color-dark-category-a-drag);
    --color-on-category-a: var(--color-dark-on-category-a);
    --color-on-category-a-hover: var(--color-dark-on-category-a-hover);
    --color-on-category-a-focus: var(--color-dark-on-category-a-focus);
    --color-on-category-a-press: var(--color-dark-on-category-a-press);
    --color-on-category-a-drag: var(--color-dark-on-category-a-drag);
    --color-category-b: var(--color-dark-category-b);
    --color-category-b-hover: var(--color-dark-category-b-hover);
    --color-category-b-focus: var(--color-dark-category-b-focus);
    --color-category-b-press: var(--color-dark-category-b-press);
    --color-category-b-drag: var(--color-dark-category-b-drag);
    --color-on-category-b: var(--color-dark-on-category-b);
    --color-on-category-b-hover: var(--color-dark-on-category-b-hover);
    --color-on-category-b-focus: var(--color-dark-on-category-b-focus);
    --color-on-category-b-press: var(--color-dark-on-category-b-press);
    --color-on-category-b-drag: var(--color-dark-on-category-b-drag);
    --color-category-c: var(--color-dark-category-c);
    --color-category-c-hover: var(--color-dark-category-c-hover);
    --color-category-c-focus: var(--color-dark-category-c-focus);
    --color-category-c-press: var(--color-dark-category-c-press);
    --color-category-c-drag: var(--color-dark-category-c-drag);
    --color-on-category-c: var(--color-dark-on-category-c);
    --color-on-category-c-hover: var(--color-dark-on-category-c-hover);
    --color-on-category-c-focus: var(--color-dark-on-category-c-focus);
    --color-on-category-c-press: var(--color-dark-on-category-c-press);
    --color-on-category-c-drag: var(--color-dark-on-category-c-drag);
    --color-category-d: var(--color-dark-category-d);
    --color-category-d-hover: var(--color-dark-category-d-hover);
    --color-category-d-focus: var(--color-dark-category-d-focus);
    --color-category-d-press: var(--color-dark-category-d-press);
    --color-category-d-drag: var(--color-dark-category-d-drag);
    --color-on-category-d: var(--color-dark-on-category-d);
    --color-on-category-d-hover: var(--color-dark-on-category-d-hover);
    --color-on-category-d-focus: var(--color-dark-on-category-d-focus);
    --color-on-category-d-press: var(--color-dark-on-category-d-press);
    --color-on-category-d-drag: var(--color-dark-on-category-d-drag);
    --color-category-e: var(--color-dark-category-e);
    --color-category-e-hover: var(--color-dark-category-e-hover);
    --color-category-e-focus: var(--color-dark-category-e-focus);
    --color-category-e-press: var(--color-dark-category-e-press);
    --color-category-e-drag: var(--color-dark-category-e-drag);
    --color-on-category-e: var(--color-dark-on-category-e);
    --color-on-category-e-hover: var(--color-dark-on-category-e-hover);
    --color-on-category-e-focus: var(--color-dark-on-category-e-focus);
    --color-on-category-e-press: var(--color-dark-on-category-e-press);
    --color-on-category-e-drag: var(--color-dark-on-category-e-drag);
    --color-category-f: var(--color-dark-category-f);
    --color-category-f-hover: var(--color-dark-category-f-hover);
    --color-category-f-focus: var(--color-dark-category-f-focus);
    --color-category-f-press: var(--color-dark-category-f-press);
    --color-category-f-drag: var(--color-dark-category-f-drag);
    --color-on-category-f: var(--color-dark-on-category-f);
    --color-on-category-f-hover: var(--color-dark-on-category-f-hover);
    --color-on-category-f-focus: var(--color-dark-on-category-f-focus);
    --color-on-category-f-press: var(--color-dark-on-category-f-press);
    --color-on-category-f-drag: var(--color-dark-on-category-f-drag);
    --color-category-g: var(--color-dark-category-g);
    --color-category-g-hover: var(--color-dark-category-g-hover);
    --color-category-g-focus: var(--color-dark-category-g-focus);
    --color-category-g-press: var(--color-dark-category-g-press);
    --color-category-g-drag: var(--color-dark-category-g-drag);
    --color-on-category-g: var(--color-dark-on-category-g);
    --color-on-category-g-hover: var(--color-dark-on-category-g-hover);
    --color-on-category-g-focus: var(--color-dark-on-category-g-focus);
    --color-on-category-g-press: var(--color-dark-on-category-g-press);
    --color-on-category-g-drag: var(--color-dark-on-category-g-drag);
    --color-category-h: var(--color-dark-category-h);
    --color-category-h-hover: var(--color-dark-category-h-hover);
    --color-category-h-focus: var(--color-dark-category-h-focus);
    --color-category-h-press: var(--color-dark-category-h-press);
    --color-category-h-drag: var(--color-dark-category-h-drag);
    --color-on-category-h: var(--color-dark-on-category-h);
    --color-on-category-h-hover: var(--color-dark-on-category-h-hover);
    --color-on-category-h-focus: var(--color-dark-on-category-h-focus);
    --color-on-category-h-press: var(--color-dark-on-category-h-press);
    --color-on-category-h-drag: var(--color-dark-on-category-h-drag);
    --color-input-background: var(--color-dark-input-background);
    --color-input-background-hover: var(--color-dark-input-background-hover);
    --color-input-background-focus: var(--color-dark-input-background-focus);
    --color-input-background-press: var(--color-dark-input-background-press);
    --color-input-background-drag: var(--color-dark-input-background-drag);
    --color-on-input-background: var(--color-dark-on-input-background);
    --color-on-input-background-hover: var(
      --color-dark-on-input-background-hover
    );
    --color-on-input-background-focus: var(
      --color-dark-on-input-background-focus
    );
    --color-on-input-background-press: var(
      --color-dark-on-input-background-press
    );
    --color-on-input-background-drag: var(
      --color-dark-on-input-background-drag
    );
    --color-input-active: var(--color-dark-input-active);
    --color-input-active-hover: var(--color-dark-input-active-hover);
    --color-input-active-focus: var(--color-dark-input-active-focus);
    --color-input-active-press: var(--color-dark-input-active-press);
    --color-input-active-drag: var(--color-dark-input-active-drag);
    --color-on-input-active: var(--color-dark-on-input-active);
    --color-on-input-active-hover: var(--color-dark-on-input-active-hover);
    --color-on-input-active-focus: var(--color-dark-on-input-active-focus);
    --color-on-input-active-press: var(--color-dark-on-input-active-press);
    --color-on-input-active-drag: var(--color-dark-on-input-active-drag);
    --color-input-value: var(--color-dark-input-value);
    --color-input-value-hover: var(--color-dark-input-value-hover);
    --color-input-value-focus: var(--color-dark-input-value-focus);
    --color-input-value-press: var(--color-dark-input-value-press);
    --color-input-value-drag: var(--color-dark-input-value-drag);
    --color-input-value-disabled: var(--color-dark-input-value-disabled);
    --color-input-value-disabled-hover: var(
      --color-dark-input-value-disabled-hover
    );
    --color-input-value-disabled-focus: var(
      --color-dark-input-value-disabled-focus
    );
    --color-input-value-disabled-press: var(
      --color-dark-input-value-disabled-press
    );
    --color-input-value-disabled-drag: var(
      --color-dark-input-value-disabled-drag
    );
  }

  .dark-scheme {
    --color-primary: var(--color-dark-primary);
    --color-primary-hover: var(--color-dark-primary-hover);
    --color-primary-focus: var(--color-dark-primary-focus);
    --color-primary-press: var(--color-dark-primary-press);
    --color-primary-drag: var(--color-dark-primary-drag);
    --color-on-primary: var(--color-dark-on-primary);
    --color-on-primary-hover: var(--color-dark-on-primary-hover);
    --color-on-primary-focus: var(--color-dark-on-primary-focus);
    --color-on-primary-press: var(--color-dark-on-primary-press);
    --color-on-primary-drag: var(--color-dark-on-primary-drag);
    --color-primary-container: var(--color-dark-primary-container);
    --color-primary-container-hover: var(--color-dark-primary-container-hover);
    --color-primary-container-focus: var(--color-dark-primary-container-focus);
    --color-primary-container-press: var(--color-dark-primary-container-press);
    --color-primary-container-drag: var(--color-dark-primary-container-drag);
    --color-on-primary-container: var(--color-dark-on-primary-container);
    --color-on-primary-container-hover: var(
      --color-dark-on-primary-container-hover
    );
    --color-on-primary-container-focus: var(
      --color-dark-on-primary-container-focus
    );
    --color-on-primary-container-press: var(
      --color-dark-on-primary-container-press
    );
    --color-on-primary-container-drag: var(
      --color-dark-on-primary-container-drag
    );
    --color-secondary: var(--color-dark-secondary);
    --color-secondary-hover: var(--color-dark-secondary-hover);
    --color-secondary-focus: var(--color-dark-secondary-focus);
    --color-secondary-press: var(--color-dark-secondary-press);
    --color-secondary-drag: var(--color-dark-secondary-drag);
    --color-on-secondary: var(--color-dark-on-secondary);
    --color-on-secondary-hover: var(--color-dark-on-secondary-hover);
    --color-on-secondary-focus: var(--color-dark-on-secondary-focus);
    --color-on-secondary-press: var(--color-dark-on-secondary-press);
    --color-on-secondary-drag: var(--color-dark-on-secondary-drag);
    --color-secondary-container: var(--color-dark-secondary-container);
    --color-secondary-container-hover: var(
      --color-dark-secondary-container-hover
    );
    --color-secondary-container-focus: var(
      --color-dark-secondary-container-focus
    );
    --color-secondary-container-press: var(
      --color-dark-secondary-container-press
    );
    --color-secondary-container-drag: var(
      --color-dark-secondary-container-drag
    );
    --color-on-secondary-container: var(--color-dark-on-secondary-container);
    --color-on-secondary-container-hover: var(
      --color-dark-on-secondary-container-hover
    );
    --color-on-secondary-container-focus: var(
      --color-dark-on-secondary-container-focus
    );
    --color-on-secondary-container-press: var(
      --color-dark-on-secondary-container-press
    );
    --color-on-secondary-container-drag: var(
      --color-dark-on-secondary-container-drag
    );
    --color-tertiary: var(--color-dark-tertiary);
    --color-tertiary-hover: var(--color-dark-tertiary-hover);
    --color-tertiary-focus: var(--color-dark-tertiary-focus);
    --color-tertiary-press: var(--color-dark-tertiary-press);
    --color-tertiary-drag: var(--color-dark-tertiary-drag);
    --color-on-tertiary: var(--color-dark-on-tertiary);
    --color-on-tertiary-hover: var(--color-dark-on-tertiary-hover);
    --color-on-tertiary-focus: var(--color-dark-on-tertiary-focus);
    --color-on-tertiary-press: var(--color-dark-on-tertiary-press);
    --color-on-tertiary-drag: var(--color-dark-on-tertiary-drag);
    --color-tertiary-container: var(--color-dark-tertiary-container);
    --color-tertiary-container-hover: var(
      --color-dark-tertiary-container-hover
    );
    --color-tertiary-container-focus: var(
      --color-dark-tertiary-container-focus
    );
    --color-tertiary-container-press: var(
      --color-dark-tertiary-container-press
    );
    --color-tertiary-container-drag: var(--color-dark-tertiary-container-drag);
    --color-on-tertiary-container: var(--color-dark-on-tertiary-container);
    --color-on-tertiary-container-hover: var(
      --color-dark-on-tertiary-container-hover
    );
    --color-on-tertiary-container-focus: var(
      --color-dark-on-tertiary-container-focus
    );
    --color-on-tertiary-container-press: var(
      --color-dark-on-tertiary-container-press
    );
    --color-on-tertiary-container-drag: var(
      --color-dark-on-tertiary-container-drag
    );
    --color-error: var(--color-dark-error);
    --color-error-hover: var(--color-dark-error-hover);
    --color-error-focus: var(--color-dark-error-focus);
    --color-error-press: var(--color-dark-error-press);
    --color-error-drag: var(--color-dark-error-drag);
    --color-on-error: var(--color-dark-on-error);
    --color-on-error-hover: var(--color-dark-on-error-hover);
    --color-on-error-focus: var(--color-dark-on-error-focus);
    --color-on-error-press: var(--color-dark-on-error-press);
    --color-on-error-drag: var(--color-dark-on-error-drag);
    --color-error-container: var(--color-dark-error-container);
    --color-error-container-hover: var(--color-dark-error-container-hover);
    --color-error-container-focus: var(--color-dark-error-container-focus);
    --color-error-container-press: var(--color-dark-error-container-press);
    --color-error-container-drag: var(--color-dark-error-container-drag);
    --color-on-error-container: var(--color-dark-on-error-container);
    --color-on-error-container-hover: var(
      --color-dark-on-error-container-hover
    );
    --color-on-error-container-focus: var(
      --color-dark-on-error-container-focus
    );
    --color-on-error-container-press: var(
      --color-dark-on-error-container-press
    );
    --color-on-error-container-drag: var(--color-dark-on-error-container-drag);
    --color-background: var(--color-dark-background);
    --color-background-hover: var(--color-dark-background-hover);
    --color-background-focus: var(--color-dark-background-focus);
    --color-background-press: var(--color-dark-background-press);
    --color-background-drag: var(--color-dark-background-drag);
    --color-on-background: var(--color-dark-on-background);
    --color-on-background-hover: var(--color-dark-on-background-hover);
    --color-on-background-focus: var(--color-dark-on-background-focus);
    --color-on-background-press: var(--color-dark-on-background-press);
    --color-on-background-drag: var(--color-dark-on-background-drag);
    --color-outline: var(--color-dark-outline);
    --color-outline-hover: var(--color-dark-outline-hover);
    --color-outline-focus: var(--color-dark-outline-focus);
    --color-outline-press: var(--color-dark-outline-press);
    --color-outline-drag: var(--color-dark-outline-drag);
    --color-surface: var(--color-dark-surface);
    --color-surface-hover: var(--color-dark-surface-hover);
    --color-surface-focus: var(--color-dark-surface-focus);
    --color-surface-press: var(--color-dark-surface-press);
    --color-surface-drag: var(--color-dark-surface-drag);
    --color-on-surface: var(--color-dark-on-surface);
    --color-on-surface-hover: var(--color-dark-on-surface-hover);
    --color-on-surface-focus: var(--color-dark-on-surface-focus);
    --color-on-surface-press: var(--color-dark-on-surface-press);
    --color-on-surface-drag: var(--color-dark-on-surface-drag);
    --color-surface-variant: var(--color-dark-surface-variant);
    --color-surface-variant-hover: var(--color-dark-surface-variant-hover);
    --color-surface-variant-focus: var(--color-dark-surface-variant-focus);
    --color-surface-variant-press: var(--color-dark-surface-variant-press);
    --color-surface-variant-drag: var(--color-dark-surface-variant-drag);
    --color-on-surface-variant: var(--color-dark-on-surface-variant);
    --color-on-surface-variant-hover: var(
      --color-dark-on-surface-variant-hover
    );
    --color-on-surface-variant-focus: var(
      --color-dark-on-surface-variant-focus
    );
    --color-on-surface-variant-press: var(
      --color-dark-on-surface-variant-press
    );
    --color-on-surface-variant-drag: var(--color-dark-on-surface-variant-drag);
    --color-surface-1: var(--color-dark-surface-1);
    --color-surface-1-hover: var(--color-dark-surface-1-hover);
    --color-surface-1-focus: var(--color-dark-surface-1-focus);
    --color-surface-1-press: var(--color-dark-surface-1-press);
    --color-surface-1-drag: var(--color-dark-surface-1-drag);
    --color-surface-2: var(--color-dark-surface-2);
    --color-surface-2-hover: var(--color-dark-surface-2-hover);
    --color-surface-2-focus: var(--color-dark-surface-2-focus);
    --color-surface-2-press: var(--color-dark-surface-2-press);
    --color-surface-2-drag: var(--color-dark-surface-2-drag);
    --color-surface-3: var(--color-dark-surface-3);
    --color-surface-3-hover: var(--color-dark-surface-3-hover);
    --color-surface-3-focus: var(--color-dark-surface-3-focus);
    --color-surface-3-press: var(--color-dark-surface-3-press);
    --color-surface-3-drag: var(--color-dark-surface-3-drag);
    --color-surface-4: var(--color-dark-surface-4);
    --color-surface-4-hover: var(--color-dark-surface-4-hover);
    --color-surface-4-focus: var(--color-dark-surface-4-focus);
    --color-surface-4-press: var(--color-dark-surface-4-press);
    --color-surface-4-drag: var(--color-dark-surface-4-drag);
    --color-surface-5: var(--color-dark-surface-5);
    --color-surface-5-hover: var(--color-dark-surface-5-hover);
    --color-surface-5-focus: var(--color-dark-surface-5-focus);
    --color-surface-5-press: var(--color-dark-surface-5-press);
    --color-surface-5-drag: var(--color-dark-surface-5-drag);
    --color-category-success: var(--color-dark-category-success);
    --color-category-success-hover: var(--color-dark-category-success-hover);
    --color-category-success-focus: var(--color-dark-category-success-focus);
    --color-category-success-press: var(--color-dark-category-success-press);
    --color-category-success-drag: var(--color-dark-category-success-drag);
    --color-on-category-success: var(--color-dark-on-category-success);
    --color-on-category-success-hover: var(
      --color-dark-on-category-success-hover
    );
    --color-on-category-success-focus: var(
      --color-dark-on-category-success-focus
    );
    --color-on-category-success-press: var(
      --color-dark-on-category-success-press
    );
    --color-on-category-success-drag: var(
      --color-dark-on-category-success-drag
    );
    --color-category-info: var(--color-dark-category-info);
    --color-category-info-hover: var(--color-dark-category-info-hover);
    --color-category-info-focus: var(--color-dark-category-info-focus);
    --color-category-info-press: var(--color-dark-category-info-press);
    --color-category-info-drag: var(--color-dark-category-info-drag);
    --color-on-category-info: var(--color-dark-on-category-info);
    --color-on-category-info-hover: var(--color-dark-on-category-info-hover);
    --color-on-category-info-focus: var(--color-dark-on-category-info-focus);
    --color-on-category-info-press: var(--color-dark-on-category-info-press);
    --color-on-category-info-drag: var(--color-dark-on-category-info-drag);
    --color-category-warning: var(--color-dark-category-warning);
    --color-category-warning-hover: var(--color-dark-category-warning-hover);
    --color-category-warning-focus: var(--color-dark-category-warning-focus);
    --color-category-warning-press: var(--color-dark-category-warning-press);
    --color-category-warning-drag: var(--color-dark-category-warning-drag);
    --color-on-category-warning: var(--color-dark-on-category-warning);
    --color-on-category-warning-hover: var(
      --color-dark-on-category-warning-hover
    );
    --color-on-category-warning-focus: var(
      --color-dark-on-category-warning-focus
    );
    --color-on-category-warning-press: var(
      --color-dark-on-category-warning-press
    );
    --color-on-category-warning-drag: var(
      --color-dark-on-category-warning-drag
    );
    --color-category-error: var(--color-dark-category-error);
    --color-category-error-hover: var(--color-dark-category-error-hover);
    --color-category-error-focus: var(--color-dark-category-error-focus);
    --color-category-error-press: var(--color-dark-category-error-press);
    --color-category-error-drag: var(--color-dark-category-error-drag);
    --color-on-category-error: var(--color-dark-on-category-error);
    --color-on-category-error-hover: var(--color-dark-on-category-error-hover);
    --color-on-category-error-focus: var(--color-dark-on-category-error-focus);
    --color-on-category-error-press: var(--color-dark-on-category-error-press);
    --color-on-category-error-drag: var(--color-dark-on-category-error-drag);
    --color-category-a: var(--color-dark-category-a);
    --color-category-a-hover: var(--color-dark-category-a-hover);
    --color-category-a-focus: var(--color-dark-category-a-focus);
    --color-category-a-press: var(--color-dark-category-a-press);
    --color-category-a-drag: var(--color-dark-category-a-drag);
    --color-on-category-a: var(--color-dark-on-category-a);
    --color-on-category-a-hover: var(--color-dark-on-category-a-hover);
    --color-on-category-a-focus: var(--color-dark-on-category-a-focus);
    --color-on-category-a-press: var(--color-dark-on-category-a-press);
    --color-on-category-a-drag: var(--color-dark-on-category-a-drag);
    --color-category-b: var(--color-dark-category-b);
    --color-category-b-hover: var(--color-dark-category-b-hover);
    --color-category-b-focus: var(--color-dark-category-b-focus);
    --color-category-b-press: var(--color-dark-category-b-press);
    --color-category-b-drag: var(--color-dark-category-b-drag);
    --color-on-category-b: var(--color-dark-on-category-b);
    --color-on-category-b-hover: var(--color-dark-on-category-b-hover);
    --color-on-category-b-focus: var(--color-dark-on-category-b-focus);
    --color-on-category-b-press: var(--color-dark-on-category-b-press);
    --color-on-category-b-drag: var(--color-dark-on-category-b-drag);
    --color-category-c: var(--color-dark-category-c);
    --color-category-c-hover: var(--color-dark-category-c-hover);
    --color-category-c-focus: var(--color-dark-category-c-focus);
    --color-category-c-press: var(--color-dark-category-c-press);
    --color-category-c-drag: var(--color-dark-category-c-drag);
    --color-on-category-c: var(--color-dark-on-category-c);
    --color-on-category-c-hover: var(--color-dark-on-category-c-hover);
    --color-on-category-c-focus: var(--color-dark-on-category-c-focus);
    --color-on-category-c-press: var(--color-dark-on-category-c-press);
    --color-on-category-c-drag: var(--color-dark-on-category-c-drag);
    --color-category-d: var(--color-dark-category-d);
    --color-category-d-hover: var(--color-dark-category-d-hover);
    --color-category-d-focus: var(--color-dark-category-d-focus);
    --color-category-d-press: var(--color-dark-category-d-press);
    --color-category-d-drag: var(--color-dark-category-d-drag);
    --color-on-category-d: var(--color-dark-on-category-d);
    --color-on-category-d-hover: var(--color-dark-on-category-d-hover);
    --color-on-category-d-focus: var(--color-dark-on-category-d-focus);
    --color-on-category-d-press: var(--color-dark-on-category-d-press);
    --color-on-category-d-drag: var(--color-dark-on-category-d-drag);
    --color-category-e: var(--color-dark-category-e);
    --color-category-e-hover: var(--color-dark-category-e-hover);
    --color-category-e-focus: var(--color-dark-category-e-focus);
    --color-category-e-press: var(--color-dark-category-e-press);
    --color-category-e-drag: var(--color-dark-category-e-drag);
    --color-on-category-e: var(--color-dark-on-category-e);
    --color-on-category-e-hover: var(--color-dark-on-category-e-hover);
    --color-on-category-e-focus: var(--color-dark-on-category-e-focus);
    --color-on-category-e-press: var(--color-dark-on-category-e-press);
    --color-on-category-e-drag: var(--color-dark-on-category-e-drag);
    --color-category-f: var(--color-dark-category-f);
    --color-category-f-hover: var(--color-dark-category-f-hover);
    --color-category-f-focus: var(--color-dark-category-f-focus);
    --color-category-f-press: var(--color-dark-category-f-press);
    --color-category-f-drag: var(--color-dark-category-f-drag);
    --color-on-category-f: var(--color-dark-on-category-f);
    --color-on-category-f-hover: var(--color-dark-on-category-f-hover);
    --color-on-category-f-focus: var(--color-dark-on-category-f-focus);
    --color-on-category-f-press: var(--color-dark-on-category-f-press);
    --color-on-category-f-drag: var(--color-dark-on-category-f-drag);
    --color-category-g: var(--color-dark-category-g);
    --color-category-g-hover: var(--color-dark-category-g-hover);
    --color-category-g-focus: var(--color-dark-category-g-focus);
    --color-category-g-press: var(--color-dark-category-g-press);
    --color-category-g-drag: var(--color-dark-category-g-drag);
    --color-on-category-g: var(--color-dark-on-category-g);
    --color-on-category-g-hover: var(--color-dark-on-category-g-hover);
    --color-on-category-g-focus: var(--color-dark-on-category-g-focus);
    --color-on-category-g-press: var(--color-dark-on-category-g-press);
    --color-on-category-g-drag: var(--color-dark-on-category-g-drag);
    --color-category-h: var(--color-dark-category-h);
    --color-category-h-hover: var(--color-dark-category-h-hover);
    --color-category-h-focus: var(--color-dark-category-h-focus);
    --color-category-h-press: var(--color-dark-category-h-press);
    --color-category-h-drag: var(--color-dark-category-h-drag);
    --color-on-category-h: var(--color-dark-on-category-h);
    --color-on-category-h-hover: var(--color-dark-on-category-h-hover);
    --color-on-category-h-focus: var(--color-dark-on-category-h-focus);
    --color-on-category-h-press: var(--color-dark-on-category-h-press);
    --color-on-category-h-drag: var(--color-dark-on-category-h-drag);
    --color-input-background: var(--color-dark-input-background);
    --color-input-background-hover: var(--color-dark-input-background-hover);
    --color-input-background-focus: var(--color-dark-input-background-focus);
    --color-input-background-press: var(--color-dark-input-background-press);
    --color-input-background-drag: var(--color-dark-input-background-drag);
    --color-on-input-background: var(--color-dark-on-input-background);
    --color-on-input-background-hover: var(
      --color-dark-on-input-background-hover
    );
    --color-on-input-background-focus: var(
      --color-dark-on-input-background-focus
    );
    --color-on-input-background-press: var(
      --color-dark-on-input-background-press
    );
    --color-on-input-background-drag: var(
      --color-dark-on-input-background-drag
    );
    --color-input-active: var(--color-dark-input-active);
    --color-input-active-hover: var(--color-dark-input-active-hover);
    --color-input-active-focus: var(--color-dark-input-active-focus);
    --color-input-active-press: var(--color-dark-input-active-press);
    --color-input-active-drag: var(--color-dark-input-active-drag);
    --color-on-input-active: var(--color-dark-on-input-active);
    --color-on-input-active-hover: var(--color-dark-on-input-active-hover);
    --color-on-input-active-focus: var(--color-dark-on-input-active-focus);
    --color-on-input-active-press: var(--color-dark-on-input-active-press);
    --color-on-input-active-drag: var(--color-dark-on-input-active-drag);
    --color-input-value: var(--color-dark-input-value);
    --color-input-value-hover: var(--color-dark-input-value-hover);
    --color-input-value-focus: var(--color-dark-input-value-focus);
    --color-input-value-press: var(--color-dark-input-value-press);
    --color-input-value-drag: var(--color-dark-input-value-drag);
    --color-input-value-disabled: var(--color-dark-input-value-disabled);
    --color-input-value-disabled-hover: var(
      --color-dark-input-value-disabled-hover
    );
    --color-input-value-disabled-focus: var(
      --color-dark-input-value-disabled-focus
    );
    --color-input-value-disabled-press: var(
      --color-dark-input-value-disabled-press
    );
    --color-input-value-disabled-drag: var(
      --color-dark-input-value-disabled-drag
    );
  }

  .light-scheme {
    --color-primary: var(--color-light-primary);
    --color-primary-hover: var(--color-light-primary-hover);
    --color-primary-focus: var(--color-light-primary-focus);
    --color-primary-press: var(--color-light-primary-press);
    --color-primary-drag: var(--color-light-primary-drag);
    --color-on-primary: var(--color-light-on-primary);
    --color-on-primary-hover: var(--color-light-on-primary-hover);
    --color-on-primary-focus: var(--color-light-on-primary-focus);
    --color-on-primary-press: var(--color-light-on-primary-press);
    --color-on-primary-drag: var(--color-light-on-primary-drag);
    --color-primary-container: var(--color-light-primary-container);
    --color-primary-container-hover: var(--color-light-primary-container-hover);
    --color-primary-container-focus: var(--color-light-primary-container-focus);
    --color-primary-container-press: var(--color-light-primary-container-press);
    --color-primary-container-drag: var(--color-light-primary-container-drag);
    --color-on-primary-container: var(--color-light-on-primary-container);
    --color-on-primary-container-hover: var(
      --color-light-on-primary-container-hover
    );
    --color-on-primary-container-focus: var(
      --color-light-on-primary-container-focus
    );
    --color-on-primary-container-press: var(
      --color-light-on-primary-container-press
    );
    --color-on-primary-container-drag: var(
      --color-light-on-primary-container-drag
    );
    --color-secondary: var(--color-light-secondary);
    --color-secondary-hover: var(--color-light-secondary-hover);
    --color-secondary-focus: var(--color-light-secondary-focus);
    --color-secondary-press: var(--color-light-secondary-press);
    --color-secondary-drag: var(--color-light-secondary-drag);
    --color-on-secondary: var(--color-light-on-secondary);
    --color-on-secondary-hover: var(--color-light-on-secondary-hover);
    --color-on-secondary-focus: var(--color-light-on-secondary-focus);
    --color-on-secondary-press: var(--color-light-on-secondary-press);
    --color-on-secondary-drag: var(--color-light-on-secondary-drag);
    --color-secondary-container: var(--color-light-secondary-container);
    --color-secondary-container-hover: var(
      --color-light-secondary-container-hover
    );
    --color-secondary-container-focus: var(
      --color-light-secondary-container-focus
    );
    --color-secondary-container-press: var(
      --color-light-secondary-container-press
    );
    --color-secondary-container-drag: var(
      --color-light-secondary-container-drag
    );
    --color-on-secondary-container: var(--color-light-on-secondary-container);
    --color-on-secondary-container-hover: var(
      --color-light-on-secondary-container-hover
    );
    --color-on-secondary-container-focus: var(
      --color-light-on-secondary-container-focus
    );
    --color-on-secondary-container-press: var(
      --color-light-on-secondary-container-press
    );
    --color-on-secondary-container-drag: var(
      --color-light-on-secondary-container-drag
    );
    --color-tertiary: var(--color-light-tertiary);
    --color-tertiary-hover: var(--color-light-tertiary-hover);
    --color-tertiary-focus: var(--color-light-tertiary-focus);
    --color-tertiary-press: var(--color-light-tertiary-press);
    --color-tertiary-drag: var(--color-light-tertiary-drag);
    --color-on-tertiary: var(--color-light-on-tertiary);
    --color-on-tertiary-hover: var(--color-light-on-tertiary-hover);
    --color-on-tertiary-focus: var(--color-light-on-tertiary-focus);
    --color-on-tertiary-press: var(--color-light-on-tertiary-press);
    --color-on-tertiary-drag: var(--color-light-on-tertiary-drag);
    --color-tertiary-container: var(--color-light-tertiary-container);
    --color-tertiary-container-hover: var(
      --color-light-tertiary-container-hover
    );
    --color-tertiary-container-focus: var(
      --color-light-tertiary-container-focus
    );
    --color-tertiary-container-press: var(
      --color-light-tertiary-container-press
    );
    --color-tertiary-container-drag: var(--color-light-tertiary-container-drag);
    --color-on-tertiary-container: var(--color-light-on-tertiary-container);
    --color-on-tertiary-container-hover: var(
      --color-light-on-tertiary-container-hover
    );
    --color-on-tertiary-container-focus: var(
      --color-light-on-tertiary-container-focus
    );
    --color-on-tertiary-container-press: var(
      --color-light-on-tertiary-container-press
    );
    --color-on-tertiary-container-drag: var(
      --color-light-on-tertiary-container-drag
    );
    --color-error: var(--color-light-error);
    --color-error-hover: var(--color-light-error-hover);
    --color-error-focus: var(--color-light-error-focus);
    --color-error-press: var(--color-light-error-press);
    --color-error-drag: var(--color-light-error-drag);
    --color-on-error: var(--color-light-on-error);
    --color-on-error-hover: var(--color-light-on-error-hover);
    --color-on-error-focus: var(--color-light-on-error-focus);
    --color-on-error-press: var(--color-light-on-error-press);
    --color-on-error-drag: var(--color-light-on-error-drag);
    --color-error-container: var(--color-light-error-container);
    --color-error-container-hover: var(--color-light-error-container-hover);
    --color-error-container-focus: var(--color-light-error-container-focus);
    --color-error-container-press: var(--color-light-error-container-press);
    --color-error-container-drag: var(--color-light-error-container-drag);
    --color-on-error-container: var(--color-light-on-error-container);
    --color-on-error-container-hover: var(
      --color-light-on-error-container-hover
    );
    --color-on-error-container-focus: var(
      --color-light-on-error-container-focus
    );
    --color-on-error-container-press: var(
      --color-light-on-error-container-press
    );
    --color-on-error-container-drag: var(--color-light-on-error-container-drag);
    --color-background: var(--color-light-background);
    --color-background-hover: var(--color-light-background-hover);
    --color-background-focus: var(--color-light-background-focus);
    --color-background-press: var(--color-light-background-press);
    --color-background-drag: var(--color-light-background-drag);
    --color-on-background: var(--color-light-on-background);
    --color-on-background-hover: var(--color-light-on-background-hover);
    --color-on-background-focus: var(--color-light-on-background-focus);
    --color-on-background-press: var(--color-light-on-background-press);
    --color-on-background-drag: var(--color-light-on-background-drag);
    --color-outline: var(--color-light-outline);
    --color-outline-hover: var(--color-light-outline-hover);
    --color-outline-focus: var(--color-light-outline-focus);
    --color-outline-press: var(--color-light-outline-press);
    --color-outline-drag: var(--color-light-outline-drag);
    --color-surface: var(--color-light-surface);
    --color-surface-hover: var(--color-light-surface-hover);
    --color-surface-focus: var(--color-light-surface-focus);
    --color-surface-press: var(--color-light-surface-press);
    --color-surface-drag: var(--color-light-surface-drag);
    --color-on-surface: var(--color-light-on-surface);
    --color-on-surface-hover: var(--color-light-on-surface-hover);
    --color-on-surface-focus: var(--color-light-on-surface-focus);
    --color-on-surface-press: var(--color-light-on-surface-press);
    --color-on-surface-drag: var(--color-light-on-surface-drag);
    --color-surface-variant: var(--color-light-surface-variant);
    --color-surface-variant-hover: var(--color-light-surface-variant-hover);
    --color-surface-variant-focus: var(--color-light-surface-variant-focus);
    --color-surface-variant-press: var(--color-light-surface-variant-press);
    --color-surface-variant-drag: var(--color-light-surface-variant-drag);
    --color-on-surface-variant: var(--color-light-on-surface-variant);
    --color-on-surface-variant-hover: var(
      --color-light-on-surface-variant-hover
    );
    --color-on-surface-variant-focus: var(
      --color-light-on-surface-variant-focus
    );
    --color-on-surface-variant-press: var(
      --color-light-on-surface-variant-press
    );
    --color-on-surface-variant-drag: var(--color-light-on-surface-variant-drag);
    --color-surface-1: var(--color-light-surface-1);
    --color-surface-1-hover: var(--color-light-surface-1-hover);
    --color-surface-1-focus: var(--color-light-surface-1-focus);
    --color-surface-1-press: var(--color-light-surface-1-press);
    --color-surface-1-drag: var(--color-light-surface-1-drag);
    --color-surface-2: var(--color-light-surface-2);
    --color-surface-2-hover: var(--color-light-surface-2-hover);
    --color-surface-2-focus: var(--color-light-surface-2-focus);
    --color-surface-2-press: var(--color-light-surface-2-press);
    --color-surface-2-drag: var(--color-light-surface-2-drag);
    --color-surface-3: var(--color-light-surface-3);
    --color-surface-3-hover: var(--color-light-surface-3-hover);
    --color-surface-3-focus: var(--color-light-surface-3-focus);
    --color-surface-3-press: var(--color-light-surface-3-press);
    --color-surface-3-drag: var(--color-light-surface-3-drag);
    --color-surface-4: var(--color-light-surface-4);
    --color-surface-4-hover: var(--color-light-surface-4-hover);
    --color-surface-4-focus: var(--color-light-surface-4-focus);
    --color-surface-4-press: var(--color-light-surface-4-press);
    --color-surface-4-drag: var(--color-light-surface-4-drag);
    --color-surface-5: var(--color-light-surface-5);
    --color-surface-5-hover: var(--color-light-surface-5-hover);
    --color-surface-5-focus: var(--color-light-surface-5-focus);
    --color-surface-5-press: var(--color-light-surface-5-press);
    --color-surface-5-drag: var(--color-light-surface-5-drag);
    --color-category-success: var(--color-light-category-success);
    --color-category-success-hover: var(--color-light-category-success-hover);
    --color-category-success-focus: var(--color-light-category-success-focus);
    --color-category-success-press: var(--color-light-category-success-press);
    --color-category-success-drag: var(--color-light-category-success-drag);
    --color-on-category-success: var(--color-light-on-category-success);
    --color-on-category-success-hover: var(
      --color-light-on-category-success-hover
    );
    --color-on-category-success-focus: var(
      --color-light-on-category-success-focus
    );
    --color-on-category-success-press: var(
      --color-light-on-category-success-press
    );
    --color-on-category-success-drag: var(
      --color-light-on-category-success-drag
    );
    --color-category-info: var(--color-light-category-info);
    --color-category-info-hover: var(--color-light-category-info-hover);
    --color-category-info-focus: var(--color-light-category-info-focus);
    --color-category-info-press: var(--color-light-category-info-press);
    --color-category-info-drag: var(--color-light-category-info-drag);
    --color-on-category-info: var(--color-light-on-category-info);
    --color-on-category-info-hover: var(--color-light-on-category-info-hover);
    --color-on-category-info-focus: var(--color-light-on-category-info-focus);
    --color-on-category-info-press: var(--color-light-on-category-info-press);
    --color-on-category-info-drag: var(--color-light-on-category-info-drag);
    --color-category-warning: var(--color-light-category-warning);
    --color-category-warning-hover: var(--color-light-category-warning-hover);
    --color-category-warning-focus: var(--color-light-category-warning-focus);
    --color-category-warning-press: var(--color-light-category-warning-press);
    --color-category-warning-drag: var(--color-light-category-warning-drag);
    --color-on-category-warning: var(--color-light-on-category-warning);
    --color-on-category-warning-hover: var(
      --color-light-on-category-warning-hover
    );
    --color-on-category-warning-focus: var(
      --color-light-on-category-warning-focus
    );
    --color-on-category-warning-press: var(
      --color-light-on-category-warning-press
    );
    --color-on-category-warning-drag: var(
      --color-light-on-category-warning-drag
    );
    --color-category-error: var(--color-light-category-error);
    --color-category-error-hover: var(--color-light-category-error-hover);
    --color-category-error-focus: var(--color-light-category-error-focus);
    --color-category-error-press: var(--color-light-category-error-press);
    --color-category-error-drag: var(--color-light-category-error-drag);
    --color-on-category-error: var(--color-light-on-category-error);
    --color-on-category-error-hover: var(--color-light-on-category-error-hover);
    --color-on-category-error-focus: var(--color-light-on-category-error-focus);
    --color-on-category-error-press: var(--color-light-on-category-error-press);
    --color-on-category-error-drag: var(--color-light-on-category-error-drag);
    --color-category-a: var(--color-light-category-a);
    --color-category-a-hover: var(--color-light-category-a-hover);
    --color-category-a-focus: var(--color-light-category-a-focus);
    --color-category-a-press: var(--color-light-category-a-press);
    --color-category-a-drag: var(--color-light-category-a-drag);
    --color-on-category-a: var(--color-light-on-category-a);
    --color-on-category-a-hover: var(--color-light-on-category-a-hover);
    --color-on-category-a-focus: var(--color-light-on-category-a-focus);
    --color-on-category-a-press: var(--color-light-on-category-a-press);
    --color-on-category-a-drag: var(--color-light-on-category-a-drag);
    --color-category-b: var(--color-light-category-b);
    --color-category-b-hover: var(--color-light-category-b-hover);
    --color-category-b-focus: var(--color-light-category-b-focus);
    --color-category-b-press: var(--color-light-category-b-press);
    --color-category-b-drag: var(--color-light-category-b-drag);
    --color-on-category-b: var(--color-light-on-category-b);
    --color-on-category-b-hover: var(--color-light-on-category-b-hover);
    --color-on-category-b-focus: var(--color-light-on-category-b-focus);
    --color-on-category-b-press: var(--color-light-on-category-b-press);
    --color-on-category-b-drag: var(--color-light-on-category-b-drag);
    --color-category-c: var(--color-light-category-c);
    --color-category-c-hover: var(--color-light-category-c-hover);
    --color-category-c-focus: var(--color-light-category-c-focus);
    --color-category-c-press: var(--color-light-category-c-press);
    --color-category-c-drag: var(--color-light-category-c-drag);
    --color-on-category-c: var(--color-light-on-category-c);
    --color-on-category-c-hover: var(--color-light-on-category-c-hover);
    --color-on-category-c-focus: var(--color-light-on-category-c-focus);
    --color-on-category-c-press: var(--color-light-on-category-c-press);
    --color-on-category-c-drag: var(--color-light-on-category-c-drag);
    --color-category-d: var(--color-light-category-d);
    --color-category-d-hover: var(--color-light-category-d-hover);
    --color-category-d-focus: var(--color-light-category-d-focus);
    --color-category-d-press: var(--color-light-category-d-press);
    --color-category-d-drag: var(--color-light-category-d-drag);
    --color-on-category-d: var(--color-light-on-category-d);
    --color-on-category-d-hover: var(--color-light-on-category-d-hover);
    --color-on-category-d-focus: var(--color-light-on-category-d-focus);
    --color-on-category-d-press: var(--color-light-on-category-d-press);
    --color-on-category-d-drag: var(--color-light-on-category-d-drag);
    --color-category-e: var(--color-light-category-e);
    --color-category-e-hover: var(--color-light-category-e-hover);
    --color-category-e-focus: var(--color-light-category-e-focus);
    --color-category-e-press: var(--color-light-category-e-press);
    --color-category-e-drag: var(--color-light-category-e-drag);
    --color-on-category-e: var(--color-light-on-category-e);
    --color-on-category-e-hover: var(--color-light-on-category-e-hover);
    --color-on-category-e-focus: var(--color-light-on-category-e-focus);
    --color-on-category-e-press: var(--color-light-on-category-e-press);
    --color-on-category-e-drag: var(--color-light-on-category-e-drag);
    --color-category-f: var(--color-light-category-f);
    --color-category-f-hover: var(--color-light-category-f-hover);
    --color-category-f-focus: var(--color-light-category-f-focus);
    --color-category-f-press: var(--color-light-category-f-press);
    --color-category-f-drag: var(--color-light-category-f-drag);
    --color-on-category-f: var(--color-light-on-category-f);
    --color-on-category-f-hover: var(--color-light-on-category-f-hover);
    --color-on-category-f-focus: var(--color-light-on-category-f-focus);
    --color-on-category-f-press: var(--color-light-on-category-f-press);
    --color-on-category-f-drag: var(--color-light-on-category-f-drag);
    --color-category-g: var(--color-light-category-g);
    --color-category-g-hover: var(--color-light-category-g-hover);
    --color-category-g-focus: var(--color-light-category-g-focus);
    --color-category-g-press: var(--color-light-category-g-press);
    --color-category-g-drag: var(--color-light-category-g-drag);
    --color-on-category-g: var(--color-light-on-category-g);
    --color-on-category-g-hover: var(--color-light-on-category-g-hover);
    --color-on-category-g-focus: var(--color-light-on-category-g-focus);
    --color-on-category-g-press: var(--color-light-on-category-g-press);
    --color-on-category-g-drag: var(--color-light-on-category-g-drag);
    --color-category-h: var(--color-light-category-h);
    --color-category-h-hover: var(--color-light-category-h-hover);
    --color-category-h-focus: var(--color-light-category-h-focus);
    --color-category-h-press: var(--color-light-category-h-press);
    --color-category-h-drag: var(--color-light-category-h-drag);
    --color-on-category-h: var(--color-light-on-category-h);
    --color-on-category-h-hover: var(--color-light-on-category-h-hover);
    --color-on-category-h-focus: var(--color-light-on-category-h-focus);
    --color-on-category-h-press: var(--color-light-on-category-h-press);
    --color-on-category-h-drag: var(--color-light-on-category-h-drag);
    --color-input-background: var(--color-light-input-background);
    --color-input-background-hover: var(--color-light-input-background-hover);
    --color-input-background-focus: var(--color-light-input-background-focus);
    --color-input-background-press: var(--color-light-input-background-press);
    --color-input-background-drag: var(--color-light-input-background-drag);
    --color-on-input-background: var(--color-light-on-input-background);
    --color-on-input-background-hover: var(
      --color-light-on-input-background-hover
    );
    --color-on-input-background-focus: var(
      --color-light-on-input-background-focus
    );
    --color-on-input-background-press: var(
      --color-light-on-input-background-press
    );
    --color-on-input-background-drag: var(
      --color-light-on-input-background-drag
    );
    --color-input-active: var(--color-light-input-active);
    --color-input-active-hover: var(--color-light-input-active-hover);
    --color-input-active-focus: var(--color-light-input-active-focus);
    --color-input-active-press: var(--color-light-input-active-press);
    --color-input-active-drag: var(--color-light-input-active-drag);
    --color-on-input-active: var(--color-light-on-input-active);
    --color-on-input-active-hover: var(--color-light-on-input-active-hover);
    --color-on-input-active-focus: var(--color-light-on-input-active-focus);
    --color-on-input-active-press: var(--color-light-on-input-active-press);
    --color-on-input-active-drag: var(--color-light-on-input-active-drag);
    --color-input-value: var(--color-light-input-value);
    --color-input-value-hover: var(--color-light-input-value-hover);
    --color-input-value-focus: var(--color-light-input-value-focus);
    --color-input-value-press: var(--color-light-input-value-press);
    --color-input-value-drag: var(--color-light-input-value-drag);
    --color-input-value-disabled: var(--color-light-input-value-disabled);
    --color-input-value-disabled-hover: var(
      --color-light-input-value-disabled-hover
    );
    --color-input-value-disabled-focus: var(
      --color-light-input-value-disabled-focus
    );
    --color-input-value-disabled-press: var(
      --color-light-input-value-disabled-press
    );
    --color-input-value-disabled-drag: var(
      --color-light-input-value-disabled-drag
    );
  }
}

@media (prefers-color-scheme: light) {
  :root {
    --color-primary: var(--color-light-primary);
    --color-primary-hover: var(--color-light-primary-hover);
    --color-primary-focus: var(--color-light-primary-focus);
    --color-primary-press: var(--color-light-primary-press);
    --color-primary-drag: var(--color-light-primary-drag);
    --color-on-primary: var(--color-light-on-primary);
    --color-on-primary-hover: var(--color-light-on-primary-hover);
    --color-on-primary-focus: var(--color-light-on-primary-focus);
    --color-on-primary-press: var(--color-light-on-primary-press);
    --color-on-primary-drag: var(--color-light-on-primary-drag);
    --color-primary-container: var(--color-light-primary-container);
    --color-primary-container-hover: var(--color-light-primary-container-hover);
    --color-primary-container-focus: var(--color-light-primary-container-focus);
    --color-primary-container-press: var(--color-light-primary-container-press);
    --color-primary-container-drag: var(--color-light-primary-container-drag);
    --color-on-primary-container: var(--color-light-on-primary-container);
    --color-on-primary-container-hover: var(
      --color-light-on-primary-container-hover
    );
    --color-on-primary-container-focus: var(
      --color-light-on-primary-container-focus
    );
    --color-on-primary-container-press: var(
      --color-light-on-primary-container-press
    );
    --color-on-primary-container-drag: var(
      --color-light-on-primary-container-drag
    );
    --color-secondary: var(--color-light-secondary);
    --color-secondary-hover: var(--color-light-secondary-hover);
    --color-secondary-focus: var(--color-light-secondary-focus);
    --color-secondary-press: var(--color-light-secondary-press);
    --color-secondary-drag: var(--color-light-secondary-drag);
    --color-on-secondary: var(--color-light-on-secondary);
    --color-on-secondary-hover: var(--color-light-on-secondary-hover);
    --color-on-secondary-focus: var(--color-light-on-secondary-focus);
    --color-on-secondary-press: var(--color-light-on-secondary-press);
    --color-on-secondary-drag: var(--color-light-on-secondary-drag);
    --color-secondary-container: var(--color-light-secondary-container);
    --color-secondary-container-hover: var(
      --color-light-secondary-container-hover
    );
    --color-secondary-container-focus: var(
      --color-light-secondary-container-focus
    );
    --color-secondary-container-press: var(
      --color-light-secondary-container-press
    );
    --color-secondary-container-drag: var(
      --color-light-secondary-container-drag
    );
    --color-on-secondary-container: var(--color-light-on-secondary-container);
    --color-on-secondary-container-hover: var(
      --color-light-on-secondary-container-hover
    );
    --color-on-secondary-container-focus: var(
      --color-light-on-secondary-container-focus
    );
    --color-on-secondary-container-press: var(
      --color-light-on-secondary-container-press
    );
    --color-on-secondary-container-drag: var(
      --color-light-on-secondary-container-drag
    );
    --color-tertiary: var(--color-light-tertiary);
    --color-tertiary-hover: var(--color-light-tertiary-hover);
    --color-tertiary-focus: var(--color-light-tertiary-focus);
    --color-tertiary-press: var(--color-light-tertiary-press);
    --color-tertiary-drag: var(--color-light-tertiary-drag);
    --color-on-tertiary: var(--color-light-on-tertiary);
    --color-on-tertiary-hover: var(--color-light-on-tertiary-hover);
    --color-on-tertiary-focus: var(--color-light-on-tertiary-focus);
    --color-on-tertiary-press: var(--color-light-on-tertiary-press);
    --color-on-tertiary-drag: var(--color-light-on-tertiary-drag);
    --color-tertiary-container: var(--color-light-tertiary-container);
    --color-tertiary-container-hover: var(
      --color-light-tertiary-container-hover
    );
    --color-tertiary-container-focus: var(
      --color-light-tertiary-container-focus
    );
    --color-tertiary-container-press: var(
      --color-light-tertiary-container-press
    );
    --color-tertiary-container-drag: var(--color-light-tertiary-container-drag);
    --color-on-tertiary-container: var(--color-light-on-tertiary-container);
    --color-on-tertiary-container-hover: var(
      --color-light-on-tertiary-container-hover
    );
    --color-on-tertiary-container-focus: var(
      --color-light-on-tertiary-container-focus
    );
    --color-on-tertiary-container-press: var(
      --color-light-on-tertiary-container-press
    );
    --color-on-tertiary-container-drag: var(
      --color-light-on-tertiary-container-drag
    );
    --color-error: var(--color-light-error);
    --color-error-hover: var(--color-light-error-hover);
    --color-error-focus: var(--color-light-error-focus);
    --color-error-press: var(--color-light-error-press);
    --color-error-drag: var(--color-light-error-drag);
    --color-on-error: var(--color-light-on-error);
    --color-on-error-hover: var(--color-light-on-error-hover);
    --color-on-error-focus: var(--color-light-on-error-focus);
    --color-on-error-press: var(--color-light-on-error-press);
    --color-on-error-drag: var(--color-light-on-error-drag);
    --color-error-container: var(--color-light-error-container);
    --color-error-container-hover: var(--color-light-error-container-hover);
    --color-error-container-focus: var(--color-light-error-container-focus);
    --color-error-container-press: var(--color-light-error-container-press);
    --color-error-container-drag: var(--color-light-error-container-drag);
    --color-on-error-container: var(--color-light-on-error-container);
    --color-on-error-container-hover: var(
      --color-light-on-error-container-hover
    );
    --color-on-error-container-focus: var(
      --color-light-on-error-container-focus
    );
    --color-on-error-container-press: var(
      --color-light-on-error-container-press
    );
    --color-on-error-container-drag: var(--color-light-on-error-container-drag);
    --color-background: var(--color-light-background);
    --color-background-hover: var(--color-light-background-hover);
    --color-background-focus: var(--color-light-background-focus);
    --color-background-press: var(--color-light-background-press);
    --color-background-drag: var(--color-light-background-drag);
    --color-on-background: var(--color-light-on-background);
    --color-on-background-hover: var(--color-light-on-background-hover);
    --color-on-background-focus: var(--color-light-on-background-focus);
    --color-on-background-press: var(--color-light-on-background-press);
    --color-on-background-drag: var(--color-light-on-background-drag);
    --color-outline: var(--color-light-outline);
    --color-outline-hover: var(--color-light-outline-hover);
    --color-outline-focus: var(--color-light-outline-focus);
    --color-outline-press: var(--color-light-outline-press);
    --color-outline-drag: var(--color-light-outline-drag);
    --color-surface: var(--color-light-surface);
    --color-surface-hover: var(--color-light-surface-hover);
    --color-surface-focus: var(--color-light-surface-focus);
    --color-surface-press: var(--color-light-surface-press);
    --color-surface-drag: var(--color-light-surface-drag);
    --color-on-surface: var(--color-light-on-surface);
    --color-on-surface-hover: var(--color-light-on-surface-hover);
    --color-on-surface-focus: var(--color-light-on-surface-focus);
    --color-on-surface-press: var(--color-light-on-surface-press);
    --color-on-surface-drag: var(--color-light-on-surface-drag);
    --color-surface-variant: var(--color-light-surface-variant);
    --color-surface-variant-hover: var(--color-light-surface-variant-hover);
    --color-surface-variant-focus: var(--color-light-surface-variant-focus);
    --color-surface-variant-press: var(--color-light-surface-variant-press);
    --color-surface-variant-drag: var(--color-light-surface-variant-drag);
    --color-on-surface-variant: var(--color-light-on-surface-variant);
    --color-on-surface-variant-hover: var(
      --color-light-on-surface-variant-hover
    );
    --color-on-surface-variant-focus: var(
      --color-light-on-surface-variant-focus
    );
    --color-on-surface-variant-press: var(
      --color-light-on-surface-variant-press
    );
    --color-on-surface-variant-drag: var(--color-light-on-surface-variant-drag);
    --color-surface-1: var(--color-light-surface-1);
    --color-surface-1-hover: var(--color-light-surface-1-hover);
    --color-surface-1-focus: var(--color-light-surface-1-focus);
    --color-surface-1-press: var(--color-light-surface-1-press);
    --color-surface-1-drag: var(--color-light-surface-1-drag);
    --color-surface-2: var(--color-light-surface-2);
    --color-surface-2-hover: var(--color-light-surface-2-hover);
    --color-surface-2-focus: var(--color-light-surface-2-focus);
    --color-surface-2-press: var(--color-light-surface-2-press);
    --color-surface-2-drag: var(--color-light-surface-2-drag);
    --color-surface-3: var(--color-light-surface-3);
    --color-surface-3-hover: var(--color-light-surface-3-hover);
    --color-surface-3-focus: var(--color-light-surface-3-focus);
    --color-surface-3-press: var(--color-light-surface-3-press);
    --color-surface-3-drag: var(--color-light-surface-3-drag);
    --color-surface-4: var(--color-light-surface-4);
    --color-surface-4-hover: var(--color-light-surface-4-hover);
    --color-surface-4-focus: var(--color-light-surface-4-focus);
    --color-surface-4-press: var(--color-light-surface-4-press);
    --color-surface-4-drag: var(--color-light-surface-4-drag);
    --color-surface-5: var(--color-light-surface-5);
    --color-surface-5-hover: var(--color-light-surface-5-hover);
    --color-surface-5-focus: var(--color-light-surface-5-focus);
    --color-surface-5-press: var(--color-light-surface-5-press);
    --color-surface-5-drag: var(--color-light-surface-5-drag);
    --color-category-success: var(--color-light-category-success);
    --color-category-success-hover: var(--color-light-category-success-hover);
    --color-category-success-focus: var(--color-light-category-success-focus);
    --color-category-success-press: var(--color-light-category-success-press);
    --color-category-success-drag: var(--color-light-category-success-drag);
    --color-on-category-success: var(--color-light-on-category-success);
    --color-on-category-success-hover: var(
      --color-light-on-category-success-hover
    );
    --color-on-category-success-focus: var(
      --color-light-on-category-success-focus
    );
    --color-on-category-success-press: var(
      --color-light-on-category-success-press
    );
    --color-on-category-success-drag: var(
      --color-light-on-category-success-drag
    );
    --color-category-info: var(--color-light-category-info);
    --color-category-info-hover: var(--color-light-category-info-hover);
    --color-category-info-focus: var(--color-light-category-info-focus);
    --color-category-info-press: var(--color-light-category-info-press);
    --color-category-info-drag: var(--color-light-category-info-drag);
    --color-on-category-info: var(--color-light-on-category-info);
    --color-on-category-info-hover: var(--color-light-on-category-info-hover);
    --color-on-category-info-focus: var(--color-light-on-category-info-focus);
    --color-on-category-info-press: var(--color-light-on-category-info-press);
    --color-on-category-info-drag: var(--color-light-on-category-info-drag);
    --color-category-warning: var(--color-light-category-warning);
    --color-category-warning-hover: var(--color-light-category-warning-hover);
    --color-category-warning-focus: var(--color-light-category-warning-focus);
    --color-category-warning-press: var(--color-light-category-warning-press);
    --color-category-warning-drag: var(--color-light-category-warning-drag);
    --color-on-category-warning: var(--color-light-on-category-warning);
    --color-on-category-warning-hover: var(
      --color-light-on-category-warning-hover
    );
    --color-on-category-warning-focus: var(
      --color-light-on-category-warning-focus
    );
    --color-on-category-warning-press: var(
      --color-light-on-category-warning-press
    );
    --color-on-category-warning-drag: var(
      --color-light-on-category-warning-drag
    );
    --color-category-error: var(--color-light-category-error);
    --color-category-error-hover: var(--color-light-category-error-hover);
    --color-category-error-focus: var(--color-light-category-error-focus);
    --color-category-error-press: var(--color-light-category-error-press);
    --color-category-error-drag: var(--color-light-category-error-drag);
    --color-on-category-error: var(--color-light-on-category-error);
    --color-on-category-error-hover: var(--color-light-on-category-error-hover);
    --color-on-category-error-focus: var(--color-light-on-category-error-focus);
    --color-on-category-error-press: var(--color-light-on-category-error-press);
    --color-on-category-error-drag: var(--color-light-on-category-error-drag);
    --color-category-a: var(--color-light-category-a);
    --color-category-a-hover: var(--color-light-category-a-hover);
    --color-category-a-focus: var(--color-light-category-a-focus);
    --color-category-a-press: var(--color-light-category-a-press);
    --color-category-a-drag: var(--color-light-category-a-drag);
    --color-on-category-a: var(--color-light-on-category-a);
    --color-on-category-a-hover: var(--color-light-on-category-a-hover);
    --color-on-category-a-focus: var(--color-light-on-category-a-focus);
    --color-on-category-a-press: var(--color-light-on-category-a-press);
    --color-on-category-a-drag: var(--color-light-on-category-a-drag);
    --color-category-b: var(--color-light-category-b);
    --color-category-b-hover: var(--color-light-category-b-hover);
    --color-category-b-focus: var(--color-light-category-b-focus);
    --color-category-b-press: var(--color-light-category-b-press);
    --color-category-b-drag: var(--color-light-category-b-drag);
    --color-on-category-b: var(--color-light-on-category-b);
    --color-on-category-b-hover: var(--color-light-on-category-b-hover);
    --color-on-category-b-focus: var(--color-light-on-category-b-focus);
    --color-on-category-b-press: var(--color-light-on-category-b-press);
    --color-on-category-b-drag: var(--color-light-on-category-b-drag);
    --color-category-c: var(--color-light-category-c);
    --color-category-c-hover: var(--color-light-category-c-hover);
    --color-category-c-focus: var(--color-light-category-c-focus);
    --color-category-c-press: var(--color-light-category-c-press);
    --color-category-c-drag: var(--color-light-category-c-drag);
    --color-on-category-c: var(--color-light-on-category-c);
    --color-on-category-c-hover: var(--color-light-on-category-c-hover);
    --color-on-category-c-focus: var(--color-light-on-category-c-focus);
    --color-on-category-c-press: var(--color-light-on-category-c-press);
    --color-on-category-c-drag: var(--color-light-on-category-c-drag);
    --color-category-d: var(--color-light-category-d);
    --color-category-d-hover: var(--color-light-category-d-hover);
    --color-category-d-focus: var(--color-light-category-d-focus);
    --color-category-d-press: var(--color-light-category-d-press);
    --color-category-d-drag: var(--color-light-category-d-drag);
    --color-on-category-d: var(--color-light-on-category-d);
    --color-on-category-d-hover: var(--color-light-on-category-d-hover);
    --color-on-category-d-focus: var(--color-light-on-category-d-focus);
    --color-on-category-d-press: var(--color-light-on-category-d-press);
    --color-on-category-d-drag: var(--color-light-on-category-d-drag);
    --color-category-e: var(--color-light-category-e);
    --color-category-e-hover: var(--color-light-category-e-hover);
    --color-category-e-focus: var(--color-light-category-e-focus);
    --color-category-e-press: var(--color-light-category-e-press);
    --color-category-e-drag: var(--color-light-category-e-drag);
    --color-on-category-e: var(--color-light-on-category-e);
    --color-on-category-e-hover: var(--color-light-on-category-e-hover);
    --color-on-category-e-focus: var(--color-light-on-category-e-focus);
    --color-on-category-e-press: var(--color-light-on-category-e-press);
    --color-on-category-e-drag: var(--color-light-on-category-e-drag);
    --color-category-f: var(--color-light-category-f);
    --color-category-f-hover: var(--color-light-category-f-hover);
    --color-category-f-focus: var(--color-light-category-f-focus);
    --color-category-f-press: var(--color-light-category-f-press);
    --color-category-f-drag: var(--color-light-category-f-drag);
    --color-on-category-f: var(--color-light-on-category-f);
    --color-on-category-f-hover: var(--color-light-on-category-f-hover);
    --color-on-category-f-focus: var(--color-light-on-category-f-focus);
    --color-on-category-f-press: var(--color-light-on-category-f-press);
    --color-on-category-f-drag: var(--color-light-on-category-f-drag);
    --color-category-g: var(--color-light-category-g);
    --color-category-g-hover: var(--color-light-category-g-hover);
    --color-category-g-focus: var(--color-light-category-g-focus);
    --color-category-g-press: var(--color-light-category-g-press);
    --color-category-g-drag: var(--color-light-category-g-drag);
    --color-on-category-g: var(--color-light-on-category-g);
    --color-on-category-g-hover: var(--color-light-on-category-g-hover);
    --color-on-category-g-focus: var(--color-light-on-category-g-focus);
    --color-on-category-g-press: var(--color-light-on-category-g-press);
    --color-on-category-g-drag: var(--color-light-on-category-g-drag);
    --color-category-h: var(--color-light-category-h);
    --color-category-h-hover: var(--color-light-category-h-hover);
    --color-category-h-focus: var(--color-light-category-h-focus);
    --color-category-h-press: var(--color-light-category-h-press);
    --color-category-h-drag: var(--color-light-category-h-drag);
    --color-on-category-h: var(--color-light-on-category-h);
    --color-on-category-h-hover: var(--color-light-on-category-h-hover);
    --color-on-category-h-focus: var(--color-light-on-category-h-focus);
    --color-on-category-h-press: var(--color-light-on-category-h-press);
    --color-on-category-h-drag: var(--color-light-on-category-h-drag);
    --color-input-background: var(--color-light-input-background);
    --color-input-background-hover: var(--color-light-input-background-hover);
    --color-input-background-focus: var(--color-light-input-background-focus);
    --color-input-background-press: var(--color-light-input-background-press);
    --color-input-background-drag: var(--color-light-input-background-drag);
    --color-on-input-background: var(--color-light-on-input-background);
    --color-on-input-background-hover: var(
      --color-light-on-input-background-hover
    );
    --color-on-input-background-focus: var(
      --color-light-on-input-background-focus
    );
    --color-on-input-background-press: var(
      --color-light-on-input-background-press
    );
    --color-on-input-background-drag: var(
      --color-light-on-input-background-drag
    );
    --color-input-active: var(--color-light-input-active);
    --color-input-active-hover: var(--color-light-input-active-hover);
    --color-input-active-focus: var(--color-light-input-active-focus);
    --color-input-active-press: var(--color-light-input-active-press);
    --color-input-active-drag: var(--color-light-input-active-drag);
    --color-on-input-active: var(--color-light-on-input-active);
    --color-on-input-active-hover: var(--color-light-on-input-active-hover);
    --color-on-input-active-focus: var(--color-light-on-input-active-focus);
    --color-on-input-active-press: var(--color-light-on-input-active-press);
    --color-on-input-active-drag: var(--color-light-on-input-active-drag);
    --color-input-value: var(--color-light-input-value);
    --color-input-value-hover: var(--color-light-input-value-hover);
    --color-input-value-focus: var(--color-light-input-value-focus);
    --color-input-value-press: var(--color-light-input-value-press);
    --color-input-value-drag: var(--color-light-input-value-drag);
    --color-input-value-disabled: var(--color-light-input-value-disabled);
    --color-input-value-disabled-hover: var(
      --color-light-input-value-disabled-hover
    );
    --color-input-value-disabled-focus: var(
      --color-light-input-value-disabled-focus
    );
    --color-input-value-disabled-press: var(
      --color-light-input-value-disabled-press
    );
    --color-input-value-disabled-drag: var(
      --color-light-input-value-disabled-drag
    );
  }

  .dark-scheme {
    --color-primary: var(--color-dark-primary);
    --color-primary-hover: var(--color-dark-primary-hover);
    --color-primary-focus: var(--color-dark-primary-focus);
    --color-primary-press: var(--color-dark-primary-press);
    --color-primary-drag: var(--color-dark-primary-drag);
    --color-on-primary: var(--color-dark-on-primary);
    --color-on-primary-hover: var(--color-dark-on-primary-hover);
    --color-on-primary-focus: var(--color-dark-on-primary-focus);
    --color-on-primary-press: var(--color-dark-on-primary-press);
    --color-on-primary-drag: var(--color-dark-on-primary-drag);
    --color-primary-container: var(--color-dark-primary-container);
    --color-primary-container-hover: var(--color-dark-primary-container-hover);
    --color-primary-container-focus: var(--color-dark-primary-container-focus);
    --color-primary-container-press: var(--color-dark-primary-container-press);
    --color-primary-container-drag: var(--color-dark-primary-container-drag);
    --color-on-primary-container: var(--color-dark-on-primary-container);
    --color-on-primary-container-hover: var(
      --color-dark-on-primary-container-hover
    );
    --color-on-primary-container-focus: var(
      --color-dark-on-primary-container-focus
    );
    --color-on-primary-container-press: var(
      --color-dark-on-primary-container-press
    );
    --color-on-primary-container-drag: var(
      --color-dark-on-primary-container-drag
    );
    --color-secondary: var(--color-dark-secondary);
    --color-secondary-hover: var(--color-dark-secondary-hover);
    --color-secondary-focus: var(--color-dark-secondary-focus);
    --color-secondary-press: var(--color-dark-secondary-press);
    --color-secondary-drag: var(--color-dark-secondary-drag);
    --color-on-secondary: var(--color-dark-on-secondary);
    --color-on-secondary-hover: var(--color-dark-on-secondary-hover);
    --color-on-secondary-focus: var(--color-dark-on-secondary-focus);
    --color-on-secondary-press: var(--color-dark-on-secondary-press);
    --color-on-secondary-drag: var(--color-dark-on-secondary-drag);
    --color-secondary-container: var(--color-dark-secondary-container);
    --color-secondary-container-hover: var(
      --color-dark-secondary-container-hover
    );
    --color-secondary-container-focus: var(
      --color-dark-secondary-container-focus
    );
    --color-secondary-container-press: var(
      --color-dark-secondary-container-press
    );
    --color-secondary-container-drag: var(
      --color-dark-secondary-container-drag
    );
    --color-on-secondary-container: var(--color-dark-on-secondary-container);
    --color-on-secondary-container-hover: var(
      --color-dark-on-secondary-container-hover
    );
    --color-on-secondary-container-focus: var(
      --color-dark-on-secondary-container-focus
    );
    --color-on-secondary-container-press: var(
      --color-dark-on-secondary-container-press
    );
    --color-on-secondary-container-drag: var(
      --color-dark-on-secondary-container-drag
    );
    --color-tertiary: var(--color-dark-tertiary);
    --color-tertiary-hover: var(--color-dark-tertiary-hover);
    --color-tertiary-focus: var(--color-dark-tertiary-focus);
    --color-tertiary-press: var(--color-dark-tertiary-press);
    --color-tertiary-drag: var(--color-dark-tertiary-drag);
    --color-on-tertiary: var(--color-dark-on-tertiary);
    --color-on-tertiary-hover: var(--color-dark-on-tertiary-hover);
    --color-on-tertiary-focus: var(--color-dark-on-tertiary-focus);
    --color-on-tertiary-press: var(--color-dark-on-tertiary-press);
    --color-on-tertiary-drag: var(--color-dark-on-tertiary-drag);
    --color-tertiary-container: var(--color-dark-tertiary-container);
    --color-tertiary-container-hover: var(
      --color-dark-tertiary-container-hover
    );
    --color-tertiary-container-focus: var(
      --color-dark-tertiary-container-focus
    );
    --color-tertiary-container-press: var(
      --color-dark-tertiary-container-press
    );
    --color-tertiary-container-drag: var(--color-dark-tertiary-container-drag);
    --color-on-tertiary-container: var(--color-dark-on-tertiary-container);
    --color-on-tertiary-container-hover: var(
      --color-dark-on-tertiary-container-hover
    );
    --color-on-tertiary-container-focus: var(
      --color-dark-on-tertiary-container-focus
    );
    --color-on-tertiary-container-press: var(
      --color-dark-on-tertiary-container-press
    );
    --color-on-tertiary-container-drag: var(
      --color-dark-on-tertiary-container-drag
    );
    --color-error: var(--color-dark-error);
    --color-error-hover: var(--color-dark-error-hover);
    --color-error-focus: var(--color-dark-error-focus);
    --color-error-press: var(--color-dark-error-press);
    --color-error-drag: var(--color-dark-error-drag);
    --color-on-error: var(--color-dark-on-error);
    --color-on-error-hover: var(--color-dark-on-error-hover);
    --color-on-error-focus: var(--color-dark-on-error-focus);
    --color-on-error-press: var(--color-dark-on-error-press);
    --color-on-error-drag: var(--color-dark-on-error-drag);
    --color-error-container: var(--color-dark-error-container);
    --color-error-container-hover: var(--color-dark-error-container-hover);
    --color-error-container-focus: var(--color-dark-error-container-focus);
    --color-error-container-press: var(--color-dark-error-container-press);
    --color-error-container-drag: var(--color-dark-error-container-drag);
    --color-on-error-container: var(--color-dark-on-error-container);
    --color-on-error-container-hover: var(
      --color-dark-on-error-container-hover
    );
    --color-on-error-container-focus: var(
      --color-dark-on-error-container-focus
    );
    --color-on-error-container-press: var(
      --color-dark-on-error-container-press
    );
    --color-on-error-container-drag: var(--color-dark-on-error-container-drag);
    --color-background: var(--color-dark-background);
    --color-background-hover: var(--color-dark-background-hover);
    --color-background-focus: var(--color-dark-background-focus);
    --color-background-press: var(--color-dark-background-press);
    --color-background-drag: var(--color-dark-background-drag);
    --color-on-background: var(--color-dark-on-background);
    --color-on-background-hover: var(--color-dark-on-background-hover);
    --color-on-background-focus: var(--color-dark-on-background-focus);
    --color-on-background-press: var(--color-dark-on-background-press);
    --color-on-background-drag: var(--color-dark-on-background-drag);
    --color-outline: var(--color-dark-outline);
    --color-outline-hover: var(--color-dark-outline-hover);
    --color-outline-focus: var(--color-dark-outline-focus);
    --color-outline-press: var(--color-dark-outline-press);
    --color-outline-drag: var(--color-dark-outline-drag);
    --color-surface: var(--color-dark-surface);
    --color-surface-hover: var(--color-dark-surface-hover);
    --color-surface-focus: var(--color-dark-surface-focus);
    --color-surface-press: var(--color-dark-surface-press);
    --color-surface-drag: var(--color-dark-surface-drag);
    --color-on-surface: var(--color-dark-on-surface);
    --color-on-surface-hover: var(--color-dark-on-surface-hover);
    --color-on-surface-focus: var(--color-dark-on-surface-focus);
    --color-on-surface-press: var(--color-dark-on-surface-press);
    --color-on-surface-drag: var(--color-dark-on-surface-drag);
    --color-surface-variant: var(--color-dark-surface-variant);
    --color-surface-variant-hover: var(--color-dark-surface-variant-hover);
    --color-surface-variant-focus: var(--color-dark-surface-variant-focus);
    --color-surface-variant-press: var(--color-dark-surface-variant-press);
    --color-surface-variant-drag: var(--color-dark-surface-variant-drag);
    --color-on-surface-variant: var(--color-dark-on-surface-variant);
    --color-on-surface-variant-hover: var(
      --color-dark-on-surface-variant-hover
    );
    --color-on-surface-variant-focus: var(
      --color-dark-on-surface-variant-focus
    );
    --color-on-surface-variant-press: var(
      --color-dark-on-surface-variant-press
    );
    --color-on-surface-variant-drag: var(--color-dark-on-surface-variant-drag);
    --color-surface-1: var(--color-dark-surface-1);
    --color-surface-1-hover: var(--color-dark-surface-1-hover);
    --color-surface-1-focus: var(--color-dark-surface-1-focus);
    --color-surface-1-press: var(--color-dark-surface-1-press);
    --color-surface-1-drag: var(--color-dark-surface-1-drag);
    --color-surface-2: var(--color-dark-surface-2);
    --color-surface-2-hover: var(--color-dark-surface-2-hover);
    --color-surface-2-focus: var(--color-dark-surface-2-focus);
    --color-surface-2-press: var(--color-dark-surface-2-press);
    --color-surface-2-drag: var(--color-dark-surface-2-drag);
    --color-surface-3: var(--color-dark-surface-3);
    --color-surface-3-hover: var(--color-dark-surface-3-hover);
    --color-surface-3-focus: var(--color-dark-surface-3-focus);
    --color-surface-3-press: var(--color-dark-surface-3-press);
    --color-surface-3-drag: var(--color-dark-surface-3-drag);
    --color-surface-4: var(--color-dark-surface-4);
    --color-surface-4-hover: var(--color-dark-surface-4-hover);
    --color-surface-4-focus: var(--color-dark-surface-4-focus);
    --color-surface-4-press: var(--color-dark-surface-4-press);
    --color-surface-4-drag: var(--color-dark-surface-4-drag);
    --color-surface-5: var(--color-dark-surface-5);
    --color-surface-5-hover: var(--color-dark-surface-5-hover);
    --color-surface-5-focus: var(--color-dark-surface-5-focus);
    --color-surface-5-press: var(--color-dark-surface-5-press);
    --color-surface-5-drag: var(--color-dark-surface-5-drag);
    --color-category-success: var(--color-dark-category-success);
    --color-category-success-hover: var(--color-dark-category-success-hover);
    --color-category-success-focus: var(--color-dark-category-success-focus);
    --color-category-success-press: var(--color-dark-category-success-press);
    --color-category-success-drag: var(--color-dark-category-success-drag);
    --color-on-category-success: var(--color-dark-on-category-success);
    --color-on-category-success-hover: var(
      --color-dark-on-category-success-hover
    );
    --color-on-category-success-focus: var(
      --color-dark-on-category-success-focus
    );
    --color-on-category-success-press: var(
      --color-dark-on-category-success-press
    );
    --color-on-category-success-drag: var(
      --color-dark-on-category-success-drag
    );
    --color-category-info: var(--color-dark-category-info);
    --color-category-info-hover: var(--color-dark-category-info-hover);
    --color-category-info-focus: var(--color-dark-category-info-focus);
    --color-category-info-press: var(--color-dark-category-info-press);
    --color-category-info-drag: var(--color-dark-category-info-drag);
    --color-on-category-info: var(--color-dark-on-category-info);
    --color-on-category-info-hover: var(--color-dark-on-category-info-hover);
    --color-on-category-info-focus: var(--color-dark-on-category-info-focus);
    --color-on-category-info-press: var(--color-dark-on-category-info-press);
    --color-on-category-info-drag: var(--color-dark-on-category-info-drag);
    --color-category-warning: var(--color-dark-category-warning);
    --color-category-warning-hover: var(--color-dark-category-warning-hover);
    --color-category-warning-focus: var(--color-dark-category-warning-focus);
    --color-category-warning-press: var(--color-dark-category-warning-press);
    --color-category-warning-drag: var(--color-dark-category-warning-drag);
    --color-on-category-warning: var(--color-dark-on-category-warning);
    --color-on-category-warning-hover: var(
      --color-dark-on-category-warning-hover
    );
    --color-on-category-warning-focus: var(
      --color-dark-on-category-warning-focus
    );
    --color-on-category-warning-press: var(
      --color-dark-on-category-warning-press
    );
    --color-on-category-warning-drag: var(
      --color-dark-on-category-warning-drag
    );
    --color-category-error: var(--color-dark-category-error);
    --color-category-error-hover: var(--color-dark-category-error-hover);
    --color-category-error-focus: var(--color-dark-category-error-focus);
    --color-category-error-press: var(--color-dark-category-error-press);
    --color-category-error-drag: var(--color-dark-category-error-drag);
    --color-on-category-error: var(--color-dark-on-category-error);
    --color-on-category-error-hover: var(--color-dark-on-category-error-hover);
    --color-on-category-error-focus: var(--color-dark-on-category-error-focus);
    --color-on-category-error-press: var(--color-dark-on-category-error-press);
    --color-on-category-error-drag: var(--color-dark-on-category-error-drag);
    --color-category-a: var(--color-dark-category-a);
    --color-category-a-hover: var(--color-dark-category-a-hover);
    --color-category-a-focus: var(--color-dark-category-a-focus);
    --color-category-a-press: var(--color-dark-category-a-press);
    --color-category-a-drag: var(--color-dark-category-a-drag);
    --color-on-category-a: var(--color-dark-on-category-a);
    --color-on-category-a-hover: var(--color-dark-on-category-a-hover);
    --color-on-category-a-focus: var(--color-dark-on-category-a-focus);
    --color-on-category-a-press: var(--color-dark-on-category-a-press);
    --color-on-category-a-drag: var(--color-dark-on-category-a-drag);
    --color-category-b: var(--color-dark-category-b);
    --color-category-b-hover: var(--color-dark-category-b-hover);
    --color-category-b-focus: var(--color-dark-category-b-focus);
    --color-category-b-press: var(--color-dark-category-b-press);
    --color-category-b-drag: var(--color-dark-category-b-drag);
    --color-on-category-b: var(--color-dark-on-category-b);
    --color-on-category-b-hover: var(--color-dark-on-category-b-hover);
    --color-on-category-b-focus: var(--color-dark-on-category-b-focus);
    --color-on-category-b-press: var(--color-dark-on-category-b-press);
    --color-on-category-b-drag: var(--color-dark-on-category-b-drag);
    --color-category-c: var(--color-dark-category-c);
    --color-category-c-hover: var(--color-dark-category-c-hover);
    --color-category-c-focus: var(--color-dark-category-c-focus);
    --color-category-c-press: var(--color-dark-category-c-press);
    --color-category-c-drag: var(--color-dark-category-c-drag);
    --color-on-category-c: var(--color-dark-on-category-c);
    --color-on-category-c-hover: var(--color-dark-on-category-c-hover);
    --color-on-category-c-focus: var(--color-dark-on-category-c-focus);
    --color-on-category-c-press: var(--color-dark-on-category-c-press);
    --color-on-category-c-drag: var(--color-dark-on-category-c-drag);
    --color-category-d: var(--color-dark-category-d);
    --color-category-d-hover: var(--color-dark-category-d-hover);
    --color-category-d-focus: var(--color-dark-category-d-focus);
    --color-category-d-press: var(--color-dark-category-d-press);
    --color-category-d-drag: var(--color-dark-category-d-drag);
    --color-on-category-d: var(--color-dark-on-category-d);
    --color-on-category-d-hover: var(--color-dark-on-category-d-hover);
    --color-on-category-d-focus: var(--color-dark-on-category-d-focus);
    --color-on-category-d-press: var(--color-dark-on-category-d-press);
    --color-on-category-d-drag: var(--color-dark-on-category-d-drag);
    --color-category-e: var(--color-dark-category-e);
    --color-category-e-hover: var(--color-dark-category-e-hover);
    --color-category-e-focus: var(--color-dark-category-e-focus);
    --color-category-e-press: var(--color-dark-category-e-press);
    --color-category-e-drag: var(--color-dark-category-e-drag);
    --color-on-category-e: var(--color-dark-on-category-e);
    --color-on-category-e-hover: var(--color-dark-on-category-e-hover);
    --color-on-category-e-focus: var(--color-dark-on-category-e-focus);
    --color-on-category-e-press: var(--color-dark-on-category-e-press);
    --color-on-category-e-drag: var(--color-dark-on-category-e-drag);
    --color-category-f: var(--color-dark-category-f);
    --color-category-f-hover: var(--color-dark-category-f-hover);
    --color-category-f-focus: var(--color-dark-category-f-focus);
    --color-category-f-press: var(--color-dark-category-f-press);
    --color-category-f-drag: var(--color-dark-category-f-drag);
    --color-on-category-f: var(--color-dark-on-category-f);
    --color-on-category-f-hover: var(--color-dark-on-category-f-hover);
    --color-on-category-f-focus: var(--color-dark-on-category-f-focus);
    --color-on-category-f-press: var(--color-dark-on-category-f-press);
    --color-on-category-f-drag: var(--color-dark-on-category-f-drag);
    --color-category-g: var(--color-dark-category-g);
    --color-category-g-hover: var(--color-dark-category-g-hover);
    --color-category-g-focus: var(--color-dark-category-g-focus);
    --color-category-g-press: var(--color-dark-category-g-press);
    --color-category-g-drag: var(--color-dark-category-g-drag);
    --color-on-category-g: var(--color-dark-on-category-g);
    --color-on-category-g-hover: var(--color-dark-on-category-g-hover);
    --color-on-category-g-focus: var(--color-dark-on-category-g-focus);
    --color-on-category-g-press: var(--color-dark-on-category-g-press);
    --color-on-category-g-drag: var(--color-dark-on-category-g-drag);
    --color-category-h: var(--color-dark-category-h);
    --color-category-h-hover: var(--color-dark-category-h-hover);
    --color-category-h-focus: var(--color-dark-category-h-focus);
    --color-category-h-press: var(--color-dark-category-h-press);
    --color-category-h-drag: var(--color-dark-category-h-drag);
    --color-on-category-h: var(--color-dark-on-category-h);
    --color-on-category-h-hover: var(--color-dark-on-category-h-hover);
    --color-on-category-h-focus: var(--color-dark-on-category-h-focus);
    --color-on-category-h-press: var(--color-dark-on-category-h-press);
    --color-on-category-h-drag: var(--color-dark-on-category-h-drag);
    --color-input-background: var(--color-dark-input-background);
    --color-input-background-hover: var(--color-dark-input-background-hover);
    --color-input-background-focus: var(--color-dark-input-background-focus);
    --color-input-background-press: var(--color-dark-input-background-press);
    --color-input-background-drag: var(--color-dark-input-background-drag);
    --color-on-input-background: var(--color-dark-on-input-background);
    --color-on-input-background-hover: var(
      --color-dark-on-input-background-hover
    );
    --color-on-input-background-focus: var(
      --color-dark-on-input-background-focus
    );
    --color-on-input-background-press: var(
      --color-dark-on-input-background-press
    );
    --color-on-input-background-drag: var(
      --color-dark-on-input-background-drag
    );
    --color-input-active: var(--color-dark-input-active);
    --color-input-active-hover: var(--color-dark-input-active-hover);
    --color-input-active-focus: var(--color-dark-input-active-focus);
    --color-input-active-press: var(--color-dark-input-active-press);
    --color-input-active-drag: var(--color-dark-input-active-drag);
    --color-on-input-active: var(--color-dark-on-input-active);
    --color-on-input-active-hover: var(--color-dark-on-input-active-hover);
    --color-on-input-active-focus: var(--color-dark-on-input-active-focus);
    --color-on-input-active-press: var(--color-dark-on-input-active-press);
    --color-on-input-active-drag: var(--color-dark-on-input-active-drag);
    --color-input-value: var(--color-dark-input-value);
    --color-input-value-hover: var(--color-dark-input-value-hover);
    --color-input-value-focus: var(--color-dark-input-value-focus);
    --color-input-value-press: var(--color-dark-input-value-press);
    --color-input-value-drag: var(--color-dark-input-value-drag);
    --color-input-value-disabled: var(--color-dark-input-value-disabled);
    --color-input-value-disabled-hover: var(
      --color-dark-input-value-disabled-hover
    );
    --color-input-value-disabled-focus: var(
      --color-dark-input-value-disabled-focus
    );
    --color-input-value-disabled-press: var(
      --color-dark-input-value-disabled-press
    );
    --color-input-value-disabled-drag: var(
      --color-dark-input-value-disabled-drag
    );
  }

  .light-scheme {
    --color-primary: var(--color-light-primary);
    --color-primary-hover: var(--color-light-primary-hover);
    --color-primary-focus: var(--color-light-primary-focus);
    --color-primary-press: var(--color-light-primary-press);
    --color-primary-drag: var(--color-light-primary-drag);
    --color-on-primary: var(--color-light-on-primary);
    --color-on-primary-hover: var(--color-light-on-primary-hover);
    --color-on-primary-focus: var(--color-light-on-primary-focus);
    --color-on-primary-press: var(--color-light-on-primary-press);
    --color-on-primary-drag: var(--color-light-on-primary-drag);
    --color-primary-container: var(--color-light-primary-container);
    --color-primary-container-hover: var(--color-light-primary-container-hover);
    --color-primary-container-focus: var(--color-light-primary-container-focus);
    --color-primary-container-press: var(--color-light-primary-container-press);
    --color-primary-container-drag: var(--color-light-primary-container-drag);
    --color-on-primary-container: var(--color-light-on-primary-container);
    --color-on-primary-container-hover: var(
      --color-light-on-primary-container-hover
    );
    --color-on-primary-container-focus: var(
      --color-light-on-primary-container-focus
    );
    --color-on-primary-container-press: var(
      --color-light-on-primary-container-press
    );
    --color-on-primary-container-drag: var(
      --color-light-on-primary-container-drag
    );
    --color-secondary: var(--color-light-secondary);
    --color-secondary-hover: var(--color-light-secondary-hover);
    --color-secondary-focus: var(--color-light-secondary-focus);
    --color-secondary-press: var(--color-light-secondary-press);
    --color-secondary-drag: var(--color-light-secondary-drag);
    --color-on-secondary: var(--color-light-on-secondary);
    --color-on-secondary-hover: var(--color-light-on-secondary-hover);
    --color-on-secondary-focus: var(--color-light-on-secondary-focus);
    --color-on-secondary-press: var(--color-light-on-secondary-press);
    --color-on-secondary-drag: var(--color-light-on-secondary-drag);
    --color-secondary-container: var(--color-light-secondary-container);
    --color-secondary-container-hover: var(
      --color-light-secondary-container-hover
    );
    --color-secondary-container-focus: var(
      --color-light-secondary-container-focus
    );
    --color-secondary-container-press: var(
      --color-light-secondary-container-press
    );
    --color-secondary-container-drag: var(
      --color-light-secondary-container-drag
    );
    --color-on-secondary-container: var(--color-light-on-secondary-container);
    --color-on-secondary-container-hover: var(
      --color-light-on-secondary-container-hover
    );
    --color-on-secondary-container-focus: var(
      --color-light-on-secondary-container-focus
    );
    --color-on-secondary-container-press: var(
      --color-light-on-secondary-container-press
    );
    --color-on-secondary-container-drag: var(
      --color-light-on-secondary-container-drag
    );
    --color-tertiary: var(--color-light-tertiary);
    --color-tertiary-hover: var(--color-light-tertiary-hover);
    --color-tertiary-focus: var(--color-light-tertiary-focus);
    --color-tertiary-press: var(--color-light-tertiary-press);
    --color-tertiary-drag: var(--color-light-tertiary-drag);
    --color-on-tertiary: var(--color-light-on-tertiary);
    --color-on-tertiary-hover: var(--color-light-on-tertiary-hover);
    --color-on-tertiary-focus: var(--color-light-on-tertiary-focus);
    --color-on-tertiary-press: var(--color-light-on-tertiary-press);
    --color-on-tertiary-drag: var(--color-light-on-tertiary-drag);
    --color-tertiary-container: var(--color-light-tertiary-container);
    --color-tertiary-container-hover: var(
      --color-light-tertiary-container-hover
    );
    --color-tertiary-container-focus: var(
      --color-light-tertiary-container-focus
    );
    --color-tertiary-container-press: var(
      --color-light-tertiary-container-press
    );
    --color-tertiary-container-drag: var(--color-light-tertiary-container-drag);
    --color-on-tertiary-container: var(--color-light-on-tertiary-container);
    --color-on-tertiary-container-hover: var(
      --color-light-on-tertiary-container-hover
    );
    --color-on-tertiary-container-focus: var(
      --color-light-on-tertiary-container-focus
    );
    --color-on-tertiary-container-press: var(
      --color-light-on-tertiary-container-press
    );
    --color-on-tertiary-container-drag: var(
      --color-light-on-tertiary-container-drag
    );
    --color-error: var(--color-light-error);
    --color-error-hover: var(--color-light-error-hover);
    --color-error-focus: var(--color-light-error-focus);
    --color-error-press: var(--color-light-error-press);
    --color-error-drag: var(--color-light-error-drag);
    --color-on-error: var(--color-light-on-error);
    --color-on-error-hover: var(--color-light-on-error-hover);
    --color-on-error-focus: var(--color-light-on-error-focus);
    --color-on-error-press: var(--color-light-on-error-press);
    --color-on-error-drag: var(--color-light-on-error-drag);
    --color-error-container: var(--color-light-error-container);
    --color-error-container-hover: var(--color-light-error-container-hover);
    --color-error-container-focus: var(--color-light-error-container-focus);
    --color-error-container-press: var(--color-light-error-container-press);
    --color-error-container-drag: var(--color-light-error-container-drag);
    --color-on-error-container: var(--color-light-on-error-container);
    --color-on-error-container-hover: var(
      --color-light-on-error-container-hover
    );
    --color-on-error-container-focus: var(
      --color-light-on-error-container-focus
    );
    --color-on-error-container-press: var(
      --color-light-on-error-container-press
    );
    --color-on-error-container-drag: var(--color-light-on-error-container-drag);
    --color-background: var(--color-light-background);
    --color-background-hover: var(--color-light-background-hover);
    --color-background-focus: var(--color-light-background-focus);
    --color-background-press: var(--color-light-background-press);
    --color-background-drag: var(--color-light-background-drag);
    --color-on-background: var(--color-light-on-background);
    --color-on-background-hover: var(--color-light-on-background-hover);
    --color-on-background-focus: var(--color-light-on-background-focus);
    --color-on-background-press: var(--color-light-on-background-press);
    --color-on-background-drag: var(--color-light-on-background-drag);
    --color-outline: var(--color-light-outline);
    --color-outline-hover: var(--color-light-outline-hover);
    --color-outline-focus: var(--color-light-outline-focus);
    --color-outline-press: var(--color-light-outline-press);
    --color-outline-drag: var(--color-light-outline-drag);
    --color-surface: var(--color-light-surface);
    --color-surface-hover: var(--color-light-surface-hover);
    --color-surface-focus: var(--color-light-surface-focus);
    --color-surface-press: var(--color-light-surface-press);
    --color-surface-drag: var(--color-light-surface-drag);
    --color-on-surface: var(--color-light-on-surface);
    --color-on-surface-hover: var(--color-light-on-surface-hover);
    --color-on-surface-focus: var(--color-light-on-surface-focus);
    --color-on-surface-press: var(--color-light-on-surface-press);
    --color-on-surface-drag: var(--color-light-on-surface-drag);
    --color-surface-variant: var(--color-light-surface-variant);
    --color-surface-variant-hover: var(--color-light-surface-variant-hover);
    --color-surface-variant-focus: var(--color-light-surface-variant-focus);
    --color-surface-variant-press: var(--color-light-surface-variant-press);
    --color-surface-variant-drag: var(--color-light-surface-variant-drag);
    --color-on-surface-variant: var(--color-light-on-surface-variant);
    --color-on-surface-variant-hover: var(
      --color-light-on-surface-variant-hover
    );
    --color-on-surface-variant-focus: var(
      --color-light-on-surface-variant-focus
    );
    --color-on-surface-variant-press: var(
      --color-light-on-surface-variant-press
    );
    --color-on-surface-variant-drag: var(--color-light-on-surface-variant-drag);
    --color-surface-1: var(--color-light-surface-1);
    --color-surface-1-hover: var(--color-light-surface-1-hover);
    --color-surface-1-focus: var(--color-light-surface-1-focus);
    --color-surface-1-press: var(--color-light-surface-1-press);
    --color-surface-1-drag: var(--color-light-surface-1-drag);
    --color-surface-2: var(--color-light-surface-2);
    --color-surface-2-hover: var(--color-light-surface-2-hover);
    --color-surface-2-focus: var(--color-light-surface-2-focus);
    --color-surface-2-press: var(--color-light-surface-2-press);
    --color-surface-2-drag: var(--color-light-surface-2-drag);
    --color-surface-3: var(--color-light-surface-3);
    --color-surface-3-hover: var(--color-light-surface-3-hover);
    --color-surface-3-focus: var(--color-light-surface-3-focus);
    --color-surface-3-press: var(--color-light-surface-3-press);
    --color-surface-3-drag: var(--color-light-surface-3-drag);
    --color-surface-4: var(--color-light-surface-4);
    --color-surface-4-hover: var(--color-light-surface-4-hover);
    --color-surface-4-focus: var(--color-light-surface-4-focus);
    --color-surface-4-press: var(--color-light-surface-4-press);
    --color-surface-4-drag: var(--color-light-surface-4-drag);
    --color-surface-5: var(--color-light-surface-5);
    --color-surface-5-hover: var(--color-light-surface-5-hover);
    --color-surface-5-focus: var(--color-light-surface-5-focus);
    --color-surface-5-press: var(--color-light-surface-5-press);
    --color-surface-5-drag: var(--color-light-surface-5-drag);
    --color-category-success: var(--color-light-category-success);
    --color-category-success-hover: var(--color-light-category-success-hover);
    --color-category-success-focus: var(--color-light-category-success-focus);
    --color-category-success-press: var(--color-light-category-success-press);
    --color-category-success-drag: var(--color-light-category-success-drag);
    --color-on-category-success: var(--color-light-on-category-success);
    --color-on-category-success-hover: var(
      --color-light-on-category-success-hover
    );
    --color-on-category-success-focus: var(
      --color-light-on-category-success-focus
    );
    --color-on-category-success-press: var(
      --color-light-on-category-success-press
    );
    --color-on-category-success-drag: var(
      --color-light-on-category-success-drag
    );
    --color-category-info: var(--color-light-category-info);
    --color-category-info-hover: var(--color-light-category-info-hover);
    --color-category-info-focus: var(--color-light-category-info-focus);
    --color-category-info-press: var(--color-light-category-info-press);
    --color-category-info-drag: var(--color-light-category-info-drag);
    --color-on-category-info: var(--color-light-on-category-info);
    --color-on-category-info-hover: var(--color-light-on-category-info-hover);
    --color-on-category-info-focus: var(--color-light-on-category-info-focus);
    --color-on-category-info-press: var(--color-light-on-category-info-press);
    --color-on-category-info-drag: var(--color-light-on-category-info-drag);
    --color-category-warning: var(--color-light-category-warning);
    --color-category-warning-hover: var(--color-light-category-warning-hover);
    --color-category-warning-focus: var(--color-light-category-warning-focus);
    --color-category-warning-press: var(--color-light-category-warning-press);
    --color-category-warning-drag: var(--color-light-category-warning-drag);
    --color-on-category-warning: var(--color-light-on-category-warning);
    --color-on-category-warning-hover: var(
      --color-light-on-category-warning-hover
    );
    --color-on-category-warning-focus: var(
      --color-light-on-category-warning-focus
    );
    --color-on-category-warning-press: var(
      --color-light-on-category-warning-press
    );
    --color-on-category-warning-drag: var(
      --color-light-on-category-warning-drag
    );
    --color-category-error: var(--color-light-category-error);
    --color-category-error-hover: var(--color-light-category-error-hover);
    --color-category-error-focus: var(--color-light-category-error-focus);
    --color-category-error-press: var(--color-light-category-error-press);
    --color-category-error-drag: var(--color-light-category-error-drag);
    --color-on-category-error: var(--color-light-on-category-error);
    --color-on-category-error-hover: var(--color-light-on-category-error-hover);
    --color-on-category-error-focus: var(--color-light-on-category-error-focus);
    --color-on-category-error-press: var(--color-light-on-category-error-press);
    --color-on-category-error-drag: var(--color-light-on-category-error-drag);
    --color-category-a: var(--color-light-category-a);
    --color-category-a-hover: var(--color-light-category-a-hover);
    --color-category-a-focus: var(--color-light-category-a-focus);
    --color-category-a-press: var(--color-light-category-a-press);
    --color-category-a-drag: var(--color-light-category-a-drag);
    --color-on-category-a: var(--color-light-on-category-a);
    --color-on-category-a-hover: var(--color-light-on-category-a-hover);
    --color-on-category-a-focus: var(--color-light-on-category-a-focus);
    --color-on-category-a-press: var(--color-light-on-category-a-press);
    --color-on-category-a-drag: var(--color-light-on-category-a-drag);
    --color-category-b: var(--color-light-category-b);
    --color-category-b-hover: var(--color-light-category-b-hover);
    --color-category-b-focus: var(--color-light-category-b-focus);
    --color-category-b-press: var(--color-light-category-b-press);
    --color-category-b-drag: var(--color-light-category-b-drag);
    --color-on-category-b: var(--color-light-on-category-b);
    --color-on-category-b-hover: var(--color-light-on-category-b-hover);
    --color-on-category-b-focus: var(--color-light-on-category-b-focus);
    --color-on-category-b-press: var(--color-light-on-category-b-press);
    --color-on-category-b-drag: var(--color-light-on-category-b-drag);
    --color-category-c: var(--color-light-category-c);
    --color-category-c-hover: var(--color-light-category-c-hover);
    --color-category-c-focus: var(--color-light-category-c-focus);
    --color-category-c-press: var(--color-light-category-c-press);
    --color-category-c-drag: var(--color-light-category-c-drag);
    --color-on-category-c: var(--color-light-on-category-c);
    --color-on-category-c-hover: var(--color-light-on-category-c-hover);
    --color-on-category-c-focus: var(--color-light-on-category-c-focus);
    --color-on-category-c-press: var(--color-light-on-category-c-press);
    --color-on-category-c-drag: var(--color-light-on-category-c-drag);
    --color-category-d: var(--color-light-category-d);
    --color-category-d-hover: var(--color-light-category-d-hover);
    --color-category-d-focus: var(--color-light-category-d-focus);
    --color-category-d-press: var(--color-light-category-d-press);
    --color-category-d-drag: var(--color-light-category-d-drag);
    --color-on-category-d: var(--color-light-on-category-d);
    --color-on-category-d-hover: var(--color-light-on-category-d-hover);
    --color-on-category-d-focus: var(--color-light-on-category-d-focus);
    --color-on-category-d-press: var(--color-light-on-category-d-press);
    --color-on-category-d-drag: var(--color-light-on-category-d-drag);
    --color-category-e: var(--color-light-category-e);
    --color-category-e-hover: var(--color-light-category-e-hover);
    --color-category-e-focus: var(--color-light-category-e-focus);
    --color-category-e-press: var(--color-light-category-e-press);
    --color-category-e-drag: var(--color-light-category-e-drag);
    --color-on-category-e: var(--color-light-on-category-e);
    --color-on-category-e-hover: var(--color-light-on-category-e-hover);
    --color-on-category-e-focus: var(--color-light-on-category-e-focus);
    --color-on-category-e-press: var(--color-light-on-category-e-press);
    --color-on-category-e-drag: var(--color-light-on-category-e-drag);
    --color-category-f: var(--color-light-category-f);
    --color-category-f-hover: var(--color-light-category-f-hover);
    --color-category-f-focus: var(--color-light-category-f-focus);
    --color-category-f-press: var(--color-light-category-f-press);
    --color-category-f-drag: var(--color-light-category-f-drag);
    --color-on-category-f: var(--color-light-on-category-f);
    --color-on-category-f-hover: var(--color-light-on-category-f-hover);
    --color-on-category-f-focus: var(--color-light-on-category-f-focus);
    --color-on-category-f-press: var(--color-light-on-category-f-press);
    --color-on-category-f-drag: var(--color-light-on-category-f-drag);
    --color-category-g: var(--color-light-category-g);
    --color-category-g-hover: var(--color-light-category-g-hover);
    --color-category-g-focus: var(--color-light-category-g-focus);
    --color-category-g-press: var(--color-light-category-g-press);
    --color-category-g-drag: var(--color-light-category-g-drag);
    --color-on-category-g: var(--color-light-on-category-g);
    --color-on-category-g-hover: var(--color-light-on-category-g-hover);
    --color-on-category-g-focus: var(--color-light-on-category-g-focus);
    --color-on-category-g-press: var(--color-light-on-category-g-press);
    --color-on-category-g-drag: var(--color-light-on-category-g-drag);
    --color-category-h: var(--color-light-category-h);
    --color-category-h-hover: var(--color-light-category-h-hover);
    --color-category-h-focus: var(--color-light-category-h-focus);
    --color-category-h-press: var(--color-light-category-h-press);
    --color-category-h-drag: var(--color-light-category-h-drag);
    --color-on-category-h: var(--color-light-on-category-h);
    --color-on-category-h-hover: var(--color-light-on-category-h-hover);
    --color-on-category-h-focus: var(--color-light-on-category-h-focus);
    --color-on-category-h-press: var(--color-light-on-category-h-press);
    --color-on-category-h-drag: var(--color-light-on-category-h-drag);
    --color-input-background: var(--color-light-input-background);
    --color-input-background-hover: var(--color-light-input-background-hover);
    --color-input-background-focus: var(--color-light-input-background-focus);
    --color-input-background-press: var(--color-light-input-background-press);
    --color-input-background-drag: var(--color-light-input-background-drag);
    --color-on-input-background: var(--color-light-on-input-background);
    --color-on-input-background-hover: var(
      --color-light-on-input-background-hover
    );
    --color-on-input-background-focus: var(
      --color-light-on-input-background-focus
    );
    --color-on-input-background-press: var(
      --color-light-on-input-background-press
    );
    --color-on-input-background-drag: var(
      --color-light-on-input-background-drag
    );
    --color-input-active: var(--color-light-input-active);
    --color-input-active-hover: var(--color-light-input-active-hover);
    --color-input-active-focus: var(--color-light-input-active-focus);
    --color-input-active-press: var(--color-light-input-active-press);
    --color-input-active-drag: var(--color-light-input-active-drag);
    --color-on-input-active: var(--color-light-on-input-active);
    --color-on-input-active-hover: var(--color-light-on-input-active-hover);
    --color-on-input-active-focus: var(--color-light-on-input-active-focus);
    --color-on-input-active-press: var(--color-light-on-input-active-press);
    --color-on-input-active-drag: var(--color-light-on-input-active-drag);
    --color-input-value: var(--color-light-input-value);
    --color-input-value-hover: var(--color-light-input-value-hover);
    --color-input-value-focus: var(--color-light-input-value-focus);
    --color-input-value-press: var(--color-light-input-value-press);
    --color-input-value-drag: var(--color-light-input-value-drag);
    --color-input-value-disabled: var(--color-light-input-value-disabled);
    --color-input-value-disabled-hover: var(
      --color-light-input-value-disabled-hover
    );
    --color-input-value-disabled-focus: var(
      --color-light-input-value-disabled-focus
    );
    --color-input-value-disabled-press: var(
      --color-light-input-value-disabled-press
    );
    --color-input-value-disabled-drag: var(
      --color-light-input-value-disabled-drag
    );
  }
}

.wrapper {
  display: contents;
}

.inputFullHeight {
  height: 100%;
}

.inputFullWidth {
  width: 100%;
}

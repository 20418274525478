.label {
  font-family: var(--typography-label-medium-font-family);
  font-size: var(--typography-label-medium-size);
  font-weight: var(--typography-label-medium-weight);
  line-height: var(--typography-label-medium-line-height);
}

.labelRequired::before {
  color: #ff4d4f;
  content: '*';
  display: inline-block;
  font-family: var(--typography-label-medium-font-family);
  font-size: var(--typography-label-medium-size);
  font-weight: var(--typography-label-medium-weight);
  line-height: var(--typography-label-medium-line-height);
  margin-right: 4px;
}

.labelSmall {
  font-family: var(--typography-label-small-font-family);
  font-size: var(--typography-label-small-size);
  font-weight: var(--typography-label-small-weight);
  line-height: var(--typography-label-small-line-height);
}

.labelLarge {
  font-family: var(--typography-label-large-font-family);
  font-size: var(--typography-label-large-size);
  font-weight: var(--typography-label-large-weight);
  line-height: var(--typography-label-large-line-height);
}

.wrapper {
  display: contents;
}

.dragger {
  padding: 0;
}

.inputFullWidth {
  width: 100%;
}

.padded {
  padding: var(--space-3x) !important;
}

.preview {
  padding: 0 !important;
}

.loadingContainer {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.loadingContainerBackdrop {
  background-color: var(--color-backdrop-opaque);
  bottom: 0;
  left: 0;
  opacity: var(--opacity-backdrop);
  position: absolute;
  right: 0;
  top: 0;
}

.previewImage {
  max-height: 100%;
  max-width: 100%;
  vertical-align: bottom;
}

.removeButton {
  bottom: var(--space-3x);
  position: absolute;
  right: var(--space-3x);
}

.modal {
  height: 100% !important;
  max-width: 100%;
  padding: 16px !important;
  width: 100% !important;
}

.root {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.content {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.deviceSelector {
  margin: 8px 0;
  width: 100%;
}

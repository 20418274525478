.wrapper {
  display: inline-flex;
  flex-direction: column;
}

.wrapperDisabled {
  opacity: var(--opacity-disabled);
}

.wrapperWithoutError {
  padding-bottom: var(--typography-label-small-line-height);
}

.fullWidthWrapper {
  width: 100%;
}

.errorMessage {
  font-family: var(--typography-label-small-font-family);
  font-size: var(--typography-label-small-size);
  font-weight: var(--typography-label-small-weight);
  line-height: var(--typography-label-small-line-height);
  min-height: var(--typography-label-small-line-height);
}

.button {
  align-items: center;
  border-radius: 0;
  box-sizing: border-box;
  display: inline-flex;
  font-family: var(--typography-button-medium-font-family);
  font-size: var(--typography-button-medium-size);
  font-weight: var(--typography-button-medium-weight);
  height: var(--height-button-medium);
  justify-content: center;
  line-height: var(--typography-button-medium-line-height);
  padding-bottom: 0px !important;
  padding-left: var(--padding-left-button-medium) !important;
  padding-right: var(--padding-right-button-medium) !important;
  padding-top: 0px !important;
}

.buttonWithIcon {
  padding-left: var(--padding-left-button-with-icon-medium) !important;
}

.rounded {
  border-radius: var(--shape-button-border-radius);
}

.icon {
  padding-right: var(--space-button-icon-medium);
}

.iconSmall {
  font-size: var(--size-icon-button-small-icon) !important;
}

.small {
  height: var(--height-button-small);
  line-height: var(--height-button-small);
  padding-left: var(--padding-left-button-small) !important;
  padding-right: var(--padding-right-button-small) !important;
}

.small.buttonWithIcon {
  padding-left: var(--padding-left-button-with-icon-small) !important;
}

.small .icon {
  padding-right: var(--space-button-icon-small);
}

.large {
  height: var(--height-button-large);
  line-height: var(--height-button-large);
  padding-left: var(--padding-left-button-large) !important;
  padding-right: var(--padding-right-button-large) !important;
}

.large.buttonWithIcon {
  padding-left: var(--padding-left-button-with-icon-large) !important;
}

.large .icon {
  padding-right: var(--space-button-icon-large);
}

.disabled {
  opacity: var(--opacity-disabled) !important;
}

.link {
  background-color: transparent !important;
}

.link:hover {
  background-color: transparent !important;
}

.link:focus {
  background-color: transparent !important;
}

.link:active {
  background-color: transparent !important;
}

.text {
  background-color: transparent !important;
}

.text:hover {
  background-color: transparent !important;
}

.text:focus {
  background-color: transparent !important;
}

.text:active {
  background-color: transparent !important;
}

.link.disabled,
.text.disabled {
  color: var(--color-on-surface-variant) !important;
  opacity: var(--opacity-disabled) !important;
}

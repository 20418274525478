.root {
  display: flex;
  flex-direction: row;
}

.horizontalRoot {
  display: flex;
  flex-direction: column;
}

.imageContainer {
  flex-grow: 0;
  flex-shrink: 0;
  height: 100%;
  position: relative;
}

.image {
  height: 100%;
}

.imageTag {
  bottom: var(--space-gap);
  position: absolute;
  right: var(--space-gap);
}

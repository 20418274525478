.ant-collapse {
  background-color: transparent;
  border: none;
  color: inherit;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  border: none;
  color: inherit !important;
}

.ant-collapse .ant-collapse-content {
  background-color: transparent;
  border: none;
  color: inherit;
}

.ant-collapse > .ant-collapse-item {
  border: none;
}

.ant-collapse-item + .ant-collapse-item {
  border-top: 1px solid var(--color-outline);
}

.tag {
  align-items: center;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  display: flex;
  justify-content: center;
  padding: 2px 12px;
}

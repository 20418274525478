.ant-modal .ant-modal-content {
  background-color: var(--color-surface);
  color: var(--color-on-surface);
  border-radius: var(--dimension-surface-border-radius);
  padding: var(--dimension-surface-padding-vertical)
    var(--dimension-surface-padding-horizontal);
}

.ant-modal .ant-modal-header {
  background-color: transparent;
  color: inherit;
}

.ant-modal .ant-modal-title {
  color: inherit;
}

.ant-modal .ant-modal-close {
  color: var(--color-on-surface);
}

.ant-modal .ant-modal-close:hover {
  color: var(--color-on-surface-hover);
}

.ant-modal .ant-modal-close:focus {
  color: var(--color-on-surface-focus);
}

.ant-modal .ant-modal-close:active {
  color: var(--color-on-surface-press);
}

.icon {
  font-size: var(--typography-label-large-size);
}

.iconDark {
  color: rgba(0, 0, 0, 0.54);
}

.iconDark.iconDisabled {
  color: rgba(0, 0, 0, 0.26);
}

.iconLight {
  color: rgba(255, 255, 255, 1);
}

.iconLight.iconDisabled {
  color: rgba(255, 255, 255, 0.3);
}

.iconSpin {
  animation-duration: 1024ms;
  animation-iteration-count: infinite;
  animation-name: spin;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

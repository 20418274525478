.root {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  position: relative;
}

.slideFullWidth {
  width: 100%;
}

a {
  color: var(--color-primary);
  text-decoration: none;
}

a:hover {
  color: var(--color-primary-hover);
}

a:focus {
  color: var(--color-primary-focus);
}

a:active {
  color: var(--color-primary-press);
}

.block {
  display: block;
}

.body {
  font-family: var(--typography-body-medium-font-family);
  font-size: var(--typography-body-medium-size);
  font-weight: var(--typography-body-medium-weight);
  line-height: var(--typography-body-medium-line-height);
}

.bodyLarge {
  font-family: var(--typography-body-large-font-family);
  font-size: var(--typography-body-large-size);
  font-weight: var(--typography-body-large-weight);
  line-height: var(--typography-body-large-line-height);
}

.bodySmall {
  font-family: var(--typography-body-small-font-family);
  font-size: var(--typography-body-small-size);
  font-weight: var(--typography-body-small-weight);
  line-height: var(--typography-body-small-line-height);
}

.display {
  font-family: var(--typography-display-medium-font-family);
  font-size: var(--typography-display-medium-size);
  font-weight: var(--typography-display-medium-weight);
  line-height: var(--typography-display-medium-line-height);
}

.display1 {
  font-family: var(--typography-display1-font-family);
  font-size: var(--typography-display1-size);
  font-weight: var(--typography-display1-weight);
  line-height: var(--typography-display1-line-height);
}

.displayLarge {
  font-family: var(--typography-display-large-font-family);
  font-size: var(--typography-display-large-size);
  font-weight: var(--typography-display-large-weight);
  line-height: var(--typography-display-large-line-height);
}

.displaySmall {
  font-family: var(--typography-display-small-font-family);
  font-size: var(--typography-display-small-size);
  font-weight: var(--typography-display-small-weight);
  line-height: var(--typography-display-small-line-height);
}

.headline {
  font-family: var(--typography-headline-medium-font-family);
  font-size: var(--typography-headline-medium-size);
  font-weight: var(--typography-headline-medium-weight);
  line-height: var(--typography-headline-medium-line-height);
}

.headline6 {
  font-family: var(--typography-headline6-font-family);
  font-size: var(--typography-headline6-size);
  font-weight: var(--typography-headline6-weight);
  line-height: var(--typography-headline6-line-height);
}

.headlineLarge {
  font-family: var(--typography-headline-large-font-family);
  font-size: var(--typography-headline-large-size);
  font-weight: var(--typography-headline-large-weight);
  line-height: var(--typography-headline-large-line-height);
}

.headlineSmall {
  font-family: var(--typography-headline-small-font-family);
  font-size: var(--typography-headline-small-size);
  font-weight: var(--typography-headline-small-weight);
  line-height: var(--typography-headline-small-line-height);
}

.label {
  font-family: var(--typography-label-medium-font-family);
  font-size: var(--typography-label-medium-size);
  font-weight: var(--typography-label-medium-weight);
  line-height: var(--typography-label-medium-line-height);
}

.labelLarge {
  font-family: var(--typography-label-large-font-family);
  font-size: var(--typography-label-large-size);
  font-weight: var(--typography-label-large-weight);
  line-height: var(--typography-label-large-line-height);
}

.labelSmall {
  font-family: var(--typography-label-small-font-family);
  font-size: var(--typography-label-small-size);
  font-weight: var(--typography-label-small-weight);
  line-height: var(--typography-label-small-line-height);
}

.title {
  font-family: var(--typography-title-medium-font-family);
  font-size: var(--typography-title-medium-size);
  font-weight: var(--typography-title-medium-weight);
  line-height: var(--typography-title-medium-line-height);
}

.titleLarge {
  font-family: var(--typography-title-large-font-family);
  font-size: var(--typography-title-large-size);
  font-weight: var(--typography-title-large-weight);
  line-height: var(--typography-title-large-line-height);
}

.titleSmall {
  font-family: var(--typography-title-small-font-family);
  font-size: var(--typography-title-small-size);
  font-weight: var(--typography-title-small-weight);
  line-height: var(--typography-title-small-line-height);
}

.colorDefault {
  color: var(--color-on-background);
}

.colorBackground {
  color: var(--color-background);
}

.colorPrimary {
  color: var(--color-primary);
}

.colorOnPrimary {
  color: var(--color-on-primary);
}

.colorSecondary {
  color: var(--color-secondary);
}

.colorOnSecondary {
  color: var(--color-on-secondary);
}

.colorTertiary {
  color: var(--color-tertiary);
}

.colorOnTertiary {
  color: var(--color-on-tertiary);
}

.colorError {
  color: var(--color-error);
}

.colorOnError {
  color: var(--color-on-error);
}

.colorSurface {
  color: var(--color-surface);
}

.colorOnSurface {
  color: var(--color-on-surface);
}

.colorSurfaceVariant {
  color: var(--color-surface-variant);
}

.colorOnSurfaceVariant {
  color: var(--color-on-surface-variant);
}

html,
body {
  background-color: var(--color-background);
  color: var(--color-on-background);
  font-family: var(--typography-body-medium-font-family);
  font-size: var(--typography-body-medium-size);
  font-weight: var(--typography-body-medium-weight);
  line-height: var(--typography-body-medium-line-height);
}

html {
  display: flex;
  min-height: 100%;
}

body {
  flex: 1;
  margin: 0;
  padding: 0;
  width: 100%;
}

#__next {
  display: flex;
  min-height: 100%;
}

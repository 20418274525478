.wrapper {
  display: contents;
}

.input {
  align-items: center;
  display: flex;
}

.inputReverse {
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.inputLabelLeft {
  margin-right: 10px;
}

.inputLabelRight {
  margin-left: 10px;
}

.inputFullWidth {
  width: 100%;
}

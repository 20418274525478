.root {
  display: flex;
  flex-direction: column;
  width: 240px;
}

.imageContainer {
  position: relative;
}

.image {
  height: 144px;
  object-fit: cover;
  width: 240px;
}

.cardNoticeContainer {
  align-items: center;
  display: flex;
  height: 32px;
  padding-left: var(--space-4x);
  padding-right: var(--space-4x);
  position: absolute;
  right: 0;
  top: 0;
}

.cardActionContainer {
  padding-top: var(--space-4x);
  text-align: center;
}

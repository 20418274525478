.ant-progress {
  color: inherit;
}

.ant-progress.ant-progress-circle .ant-progress-text {
  color: inherit;
}

.ant-progress .ant-progress-circle-trail {
  stroke: var(--color-input-background);
}

.ant-progress
  .ant-progress-inner:not(.ant-progress-circle-gradient)
  .ant-progress-circle-path {
  stroke: var(--color-input-active);
}

.ant-progress.ant-progress-status-success
  .ant-progress-inner:not(.ant-progress-circle-gradient)
  .ant-progress-circle-path {
  stroke: var(--color-category-success);
}

.wrapper {
  display: inline-flex;
}

.fullWidthWrapper {
  width: 100%;
}

.horizontal {
  flex-direction: row;
}

.buttonHorizontal {
  transition: all var(--transition-duration),
    z-index var(--transition-duration) cubic-bezier(0, 0, 1, 1);
  z-index: 0;
}

.buttonHorizontal:hover {
  transition: all var(--transition-duration), z-index 0s;
  z-index: 2;
}

.buttonHorizontal:active,
.buttonHorizontal:focus {
  transition: all var(--transition-duration), z-index 0s;
  z-index: 3;
}

.buttonHorizontal:not(:first-child) {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  margin-left: -1px;
}

.buttonHorizontal:not(:last-child) {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.vertical {
  flex-direction: column;
}

.buttonVertical {
  transition: all var(--transition-duration),
    z-index var(--transition-duration) cubic-bezier(0, 0, 1, 1);
  z-index: 0;
}

.buttonVertical:hover {
  transition: all var(--transition-duration), z-index 0s;
  z-index: 2;
}

.buttonVertical:active,
.buttonVertical:focus {
  transition: all var(--transition-duration), z-index 0s;
  z-index: 3;
}

.buttonVertical:not(:first-child) {
  margin-top: -1px;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.buttonVertical:not(:last-child) {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.wrapper {
  display: inline-block;
}

.fullHeightWrapper {
  height: 100%;
}

.fullWidthWrapper {
  width: 100%;
}

.flexWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.wrapperWithoutError {
  padding-bottom: 16px;
}

.labelRequired::before {
  color: #ff4d4f;
  content: '*';
  display: inline-block;
  font-family: SimSun, sans-serif;
  font-size: 14px;
  line-height: 1;
  margin-right: 4px;
}

.errorMessage {
  font-size: 12px;
  line-height: 16px;
  min-height: 16px;
}

/*!******************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/general/color/Color/Color.module.css ***!
  \******************************************************************************************************************************************************************************************************/
.YI_VxkO2X72gRsnmzemm {
  color: var(--color-on-primary) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.wuRgR1BOQCH8zL_hsC8p {
  color: var(--color-primary) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.YkdoBzA6vGzu0m4IDNsb {
  color: var(--color-on-primary-container) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.pzT9X0ffvKDruTfZ0Fdx {
  color: var(--color-primary-container) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.f_d0b5vEXO2RZ3Fou9v6 {
  color: var(--color-on-secondary) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.wbJX5mhkv59hcwVLWdnQ {
  color: var(--color-secondary) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.iySBgFzbIJo4lPrreXuQ {
  color: var(--color-on-secondary-container) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.qY0n0CyKzBb6kVUZcOSw {
  color: var(--color-secondary-container) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.HfVaDlxoiNK0pKEbR6ne {
  color: var(--color-on-tertiary) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.Eip0CLSXp8BLdZPV8gAz {
  color: var(--color-tertiary) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.LWoPTYCJINXcnuQ14sxv {
  color: var(--color-on-tertiary-container) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.bvszfLJqa5z2gPHztHHg {
  color: var(--color-tertiary-container) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.zilhrBMGw4U5hRX3bpzc {
  color: var(--color-on-error) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.NVqMZPJJ1dRuyYLYUBNt {
  color: var(--color-error) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.Z7CYJ2XJEG6nts5XlFu8 {
  color: var(--color-on-error-container) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.OI4_WV99ukOkXP1ni3fL {
  color: var(--color-error-container) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.W4voCGF9ETRfglEFHT_6 {
  color: var(--color-on-background) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.oEIP0j7gH3TO9bqSio1w {
  color: var(--color-background) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.gpx3NSZbfg4YT4600thN {
  color: var(--color-on-surface) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.K9UMic5xmLHDOCZvf8Yh {
  color: var(--color-surface) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.qGSuFGy9psppYrzMijGX {
  color: var(--color-on-surface) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.eN3X1tynwsDVVoKUyeXs {
  color: var(--color-surface-1) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.NIrIt8apNmKF7FB2gywz {
  color: var(--color-on-surface) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.JbkOAFgH73cPyiTvyxNb {
  color: var(--color-surface-2) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.re2krnqPpzmsOWWJ30wb {
  color: var(--color-on-surface) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.DKHijx2vAcAQu4KaMo0Q {
  color: var(--color-surface-3) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.xswQjLSyiQbvYJ0YgGc8 {
  color: var(--color-on-surface) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.GUn79XMlksJj2QNWKhgk {
  color: var(--color-surface-4) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.ZMl9nWgqwL508bWfcFAg {
  color: var(--color-on-surface) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.kBHSNRqVBx5lrFQbPuYd {
  color: var(--color-surface-5) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.hI16VkBW49tR10cFbYTX {
  color: var(--color-on-outline) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.G2fcfwytUTl95aGAyEMi {
  color: var(--color-on-surface-variant) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.l0qaw6n0IwqRTaNakUFc {
  color: var(--color-surface-variant) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.u6gG4OW5dgB2H4ZXpYgx {
  color: var(--color-on-category-success) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.vShGhxxu1XWvAROhog5i {
  color: var(--color-category-success) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.CjkJJIXjfKV_oZtmGG7w {
  color: var(--color-on-category-info) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.feiiGk_eqJzIKQEsAE1g {
  color: var(--color-category-info) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.TmVAOSHMhMmui_APyHA2 {
  color: var(--color-on-category-warning) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.uTOmTVGcUrgBzOrtVhbl {
  color: var(--color-category-warning) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.WLWceLSneLZKFZSYxC0L {
  color: var(--color-on-category-error) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.aLhb7LdPX5I_XBiTa90g {
  color: var(--color-category-error) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.D_5PNNOh4RJipy85LFfF {
  color: var(--color-on-category-a) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.gfrITwHIFWqGRktx2eXz {
  color: var(--color-category-a) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.KdAx48SOlNeJQWqQ4B5K {
  color: var(--color-on-category-b) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.SIeZoZvMGvPGD4fRzxXs {
  color: var(--color-category-b) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.VoB2yPqrLltVLIGve7mg {
  color: var(--color-on-category-c) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.fV7fEob99WYpnSfRdvZa {
  color: var(--color-category-c) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.ARONZ2mN00l5h3EKr6qM {
  color: var(--color-on-category-d) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.lUZ6v7aaopUvjmM5SXNz {
  color: var(--color-category-d) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.oSmim711aZGjcYVkv3qK {
  color: var(--color-on-category-e) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.D5EaAi1Q8r7zDYIOcgfq {
  color: var(--color-category-e) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.ddLZm3YLVc0PkHkbfXAg {
  color: var(--color-on-category-f) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.gqoinN0ayrvq9HFGiB2V {
  color: var(--color-category-f) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.MVL1ly6FQycqEjHS1szc {
  color: var(--color-on-category-g) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.uXucJKfOQnGc_0wYFkB7 {
  color: var(--color-category-g) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.ltxYBvTwOc0xEGwjwrCM {
  color: var(--color-on-category-h) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.xDybutIKoQhCZbV1XZ1s {
  color: var(--color-category-h) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

/*!*******************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/general/color/Color/Color.interactions.module.css ***!
  \*******************************************************************************************************************************************************************************************************************/
.tcTkcaWVEX7itlVnnhSA:hover {
  color: var(--color-on-primary-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.tcTkcaWVEX7itlVnnhSA:focus {
  color: var(--color-on-primary-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.tcTkcaWVEX7itlVnnhSA:active {
  color: var(--color-on-primary-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.qPOyRSjTR_2eaIO635jy:hover {
  color: var(--color-primary-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.qPOyRSjTR_2eaIO635jy:focus {
  color: var(--color-primary-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.qPOyRSjTR_2eaIO635jy:active {
  color: var(--color-primary-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.MiMhViqBbARbTVtS6GK9:hover {
  color: var(--color-on-primary-container-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.MiMhViqBbARbTVtS6GK9:focus {
  color: var(--color-on-primary-container-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.MiMhViqBbARbTVtS6GK9:active {
  color: var(--color-on-primary-container-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.D_cy_9XVkRvO3QrAgsvc:hover {
  color: var(--color-primary-container-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.D_cy_9XVkRvO3QrAgsvc:focus {
  color: var(--color-primary-container-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.D_cy_9XVkRvO3QrAgsvc:active {
  color: var(--color-primary-container-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.HSZSjtdaoYLszrkd2el7:hover {
  color: var(--color-on-secondary-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.HSZSjtdaoYLszrkd2el7:focus {
  color: var(--color-on-secondary-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.HSZSjtdaoYLszrkd2el7:active {
  color: var(--color-on-secondary-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.ddpNBg8OZ0Ox0esrGelB:hover {
  color: var(--color-secondary-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.ddpNBg8OZ0Ox0esrGelB:focus {
  color: var(--color-secondary-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.ddpNBg8OZ0Ox0esrGelB:active {
  color: var(--color-secondary-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.e4gavtbbmzbRedBIGg7m:hover {
  color: var(--color-on-secondary-container-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.e4gavtbbmzbRedBIGg7m:focus {
  color: var(--color-on-secondary-container-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.e4gavtbbmzbRedBIGg7m:active {
  color: var(--color-on-secondary-container-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.vfKvuBTEBM5ioR4yDt67:hover {
  color: var(--color-secondary-container-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.vfKvuBTEBM5ioR4yDt67:focus {
  color: var(--color-secondary-container-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.vfKvuBTEBM5ioR4yDt67:active {
  color: var(--color-secondary-container-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.OdxYM2tF8b_CIE6GwN77:hover {
  color: var(--color-on-tertiary-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.OdxYM2tF8b_CIE6GwN77:focus {
  color: var(--color-on-tertiary-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.OdxYM2tF8b_CIE6GwN77:active {
  color: var(--color-on-tertiary-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.L3FLdVZBCTOFn6Fl2g_9:hover {
  color: var(--color-tertiary-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.L3FLdVZBCTOFn6Fl2g_9:focus {
  color: var(--color-tertiary-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.L3FLdVZBCTOFn6Fl2g_9:active {
  color: var(--color-tertiary-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.mQlD09UWHrSieoNRmEWQ:hover {
  color: var(--color-on-tertiary-container-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.mQlD09UWHrSieoNRmEWQ:focus {
  color: var(--color-on-tertiary-container-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.mQlD09UWHrSieoNRmEWQ:active {
  color: var(--color-on-tertiary-container-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.YYBlczw6vkCwIA_6Bzp8:hover {
  color: var(--color-tertiary-container-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.YYBlczw6vkCwIA_6Bzp8:focus {
  color: var(--color-tertiary-container-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.YYBlczw6vkCwIA_6Bzp8:active {
  color: var(--color-tertiary-container-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.Kt6uW6RKZzM70t4ORX4l:hover {
  color: var(--color-on-error-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.Kt6uW6RKZzM70t4ORX4l:focus {
  color: var(--color-on-error-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.Kt6uW6RKZzM70t4ORX4l:active {
  color: var(--color-on-error-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.dWI7fpKEJYmhtQyiNSWp:hover {
  color: var(--color-error-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.dWI7fpKEJYmhtQyiNSWp:focus {
  color: var(--color-error-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.dWI7fpKEJYmhtQyiNSWp:active {
  color: var(--color-error-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.MJycWLJTQOOlLRjtEFgA:hover {
  color: var(--color-on-error-container-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.MJycWLJTQOOlLRjtEFgA:focus {
  color: var(--color-on-error-container-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.MJycWLJTQOOlLRjtEFgA:active {
  color: var(--color-on-error-container-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.huHY1rmhnBsihEgfUdkX:hover {
  color: var(--color-error-container-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.huHY1rmhnBsihEgfUdkX:focus {
  color: var(--color-error-container-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.huHY1rmhnBsihEgfUdkX:active {
  color: var(--color-error-container-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

._6MdwDfXVggGRK22Akb1H:hover {
  color: var(--color-on-background-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

._6MdwDfXVggGRK22Akb1H:focus {
  color: var(--color-on-background-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

._6MdwDfXVggGRK22Akb1H:active {
  color: var(--color-on-background-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.Wyh4s80W0UDvSJx7HBbl:hover {
  color: var(--color-background-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.Wyh4s80W0UDvSJx7HBbl:focus {
  color: var(--color-background-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.Wyh4s80W0UDvSJx7HBbl:active {
  color: var(--color-background-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.SP8v_3POQJZ84VOlO8XA:hover {
  color: var(--color-on-surface-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.SP8v_3POQJZ84VOlO8XA:focus {
  color: var(--color-on-surface-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.SP8v_3POQJZ84VOlO8XA:active {
  color: var(--color-on-surface-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.nlltEhiQbdCBDWfvtkgR:hover {
  color: var(--color-surface-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.nlltEhiQbdCBDWfvtkgR:focus {
  color: var(--color-surface-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.nlltEhiQbdCBDWfvtkgR:active {
  color: var(--color-surface-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.FfwBPV1oQvrXT4UbIlSE:hover {
  color: var(--color-on-surface-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.FfwBPV1oQvrXT4UbIlSE:focus {
  color: var(--color-on-surface-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.FfwBPV1oQvrXT4UbIlSE:active {
  color: var(--color-on-surface-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.EGopW2okaUnjc1XC3DKq:hover {
  color: var(--color-surface-1-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.EGopW2okaUnjc1XC3DKq:focus {
  color: var(--color-surface-1-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.EGopW2okaUnjc1XC3DKq:active {
  color: var(--color-surface-1-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.zgDJCmJmjTMfjEC8Hiaq:hover {
  color: var(--color-on-surface-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.zgDJCmJmjTMfjEC8Hiaq:focus {
  color: var(--color-on-surface-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.zgDJCmJmjTMfjEC8Hiaq:active {
  color: var(--color-on-surface-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.Vrx8UzejbwY5TYU4_xrJ:hover {
  color: var(--color-surface-2-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.Vrx8UzejbwY5TYU4_xrJ:focus {
  color: var(--color-surface-2-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.Vrx8UzejbwY5TYU4_xrJ:active {
  color: var(--color-surface-2-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.FAiQOnsCbvSHZ_klfyEA:hover {
  color: var(--color-on-surface-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.FAiQOnsCbvSHZ_klfyEA:focus {
  color: var(--color-on-surface-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.FAiQOnsCbvSHZ_klfyEA:active {
  color: var(--color-on-surface-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.BArp3Bjm4jnC3jdThhjC:hover {
  color: var(--color-surface-3-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.BArp3Bjm4jnC3jdThhjC:focus {
  color: var(--color-surface-3-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.BArp3Bjm4jnC3jdThhjC:active {
  color: var(--color-surface-3-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.ytgKnczSc2Jcg3amGTuF:hover {
  color: var(--color-on-surface-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.ytgKnczSc2Jcg3amGTuF:focus {
  color: var(--color-on-surface-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.ytgKnczSc2Jcg3amGTuF:active {
  color: var(--color-on-surface-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.oXE9_Kh4gXDbROOngI2P:hover {
  color: var(--color-surface-4-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.oXE9_Kh4gXDbROOngI2P:focus {
  color: var(--color-surface-4-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.oXE9_Kh4gXDbROOngI2P:active {
  color: var(--color-surface-4-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.U1ue284h_0aRF7NjMCd8:hover {
  color: var(--color-on-surface-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.U1ue284h_0aRF7NjMCd8:focus {
  color: var(--color-on-surface-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.U1ue284h_0aRF7NjMCd8:active {
  color: var(--color-on-surface-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.vJcaugF_RRkRgalYMf5Y:hover {
  color: var(--color-surface-5-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.vJcaugF_RRkRgalYMf5Y:focus {
  color: var(--color-surface-5-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.vJcaugF_RRkRgalYMf5Y:active {
  color: var(--color-surface-5-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.HZwQQlhgIoKWASsRgr3q:hover {
  color: var(--color-on-outline-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.HZwQQlhgIoKWASsRgr3q:focus {
  color: var(--color-on-outline-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.HZwQQlhgIoKWASsRgr3q:active {
  color: var(--color-on-outline-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.LrjtFtl3dn7JUClLlfIS:hover {
  color: var(--color-on-surface-variant-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.LrjtFtl3dn7JUClLlfIS:focus {
  color: var(--color-on-surface-variant-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.LrjtFtl3dn7JUClLlfIS:active {
  color: var(--color-on-surface-variant-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.j0xPeWLB_Ne8RlCz1s_G:hover {
  color: var(--color-surface-variant-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.j0xPeWLB_Ne8RlCz1s_G:focus {
  color: var(--color-surface-variant-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.j0xPeWLB_Ne8RlCz1s_G:active {
  color: var(--color-surface-variant-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.wSvRglnS9ShW8rI5Op7x:hover {
  color: var(--color-on-category-success-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.wSvRglnS9ShW8rI5Op7x:focus {
  color: var(--color-on-category-success-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.wSvRglnS9ShW8rI5Op7x:active {
  color: var(--color-on-category-success-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.cMjMZy88Ykx7S6PtCpwa:hover {
  color: var(--color-category-success-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.cMjMZy88Ykx7S6PtCpwa:focus {
  color: var(--color-category-success-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.cMjMZy88Ykx7S6PtCpwa:active {
  color: var(--color-category-success-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.xOLMRfz6ZFRDS5y6ut6V:hover {
  color: var(--color-on-category-info-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.xOLMRfz6ZFRDS5y6ut6V:focus {
  color: var(--color-on-category-info-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.xOLMRfz6ZFRDS5y6ut6V:active {
  color: var(--color-on-category-info-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.R1uo6n4U8W9iKQDCv7Qi:hover {
  color: var(--color-category-info-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.R1uo6n4U8W9iKQDCv7Qi:focus {
  color: var(--color-category-info-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.R1uo6n4U8W9iKQDCv7Qi:active {
  color: var(--color-category-info-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.Mp96alOcYe60o03KetDv:hover {
  color: var(--color-on-category-warning-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.Mp96alOcYe60o03KetDv:focus {
  color: var(--color-on-category-warning-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.Mp96alOcYe60o03KetDv:active {
  color: var(--color-on-category-warning-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.BOLCNGXrE81B8Q92DRdg:hover {
  color: var(--color-category-warning-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.BOLCNGXrE81B8Q92DRdg:focus {
  color: var(--color-category-warning-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.BOLCNGXrE81B8Q92DRdg:active {
  color: var(--color-category-warning-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.SFIbeYXVGRCOGjvMQZBo:hover {
  color: var(--color-on-category-error-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.SFIbeYXVGRCOGjvMQZBo:focus {
  color: var(--color-on-category-error-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.SFIbeYXVGRCOGjvMQZBo:active {
  color: var(--color-on-category-error-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.T5kh4toZTCrJVdOQ1JGN:hover {
  color: var(--color-category-error-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.T5kh4toZTCrJVdOQ1JGN:focus {
  color: var(--color-category-error-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.T5kh4toZTCrJVdOQ1JGN:active {
  color: var(--color-category-error-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.SGBmXjA0x9b0NwIFUc9n:hover {
  color: var(--color-on-category-a-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.SGBmXjA0x9b0NwIFUc9n:focus {
  color: var(--color-on-category-a-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.SGBmXjA0x9b0NwIFUc9n:active {
  color: var(--color-on-category-a-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.Pan_4deDi7R_vOwMg6xM:hover {
  color: var(--color-category-a-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.Pan_4deDi7R_vOwMg6xM:focus {
  color: var(--color-category-a-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.Pan_4deDi7R_vOwMg6xM:active {
  color: var(--color-category-a-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.LkBmKAOtjms_h6CDyRci:hover {
  color: var(--color-on-category-b-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.LkBmKAOtjms_h6CDyRci:focus {
  color: var(--color-on-category-b-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.LkBmKAOtjms_h6CDyRci:active {
  color: var(--color-on-category-b-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.dHnZ8K2Vn6dsHe0lIPFp:hover {
  color: var(--color-category-b-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.dHnZ8K2Vn6dsHe0lIPFp:focus {
  color: var(--color-category-b-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.dHnZ8K2Vn6dsHe0lIPFp:active {
  color: var(--color-category-b-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.nwbkMmMQN8OkzjD0kli3:hover {
  color: var(--color-on-category-c-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.nwbkMmMQN8OkzjD0kli3:focus {
  color: var(--color-on-category-c-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.nwbkMmMQN8OkzjD0kli3:active {
  color: var(--color-on-category-c-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.fIGIiQ0_RCD7tEjIfWrg:hover {
  color: var(--color-category-c-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.fIGIiQ0_RCD7tEjIfWrg:focus {
  color: var(--color-category-c-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.fIGIiQ0_RCD7tEjIfWrg:active {
  color: var(--color-category-c-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.yvWumcBfRyUYezZmO5nC:hover {
  color: var(--color-on-category-d-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.yvWumcBfRyUYezZmO5nC:focus {
  color: var(--color-on-category-d-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.yvWumcBfRyUYezZmO5nC:active {
  color: var(--color-on-category-d-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.AIk_oPKzp7JNFyRBdiGL:hover {
  color: var(--color-category-d-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.AIk_oPKzp7JNFyRBdiGL:focus {
  color: var(--color-category-d-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.AIk_oPKzp7JNFyRBdiGL:active {
  color: var(--color-category-d-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.hhJXV6zJkzFobmVYuOtC:hover {
  color: var(--color-on-category-e-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.hhJXV6zJkzFobmVYuOtC:focus {
  color: var(--color-on-category-e-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.hhJXV6zJkzFobmVYuOtC:active {
  color: var(--color-on-category-e-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.BfD6K2baWcCdXqauhFRl:hover {
  color: var(--color-category-e-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.BfD6K2baWcCdXqauhFRl:focus {
  color: var(--color-category-e-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.BfD6K2baWcCdXqauhFRl:active {
  color: var(--color-category-e-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.oICmpJFNVNNwbtzAeTns:hover {
  color: var(--color-on-category-f-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.oICmpJFNVNNwbtzAeTns:focus {
  color: var(--color-on-category-f-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.oICmpJFNVNNwbtzAeTns:active {
  color: var(--color-on-category-f-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.CwKJANHVVcV_mOTP2xtU:hover {
  color: var(--color-category-f-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.CwKJANHVVcV_mOTP2xtU:focus {
  color: var(--color-category-f-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.CwKJANHVVcV_mOTP2xtU:active {
  color: var(--color-category-f-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.vKTnVHR5SI0yHta5n_R8:hover {
  color: var(--color-on-category-g-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.vKTnVHR5SI0yHta5n_R8:focus {
  color: var(--color-on-category-g-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.vKTnVHR5SI0yHta5n_R8:active {
  color: var(--color-on-category-g-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.d9445bIJDNdu04JWRplN:hover {
  color: var(--color-category-g-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.d9445bIJDNdu04JWRplN:focus {
  color: var(--color-category-g-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.d9445bIJDNdu04JWRplN:active {
  color: var(--color-category-g-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.QBc3_XgctP8ZgizYQS9h:hover {
  color: var(--color-on-category-h-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.QBc3_XgctP8ZgizYQS9h:focus {
  color: var(--color-on-category-h-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.QBc3_XgctP8ZgizYQS9h:active {
  color: var(--color-on-category-h-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.A6upP8sIiuHHj50eSlQd:hover {
  color: var(--color-category-h-hover) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.A6upP8sIiuHHj50eSlQd:focus {
  color: var(--color-category-h-focus) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

.A6upP8sIiuHHj50eSlQd:active {
  color: var(--color-category-h-press) !important;
  transition-duration: var(--transition-duration) !important;
  transition-property: background-color, border-color, color;
  transition-timing-function: var(--transition-timing-function) !important;
}

/*!*****************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/general/color/Color/Color.background.module.css ***!
  \*****************************************************************************************************************************************************************************************************************/
.J1S5edM81vglpOxex5Qg {
  background-color: var(--color-primary) !important;
}

.d2MkoAyvB2Ovp2ciF3Va {
  background-color: var(--color-on-primary) !important;
}

.bKuSdAzhmp70sfTK6w2p {
  background-color: var(--color-primary-container) !important;
}

.NfgDTjLQG191gPfcai15 {
  background-color: var(--color-on-primary-container) !important;
}

.Wg9Xfxso_k0Hd44vxeRW {
  background-color: var(--color-secondary) !important;
}

.QBSwbdjUfLcTU9hEwgbR {
  background-color: var(--color-on-secondary) !important;
}

.CiCp0eNq87a9aW0ZiAnF {
  background-color: var(--color-secondary-container) !important;
}

.iZGzPJSp_7PCXoZnqsUq {
  background-color: var(--color-on-secondary-container) !important;
}

._ZabwUIs74GPfpmZHV0Y {
  background-color: var(--color-tertiary) !important;
}

.Ua9APzjV2QrDmBEL6FtY {
  background-color: var(--color-on-tertiary) !important;
}

.l34JEB7oEQ8W9PZsXxgP {
  background-color: var(--color-tertiary-container) !important;
}

.fG5J9Ej9rbaXwfpJkPbY {
  background-color: var(--color-on-tertiary-container) !important;
}

.Ysb5nxY5b0U3XAJYU7Kh {
  background-color: var(--color-error) !important;
}

.ihi6XiH7LOPXjGzckLjg {
  background-color: var(--color-on-error) !important;
}

.Z2Q0JVrTKhWqqFJiBOz8 {
  background-color: var(--color-error-container) !important;
}

._9iZvobBKeP0q92EE3jG {
  background-color: var(--color-on-error-container) !important;
}

.WrmXb9A0vFWAlxcbD603 {
  background-color: var(--color-background) !important;
}

.xdrsMuzcfu7gwO9qGsm6 {
  background-color: var(--color-on-background) !important;
}

.JspGBoOED4fHRSXEXV7A {
  background-color: var(--color-surface) !important;
}

.CpxvGUCrPHik0IwJ0ip1 {
  background-color: var(--color-on-surface) !important;
}

.umEQpL5lSgNPeOzulM9j {
  background-color: var(--color-surface-1) !important;
}

.Xb4_6DDn0un7qpOTNhPx {
  background-color: var(--color-on-surface) !important;
}

.gzblodiKpch3fycmFEBg {
  background-color: var(--color-surface-2) !important;
}

.zmZmMWnJD_UPhXXqLbO_ {
  background-color: var(--color-on-surface) !important;
}

.Ge6hj7Nz8AmwM81_0ilU {
  background-color: var(--color-surface-3) !important;
}

.UrWZJpDcBh_tOZMZAwcT {
  background-color: var(--color-on-surface) !important;
}

.INAm3PeuTaCV7Ymss1Bg {
  background-color: var(--color-surface-4) !important;
}

.zXvLiV6sz6DPsLagvK4H {
  background-color: var(--color-on-surface) !important;
}

.BppDcIUVTfSfCX2HwD96 {
  background-color: var(--color-surface-5) !important;
}

.LSujLzqDOZLLonhB4j1Y {
  background-color: var(--color-on-surface) !important;
}

.cfxp_Hp3bxOqxOtdBZ4l {
  background-color: var(--color-outline) !important;
}

.PesW9Be8EbVb2sgCmRqk {
  background-color: var(--color-surface-variant) !important;
}

.DdG4M7g71G0tIRggiO9w {
  background-color: var(--color-on-surface-variant) !important;
}

.XDSc6mmromNIjglFjkJg {
  background-color: var(--color-category-success) !important;
}

.l0SwvjbEqu_VVimC0ziQ {
  background-color: var(--color-on-category-success) !important;
}

.LwdJEqE1dfoLxfUIZ8s3 {
  background-color: var(--color-category-info) !important;
}

.NCBOFJ9gYmAY2Bg9thUe {
  background-color: var(--color-on-category-info) !important;
}

.q_Nn0ac3HQGlVXAiRiHf {
  background-color: var(--color-category-warning) !important;
}

.jYKFhDJw7W9OItvCBtGZ {
  background-color: var(--color-on-category-warning) !important;
}

.fUo4LqfUTH0TFBhF79qw {
  background-color: var(--color-category-error) !important;
}

.P7gzhtNTAJ6I4zUCk8vV {
  background-color: var(--color-on-category-error) !important;
}

.sghCR7W1_slYqUaDy1AS {
  background-color: var(--color-category-a) !important;
}

.OkOAfgAPZsRkJ1Rdl66S {
  background-color: var(--color-on-category-a) !important;
}

.TpKWBrwRNCI4hxXcmV6x {
  background-color: var(--color-category-b) !important;
}

.HVy2OQVfBpMnZIIg4XVb {
  background-color: var(--color-on-category-b) !important;
}

.uBs8PldBrQIC1GYJGM4h {
  background-color: var(--color-category-c) !important;
}

.fJ905L4qlrs97z3ekPY1 {
  background-color: var(--color-on-category-c) !important;
}

.tvJjdknbbFhOt2VrynCk {
  background-color: var(--color-category-d) !important;
}

.MGk1K0NY_aZ2KTisV3Rq {
  background-color: var(--color-on-category-d) !important;
}

.wdWG7mePFZxhIe_UCoI3 {
  background-color: var(--color-category-e) !important;
}

.Xalq5gXkesz9WybBYJoi {
  background-color: var(--color-on-category-e) !important;
}

.FhnD6VF6I3z6vSO5agpD {
  background-color: var(--color-category-f) !important;
}

.s7i3dxV474g1IpYmGBeB {
  background-color: var(--color-on-category-f) !important;
}

.mixGY5EtUq81i7Bzg4lK {
  background-color: var(--color-category-g) !important;
}

.QHdwMP_HWjceciqWRkUK {
  background-color: var(--color-on-category-g) !important;
}

.bsdw3kJ3ijT1Hd5b9poY {
  background-color: var(--color-category-h) !important;
}

.QgFmJl1lhV2nnFAp6IhM {
  background-color: var(--color-on-category-h) !important;
}

/*!******************************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/general/color/Color/Color.background.interactions.module.css ***!
  \******************************************************************************************************************************************************************************************************************************/
.IGn_99CYlp7n5tUyIXtG:hover {
  background-color: var(--color-primary-hover) !important;
}

.IGn_99CYlp7n5tUyIXtG:focus {
  background-color: var(--color-primary-focus) !important;
}

.IGn_99CYlp7n5tUyIXtG:active {
  background-color: var(--color-primary-press) !important;
}

.I0pKKE_zurbQ2BlWqEbq:hover {
  background-color: var(--color-on-primary-hover) !important;
}

.I0pKKE_zurbQ2BlWqEbq:focus {
  background-color: var(--color-on-primary-focus) !important;
}

.I0pKKE_zurbQ2BlWqEbq:active {
  background-color: var(--color-on-primary-press) !important;
}

.ivQUNsuP9qwEJp_AabfA:hover {
  background-color: var(--color-primary-container-hover) !important;
}

.ivQUNsuP9qwEJp_AabfA:focus {
  background-color: var(--color-primary-container-focus) !important;
}

.ivQUNsuP9qwEJp_AabfA:active {
  background-color: var(--color-primary-container-press) !important;
}

.G8ktoXz8cA1_tLtvc5n7:hover {
  background-color: var(--color-on-primary-container-hover) !important;
}

.G8ktoXz8cA1_tLtvc5n7:focus {
  background-color: var(--color-on-primary-container-focus) !important;
}

.G8ktoXz8cA1_tLtvc5n7:active {
  background-color: var(--color-on-primary-container-press) !important;
}

.Q2naR4mid9w_23EVlPR_:hover {
  background-color: var(--color-secondary-hover) !important;
}

.Q2naR4mid9w_23EVlPR_:focus {
  background-color: var(--color-secondary-focus) !important;
}

.Q2naR4mid9w_23EVlPR_:active {
  background-color: var(--color-secondary-press) !important;
}

.JqFIFWzvPky39ox5OjZH:hover {
  background-color: var(--color-on-secondary-hover) !important;
}

.JqFIFWzvPky39ox5OjZH:focus {
  background-color: var(--color-on-secondary-focus) !important;
}

.JqFIFWzvPky39ox5OjZH:active {
  background-color: var(--color-on-secondary-press) !important;
}

.QGIEcK2EKZcv318pa4Da:hover {
  background-color: var(--color-secondary-container-hover) !important;
}

.QGIEcK2EKZcv318pa4Da:focus {
  background-color: var(--color-secondary-container-focus) !important;
}

.QGIEcK2EKZcv318pa4Da:active {
  background-color: var(--color-secondary-container-press) !important;
}

.sL_8X4x1mXQt7vAX97Yu:hover {
  background-color: var(--color-on-secondary-container-hover) !important;
}

.sL_8X4x1mXQt7vAX97Yu:focus {
  background-color: var(--color-on-secondary-container-focus) !important;
}

.sL_8X4x1mXQt7vAX97Yu:active {
  background-color: var(--color-on-secondary-container-press) !important;
}

.lxd0xUXhtha5HPQNy92s:hover {
  background-color: var(--color-tertiary-hover) !important;
}

.lxd0xUXhtha5HPQNy92s:focus {
  background-color: var(--color-tertiary-focus) !important;
}

.lxd0xUXhtha5HPQNy92s:active {
  background-color: var(--color-tertiary-press) !important;
}

.Vfz7Qd_ljMLYpnJB8F2w:hover {
  background-color: var(--color-on-tertiary-hover) !important;
}

.Vfz7Qd_ljMLYpnJB8F2w:focus {
  background-color: var(--color-on-tertiary-focus) !important;
}

.Vfz7Qd_ljMLYpnJB8F2w:active {
  background-color: var(--color-on-tertiary-press) !important;
}

._aPHRvvRsU_MQhJUnaYu:hover {
  background-color: var(--color-tertiary-container-hover) !important;
}

._aPHRvvRsU_MQhJUnaYu:focus {
  background-color: var(--color-tertiary-container-focus) !important;
}

._aPHRvvRsU_MQhJUnaYu:active {
  background-color: var(--color-tertiary-container-press) !important;
}

.syfrV1bHfPBTwXntFV4w:hover {
  background-color: var(--color-on-tertiary-container-hover) !important;
}

.syfrV1bHfPBTwXntFV4w:focus {
  background-color: var(--color-on-tertiary-container-focus) !important;
}

.syfrV1bHfPBTwXntFV4w:active {
  background-color: var(--color-on-tertiary-container-press) !important;
}

.qKaKNiWhAobd98eJAWW4:hover {
  background-color: var(--color-error-hover) !important;
}

.qKaKNiWhAobd98eJAWW4:focus {
  background-color: var(--color-error-focus) !important;
}

.qKaKNiWhAobd98eJAWW4:active {
  background-color: var(--color-error-press) !important;
}

.GCXPOdPr9Ggf9ZINiBvd:hover {
  background-color: var(--color-on-error-hover) !important;
}

.GCXPOdPr9Ggf9ZINiBvd:focus {
  background-color: var(--color-on-error-focus) !important;
}

.GCXPOdPr9Ggf9ZINiBvd:active {
  background-color: var(--color-on-error-press) !important;
}

.K57sZSwawFx2GmU8CENG:hover {
  background-color: var(--color-error-container-hover) !important;
}

.K57sZSwawFx2GmU8CENG:focus {
  background-color: var(--color-error-container-focus) !important;
}

.K57sZSwawFx2GmU8CENG:active {
  background-color: var(--color-error-container-press) !important;
}

.MH5uWbBcqZ4JZ840E9GB:hover {
  background-color: var(--color-on-error-container-hover) !important;
}

.MH5uWbBcqZ4JZ840E9GB:focus {
  background-color: var(--color-on-error-container-focus) !important;
}

.MH5uWbBcqZ4JZ840E9GB:active {
  background-color: var(--color-on-error-container-press) !important;
}

.iKGuQw_ayUPbtrZUPoy3:hover {
  background-color: var(--color-background-hover) !important;
}

.iKGuQw_ayUPbtrZUPoy3:focus {
  background-color: var(--color-background-focus) !important;
}

.iKGuQw_ayUPbtrZUPoy3:active {
  background-color: var(--color-background-press) !important;
}

.zYQjziQn9vzVC3RwnXa3:hover {
  background-color: var(--color-on-background-hover) !important;
}

.zYQjziQn9vzVC3RwnXa3:focus {
  background-color: var(--color-on-background-focus) !important;
}

.zYQjziQn9vzVC3RwnXa3:active {
  background-color: var(--color-on-background-press) !important;
}

.twk_quPMpuuHvLGwCRjN:hover {
  background-color: var(--color-surface-hover) !important;
}

.twk_quPMpuuHvLGwCRjN:focus {
  background-color: var(--color-surface-focus) !important;
}

.twk_quPMpuuHvLGwCRjN:active {
  background-color: var(--color-surface-press) !important;
}

.kbdBlR3ab23QgvvelOrA:hover {
  background-color: var(--color-on-surface-hover) !important;
}

.kbdBlR3ab23QgvvelOrA:focus {
  background-color: var(--color-on-surface-focus) !important;
}

.kbdBlR3ab23QgvvelOrA:active {
  background-color: var(--color-on-surface-press) !important;
}

.lnbeMDjKWeNDxeJK60Gj:hover {
  background-color: var(--color-surface-1-hover) !important;
}

.lnbeMDjKWeNDxeJK60Gj:focus {
  background-color: var(--color-surface-1-focus) !important;
}

.lnbeMDjKWeNDxeJK60Gj:active {
  background-color: var(--color-surface-1-press) !important;
}

.uaKuS37BwiUHpwf3V509:hover {
  background-color: var(--color-on-surface-hover) !important;
}

.uaKuS37BwiUHpwf3V509:focus {
  background-color: var(--color-on-surface-focus) !important;
}

.uaKuS37BwiUHpwf3V509:active {
  background-color: var(--color-on-surface-press) !important;
}

.HSGeSVkQNhbc449yBiec:hover {
  background-color: var(--color-surface-2-hover) !important;
}

.HSGeSVkQNhbc449yBiec:focus {
  background-color: var(--color-surface-2-focus) !important;
}

.HSGeSVkQNhbc449yBiec:active {
  background-color: var(--color-surface-2-press) !important;
}

.zeEpz_c0IovLM5VUvGRA:hover {
  background-color: var(--color-on-surface-hover) !important;
}

.zeEpz_c0IovLM5VUvGRA:focus {
  background-color: var(--color-on-surface-focus) !important;
}

.zeEpz_c0IovLM5VUvGRA:active {
  background-color: var(--color-on-surface-press) !important;
}

.E_gGHn5ra1a9K2G3W333:hover {
  background-color: var(--color-surface-3-hover) !important;
}

.E_gGHn5ra1a9K2G3W333:focus {
  background-color: var(--color-surface-3-focus) !important;
}

.E_gGHn5ra1a9K2G3W333:active {
  background-color: var(--color-surface-3-press) !important;
}

.VnaiWbZIjU3S6diWNc3N:hover {
  background-color: var(--color-on-surface-hover) !important;
}

.VnaiWbZIjU3S6diWNc3N:focus {
  background-color: var(--color-on-surface-focus) !important;
}

.VnaiWbZIjU3S6diWNc3N:active {
  background-color: var(--color-on-surface-press) !important;
}

.dqLH5LnLpby9ABuODp20:hover {
  background-color: var(--color-surface-4-hover) !important;
}

.dqLH5LnLpby9ABuODp20:focus {
  background-color: var(--color-surface-4-focus) !important;
}

.dqLH5LnLpby9ABuODp20:active {
  background-color: var(--color-surface-4-press) !important;
}

.AUcLNq9fawq7mveHxkDs:hover {
  background-color: var(--color-on-surface-hover) !important;
}

.AUcLNq9fawq7mveHxkDs:focus {
  background-color: var(--color-on-surface-focus) !important;
}

.AUcLNq9fawq7mveHxkDs:active {
  background-color: var(--color-on-surface-press) !important;
}

._7kpavwug6EpSNPnGD3K9:hover {
  background-color: var(--color-surface-5-hover) !important;
}

._7kpavwug6EpSNPnGD3K9:focus {
  background-color: var(--color-surface-5-focus) !important;
}

._7kpavwug6EpSNPnGD3K9:active {
  background-color: var(--color-surface-5-press) !important;
}

.t7KVoZu9E1hZvOTniJCa:hover {
  background-color: var(--color-on-surface-hover) !important;
}

.t7KVoZu9E1hZvOTniJCa:focus {
  background-color: var(--color-on-surface-focus) !important;
}

.t7KVoZu9E1hZvOTniJCa:active {
  background-color: var(--color-on-surface-press) !important;
}

._wgYx3LRoyY73U6bfhHv:hover {
  background-color: var(--color-outline-hover) !important;
}

._wgYx3LRoyY73U6bfhHv:focus {
  background-color: var(--color-outline-focus) !important;
}

._wgYx3LRoyY73U6bfhHv:active {
  background-color: var(--color-outline-press) !important;
}

.iFb2v4PzYnEvyJ4gSC7A:hover {
  background-color: var(--color-surface-variant-hover) !important;
}

.iFb2v4PzYnEvyJ4gSC7A:focus {
  background-color: var(--color-surface-variant-focus) !important;
}

.iFb2v4PzYnEvyJ4gSC7A:active {
  background-color: var(--color-surface-variant-press) !important;
}

.DYVsqJkqxvkGALI6k9yi:hover {
  background-color: var(--color-on-surface-variant-hover) !important;
}

.DYVsqJkqxvkGALI6k9yi:focus {
  background-color: var(--color-on-surface-variant-focus) !important;
}

.DYVsqJkqxvkGALI6k9yi:active {
  background-color: var(--color-on-surface-variant-press) !important;
}

.XwELFugZOmFmOU6CNOfz:hover {
  background-color: var(--color-category-success-hover) !important;
}

.XwELFugZOmFmOU6CNOfz:focus {
  background-color: var(--color-category-success-focus) !important;
}

.XwELFugZOmFmOU6CNOfz:active {
  background-color: var(--color-category-success-press) !important;
}

.Z1O69sgq6JWggujYpA0p:hover {
  background-color: var(--color-on-category-success-hover) !important;
}

.Z1O69sgq6JWggujYpA0p:focus {
  background-color: var(--color-on-category-success-focus) !important;
}

.Z1O69sgq6JWggujYpA0p:active {
  background-color: var(--color-on-category-success-press) !important;
}

.pxGpK8h7IpaYy9KRzidC:hover {
  background-color: var(--color-category-info-hover) !important;
}

.pxGpK8h7IpaYy9KRzidC:focus {
  background-color: var(--color-category-info-focus) !important;
}

.pxGpK8h7IpaYy9KRzidC:active {
  background-color: var(--color-category-info-press) !important;
}

.ACU6n3zRU8s6s9XazsgB:hover {
  background-color: var(--color-on-category-info-hover) !important;
}

.ACU6n3zRU8s6s9XazsgB:focus {
  background-color: var(--color-on-category-info-focus) !important;
}

.ACU6n3zRU8s6s9XazsgB:active {
  background-color: var(--color-on-category-info-press) !important;
}

.yWP1XpTzGmtkXFNm1WHV:hover {
  background-color: var(--color-category-warning-hover) !important;
}

.yWP1XpTzGmtkXFNm1WHV:focus {
  background-color: var(--color-category-warning-focus) !important;
}

.yWP1XpTzGmtkXFNm1WHV:active {
  background-color: var(--color-category-warning-press) !important;
}

.Bpm93KNT1fFBuj0ARJLG:hover {
  background-color: var(--color-on-category-warning-hover) !important;
}

.Bpm93KNT1fFBuj0ARJLG:focus {
  background-color: var(--color-on-category-warning-focus) !important;
}

.Bpm93KNT1fFBuj0ARJLG:active {
  background-color: var(--color-on-category-warning-press) !important;
}

.hVRno6JaIG_wHy722iRI:hover {
  background-color: var(--color-category-error-hover) !important;
}

.hVRno6JaIG_wHy722iRI:focus {
  background-color: var(--color-category-error-focus) !important;
}

.hVRno6JaIG_wHy722iRI:active {
  background-color: var(--color-category-error-press) !important;
}

.wcswgoHdwrUmpjvc0dgt:hover {
  background-color: var(--color-on-category-error-hover) !important;
}

.wcswgoHdwrUmpjvc0dgt:focus {
  background-color: var(--color-on-category-error-focus) !important;
}

.wcswgoHdwrUmpjvc0dgt:active {
  background-color: var(--color-on-category-error-press) !important;
}

.BQKuIeTykS_aMCZ9TFSl:hover {
  background-color: var(--color-category-a-hover) !important;
}

.BQKuIeTykS_aMCZ9TFSl:focus {
  background-color: var(--color-category-a-focus) !important;
}

.BQKuIeTykS_aMCZ9TFSl:active {
  background-color: var(--color-category-a-press) !important;
}

.QweYM10G6WHKXcfl6eap:hover {
  background-color: var(--color-on-category-a-hover) !important;
}

.QweYM10G6WHKXcfl6eap:focus {
  background-color: var(--color-on-category-a-focus) !important;
}

.QweYM10G6WHKXcfl6eap:active {
  background-color: var(--color-on-category-a-press) !important;
}

.WOmwNxKN4EezNG3nydn9:hover {
  background-color: var(--color-category-b-hover) !important;
}

.WOmwNxKN4EezNG3nydn9:focus {
  background-color: var(--color-category-b-focus) !important;
}

.WOmwNxKN4EezNG3nydn9:active {
  background-color: var(--color-category-b-press) !important;
}

.uNf6gF5UwTcVOioe6R0h:hover {
  background-color: var(--color-on-category-b-hover) !important;
}

.uNf6gF5UwTcVOioe6R0h:focus {
  background-color: var(--color-on-category-b-focus) !important;
}

.uNf6gF5UwTcVOioe6R0h:active {
  background-color: var(--color-on-category-b-press) !important;
}

.OF9rX1IVxSZ0_6Emihvw:hover {
  background-color: var(--color-category-c-hover) !important;
}

.OF9rX1IVxSZ0_6Emihvw:focus {
  background-color: var(--color-category-c-focus) !important;
}

.OF9rX1IVxSZ0_6Emihvw:active {
  background-color: var(--color-category-c-press) !important;
}

.zeP09q8FMPEHc72qU1B5:hover {
  background-color: var(--color-on-category-c-hover) !important;
}

.zeP09q8FMPEHc72qU1B5:focus {
  background-color: var(--color-on-category-c-focus) !important;
}

.zeP09q8FMPEHc72qU1B5:active {
  background-color: var(--color-on-category-c-press) !important;
}

.UOcvBfnBi4XVmaGwmnw9:hover {
  background-color: var(--color-category-d-hover) !important;
}

.UOcvBfnBi4XVmaGwmnw9:focus {
  background-color: var(--color-category-d-focus) !important;
}

.UOcvBfnBi4XVmaGwmnw9:active {
  background-color: var(--color-category-d-press) !important;
}

.b5WBaedJRxW4FWY6qHAV:hover {
  background-color: var(--color-on-category-d-hover) !important;
}

.b5WBaedJRxW4FWY6qHAV:focus {
  background-color: var(--color-on-category-d-focus) !important;
}

.b5WBaedJRxW4FWY6qHAV:active {
  background-color: var(--color-on-category-d-press) !important;
}

.xWVGcvgCWhrt02afZC4C:hover {
  background-color: var(--color-category-e-hover) !important;
}

.xWVGcvgCWhrt02afZC4C:focus {
  background-color: var(--color-category-e-focus) !important;
}

.xWVGcvgCWhrt02afZC4C:active {
  background-color: var(--color-category-e-press) !important;
}

.rwP41Lx0BazJEv3cgkep:hover {
  background-color: var(--color-on-category-e-hover) !important;
}

.rwP41Lx0BazJEv3cgkep:focus {
  background-color: var(--color-on-category-e-focus) !important;
}

.rwP41Lx0BazJEv3cgkep:active {
  background-color: var(--color-on-category-e-press) !important;
}

.b5cY9fApiw9xIEhIWl8g:hover {
  background-color: var(--color-category-f-hover) !important;
}

.b5cY9fApiw9xIEhIWl8g:focus {
  background-color: var(--color-category-f-focus) !important;
}

.b5cY9fApiw9xIEhIWl8g:active {
  background-color: var(--color-category-f-press) !important;
}

._xYRzd7uuhUWUiw2vGIk:hover {
  background-color: var(--color-on-category-f-hover) !important;
}

._xYRzd7uuhUWUiw2vGIk:focus {
  background-color: var(--color-on-category-f-focus) !important;
}

._xYRzd7uuhUWUiw2vGIk:active {
  background-color: var(--color-on-category-f-press) !important;
}

.SIaXEQwfC_SsOvC3lLif:hover {
  background-color: var(--color-category-g-hover) !important;
}

.SIaXEQwfC_SsOvC3lLif:focus {
  background-color: var(--color-category-g-focus) !important;
}

.SIaXEQwfC_SsOvC3lLif:active {
  background-color: var(--color-category-g-press) !important;
}

.KPRym0rCaBvc_OiptAGg:hover {
  background-color: var(--color-on-category-g-hover) !important;
}

.KPRym0rCaBvc_OiptAGg:focus {
  background-color: var(--color-on-category-g-focus) !important;
}

.KPRym0rCaBvc_OiptAGg:active {
  background-color: var(--color-on-category-g-press) !important;
}

.oViIEN0NjZdQ3a6xKqYP:hover {
  background-color: var(--color-category-h-hover) !important;
}

.oViIEN0NjZdQ3a6xKqYP:focus {
  background-color: var(--color-category-h-focus) !important;
}

.oViIEN0NjZdQ3a6xKqYP:active {
  background-color: var(--color-category-h-press) !important;
}

.obqeSeZUrr3xNG85buhu:hover {
  background-color: var(--color-on-category-h-hover) !important;
}

.obqeSeZUrr3xNG85buhu:focus {
  background-color: var(--color-on-category-h-focus) !important;
}

.obqeSeZUrr3xNG85buhu:active {
  background-color: var(--color-on-category-h-press) !important;
}

/*!*************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/general/color/Color/Color.border.module.css ***!
  \*************************************************************************************************************************************************************************************************************/
.n3FkWsFtWThaPPrrscNN {
  border-color: var(--color-primary) !important;
}

.ZKSaooG_wGmcyXIzwDdH {
  border-color: var(--color-on-primary) !important;
}

.vuRLQ0Kew4fzrqTJ0e1_ {
  border-color: var(--color-primary-container) !important;
}

.wFxWi7x3HnjPA6t0sNGA {
  border-color: var(--color-on-primary-container) !important;
}

.CsAGQ2y8tBbkdEau65jB {
  border-color: var(--color-secondary) !important;
}

.oTQGkoqIiOvGRaVIrbhB {
  border-color: var(--color-on-secondary) !important;
}

.KhkbukipWcuoKN_qgoA0 {
  border-color: var(--color-secondary-container) !important;
}

.BuoXKTHM7QpKsvoa3Yv5 {
  border-color: var(--color-on-secondary-container) !important;
}

.ehiYojUxpwPU_MRs5Tqq {
  border-color: var(--color-tertiary) !important;
}

.ACoYCs7Igtrn39TPASAH {
  border-color: var(--color-on-tertiary) !important;
}

.KjlTylXesSDEsxE33_Of {
  border-color: var(--color-tertiary-container) !important;
}

.eZ0PsZSDTV5euCj_6YKQ {
  border-color: var(--color-on-tertiary-container) !important;
}

.YWM8X_QzRiZ80kPa7AJt {
  border-color: var(--color-error) !important;
}

.L0sJuZ1vAMybkf7V3o_o {
  border-color: var(--color-on-error) !important;
}

.u9_98Vq97maSTPd9vRsg {
  border-color: var(--color-error-container) !important;
}

.CSddB3iPcRj9Fk4I9ynI {
  border-color: var(--color-on-error-container) !important;
}

.mJdMMdTJEhejWyup4D9M {
  border-color: var(--color-background) !important;
}

.Nrtc3q4MFnh84Ark1uoe {
  border-color: var(--color-on-background) !important;
}

.AI3ePS95H3jev7mBPVe1 {
  border-color: var(--color-surface) !important;
}

.g4lVaF2nKtNE_WFoaWaO {
  border-color: var(--color-on-surface) !important;
}

.tuame06y4FynpbQeHHN0 {
  border-color: var(--color-surface-1) !important;
}

.Z_UIKWWRW53r4oDZu_Ak {
  border-color: var(--color-on-surface) !important;
}

.SR7NvnZX8JKcZuTiJfjr {
  border-color: var(--color-surface-2) !important;
}

.raRSjeEh0V68YzBXp66G {
  border-color: var(--color-on-surface) !important;
}

.TY5aXwJOZjIWLwfzD46w {
  border-color: var(--color-surface-3) !important;
}

.vIbTbd_GMkTvQ95RUkl2 {
  border-color: var(--color-on-surface) !important;
}

.YAZTC6uvmb8c3uENHxaL {
  border-color: var(--color-surface-4) !important;
}

.bMyn0o6ZqWFZvP4o3YKs {
  border-color: var(--color-on-surface) !important;
}

.sOgziKwZsJMa2nNlu4T1 {
  border-color: var(--color-surface-5) !important;
}

.Nw8AeBXIpPIeDvy1t7nH {
  border-color: var(--color-on-surface) !important;
}

.uJbpqMsnb7IvAqXNNKX6 {
  border-color: var(--color-outline) !important;
}

._wxBScVIhAA8iIHmdWiY {
  border-color: var(--color-surface-variant) !important;
}

.wWmaCP7eAnPncjHzh3Hj {
  border-color: var(--color-on-surface-variant) !important;
}

.sYRrQT2BJkqhlL7VUS2R {
  border-color: var(--color-category-success) !important;
}

.v4x9Fr79wrzOdxecW0_a {
  border-color: var(--color-on-category-success) !important;
}

.nhfd_ojzVSRfhFF1tzaQ {
  border-color: var(--color-category-info) !important;
}

.tzIwj_4TKhIf2X5VB8vg {
  border-color: var(--color-on-category-info) !important;
}

.UvTvHFJuPu4cydRzg0t7 {
  border-color: var(--color-category-warning) !important;
}

.IQwJpLAzOxybio47BxAi {
  border-color: var(--color-on-category-warning) !important;
}

.qfEiVXDfY0Pw4HxZypb3 {
  border-color: var(--color-category-error) !important;
}

.Spk5hsGnM5zvXJMyLh_b {
  border-color: var(--color-on-category-error) !important;
}

.nwmhf9yQk2QdsLD0kYPs {
  border-color: var(--color-category-a) !important;
}

.YLWSj0P7yGbhsMEQ4Bco {
  border-color: var(--color-on-category-a) !important;
}

.Tr14ZICtNQJThJ68h7jm {
  border-color: var(--color-category-b) !important;
}

.d65oIh0Npfiz0wn7POEF {
  border-color: var(--color-on-category-b) !important;
}

.E1jN6dFEnNLSP8gJZ9AR {
  border-color: var(--color-category-c) !important;
}

.CnkXvMzGcP5kHGm7XvkP {
  border-color: var(--color-on-category-c) !important;
}

.XxHqySShsRRQ4REH5oP8 {
  border-color: var(--color-category-d) !important;
}

.cSkkDoiUSxifsjYYVqPK {
  border-color: var(--color-on-category-d) !important;
}

.Y2p25SZQQH7jGudXYotR {
  border-color: var(--color-category-e) !important;
}

.w8de06fj9lxzopUdG2KX {
  border-color: var(--color-on-category-e) !important;
}

.j1l57o4oU47wWwAjpuqq {
  border-color: var(--color-category-f) !important;
}

.bIhz5ulKDdLeZH3ULcBZ {
  border-color: var(--color-on-category-f) !important;
}

.nRQfY9i33aUjOTyM9dCd {
  border-color: var(--color-category-g) !important;
}

.jvmdOcKfn4QCjsbfJmSb {
  border-color: var(--color-on-category-g) !important;
}

.tZhRhKmkDrVZjp1Ew8ns {
  border-color: var(--color-category-h) !important;
}

.YaCqd9fu85wWznNVMP1B {
  border-color: var(--color-on-category-h) !important;
}

/*!**************************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/general/color/Color/Color.border.interactions.module.css ***!
  \**************************************************************************************************************************************************************************************************************************/
.KZ3vrsvePlCv1ayyUyxa:hover {
  border-color: var(--color-primary-hover) !important;
}

.KZ3vrsvePlCv1ayyUyxa:focus {
  border-color: var(--color-primary-focus) !important;
}

.KZ3vrsvePlCv1ayyUyxa:active {
  border-color: var(--color-primary-press) !important;
}

.w3GbGGuLOtM1urcZnyA_:hover {
  border-color: var(--color-on-primary-hover) !important;
}

.w3GbGGuLOtM1urcZnyA_:focus {
  border-color: var(--color-on-primary-focus) !important;
}

.w3GbGGuLOtM1urcZnyA_:active {
  border-color: var(--color-on-primary-press) !important;
}

.wokMnUOHw5FZpY1N6YIy:hover {
  border-color: var(--color-primary-container-hover) !important;
}

.wokMnUOHw5FZpY1N6YIy:focus {
  border-color: var(--color-primary-container-focus) !important;
}

.wokMnUOHw5FZpY1N6YIy:active {
  border-color: var(--color-primary-container-press) !important;
}

.LHi8Zc956ar7O21Vk7A9:hover {
  border-color: var(--color-on-primary-container-hover) !important;
}

.LHi8Zc956ar7O21Vk7A9:focus {
  border-color: var(--color-on-primary-container-focus) !important;
}

.LHi8Zc956ar7O21Vk7A9:active {
  border-color: var(--color-on-primary-container-press) !important;
}

.pokbN1ULezMqb3QRnZlN:hover {
  border-color: var(--color-secondary-hover) !important;
}

.pokbN1ULezMqb3QRnZlN:focus {
  border-color: var(--color-secondary-focus) !important;
}

.pokbN1ULezMqb3QRnZlN:active {
  border-color: var(--color-secondary-press) !important;
}

.XI9CcMJV92mDT8UlhK_p:hover {
  border-color: var(--color-on-secondary-hover) !important;
}

.XI9CcMJV92mDT8UlhK_p:focus {
  border-color: var(--color-on-secondary-focus) !important;
}

.XI9CcMJV92mDT8UlhK_p:active {
  border-color: var(--color-on-secondary-press) !important;
}

.OTqT1FmGPKdARD6fy8Om:hover {
  border-color: var(--color-secondary-container-hover) !important;
}

.OTqT1FmGPKdARD6fy8Om:focus {
  border-color: var(--color-secondary-container-focus) !important;
}

.OTqT1FmGPKdARD6fy8Om:active {
  border-color: var(--color-secondary-container-press) !important;
}

.sEx7swnWZ2CUMX6auuXj:hover {
  border-color: var(--color-on-secondary-container-hover) !important;
}

.sEx7swnWZ2CUMX6auuXj:focus {
  border-color: var(--color-on-secondary-container-focus) !important;
}

.sEx7swnWZ2CUMX6auuXj:active {
  border-color: var(--color-on-secondary-container-press) !important;
}

.V_BTxN1ZlZpAy2c8n1Ce:hover {
  border-color: var(--color-tertiary-hover) !important;
}

.V_BTxN1ZlZpAy2c8n1Ce:focus {
  border-color: var(--color-tertiary-focus) !important;
}

.V_BTxN1ZlZpAy2c8n1Ce:active {
  border-color: var(--color-tertiary-press) !important;
}

.e4cZfKPxv5Q5uxJ5mqA0:hover {
  border-color: var(--color-on-tertiary-hover) !important;
}

.e4cZfKPxv5Q5uxJ5mqA0:focus {
  border-color: var(--color-on-tertiary-focus) !important;
}

.e4cZfKPxv5Q5uxJ5mqA0:active {
  border-color: var(--color-on-tertiary-press) !important;
}

.qLlqVrymf2Ah_AYrzjDl:hover {
  border-color: var(--color-tertiary-container-hover) !important;
}

.qLlqVrymf2Ah_AYrzjDl:focus {
  border-color: var(--color-tertiary-container-focus) !important;
}

.qLlqVrymf2Ah_AYrzjDl:active {
  border-color: var(--color-tertiary-container-press) !important;
}

.dlYYLZVWzSmWQo2tbQ1F:hover {
  border-color: var(--color-on-tertiary-container-hover) !important;
}

.dlYYLZVWzSmWQo2tbQ1F:focus {
  border-color: var(--color-on-tertiary-container-focus) !important;
}

.dlYYLZVWzSmWQo2tbQ1F:active {
  border-color: var(--color-on-tertiary-container-press) !important;
}

.gWmpMYAdan_tRz3NyCUV:hover {
  border-color: var(--color-error-hover) !important;
}

.gWmpMYAdan_tRz3NyCUV:focus {
  border-color: var(--color-error-focus) !important;
}

.gWmpMYAdan_tRz3NyCUV:active {
  border-color: var(--color-error-press) !important;
}

.FBj54Oio32kFFumEhFuW:hover {
  border-color: var(--color-on-error-hover) !important;
}

.FBj54Oio32kFFumEhFuW:focus {
  border-color: var(--color-on-error-focus) !important;
}

.FBj54Oio32kFFumEhFuW:active {
  border-color: var(--color-on-error-press) !important;
}

.sozn0fYxsEcx2vetUCw0:hover {
  border-color: var(--color-error-container-hover) !important;
}

.sozn0fYxsEcx2vetUCw0:focus {
  border-color: var(--color-error-container-focus) !important;
}

.sozn0fYxsEcx2vetUCw0:active {
  border-color: var(--color-error-container-press) !important;
}

.ipLlyI4aExuKoy_diSo8:hover {
  border-color: var(--color-on-error-container-hover) !important;
}

.ipLlyI4aExuKoy_diSo8:focus {
  border-color: var(--color-on-error-container-focus) !important;
}

.ipLlyI4aExuKoy_diSo8:active {
  border-color: var(--color-on-error-container-press) !important;
}

.LUP0ShSefdGtFz6_1mcJ:hover {
  border-color: var(--color-background-hover) !important;
}

.LUP0ShSefdGtFz6_1mcJ:focus {
  border-color: var(--color-background-focus) !important;
}

.LUP0ShSefdGtFz6_1mcJ:active {
  border-color: var(--color-background-press) !important;
}

.yJ3A44NrNbdK7XWDk8YJ:hover {
  border-color: var(--color-on-background-hover) !important;
}

.yJ3A44NrNbdK7XWDk8YJ:focus {
  border-color: var(--color-on-background-focus) !important;
}

.yJ3A44NrNbdK7XWDk8YJ:active {
  border-color: var(--color-on-background-press) !important;
}

.qDkcjwKC_4pKJL6nKFsw:hover {
  border-color: var(--color-surface-hover) !important;
}

.qDkcjwKC_4pKJL6nKFsw:focus {
  border-color: var(--color-surface-focus) !important;
}

.qDkcjwKC_4pKJL6nKFsw:active {
  border-color: var(--color-surface-press) !important;
}

.urxAum83aipZxdKMGvB5:hover {
  border-color: var(--color-on-surface-hover) !important;
}

.urxAum83aipZxdKMGvB5:focus {
  border-color: var(--color-on-surface-focus) !important;
}

.urxAum83aipZxdKMGvB5:active {
  border-color: var(--color-on-surface-press) !important;
}

.IDG4ruRAGTsIUEMKVBja:hover {
  border-color: var(--color-surface-1-hover) !important;
}

.IDG4ruRAGTsIUEMKVBja:focus {
  border-color: var(--color-surface-1-focus) !important;
}

.IDG4ruRAGTsIUEMKVBja:active {
  border-color: var(--color-surface-1-press) !important;
}

.b7AexuzHWp7jdgPG9ma0:hover {
  border-color: var(--color-on-surface-1-hover) !important;
}

.b7AexuzHWp7jdgPG9ma0:focus {
  border-color: var(--color-on-surface-1-focus) !important;
}

.b7AexuzHWp7jdgPG9ma0:active {
  border-color: var(--color-on-surface-1-press) !important;
}

.evzqcGgJePpIZpv7vgZr:hover {
  border-color: var(--color-surface-2-hover) !important;
}

.evzqcGgJePpIZpv7vgZr:focus {
  border-color: var(--color-surface-2-focus) !important;
}

.evzqcGgJePpIZpv7vgZr:active {
  border-color: var(--color-surface-2-press) !important;
}

.ne7Hr5hd60oyNmYTL0vg:hover {
  border-color: var(--color-on-surface-2-hover) !important;
}

.ne7Hr5hd60oyNmYTL0vg:focus {
  border-color: var(--color-on-surface-2-focus) !important;
}

.ne7Hr5hd60oyNmYTL0vg:active {
  border-color: var(--color-on-surface-2-press) !important;
}

.AKLLMiVeTMCkAbOGMUsG:hover {
  border-color: var(--color-surface-3-hover) !important;
}

.AKLLMiVeTMCkAbOGMUsG:focus {
  border-color: var(--color-surface-3-focus) !important;
}

.AKLLMiVeTMCkAbOGMUsG:active {
  border-color: var(--color-surface-3-press) !important;
}

._1BZi5tDDhVpY0JphoYnA:hover {
  border-color: var(--color-on-surface-3-hover) !important;
}

._1BZi5tDDhVpY0JphoYnA:focus {
  border-color: var(--color-on-surface-3-focus) !important;
}

._1BZi5tDDhVpY0JphoYnA:active {
  border-color: var(--color-on-surface-3-press) !important;
}

.GJo8JT6OUI_RvvM5rvIr:hover {
  border-color: var(--color-surface-4-hover) !important;
}

.GJo8JT6OUI_RvvM5rvIr:focus {
  border-color: var(--color-surface-4-focus) !important;
}

.GJo8JT6OUI_RvvM5rvIr:active {
  border-color: var(--color-surface-4-press) !important;
}

.ggKZNf4AgMlib5ysae2_:hover {
  border-color: var(--color-on-surface-4-hover) !important;
}

.ggKZNf4AgMlib5ysae2_:focus {
  border-color: var(--color-on-surface-4-focus) !important;
}

.ggKZNf4AgMlib5ysae2_:active {
  border-color: var(--color-on-surface-4-press) !important;
}

.pTFPebXyvrMZhfZr2904:hover {
  border-color: var(--color-surface-5-hover) !important;
}

.pTFPebXyvrMZhfZr2904:focus {
  border-color: var(--color-surface-5-focus) !important;
}

.pTFPebXyvrMZhfZr2904:active {
  border-color: var(--color-surface-5-press) !important;
}

.G5u6SwTBYbC87D2cuUyr:hover {
  border-color: var(--color-on-surface-5-hover) !important;
}

.G5u6SwTBYbC87D2cuUyr:focus {
  border-color: var(--color-on-surface-5-focus) !important;
}

.G5u6SwTBYbC87D2cuUyr:active {
  border-color: var(--color-on-surface-5-press) !important;
}

.IYxcn4QwRTVIILocJE2D:hover {
  border-color: var(--color-outline-hover) !important;
}

.IYxcn4QwRTVIILocJE2D:focus {
  border-color: var(--color-outline-focus) !important;
}

.IYxcn4QwRTVIILocJE2D:active {
  border-color: var(--color-outline-press) !important;
}

.UL_n_S37jihM1NPTxQSZ:hover {
  border-color: var(--color-surface-variant-hover) !important;
}

.UL_n_S37jihM1NPTxQSZ:focus {
  border-color: var(--color-surface-variant-focus) !important;
}

.UL_n_S37jihM1NPTxQSZ:active {
  border-color: var(--color-surface-variant-press) !important;
}

.osYLtT_VwqHdmFutAcrx:hover {
  border-color: var(--color-on-surface-variant-hover) !important;
}

.osYLtT_VwqHdmFutAcrx:focus {
  border-color: var(--color-on-surface-variant-focus) !important;
}

.osYLtT_VwqHdmFutAcrx:active {
  border-color: var(--color-on-surface-variant-press) !important;
}

.SgWTTXgizIwXIvgymCjv:hover {
  border-color: var(--color-category-success-hover) !important;
}

.SgWTTXgizIwXIvgymCjv:focus {
  border-color: var(--color-category-success-focus) !important;
}

.SgWTTXgizIwXIvgymCjv:active {
  border-color: var(--color-category-success-press) !important;
}

.t0EIZpRXUR3vaMDXdo1P:hover {
  border-color: var(--color-on-category-success-hover) !important;
}

.t0EIZpRXUR3vaMDXdo1P:focus {
  border-color: var(--color-on-category-success-focus) !important;
}

.t0EIZpRXUR3vaMDXdo1P:active {
  border-color: var(--color-on-category-success-press) !important;
}

.xaaeXzCAswmnbtnuNEFA:hover {
  border-color: var(--color-category-info-hover) !important;
}

.xaaeXzCAswmnbtnuNEFA:focus {
  border-color: var(--color-category-info-focus) !important;
}

.xaaeXzCAswmnbtnuNEFA:active {
  border-color: var(--color-category-info-press) !important;
}

.dysVD28WCik6DhnGQ6FN:hover {
  border-color: var(--color-on-category-info-hover) !important;
}

.dysVD28WCik6DhnGQ6FN:focus {
  border-color: var(--color-on-category-info-focus) !important;
}

.dysVD28WCik6DhnGQ6FN:active {
  border-color: var(--color-on-category-info-press) !important;
}

.pbQ6Y05Wjz4ovJzvaxS2:hover {
  border-color: var(--color-category-warning-hover) !important;
}

.pbQ6Y05Wjz4ovJzvaxS2:focus {
  border-color: var(--color-category-warning-focus) !important;
}

.pbQ6Y05Wjz4ovJzvaxS2:active {
  border-color: var(--color-category-warning-press) !important;
}

.oXxEOoL7URYmpVe0wkLV:hover {
  border-color: var(--color-on-category-warning-hover) !important;
}

.oXxEOoL7URYmpVe0wkLV:focus {
  border-color: var(--color-on-category-warning-focus) !important;
}

.oXxEOoL7URYmpVe0wkLV:active {
  border-color: var(--color-on-category-warning-press) !important;
}

.kLBpxThENz4Kcvt_4GVZ:hover {
  border-color: var(--color-category-error-hover) !important;
}

.kLBpxThENz4Kcvt_4GVZ:focus {
  border-color: var(--color-category-error-focus) !important;
}

.kLBpxThENz4Kcvt_4GVZ:active {
  border-color: var(--color-category-error-press) !important;
}

.RlXittg_4_ucW1jqOEOr:hover {
  border-color: var(--color-on-category-error-hover) !important;
}

.RlXittg_4_ucW1jqOEOr:focus {
  border-color: var(--color-on-category-error-focus) !important;
}

.RlXittg_4_ucW1jqOEOr:active {
  border-color: var(--color-on-category-error-press) !important;
}

.GFMYt59QZCUXNzIFvQsm:hover {
  border-color: var(--color-category-a-hover) !important;
}

.GFMYt59QZCUXNzIFvQsm:focus {
  border-color: var(--color-category-a-focus) !important;
}

.GFMYt59QZCUXNzIFvQsm:active {
  border-color: var(--color-category-a-press) !important;
}

.CRemLNF8pFP13tL0G7n4:hover {
  border-color: var(--color-on-category-a-hover) !important;
}

.CRemLNF8pFP13tL0G7n4:focus {
  border-color: var(--color-on-category-a-focus) !important;
}

.CRemLNF8pFP13tL0G7n4:active {
  border-color: var(--color-on-category-a-press) !important;
}

.S5VtIuRLVoyy03I_JlHf:hover {
  border-color: var(--color-category-b-hover) !important;
}

.S5VtIuRLVoyy03I_JlHf:focus {
  border-color: var(--color-category-b-focus) !important;
}

.S5VtIuRLVoyy03I_JlHf:active {
  border-color: var(--color-category-b-press) !important;
}

.CpDw0v9QH9dlGOd9uFGo:hover {
  border-color: var(--color-on-category-b-hover) !important;
}

.CpDw0v9QH9dlGOd9uFGo:focus {
  border-color: var(--color-on-category-b-focus) !important;
}

.CpDw0v9QH9dlGOd9uFGo:active {
  border-color: var(--color-on-category-b-press) !important;
}

.TyIAh8VR7XRn1ZwRTCFT:hover {
  border-color: var(--color-category-c-hover) !important;
}

.TyIAh8VR7XRn1ZwRTCFT:focus {
  border-color: var(--color-category-c-focus) !important;
}

.TyIAh8VR7XRn1ZwRTCFT:active {
  border-color: var(--color-category-c-press) !important;
}

.nwoRNSq04S1jOM0NUt7Q:hover {
  border-color: var(--color-on-category-c-hover) !important;
}

.nwoRNSq04S1jOM0NUt7Q:focus {
  border-color: var(--color-on-category-c-focus) !important;
}

.nwoRNSq04S1jOM0NUt7Q:active {
  border-color: var(--color-on-category-c-press) !important;
}

.X8P9Vx_Vsqj8_hnLy10M:hover {
  border-color: var(--color-category-d-hover) !important;
}

.X8P9Vx_Vsqj8_hnLy10M:focus {
  border-color: var(--color-category-d-focus) !important;
}

.X8P9Vx_Vsqj8_hnLy10M:active {
  border-color: var(--color-category-d-press) !important;
}

.CEccYK7pDS6VlPk8_MZY:hover {
  border-color: var(--color-on-category-d-hover) !important;
}

.CEccYK7pDS6VlPk8_MZY:focus {
  border-color: var(--color-on-category-d-focus) !important;
}

.CEccYK7pDS6VlPk8_MZY:active {
  border-color: var(--color-on-category-d-press) !important;
}

.zxA6BFmqRh12jVZjmBI9:hover {
  border-color: var(--color-category-e-hover) !important;
}

.zxA6BFmqRh12jVZjmBI9:focus {
  border-color: var(--color-category-e-focus) !important;
}

.zxA6BFmqRh12jVZjmBI9:active {
  border-color: var(--color-category-e-press) !important;
}

.ISxJqWmNP1tluIQ62le5:hover {
  border-color: var(--color-on-category-e-hover) !important;
}

.ISxJqWmNP1tluIQ62le5:focus {
  border-color: var(--color-on-category-e-focus) !important;
}

.ISxJqWmNP1tluIQ62le5:active {
  border-color: var(--color-on-category-e-press) !important;
}

.qgiAerLQE9aPImm5O0ki:hover {
  border-color: var(--color-category-f-hover) !important;
}

.qgiAerLQE9aPImm5O0ki:focus {
  border-color: var(--color-category-f-focus) !important;
}

.qgiAerLQE9aPImm5O0ki:active {
  border-color: var(--color-category-f-press) !important;
}

.x6IwJk03i6IJP5wTYhR7:hover {
  border-color: var(--color-on-category-f-hover) !important;
}

.x6IwJk03i6IJP5wTYhR7:focus {
  border-color: var(--color-on-category-f-focus) !important;
}

.x6IwJk03i6IJP5wTYhR7:active {
  border-color: var(--color-on-category-f-press) !important;
}

.hy9qSalTmdR96FGvCWyO:hover {
  border-color: var(--color-category-g-hover) !important;
}

.hy9qSalTmdR96FGvCWyO:focus {
  border-color: var(--color-category-g-focus) !important;
}

.hy9qSalTmdR96FGvCWyO:active {
  border-color: var(--color-category-g-press) !important;
}

.LHTSrPysEfwotiJap01Y:hover {
  border-color: var(--color-on-category-g-hover) !important;
}

.LHTSrPysEfwotiJap01Y:focus {
  border-color: var(--color-on-category-g-focus) !important;
}

.LHTSrPysEfwotiJap01Y:active {
  border-color: var(--color-on-category-g-press) !important;
}

.w7PkamEvRz8snv6O7552:hover {
  border-color: var(--color-category-h-hover) !important;
}

.w7PkamEvRz8snv6O7552:focus {
  border-color: var(--color-category-h-focus) !important;
}

.w7PkamEvRz8snv6O7552:active {
  border-color: var(--color-category-h-press) !important;
}

.QGztMz1fQ19_SINaA0wC:hover {
  border-color: var(--color-on-category-h-hover) !important;
}

.QGztMz1fQ19_SINaA0wC:focus {
  border-color: var(--color-on-category-h-focus) !important;
}

.QGztMz1fQ19_SINaA0wC:active {
  border-color: var(--color-on-category-h-press) !important;
}

/*!*********************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/general/color/Color/Color.contrastBorder.module.css ***!
  \*********************************************************************************************************************************************************************************************************************/
.qe9o7qDWHQMh6lKcIawM {
  border-color: var(--color-on-primary) !important;
}

.u0mwg2grGfFmS7M0F3P3 {
  border-color: var(--color-primary) !important;
}

.rLwYxQvfrzpAc771_UvX {
  border-color: var(--color-on-primary-container) !important;
}

.jQs12GiWikbAAWFWtzqa {
  border-color: var(--color-primary-container) !important;
}

.gGYdQnVWCAAeWAVqXFHS {
  border-color: var(--color-on-secondary) !important;
}

.rwZIxEqYie4qYKdUla_q {
  border-color: var(--color-secondary) !important;
}

.CiR9y9kjqgcLiptDVMi5 {
  border-color: var(--color-on-secondary-container) !important;
}

._dlTGq7vjHo_0mbZCeFe {
  border-color: var(--color-secondary-container) !important;
}

.WlCv7GFaL_isDDUkX6yw {
  border-color: var(--color-on-tertiary) !important;
}

.gZE23_IxgRpgXiEM1hJW {
  border-color: var(--color-tertiary) !important;
}

.XQT6T9uwf7KdX2uPXvZ7 {
  border-color: var(--color-on-tertiary-container) !important;
}

.mf3_fMiygi36B5XHWmeJ {
  border-color: var(--color-tertiary-container) !important;
}

.KA4Ucfu11X2o5P0uIKAW {
  border-color: var(--color-on-error) !important;
}

.rQ7yEuDfJCS9CjeddbcQ {
  border-color: var(--color-error) !important;
}

.W4mDTiQ4lYRIyK4kOlsV {
  border-color: var(--color-on-error-container) !important;
}

.oEg6KDSuzKzZg7oLQOrL {
  border-color: var(--color-error-container) !important;
}

.JipCpNjFC1qHZoQanqsg {
  border-color: var(--color-on-background) !important;
}

.P5Qtm94YmZIuvAhobtwJ {
  border-color: var(--color-background) !important;
}

.A72rmnV9mNxUf7SBeRzx {
  border-color: var(--color-on-surface) !important;
}

.TdbnB8RcSF1Nk2wkdBZg {
  border-color: var(--color-surface) !important;
}

.Hm2A53J3oEzbg44D9N5c {
  border-color: var(--color-on-surface) !important;
}

.h4sImDOnc2gjiqmmlIVF {
  border-color: var(--color-surface-1) !important;
}

.Q4X2LMkIgWcy9QePbMjV {
  border-color: var(--color-on-surface) !important;
}

.fbeF16bN7v5VXde_sV6M {
  border-color: var(--color-surface-2) !important;
}

.v6K6IMlhsruYEup4UhOQ {
  border-color: var(--color-on-surface) !important;
}

.GOArZFQ8Nb6zC9RBE9cw {
  border-color: var(--color-surface-3) !important;
}

.EssZm7KG0FshluLQl0DE {
  border-color: var(--color-on-surface) !important;
}

.hYekqjS78Kc8XtvYiV5m {
  border-color: var(--color-surface-4) !important;
}

.ZgqGT_wMSgz72CshiVen {
  border-color: var(--color-on-surface) !important;
}

.xdBwkXeRqHYYOaFG1OU1 {
  border-color: var(--color-surface-5) !important;
}

.oDUDQheAkTZLsI2pIKar {
  border-color: var(--color-on-outline) !important;
}

.bUcISuVHOyrulu3kK40P {
  border-color: var(--color-on-surface-variant) !important;
}

.xi0mqmMwiUsNbMGlcoFw {
  border-color: var(--color-surface-variant) !important;
}

.uVp7EE6HxkBzXJmSvZBg {
  border-color: var(--color-on-category-a) !important;
}

.lL_tnl9FopkUej50j4cF {
  border-color: var(--color-category-a) !important;
}

.f506S7TTzEoMEOKyiKVA {
  border-color: var(--color-on-category-success) !important;
}

.kAFdyxZNlUUqw53Lc01w {
  border-color: var(--color-category-success) !important;
}

.SCtHWjgTdXV585PAYmdJ {
  border-color: var(--color-on-category-info) !important;
}

.yTf07zNBJtWpnvOxzcDE {
  border-color: var(--color-category-info) !important;
}

.VsdtjjSy0laPk80eloqY {
  border-color: var(--color-on-category-warning) !important;
}

.KVI7Lq072wwb6Uis23WO {
  border-color: var(--color-category-warning) !important;
}

.zvzKrt6pzrTvX44X_K34 {
  border-color: var(--color-on-category-error) !important;
}

.SwHsElFae_0qK7GYWzdQ {
  border-color: var(--color-category-error) !important;
}

._z6shPWeGqC5hvPQcNge {
  border-color: var(--color-on-category-b) !important;
}

.UW4nUSCHsTZEswFra1Mw {
  border-color: var(--color-category-b) !important;
}

.DSvjqJ6SNdxFJssqD6Qz {
  border-color: var(--color-on-category-c) !important;
}

.K4dDBYpR2WwVy2DvmsKP {
  border-color: var(--color-category-c) !important;
}

.LCo4wVnNtr6m759ERobG {
  border-color: var(--color-on-category-d) !important;
}

.o6VLfc85bVI3JSeMxexi {
  border-color: var(--color-category-d) !important;
}

.G9s_D9dTaYzay5bLO5vt {
  border-color: var(--color-on-category-e) !important;
}

.wco9skgltNQZ0XSYKfsp {
  border-color: var(--color-category-e) !important;
}

.GVi6QiUuN4710es7yRMY {
  border-color: var(--color-on-category-f) !important;
}

.qdWqtjOyvLYwaEHaeTB0 {
  border-color: var(--color-category-f) !important;
}

.TNU63wlNFb6DjdX5CEDH {
  border-color: var(--color-on-category-g) !important;
}

.pmHxuxQiUBiQMiyRp5kR {
  border-color: var(--color-category-g) !important;
}

.Acj65GnlOeRwAIm8PPXc {
  border-color: var(--color-on-category-h) !important;
}

.YOA5eCWKIrDvN9u5kemQ {
  border-color: var(--color-category-h) !important;
}

/*!**********************************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/general/color/Color/Color.contrastBorder.interactions.module.css ***!
  \**********************************************************************************************************************************************************************************************************************************/
.vDfW1aEh2xN2l5z3Lwkh:hover {
  border-color: var(--color-on-primary-hover) !important;
}

.vDfW1aEh2xN2l5z3Lwkh:focus {
  border-color: var(--color-on-primary-focus) !important;
}

.vDfW1aEh2xN2l5z3Lwkh:active {
  border-color: var(--color-on-primary-press) !important;
}

.Gq5FEUn4bfRo2oj4vvvn:hover {
  border-color: var(--color-primary-hover) !important;
}

.Gq5FEUn4bfRo2oj4vvvn:focus {
  border-color: var(--color-primary-focus) !important;
}

.Gq5FEUn4bfRo2oj4vvvn:active {
  border-color: var(--color-primary-press) !important;
}

.SXp1H4O4Nk_rcgwrGGCf:hover {
  border-color: var(--color-on-primary-container-hover) !important;
}

.SXp1H4O4Nk_rcgwrGGCf:focus {
  border-color: var(--color-on-primary-container-focus) !important;
}

.SXp1H4O4Nk_rcgwrGGCf:active {
  border-color: var(--color-on-primary-container-press) !important;
}

.rloZWqGF23lA27lYRHUS:hover {
  border-color: var(--color-primary-container-hover) !important;
}

.rloZWqGF23lA27lYRHUS:focus {
  border-color: var(--color-primary-container-focus) !important;
}

.rloZWqGF23lA27lYRHUS:active {
  border-color: var(--color-primary-container-press) !important;
}

.hSzQxCnhkYxU6cprq5V_:hover {
  border-color: var(--color-on-secondary-hover) !important;
}

.hSzQxCnhkYxU6cprq5V_:focus {
  border-color: var(--color-on-secondary-focus) !important;
}

.hSzQxCnhkYxU6cprq5V_:active {
  border-color: var(--color-on-secondary-press) !important;
}

.Tk3WyAjVvkiUNQvbx9bw:hover {
  border-color: var(--color-secondary-hover) !important;
}

.Tk3WyAjVvkiUNQvbx9bw:focus {
  border-color: var(--color-secondary-focus) !important;
}

.Tk3WyAjVvkiUNQvbx9bw:active {
  border-color: var(--color-secondary-press) !important;
}

.ttKI_kV3o3DzSST7lHiC:hover {
  border-color: var(--color-on-secondary-container-hover) !important;
}

.ttKI_kV3o3DzSST7lHiC:focus {
  border-color: var(--color-on-secondary-container-focus) !important;
}

.ttKI_kV3o3DzSST7lHiC:active {
  border-color: var(--color-on-secondary-container-press) !important;
}

.LoFE3y8hNWjkZIt_wHXq:hover {
  border-color: var(--color-secondary-container-hover) !important;
}

.LoFE3y8hNWjkZIt_wHXq:focus {
  border-color: var(--color-secondary-container-focus) !important;
}

.LoFE3y8hNWjkZIt_wHXq:active {
  border-color: var(--color-secondary-container-press) !important;
}

.Tyg4SgQIRwlYesUTf7Z9:hover {
  border-color: var(--color-on-tertiary-hover) !important;
}

.Tyg4SgQIRwlYesUTf7Z9:focus {
  border-color: var(--color-on-tertiary-focus) !important;
}

.Tyg4SgQIRwlYesUTf7Z9:active {
  border-color: var(--color-on-tertiary-press) !important;
}

.s6XY7uzcvKG0gJB7g62p:hover {
  border-color: var(--color-tertiary-hover) !important;
}

.s6XY7uzcvKG0gJB7g62p:focus {
  border-color: var(--color-tertiary-focus) !important;
}

.s6XY7uzcvKG0gJB7g62p:active {
  border-color: var(--color-tertiary-press) !important;
}

.R1mijTb8DX0MMhC5cyPr:hover {
  border-color: var(--color-on-tertiary-container-hover) !important;
}

.R1mijTb8DX0MMhC5cyPr:focus {
  border-color: var(--color-on-tertiary-container-focus) !important;
}

.R1mijTb8DX0MMhC5cyPr:active {
  border-color: var(--color-on-tertiary-container-press) !important;
}

.nE2cKqLAeuQSYfEj_Rcx:hover {
  border-color: var(--color-tertiary-container-hover) !important;
}

.nE2cKqLAeuQSYfEj_Rcx:focus {
  border-color: var(--color-tertiary-container-focus) !important;
}

.nE2cKqLAeuQSYfEj_Rcx:active {
  border-color: var(--color-tertiary-container-press) !important;
}

.NQflbJuCSLfSbZt8_p5Q:hover {
  border-color: var(--color-on-error-hover) !important;
}

.NQflbJuCSLfSbZt8_p5Q:focus {
  border-color: var(--color-on-error-focus) !important;
}

.NQflbJuCSLfSbZt8_p5Q:active {
  border-color: var(--color-on-error-press) !important;
}

.elJEhF5c9P_vgxIzBc1o:hover {
  border-color: var(--color-error-hover) !important;
}

.elJEhF5c9P_vgxIzBc1o:focus {
  border-color: var(--color-error-focus) !important;
}

.elJEhF5c9P_vgxIzBc1o:active {
  border-color: var(--color-error-press) !important;
}

.bmTDp49g8DgjG6tP19BS:hover {
  border-color: var(--color-on-error-container-hover) !important;
}

.bmTDp49g8DgjG6tP19BS:focus {
  border-color: var(--color-on-error-container-focus) !important;
}

.bmTDp49g8DgjG6tP19BS:active {
  border-color: var(--color-on-error-container-press) !important;
}

.Zdy_LTNeL3eDqVUUtSPB:hover {
  border-color: var(--color-error-container-hover) !important;
}

.Zdy_LTNeL3eDqVUUtSPB:focus {
  border-color: var(--color-error-container-focus) !important;
}

.Zdy_LTNeL3eDqVUUtSPB:active {
  border-color: var(--color-error-container-press) !important;
}

.TRWicj1joDIIkTMvj_x_:hover {
  border-color: var(--color-on-background-hover) !important;
}

.TRWicj1joDIIkTMvj_x_:focus {
  border-color: var(--color-on-background-focus) !important;
}

.TRWicj1joDIIkTMvj_x_:active {
  border-color: var(--color-on-background-press) !important;
}

.GU0f6Go_NFShY4UkNK1F:hover {
  border-color: var(--color-background-hover) !important;
}

.GU0f6Go_NFShY4UkNK1F:focus {
  border-color: var(--color-background-focus) !important;
}

.GU0f6Go_NFShY4UkNK1F:active {
  border-color: var(--color-background-press) !important;
}

.Ci3DsbwjD9cbWbvRdZEZ:hover {
  border-color: var(--color-on-surface-hover) !important;
}

.Ci3DsbwjD9cbWbvRdZEZ:focus {
  border-color: var(--color-on-surface-focus) !important;
}

.Ci3DsbwjD9cbWbvRdZEZ:active {
  border-color: var(--color-on-surface-press) !important;
}

.vssU7f_9CMKmxKKPs_QQ:hover {
  border-color: var(--color-surface-hover) !important;
}

.vssU7f_9CMKmxKKPs_QQ:focus {
  border-color: var(--color-surface-focus) !important;
}

.vssU7f_9CMKmxKKPs_QQ:active {
  border-color: var(--color-surface-press) !important;
}

.OCxojBSaBEpMGXTck34w:hover {
  border-color: var(--color-on-surface-hover) !important;
}

.OCxojBSaBEpMGXTck34w:focus {
  border-color: var(--color-on-surface-focus) !important;
}

.OCxojBSaBEpMGXTck34w:active {
  border-color: var(--color-on-surface-press) !important;
}

.Ku7Addm7pejJ3fM4Ss2G:hover {
  border-color: var(--color-surface-1-hover) !important;
}

.Ku7Addm7pejJ3fM4Ss2G:focus {
  border-color: var(--color-surface-1-focus) !important;
}

.Ku7Addm7pejJ3fM4Ss2G:active {
  border-color: var(--color-surface-1-press) !important;
}

.RPCcIRkAfync9H2Sap5Z:hover {
  border-color: var(--color-on-surface-hover) !important;
}

.RPCcIRkAfync9H2Sap5Z:focus {
  border-color: var(--color-on-surface-focus) !important;
}

.RPCcIRkAfync9H2Sap5Z:active {
  border-color: var(--color-on-surface-press) !important;
}

.nG8HzxFd0za99acM1FiP:hover {
  border-color: var(--color-surface-2-hover) !important;
}

.nG8HzxFd0za99acM1FiP:focus {
  border-color: var(--color-surface-2-focus) !important;
}

.nG8HzxFd0za99acM1FiP:active {
  border-color: var(--color-surface-2-press) !important;
}

._SqO4_YykIiDCSHx8QUb:hover {
  border-color: var(--color-on-surface-hover) !important;
}

._SqO4_YykIiDCSHx8QUb:focus {
  border-color: var(--color-on-surface-focus) !important;
}

._SqO4_YykIiDCSHx8QUb:active {
  border-color: var(--color-on-surface-press) !important;
}

.O2NdoxkuMzmXAnhoExzc:hover {
  border-color: var(--color-surface-3-hover) !important;
}

.O2NdoxkuMzmXAnhoExzc:focus {
  border-color: var(--color-surface-3-focus) !important;
}

.O2NdoxkuMzmXAnhoExzc:active {
  border-color: var(--color-surface-3-press) !important;
}

.BmsDulXizPDOtW5meNtu:hover {
  border-color: var(--color-on-surface-hover) !important;
}

.BmsDulXizPDOtW5meNtu:focus {
  border-color: var(--color-on-surface-focus) !important;
}

.BmsDulXizPDOtW5meNtu:active {
  border-color: var(--color-on-surface-press) !important;
}

.O8IJuJjcUA9Mp_9T990P:hover {
  border-color: var(--color-surface-4-hover) !important;
}

.O8IJuJjcUA9Mp_9T990P:focus {
  border-color: var(--color-surface-4-focus) !important;
}

.O8IJuJjcUA9Mp_9T990P:active {
  border-color: var(--color-surface-4-press) !important;
}

.uED6Is_OrvpELHEppT17:hover {
  border-color: var(--color-on-surface-hover) !important;
}

.uED6Is_OrvpELHEppT17:focus {
  border-color: var(--color-on-surface-focus) !important;
}

.uED6Is_OrvpELHEppT17:active {
  border-color: var(--color-on-surface-press) !important;
}

.hTOj8NIvdGeA2Ao9NyBQ:hover {
  border-color: var(--color-surface-5-hover) !important;
}

.hTOj8NIvdGeA2Ao9NyBQ:focus {
  border-color: var(--color-surface-5-focus) !important;
}

.hTOj8NIvdGeA2Ao9NyBQ:active {
  border-color: var(--color-surface-5-press) !important;
}

.PzHiowXlWHRxm31tCAzA:hover {
  border-color: var(--color-on-outline-hover) !important;
}

.PzHiowXlWHRxm31tCAzA:focus {
  border-color: var(--color-on-outline-focus) !important;
}

.PzHiowXlWHRxm31tCAzA:active {
  border-color: var(--color-on-outline-press) !important;
}

.uRgcsNpH8_iHMU5fC1ag:hover {
  border-color: var(--color-on-surface-variant-hover) !important;
}

.uRgcsNpH8_iHMU5fC1ag:focus {
  border-color: var(--color-on-surface-variant-focus) !important;
}

.uRgcsNpH8_iHMU5fC1ag:active {
  border-color: var(--color-on-surface-variant-press) !important;
}

.yOnNcEaOmMbBaazcmsN3:hover {
  border-color: var(--color-surface-variant-hover) !important;
}

.yOnNcEaOmMbBaazcmsN3:focus {
  border-color: var(--color-surface-variant-focus) !important;
}

.yOnNcEaOmMbBaazcmsN3:active {
  border-color: var(--color-surface-variant-press) !important;
}

.gnpc4jjdoPOOpfiUQhV3:hover {
  border-color: var(--color-on-category-success-hover) !important;
}

.gnpc4jjdoPOOpfiUQhV3:focus {
  border-color: var(--color-on-category-success-focus) !important;
}

.gnpc4jjdoPOOpfiUQhV3:active {
  border-color: var(--color-on-category-success-press) !important;
}

.G3ROAUXgi1gBqqeh9ILX:hover {
  border-color: var(--color-category-success-hover) !important;
}

.G3ROAUXgi1gBqqeh9ILX:focus {
  border-color: var(--color-category-success-focus) !important;
}

.G3ROAUXgi1gBqqeh9ILX:active {
  border-color: var(--color-category-success-press) !important;
}

.JaYNaFzujLG1YdXK6rGo:hover {
  border-color: var(--color-on-category-info-hover) !important;
}

.JaYNaFzujLG1YdXK6rGo:focus {
  border-color: var(--color-on-category-info-focus) !important;
}

.JaYNaFzujLG1YdXK6rGo:active {
  border-color: var(--color-on-category-info-press) !important;
}

.E7XUiyici36mmn8BKni4:hover {
  border-color: var(--color-category-info-hover) !important;
}

.E7XUiyici36mmn8BKni4:focus {
  border-color: var(--color-category-info-focus) !important;
}

.E7XUiyici36mmn8BKni4:active {
  border-color: var(--color-category-info-press) !important;
}

.ea28otflAq8Qpy0dyU97:hover {
  border-color: var(--color-on-category-warning-hover) !important;
}

.ea28otflAq8Qpy0dyU97:focus {
  border-color: var(--color-on-category-warning-focus) !important;
}

.ea28otflAq8Qpy0dyU97:active {
  border-color: var(--color-on-category-warning-press) !important;
}

.JgHQEnFejQbyoPnh6kwW:hover {
  border-color: var(--color-category-warning-hover) !important;
}

.JgHQEnFejQbyoPnh6kwW:focus {
  border-color: var(--color-category-warning-focus) !important;
}

.JgHQEnFejQbyoPnh6kwW:active {
  border-color: var(--color-category-warning-press) !important;
}

.xEeohWcfx9JSjJuQ0EYu:hover {
  border-color: var(--color-on-category-error-hover) !important;
}

.xEeohWcfx9JSjJuQ0EYu:focus {
  border-color: var(--color-on-category-error-focus) !important;
}

.xEeohWcfx9JSjJuQ0EYu:active {
  border-color: var(--color-on-category-error-press) !important;
}

.wG8oBjzmlOniJNP0sf23:hover {
  border-color: var(--color-category-error-hover) !important;
}

.wG8oBjzmlOniJNP0sf23:focus {
  border-color: var(--color-category-error-focus) !important;
}

.wG8oBjzmlOniJNP0sf23:active {
  border-color: var(--color-category-error-press) !important;
}

.O0bTEasbVw96G3wuWXQE:hover {
  border-color: var(--color-on-category-a-hover) !important;
}

.O0bTEasbVw96G3wuWXQE:focus {
  border-color: var(--color-on-category-a-focus) !important;
}

.O0bTEasbVw96G3wuWXQE:active {
  border-color: var(--color-on-category-a-press) !important;
}

.CKrNSL0lz1xpkKHvU8f0:hover {
  border-color: var(--color-category-a-hover) !important;
}

.CKrNSL0lz1xpkKHvU8f0:focus {
  border-color: var(--color-category-a-focus) !important;
}

.CKrNSL0lz1xpkKHvU8f0:active {
  border-color: var(--color-category-a-press) !important;
}

.fFvV5DmT7QrJNeEZZUNT:hover {
  border-color: var(--color-on-category-b-hover) !important;
}

.fFvV5DmT7QrJNeEZZUNT:focus {
  border-color: var(--color-on-category-b-focus) !important;
}

.fFvV5DmT7QrJNeEZZUNT:active {
  border-color: var(--color-on-category-b-press) !important;
}

.FJPwWv753MspKf70gIDl:hover {
  border-color: var(--color-category-b-hover) !important;
}

.FJPwWv753MspKf70gIDl:focus {
  border-color: var(--color-category-b-focus) !important;
}

.FJPwWv753MspKf70gIDl:active {
  border-color: var(--color-category-b-press) !important;
}

.fa_07cVfbrYlih65Y35A:hover {
  border-color: var(--color-on-category-c-hover) !important;
}

.fa_07cVfbrYlih65Y35A:focus {
  border-color: var(--color-on-category-c-focus) !important;
}

.fa_07cVfbrYlih65Y35A:active {
  border-color: var(--color-on-category-c-press) !important;
}

.iQnLKxED1jm_w2KXmJxM:hover {
  border-color: var(--color-category-c-hover) !important;
}

.iQnLKxED1jm_w2KXmJxM:focus {
  border-color: var(--color-category-c-focus) !important;
}

.iQnLKxED1jm_w2KXmJxM:active {
  border-color: var(--color-category-c-press) !important;
}

.HJsPJCP6BAi5oa47c0gQ:hover {
  border-color: var(--color-on-category-d-hover) !important;
}

.HJsPJCP6BAi5oa47c0gQ:focus {
  border-color: var(--color-on-category-d-focus) !important;
}

.HJsPJCP6BAi5oa47c0gQ:active {
  border-color: var(--color-on-category-d-press) !important;
}

.uhOkznXApHNipxKpzSOU:hover {
  border-color: var(--color-category-d-hover) !important;
}

.uhOkznXApHNipxKpzSOU:focus {
  border-color: var(--color-category-d-focus) !important;
}

.uhOkznXApHNipxKpzSOU:active {
  border-color: var(--color-category-d-press) !important;
}

.Z1wer6xIRr5b29XgNFlT:hover {
  border-color: var(--color-on-category-e-hover) !important;
}

.Z1wer6xIRr5b29XgNFlT:focus {
  border-color: var(--color-on-category-e-focus) !important;
}

.Z1wer6xIRr5b29XgNFlT:active {
  border-color: var(--color-on-category-e-press) !important;
}

.TPlKKXBmO7bgVs4Rvvtv:hover {
  border-color: var(--color-category-e-hover) !important;
}

.TPlKKXBmO7bgVs4Rvvtv:focus {
  border-color: var(--color-category-e-focus) !important;
}

.TPlKKXBmO7bgVs4Rvvtv:active {
  border-color: var(--color-category-e-press) !important;
}

.eywlw2dZ4Vxv6Dk6Raia:hover {
  border-color: var(--color-on-category-f-hover) !important;
}

.eywlw2dZ4Vxv6Dk6Raia:focus {
  border-color: var(--color-on-category-f-focus) !important;
}

.eywlw2dZ4Vxv6Dk6Raia:active {
  border-color: var(--color-on-category-f-press) !important;
}

.yEOHfDOlsgTlSGDFpPvq:hover {
  border-color: var(--color-category-f-hover) !important;
}

.yEOHfDOlsgTlSGDFpPvq:focus {
  border-color: var(--color-category-f-focus) !important;
}

.yEOHfDOlsgTlSGDFpPvq:active {
  border-color: var(--color-category-f-press) !important;
}

.h26aRdTaO4GY4Ov3bLPS:hover {
  border-color: var(--color-on-category-g-hover) !important;
}

.h26aRdTaO4GY4Ov3bLPS:focus {
  border-color: var(--color-on-category-g-focus) !important;
}

.h26aRdTaO4GY4Ov3bLPS:active {
  border-color: var(--color-on-category-g-press) !important;
}

.X4Lz_BRsyHATILQrx5Dd:hover {
  border-color: var(--color-category-g-hover) !important;
}

.X4Lz_BRsyHATILQrx5Dd:focus {
  border-color: var(--color-category-g-focus) !important;
}

.X4Lz_BRsyHATILQrx5Dd:active {
  border-color: var(--color-category-g-press) !important;
}

.ROEjaeVQ7a7NSP9MaiJg:hover {
  border-color: var(--color-on-category-h-hover) !important;
}

.ROEjaeVQ7a7NSP9MaiJg:focus {
  border-color: var(--color-on-category-h-focus) !important;
}

.ROEjaeVQ7a7NSP9MaiJg:active {
  border-color: var(--color-on-category-h-press) !important;
}

.QHVKU9xSwUxghlV0c7gB:hover {
  border-color: var(--color-category-h-hover) !important;
}

.QHVKU9xSwUxghlV0c7gB:focus {
  border-color: var(--color-category-h-focus) !important;
}

.QHVKU9xSwUxghlV0c7gB:active {
  border-color: var(--color-category-h-press) !important;
}

/*!***************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/general/text/Text/Text.module.css ***!
  \***************************************************************************************************************************************************************************************************/
.sTfraLKkolbhhc3dg9r1 {
  display: block;
}

.Ub15fsjWKGrQRPGsSIJ3 {
  font-family: var(--typography-body-medium-font-family);
  font-size: var(--typography-body-medium-size);
  font-weight: var(--typography-body-medium-weight);
  line-height: var(--typography-body-medium-line-height);
}

.GVVgW9J0q5JbCRqSzISM {
  font-family: var(--typography-body-large-font-family);
  font-size: var(--typography-body-large-size);
  font-weight: var(--typography-body-large-weight);
  line-height: var(--typography-body-large-line-height);
}

.ov7ceqfIDidt6qvxwXCV {
  font-family: var(--typography-body-small-font-family);
  font-size: var(--typography-body-small-size);
  font-weight: var(--typography-body-small-weight);
  line-height: var(--typography-body-small-line-height);
}

.SgMpps_1N8juWyO1Qd0u {
  font-family: var(--typography-display-medium-font-family);
  font-size: var(--typography-display-medium-size);
  font-weight: var(--typography-display-medium-weight);
  line-height: var(--typography-display-medium-line-height);
}

.DAqgg5LQaG9Tfl18Muwr {
  font-family: var(--typography-display1-font-family);
  font-size: var(--typography-display1-size);
  font-weight: var(--typography-display1-weight);
  line-height: var(--typography-display1-line-height);
}

.zxoVn7rjCkbwJNzF8krO {
  font-family: var(--typography-display-large-font-family);
  font-size: var(--typography-display-large-size);
  font-weight: var(--typography-display-large-weight);
  line-height: var(--typography-display-large-line-height);
}

.EY6GtCSdEEgOubvyPmrn {
  font-family: var(--typography-display-small-font-family);
  font-size: var(--typography-display-small-size);
  font-weight: var(--typography-display-small-weight);
  line-height: var(--typography-display-small-line-height);
}

.AWuF7bRMLk4dfn2wA3Dy {
  font-family: var(--typography-headline-medium-font-family);
  font-size: var(--typography-headline-medium-size);
  font-weight: var(--typography-headline-medium-weight);
  line-height: var(--typography-headline-medium-line-height);
}

.x_taPJ1NICkmn7lDg8Hw {
  font-family: var(--typography-headline6-font-family);
  font-size: var(--typography-headline6-size);
  font-weight: var(--typography-headline6-weight);
  line-height: var(--typography-headline6-line-height);
}

.EGG2nlD38Lym92vMB49z {
  font-family: var(--typography-headline-large-font-family);
  font-size: var(--typography-headline-large-size);
  font-weight: var(--typography-headline-large-weight);
  line-height: var(--typography-headline-large-line-height);
}

.x98mWL9FxANf6Iyd34wx {
  font-family: var(--typography-headline-small-font-family);
  font-size: var(--typography-headline-small-size);
  font-weight: var(--typography-headline-small-weight);
  line-height: var(--typography-headline-small-line-height);
}

.GOLbb_NpDFB8uuIgtcTc {
  font-family: var(--typography-label-medium-font-family);
  font-size: var(--typography-label-medium-size);
  font-weight: var(--typography-label-medium-weight);
  line-height: var(--typography-label-medium-line-height);
}

.BVqxpt_6u2fnWwquASi3 {
  font-family: var(--typography-label-large-font-family);
  font-size: var(--typography-label-large-size);
  font-weight: var(--typography-label-large-weight);
  line-height: var(--typography-label-large-line-height);
}

.s_lci0EVvqtqkztmw7lt {
  font-family: var(--typography-label-small-font-family);
  font-size: var(--typography-label-small-size);
  font-weight: var(--typography-label-small-weight);
  line-height: var(--typography-label-small-line-height);
}

.xOIQLQdsy0pdh4zdNczL {
  font-family: var(--typography-title-medium-font-family);
  font-size: var(--typography-title-medium-size);
  font-weight: var(--typography-title-medium-weight);
  line-height: var(--typography-title-medium-line-height);
}

.didDDoJZo7Fim9vqf0Sg {
  font-family: var(--typography-title-large-font-family);
  font-size: var(--typography-title-large-size);
  font-weight: var(--typography-title-large-weight);
  line-height: var(--typography-title-large-line-height);
}

.cLzus3_LIhhoTNEEqFLU {
  font-family: var(--typography-title-small-font-family);
  font-size: var(--typography-title-small-size);
  font-weight: var(--typography-title-small-weight);
  line-height: var(--typography-title-small-line-height);
}

.d1v7gbpzULPzx89WbNBo {
  color: var(--color-on-background);
}

.BE1IleJD0p2rZHSUOTbh {
  color: var(--color-background);
}

.ClkuJsWoxloTnmzcE4ne {
  color: var(--color-primary);
}

.AoZSEGY3T6STbmGYKzpp {
  color: var(--color-on-primary);
}

.w7qBiuCC5HOvl2WK3Rck {
  color: var(--color-secondary);
}

.ubo9OP09OcmoYmfg5Z8y {
  color: var(--color-on-secondary);
}

.BwbX1DOI_o2IDP5gIq7j {
  color: var(--color-tertiary);
}

.a84wgyXYvSj0NCVJSbZu {
  color: var(--color-on-tertiary);
}

.K8HcBrQfc34djt2SVKNa {
  color: var(--color-error);
}

.QKNp2EcI50zvI0NyQQKW {
  color: var(--color-on-error);
}

.oVXYeJKofx4Zq2myOzd1 {
  color: var(--color-surface);
}

.h6UTsr8DSkpxxi7wRJGP {
  color: var(--color-on-surface);
}

.nvwc0cT2ECx5fH0JiKfB {
  color: var(--color-surface-variant);
}

.pPnqK3isWeDElxWRbpMn {
  color: var(--color-on-surface-variant);
}

/*!******************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/general/image/Image/Image.module.css ***!
  \******************************************************************************************************************************************************************************************************/
.dUc5FdNe8VyRDXduQkaM {
  max-width: 100%;
  vertical-align: bottom;
}

/*!************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/general/surface/Surface/Surface.module.css ***!
  \************************************************************************************************************************************************************************************************************/
.Vo9Q8e_SgNyEzchLrReo {
  box-sizing: border-box;
  display: inline-block;
  overflow: clip;
  vertical-align: top;
}

.SGklRdyyQMjLU1PDBgcU {
  height: 100%;
}

.UWKcCW58Y_wwaZfT7cqG {
  width: 100%;
}

.XJnWTT32lt0jVAmNrVbU {
  padding: var(--dimension-surface-padding-horizontal)
    var(--dimension-surface-padding-vertical);
}

.ZYOR6lA1ht0EkntVQ9Ny {
  border-radius: var(--dimension-surface-border-radius);
}

.F_ieXvsC7tdu0WVMt2Sp {
  border-style: solid;
  border-width: 1px;
}

.bDA4J2DOCzPsV1IVKwK4 {
  border-style: dashed;
}

.BUVqF7Nlix_moRpOo94I {
  border-style: dotted;
}

.T9vdIciVuQXcepGHE8Hf {
  cursor: pointer;
}

.zl6rCdza46cz4HrDTsQO {
  border: none;
  cursor: pointer;
  padding: 0;
  text-align: unset;
}

/*!*********************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/general/button/Button/Button.module.css ***!
  \*********************************************************************************************************************************************************************************************************/
.tdmhVSRIa8E5pEmZ6kR2 {
  align-items: center;
  border-radius: 0;
  box-sizing: border-box;
  display: inline-flex;
  font-family: var(--typography-button-medium-font-family);
  font-size: var(--typography-button-medium-size);
  font-weight: var(--typography-button-medium-weight);
  height: var(--height-button-medium);
  justify-content: center;
  line-height: var(--typography-button-medium-line-height);
  padding-bottom: 0px !important;
  padding-left: var(--padding-left-button-medium) !important;
  padding-right: var(--padding-right-button-medium) !important;
  padding-top: 0px !important;
}

.P7pbAZ2KSuigQldKUIE8 {
  padding-left: var(--padding-left-button-with-icon-medium) !important;
}

.drR8s7eDxoc03Lrc10dW {
  border-radius: var(--shape-button-border-radius);
}

.qjq0ssRD40iRiaqSjtdW {
  padding-right: var(--space-button-icon-medium);
}

.JI_DDJ9rXIDQfkpw3ZGb {
  font-size: var(--size-icon-button-small-icon) !important;
}

.FIfUyXkpxp7z_3lQkIXj {
  height: var(--height-button-small);
  line-height: var(--height-button-small);
  padding-left: var(--padding-left-button-small) !important;
  padding-right: var(--padding-right-button-small) !important;
}

.FIfUyXkpxp7z_3lQkIXj.P7pbAZ2KSuigQldKUIE8 {
  padding-left: var(--padding-left-button-with-icon-small) !important;
}

.FIfUyXkpxp7z_3lQkIXj .qjq0ssRD40iRiaqSjtdW {
  padding-right: var(--space-button-icon-small);
}

.U00UdBjMLsQ1JbLazPcZ {
  height: var(--height-button-large);
  line-height: var(--height-button-large);
  padding-left: var(--padding-left-button-large) !important;
  padding-right: var(--padding-right-button-large) !important;
}

.U00UdBjMLsQ1JbLazPcZ.P7pbAZ2KSuigQldKUIE8 {
  padding-left: var(--padding-left-button-with-icon-large) !important;
}

.U00UdBjMLsQ1JbLazPcZ .qjq0ssRD40iRiaqSjtdW {
  padding-right: var(--space-button-icon-large);
}

.rGo2qlRCiubCl6NUuEFl {
  opacity: var(--opacity-disabled) !important;
}

.LQbppEdJ2pen6icRU6pr {
  background-color: transparent !important;
}

.LQbppEdJ2pen6icRU6pr:hover {
  background-color: transparent !important;
}

.LQbppEdJ2pen6icRU6pr:focus {
  background-color: transparent !important;
}

.LQbppEdJ2pen6icRU6pr:active {
  background-color: transparent !important;
}

.OMPC4jQcqHC8Z0SS3bmV {
  background-color: transparent !important;
}

.OMPC4jQcqHC8Z0SS3bmV:hover {
  background-color: transparent !important;
}

.OMPC4jQcqHC8Z0SS3bmV:focus {
  background-color: transparent !important;
}

.OMPC4jQcqHC8Z0SS3bmV:active {
  background-color: transparent !important;
}

.LQbppEdJ2pen6icRU6pr.rGo2qlRCiubCl6NUuEFl,
.OMPC4jQcqHC8Z0SS3bmV.rGo2qlRCiubCl6NUuEFl {
  color: var(--color-on-surface-variant) !important;
  opacity: var(--opacity-disabled) !important;
}

/*!*******************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/general/button/ButtonGroup/ButtonGroup.module.css ***!
  \*******************************************************************************************************************************************************************************************************************/
.LimuthCG8lfV8kvUZP_y {
  display: inline-flex;
}

.WIQYsA5iWQaeLzU_uhIe {
  width: 100%;
}

.AMDXJiZC4H37TNh0ycBU {
  flex-direction: row;
}

.YzM643gPBQisxPntWbuA {
  transition: all var(--transition-duration),
    z-index var(--transition-duration) cubic-bezier(0, 0, 1, 1);
  z-index: 0;
}

.YzM643gPBQisxPntWbuA:hover {
  transition: all var(--transition-duration), z-index 0s;
  z-index: 2;
}

.YzM643gPBQisxPntWbuA:active,
.YzM643gPBQisxPntWbuA:focus {
  transition: all var(--transition-duration), z-index 0s;
  z-index: 3;
}

.YzM643gPBQisxPntWbuA:not(:first-child) {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  margin-left: -1px;
}

.YzM643gPBQisxPntWbuA:not(:last-child) {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.ONU4We8_dxhvnD0pSAQ2 {
  flex-direction: column;
}

.lII_T3_nFwK4iR8dsmKh {
  transition: all var(--transition-duration),
    z-index var(--transition-duration) cubic-bezier(0, 0, 1, 1);
  z-index: 0;
}

.lII_T3_nFwK4iR8dsmKh:hover {
  transition: all var(--transition-duration), z-index 0s;
  z-index: 2;
}

.lII_T3_nFwK4iR8dsmKh:active,
.lII_T3_nFwK4iR8dsmKh:focus {
  transition: all var(--transition-duration), z-index 0s;
  z-index: 3;
}

.lII_T3_nFwK4iR8dsmKh:not(:first-child) {
  margin-top: -1px;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.lII_T3_nFwK4iR8dsmKh:not(:last-child) {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

/*!*******************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/feedback/modal/Modal/Modal.module.css ***!
  \*******************************************************************************************************************************************************************************************************/
.mJPTMFHmmdLQ8IazwLPj {
  max-width: 384px;
  padding: 24px;
  width: 100%;
}

.TZMkhi6UErm4UUoAZ0Gs {
  align-items: center;
  background: var(--color-backdrop);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
}

/*!**********************************************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/feedback/confirmationDialog/ConfirmationDialog/ConfirmationDialog.module.css ***!
  \**********************************************************************************************************************************************************************************************************************************************/
.d0084QqsXrp_ZjPlbLxt {
  padding: var(--space-5x) 0 var(--space-6x) 0;
}

.MaQq6PX6CC4BxpzUO7cV {
  display: flex;
  gap: var(--space-gap);
  justify-content: space-evenly;
}

/*!*****************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/general/button/IconButton/IconButton.module.css ***!
  \*****************************************************************************************************************************************************************************************************************/
.e9n_A9YAh11Ou7K4XWLZ {
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  padding: 0 !important;
  border-radius: 50% !important;
}

.e9n_A9YAh11Ou7K4XWLZ:not(.JxQHzY3EaPTdgNdUxigQ):not(.K9tzxQbYZywSo8lS083T) {
  border-radius: var(--shape-icon-button-medium-border-radius);
  height: var(--size-icon-button-medium);
  line-height: var(--size-icon-button-medium);
  min-width: var(--size-icon-button-medium);
}

.e9n_A9YAh11Ou7K4XWLZ:not(.JxQHzY3EaPTdgNdUxigQ):not(.K9tzxQbYZywSo8lS083T) > span {
  font-size: var(--size-icon-button-medium-icon);
  height: var(--size-icon-button-medium-icon);
  line-height: var(--size-icon-button-medium-icon);
  min-width: var(--size-icon-button-medium-icon);
}

.JxQHzY3EaPTdgNdUxigQ {
  border-radius: var(--shape-icon-button-small-border-radius);
  height: var(--size-icon-button-small);
  line-height: var(--size-icon-button-small);
  min-width: var(--size-icon-button-small);
}

.JxQHzY3EaPTdgNdUxigQ > span {
  font-size: var(--size-icon-button-small-icon);
  height: var(--size-icon-button-small-icon);
  line-height: var(--size-icon-button-small-icon);
  min-width: var(--size-icon-button-small-icon);
}

.K9tzxQbYZywSo8lS083T {
  border-radius: var(--shape-icon-button-large-border-radius);
  height: var(--size-icon-button-large);
  line-height: var(--size-icon-button-large);
  min-width: var(--size-icon-button-large);
}

.K9tzxQbYZywSo8lS083T > span {
  font-size: var(--size-icon-button-large-icon);
  height: var(--size-icon-button-large-icon);
  line-height: var(--size-icon-button-large-icon);
  min-width: var(--size-icon-button-large-icon);
}

.mdJh0q56IPXPqAdar3fQ {
  opacity: var(--opacity-disabled) !important;
}

.TPzgZSTyDgSaXiQxR_2o {
  background-color: transparent !important;
}

.TPzgZSTyDgSaXiQxR_2o:hover {
  background-color: transparent !important;
}

.TPzgZSTyDgSaXiQxR_2o:focus {
  background-color: transparent !important;
}

.TPzgZSTyDgSaXiQxR_2o:active {
  background-color: transparent !important;
}

.uWqtOPcgNDozvRy4altg {
  background-color: transparent !important;
}

.uWqtOPcgNDozvRy4altg:hover {
  background-color: transparent !important;
}

.uWqtOPcgNDozvRy4altg:focus {
  background-color: transparent !important;
}

.uWqtOPcgNDozvRy4altg:active {
  background-color: transparent !important;
}

.TPzgZSTyDgSaXiQxR_2o.mdJh0q56IPXPqAdar3fQ,
.uWqtOPcgNDozvRy4altg.mdJh0q56IPXPqAdar3fQ {
  color: var(--color-on-surface-variant) !important;
  opacity: var(--opacity-disabled) !important;
}

/*!*************************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/general/icon/FontAwesomeIcon/FontAwesomeIcon.module.css ***!
  \*************************************************************************************************************************************************************************************************************************/
.fEKcm9td3oLkmfAOu4m_ {
  font-size: var(--typography-label-large-size);
}

.wN81lIlMz8wgQJI9IDmJ {
  color: rgba(0, 0, 0, 0.54);
}

.wN81lIlMz8wgQJI9IDmJ.jT2PBOuV07DZEXFmsoLj {
  color: rgba(0, 0, 0, 0.26);
}

._5Nt4PMZuZgYGkRUNe2V {
  color: rgba(255, 255, 255, 1);
}

._5Nt4PMZuZgYGkRUNe2V.jT2PBOuV07DZEXFmsoLj {
  color: rgba(255, 255, 255, 0.3);
}

.jsgI51WODkieaaeB8reu {
  animation-duration: 1024ms;
  animation-iteration-count: infinite;
  animation-name: ldIVScemDqlNr2BkEK6N;
  animation-timing-function: linear;
}

@keyframes ldIVScemDqlNr2BkEK6N {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*!*******************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/general/icon/MaterialIcon/MaterialIcon.module.css ***!
  \*******************************************************************************************************************************************************************************************************************/
.GOOBqoP0kOyGiAezXXGK {
  font-size: var(--typography-label-large-size);
}

.HGtf1AlSysXoBt8ypv49 {
  color: rgba(0, 0, 0, 0.54);
}

.HGtf1AlSysXoBt8ypv49.ch8UxbyP3SlxTU3l1Te_ {
  color: rgba(0, 0, 0, 0.26);
}

.owfFbb0cGLxJ6dWK4T4U {
  color: rgba(255, 255, 255, 1);
}

.owfFbb0cGLxJ6dWK4T4U.ch8UxbyP3SlxTU3l1Te_ {
  color: rgba(255, 255, 255, 0.3);
}

.hwKlxET3apNOsehXOz9M {
  animation-duration: 1024ms;
  animation-iteration-count: infinite;
  animation-name: ByIfigARpJGa9VNLUNF2;
  animation-timing-function: linear;
}

@keyframes ByIfigARpJGa9VNLUNF2 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*!******************************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/general/loading/LoadingIndicator/LoadingIndicator.module.css ***!
  \******************************************************************************************************************************************************************************************************************************/
.i8ID5KBsHPl76eZ7a6Ba {
  align-items: center;
  display: flex;
  justify-content: center;
}

.AxhMcLoOJUTV2ZHwSwwY {
  flex-direction: row;
  min-height: var(--space-7x);
}

.EJjiIl9aQj8ILVou1drg {
  flex-direction: column;
  min-height: var(--space-10x);
}

.ixNmThPhbL_5N8wm8p_S {
  padding: var(--space-2x);
}

.LnSORh2DycHbNyHEGO17 {
  padding: var(--space-2x);
}

.AxhMcLoOJUTV2ZHwSwwY.hEFK1a3ICnCUu9WYHooU {
  min-height: var(--space-7x);
}

.EJjiIl9aQj8ILVou1drg.hEFK1a3ICnCUu9WYHooU {
  min-height: var(--space-11x);
}

/*!****************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/dataDisplay/tag/Tag/Tag.module.css ***!
  \****************************************************************************************************************************************************************************************************/
.jgq5QE0SwycFK_4NN_gy {
  align-items: center;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  display: flex;
  justify-content: center;
  padding: 2px 12px;
}

/*!********************************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/dataDisplay/card/Card/CardHorizontal/CardHorizontal.module.css ***!
  \********************************************************************************************************************************************************************************************************************************/
.w6rfOCfqV6HHS27xtOV8 {
  display: flex;
  flex-direction: row;
}

.sqTDuryDKbmy_Izk58qu {
  display: flex;
  flex-direction: column;
}

.yiWBh6ogDBieMpGOp1UU {
  flex-grow: 0;
  flex-shrink: 0;
  height: 100%;
  position: relative;
}

.jmNuykQI9UUYM0m9zygo {
  height: 100%;
}

.DnOT6VIKTqqQYpH6iVbx {
  bottom: var(--space-gap);
  position: absolute;
  right: var(--space-gap);
}

/*!****************************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/dataDisplay/card/Card/CardVertical/CardVertical.module.css ***!
  \****************************************************************************************************************************************************************************************************************************/
.RyRKXLj0a9iYycatbMmk {
  display: flex;
  flex-direction: column;
}

.cWQTVQTwKRucV4C9PRs5 {
  position: relative;
}

.MRbGHS4u4kitBLo23QX2 {
  width: 100%;
}

.e_MCmnAnGe5P1KBFiPrP {
  bottom: var(--space-gap);
  position: absolute;
  right: var(--space-gap);
}

/*!***************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/dataDisplay/list/ListItem/ListItem.module.css ***!
  \***************************************************************************************************************************************************************************************************************/
.KUS79_4lRPrkVtrB_o7C {
  cursor: pointer;
}

.WjFicFTFRr_6YpZa0W9Y {
  cursor: not-allowed;
}

.czbsiyIQtZ3_8Jnxnilr {
  all: unset;
  width: 100%;
}

/*!*******************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/dataDisplay/list/List/List.module.css ***!
  \*******************************************************************************************************************************************************************************************************/
.h10uGJZ45vIaNXkpErkt {
  list-style: none;
  margin: 0;
  padding: 0;
}

._eDzPK4h_FUKOyHo_SB2 {
  width: 100%;
}

/*!************************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/dataDisplay/profile/ProfileCard/ProfileCard.module.css ***!
  \************************************************************************************************************************************************************************************************************************/
.nm9wqoFRTLDEX9LCwH2g {
  -moz-box-orient: vertical;
  -moz-line-clamp: 1;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -moz-box;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}

.KVZ5B67R9YHH8GZmxXHl {
  -moz-box-orient: vertical;
  -moz-line-clamp: 1;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -moz-box;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Opxo3hArzKmwP_fyG0fH {
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

.Wkcz_Qw8srzD80xFEc6v {
  -moz-box-orient: vertical;
  -moz-line-clamp: 1;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -moz-box;
  display: -webkit-box;
  height: var(--typography-headline6-line-height);
  overflow: hidden;
  text-overflow: clip;
}

.FaCOh_9HpvGZOLGPB_51 {
  -moz-box-orient: vertical;
  -moz-line-clamp: 3;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -moz-box;
  display: -webkit-box;
  height: calc(var(--typography-body-large-line-height) * 3);
  overflow: hidden;
  text-overflow: clip;
}

.p6J7g4WeWao7v7fRu3fR {
  align-items: center;
  display: flex;
  gap: var(--space-gap);
  justify-content: center;
  margin-top: var(--space-gap);
}

/*!****************************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/dataDisplay/profile/ProfileHeader/ProfileHeader.module.css ***!
  \****************************************************************************************************************************************************************************************************************************/
.O4xyOgSJAYdA5o7EJXVu {
  height: 88px;
  object-fit: cover;
  width: 100%;
}

.iA109rLTtEsk1OUXT6oE {
  display: flex;
  flex-direction: row;
}

.XNvlbLzTMOvbk1FBWoxN {
  border-radius: 50%;
  flex-shrink: 0;
  height: 152px;
  margin: -56px 16px 16px 16px;
  padding: 4px;
  width: 152px;
}

.Rx60SHryFW_yLxFYVrsQ {
  border-radius: 50%;
  height: 144px;
  object-fit: cover;
  width: 144px;
}

.u2ZWOtF6eg00mCvaumkc {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-gap);
  justify-content: flex-start;
  margin-top: var(--space-2x);
}

.vXMxcSANsmhWy03o4qKg {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-gap);
  justify-content: flex-start;
  margin-top: var(--space-4x);
}

/*!***************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/dataDisplay/registration/RegistrationStatusCard/RegistrationStatusCard.module.css ***!
  \***************************************************************************************************************************************************************************************************************************************************/
.NHQ1x346KzV9hKF_aVEf {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  gap: var(--space-gap);
}

.l0huw7bg_frhyMGC5H0x {
  flex: 1;
}

.Qdz0XAxuTgDS3qJPoZ5z {
  height: 100%;
}

.HXZQxejj8hVry6HnNh07 {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 208px;
}

/*!***************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/dataDisplay/team/TeamCard/TeamCard.module.css ***!
  \***************************************************************************************************************************************************************************************************************/
.noF1Y2PbcNUMUtPwJYkl {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 256px;
  width: 100%;
}

.Byvsyg6WGRh5AeRCk7T_ {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 64px;
  justify-content: center;
  width: 100%;
}

.RYBggRhGY7V7wpktxJY6 {
  align-items: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--space-gap);
  justify-content: space-evenly;
  padding: var(--dimension-surface-padding-horizontal)
    var(--dimension-surface-padding-vertical);
}

.yWa5kW18CovZRF0wIupV {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.EuXzHsu70kAfbO6L_sV6 {
  align-self: center;
}

.kgGNsIXr39xY5Gc1M5OK {
  text-align: center;
}

.BPh0Uj7f3PaUI6dqlbYc {
  margin-top: 10px;
  text-align: center;
}

.D7aurcpytz5EPyJhk8Kj {
  text-align: center;
}

.wH5e1bBaiMABSJYBV_ZK {
  text-transform: capitalize;
}

/*!*******************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/dataDisplay/team/TeamEvaluationStatusListItem/TeamEvaluationStatusListItem.module.css ***!
  \*******************************************************************************************************************************************************************************************************************************************************/
.onCZnNJXygHfsWaSyKCo {
  align-items: center;
  display: flex !important;
  flex-direction: row;
  gap: var(--space-gap);
  padding: var(--padding-list-item);
}

.koBeaOevNd4FG5Wa45Gq {
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: var(--size-icon-button-medium-icon);
  justify-content: center;
  margin: 0 var(--padding-list-item-horizontal);
  width: var(--size-icon-button-medium-icon);
}

.L9wOQ9LrfjdEmV6BNeSg {
  border-radius: 50%;
}

.W6gpkDsJwJf4nO5GtsdQ {
  flex: 1;
}

.svDOGzgO72NSv8NdJNFV {
  flex: 1;
}

.VR0e16LR1yAzikWTlpwX {
  flex: 0 0 128px;
}

.R5xiaijJpWx9OfNKva1w {
  flex: 0 0 128px;
}

/*!*************************************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/dataDisplay/team/TeamRankingListItem/TeamRankingListItem.module.css ***!
  \*************************************************************************************************************************************************************************************************************************************/
.ATpix0TtZkdYFJgZJljV {
  align-items: center;
  display: flex !important;
  flex-direction: row;
  gap: var(--space-gap);
  padding: var(--padding-list-item);
}

.Kt3SEsVogCg9EAvmYbxX {
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: var(--size-icon-button-medium-icon);
  justify-content: center;
  margin: 0 var(--padding-list-item-horizontal);
  width: var(--size-icon-button-medium-icon);
}

.edTyU40wfsbtRHJaBP5Q {
  border-radius: 50%;
}

.gTTmuhKJbfYudCHT8Jrt {
  flex: 1;
}

.GnrwObGXGihsfiWbGyeO {
  flex: 1;
}

.oDn5pv11CrvK_Um4VxUJ {
  flex: 0 0 128px;
}

.EGnHkqDyBbijiFQ5bdQO {
  align-items: center;
  display: flex;
  justify-content: center;
  flex: 0 0 96px;
}

.F9UkaLdkwYVQMc1iVY1Y {
  width: 48px;
}

/*!**********************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/dataDisplay/workspace/WorkspaceCard/WorkspaceCardDefault/WorkspaceCardDefault.module.css ***!
  \**********************************************************************************************************************************************************************************************************************************************************/
.uuUt1zJfQ227nKXHWX39 {
  display: flex;
  flex-direction: column;
  width: 240px;
}

.jcFoiAHJHwHgS3mkghMy {
  position: relative;
}

.jZLOsxT3aR5UkyM25hoX {
  height: 144px;
  object-fit: cover;
  width: 240px;
}

.gC7IKynyYbZzQWllXrkT {
  align-items: center;
  display: flex;
  height: 32px;
  padding-left: var(--space-4x);
  padding-right: var(--space-4x);
  position: absolute;
  right: 0;
  top: 0;
}

.v1L3Q1lkXn78xtcQTLpi {
  padding-top: var(--space-4x);
  text-align: center;
}

/*!****************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/dataDisplay/workspace/WorkspaceCard/WorkspaceCardHorizontal/WorkspaceCardHorizontal.module.css ***!
  \****************************************************************************************************************************************************************************************************************************************************************/
.Ip0IE5GsLDt8I3B14uCG {
  display: flex;
  flex-direction: row;
  min-height: 128px;
  max-height: 192px;
}

.aYWmMfQ5uW9J__ZqLM_8 {
  overflow: hidden;
  position: relative;
  width: 256px;
}

.MMfmcBMAm6M2h_PGht6V {
  bottom: 0;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 0;
}

.R5E32Mqpg3stNEXgWoy5 {
  aspect-ratio: 1024 / 160;
  backdrop-filter: blur(4px);
  height: 100%;
  object-fit: contain;
  position: relative;
}

.ibctZpbM8AAP4rdivyDw {
  -moz-box-orient: vertical;
  -moz-line-clamp: 1;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -moz-box;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}

.eFzPmrdZ72AhnofVvSJ2 {
  -moz-box-orient: vertical;
  -moz-line-clamp: 3;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -moz-box;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Hk4M60ycjCy8KxGnEe15 {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.lOJSDjfggken82qTPbRG {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.jMOqgEM5wMfDrb2Dop1s {
  align-items: center;
  display: flex;
  flex: 1;
  height: 32px;
  justify-content: flex-end;
  padding-left: var(--space-4x);
  padding-right: var(--space-4x);
}

.jFz8qJ4GyXOF824LVU2w {
  padding-top: var(--space-4x);
}

/*!**********************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/feedback/notice/Notice/Notice.module.css ***!
  \**********************************************************************************************************************************************************************************************************/
.zeJ68uAmjo2SPBzL3gvJ {
  padding: var(--space-3x) var(--padding-surface-horizontal);
}

/*!******************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/form/core/label/FieldLabel/FieldLabel.module.css ***!
  \******************************************************************************************************************************************************************************************************************/
.ZilVjk1cSoXXaxYH8XqH {
  font-family: var(--typography-label-medium-font-family);
  font-size: var(--typography-label-medium-size);
  font-weight: var(--typography-label-medium-weight);
  line-height: var(--typography-label-medium-line-height);
}

.e3xnygdh0mNbt2iGWUU7::before {
  color: #ff4d4f;
  content: '*';
  display: inline-block;
  font-family: var(--typography-label-medium-font-family);
  font-size: var(--typography-label-medium-size);
  font-weight: var(--typography-label-medium-weight);
  line-height: var(--typography-label-medium-line-height);
  margin-right: 4px;
}

.O6cGnhPsxpykdGWuxUKQ {
  font-family: var(--typography-label-small-font-family);
  font-size: var(--typography-label-small-size);
  font-weight: var(--typography-label-small-weight);
  line-height: var(--typography-label-small-line-height);
}

.PbluXTm1VlkHTW4EdT41 {
  font-family: var(--typography-label-large-font-family);
  font-size: var(--typography-label-large-size);
  font-weight: var(--typography-label-large-weight);
  line-height: var(--typography-label-large-line-height);
}

/*!******************************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/form/core/field/BaseFieldWrapper/BaseFieldWrapper.module.css ***!
  \******************************************************************************************************************************************************************************************************************************/
.pWozsplgEg2uROYiUKc3 {
  display: inline-flex;
  flex-direction: column;
}

.soiNuzoVOmCNoK7qHwoC {
  opacity: var(--opacity-disabled);
}

.wrabHT2KrXFehtvYXIfI {
  padding-bottom: var(--typography-label-small-line-height);
}

.zGzwCTnaV17wEE9BMJDu {
  width: 100%;
}

.Nz16q4qH_z5mydWwQmaK {
  font-family: var(--typography-label-small-font-family);
  font-size: var(--typography-label-small-size);
  font-weight: var(--typography-label-small-weight);
  line-height: var(--typography-label-small-line-height);
  min-height: var(--typography-label-small-line-height);
}

/*!**********************************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/form/autocomplete/AutocompleteInput/AutocompleteInput.module.css ***!
  \**********************************************************************************************************************************************************************************************************************************/
.sY6nYFLFbjmm3EH9qncC {
  display: contents;
}

.vbyeiCFPra3610LhNpcz {
  width: 100%;
}

/*!**********************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/form/checkbox/CheckboxInput/CheckboxInput.module.css ***!
  \**********************************************************************************************************************************************************************************************************************/
.jB65YV4nsWqJd3aPRrma {
  display: contents;
}

.JK6XpdSsb0KDK7vJCPN7 {
  width: 100%;
}

/*!**************************************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/form/checkbox-group/CheckboxGroupInput/CheckboxGroupInput.module.css ***!
  \**************************************************************************************************************************************************************************************************************************************/
.zKNjSS4nyZTQRlyJShzl {
  display: contents;
}

.RAssDeZP_fHSG6KBAW0Z {
  width: 100%;
}

/*!*****************************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/form/date-picker/DatePickerInput/DatePickerInput.module.css ***!
  \*****************************************************************************************************************************************************************************************************************************/
.ARA0PkC8sO5XxXVlDGDI {
  display: contents;
}

.x_MnHdGCD8oKAEmGU5ny {
  width: 100%;
}

/*!******************************************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/form/date-time-picker/DateTimePickerInput/DateTimePickerInput.module.css ***!
  \******************************************************************************************************************************************************************************************************************************************/
.GUlK5FFxUFNDWZCafctA {
  display: contents;
}

.ZCQPUSyKhww_ZW1dfrbX {
  width: 100%;
}

/*!*************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/form/group/FieldGroup/FieldGroup.module.css ***!
  \*************************************************************************************************************************************************************************************************************/
.l3z3ovh9cKoNQfYcyfMe {
  display: inline-flex;
  padding-bottom: var(--typography-label-small-line-height);
}

.mc5ZjIWIirk7eJnj5Kuf {
  width: 100%;
}

.nKYV_p4NZqzZrDGS3WLz {
  flex-direction: row;
}

.DM0TSBmZFWEFxWK92IG6:not(:first-of-type) {
  margin-left: -1px;
}

.DM0TSBmZFWEFxWK92IG6 .z6sDLXEZwqcgYA2uTLVm {
  transition: all var(--transition-duration),
    z-index var(--transition-duration) cubic-bezier(0, 0, 1, 1);
  z-index: 0;
}

.DM0TSBmZFWEFxWK92IG6 .z6sDLXEZwqcgYA2uTLVm:hover {
  transition: all var(--transition-duration), z-index 0s;
  z-index: 2;
}

.DM0TSBmZFWEFxWK92IG6 .z6sDLXEZwqcgYA2uTLVm:active,
.DM0TSBmZFWEFxWK92IG6 .z6sDLXEZwqcgYA2uTLVm:focus {
  transition: all var(--transition-duration), z-index 0s;
  z-index: 3;
}

.DM0TSBmZFWEFxWK92IG6:not(:first-of-type) .z6sDLXEZwqcgYA2uTLVm {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.DM0TSBmZFWEFxWK92IG6:not(:last-of-type) .z6sDLXEZwqcgYA2uTLVm {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.wsrS071NE5fV4uttQLzI {
  flex-direction: column;
}

.FC84BhxwYNC37T5KVf2g .x5B9GpAkMDyicRsKgBSG {
  display: none !important;
}

.FC84BhxwYNC37T5KVf2g {
  padding-bottom: 0 !important;
}

.FC84BhxwYNC37T5KVf2g:not(:first-of-type) {
  margin-top: -1px;
}

.FC84BhxwYNC37T5KVf2g .gi0t4aW2bx1DfosW8gmQ {
  transition: all var(--transition-duration),
    z-index var(--transition-duration) cubic-bezier(0, 0, 1, 1);
  z-index: 0;
}

.FC84BhxwYNC37T5KVf2g .gi0t4aW2bx1DfosW8gmQ:hover {
  transition: all var(--transition-duration), z-index 0s;
  z-index: 2;
}

.FC84BhxwYNC37T5KVf2g .gi0t4aW2bx1DfosW8gmQ:active,
.FC84BhxwYNC37T5KVf2g .gi0t4aW2bx1DfosW8gmQ:focus {
  transition: all var(--transition-duration), z-index 0s;
  z-index: 3;
}

.FC84BhxwYNC37T5KVf2g:not(:first-of-type) .gi0t4aW2bx1DfosW8gmQ {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.FC84BhxwYNC37T5KVf2g:not(:last-of-type) .gi0t4aW2bx1DfosW8gmQ {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

/*!*************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/form/group/InputGroup/InputGroup.module.css ***!
  \*************************************************************************************************************************************************************************************************************/
.aFx8xlvEjTFDczUYLhSy {
  display: inline-flex;
  padding-bottom: var(--typography-label-small-line-height);
}

.MQsiRhKvqbZQDdV2Rm3Q {
  width: 100%;
}

.FOK991xA82XZHJcqLqr6 {
  flex-direction: row;
}

.qw0FpT1kAXskqhWgtALg:not(:first-of-type) {
  margin-left: -1px;
}

.qw0FpT1kAXskqhWgtALg .Fqh63VzJB2kjcqw9dp6X {
  transition: all var(--transition-duration),
    z-index var(--transition-duration) cubic-bezier(0, 0, 1, 1);
  z-index: 0;
}

.qw0FpT1kAXskqhWgtALg .Fqh63VzJB2kjcqw9dp6X:hover {
  transition: all var(--transition-duration), z-index 0s;
  z-index: 2;
}

.qw0FpT1kAXskqhWgtALg .Fqh63VzJB2kjcqw9dp6X:active,
.qw0FpT1kAXskqhWgtALg .Fqh63VzJB2kjcqw9dp6X:focus {
  transition: all var(--transition-duration), z-index 0s;
  z-index: 3;
}

.qw0FpT1kAXskqhWgtALg:not(:first-of-type) .Fqh63VzJB2kjcqw9dp6X {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.qw0FpT1kAXskqhWgtALg:not(:last-of-type) .Fqh63VzJB2kjcqw9dp6X {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.Qa4_Gvcm9wI6MJKfXLbz {
  flex-direction: column;
}

._9ZQB1bqE0BjiWwXDNJyQ {
  padding-bottom: 0 !important;
}

._9ZQB1bqE0BjiWwXDNJyQ:not(:first-of-type) {
  margin-top: -1px;
}

._9ZQB1bqE0BjiWwXDNJyQ .VvrA6Qt05bQBvAmwDrMd {
  transition: all var(--transition-duration),
    z-index var(--transition-duration) cubic-bezier(0, 0, 1, 1);
  z-index: 0;
}

._9ZQB1bqE0BjiWwXDNJyQ .VvrA6Qt05bQBvAmwDrMd:hover {
  transition: all var(--transition-duration), z-index 0s;
  z-index: 2;
}

._9ZQB1bqE0BjiWwXDNJyQ .VvrA6Qt05bQBvAmwDrMd:active,
._9ZQB1bqE0BjiWwXDNJyQ .VvrA6Qt05bQBvAmwDrMd:focus {
  transition: all var(--transition-duration), z-index 0s;
  z-index: 3;
}

._9ZQB1bqE0BjiWwXDNJyQ:not(:first-of-type) .VvrA6Qt05bQBvAmwDrMd {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

._9ZQB1bqE0BjiWwXDNJyQ:not(:last-of-type) .VvrA6Qt05bQBvAmwDrMd {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

/*!*******************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/form/list-item-radio-group/ListItemRadioGroupInput/ListItemRadioGroupInput.module.css ***!
  \*******************************************************************************************************************************************************************************************************************************************************/
.jZu0a2daxqeJsle_JZSN {
  display: contents;
}

.Wh7UIw91uA7VD40ZwtP6 {
  align-items: center;
}

.fwc8NkMMeu9MyQKzF1oJ {
  width: 100%;
}

.ZBVdiGrs0xywpuLaquDu {
  padding: var(--padding-surface);
}

.CcgljgTx7KWt2ae0X1fQ {
  padding: var(--padding-surface-small);
}

.RytcnNZe7RMdC_uCLN3g {
  padding: var(--padding-surface-large);
}

/*!*****************************************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/form/multiple-select/MultipleSelectInput/MultipleSelectInput.module.css ***!
  \*****************************************************************************************************************************************************************************************************************************************/
.xZaqmydtNjwYlL6WLEbz {
  display: contents;
}

.I2Bk16nionHLZS_C_xz8 {
  width: 100%;
}

/*!****************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/form/number/NumberInput/NumberInput.module.css ***!
  \****************************************************************************************************************************************************************************************************************/
.kSRKxXzcyzmWWXDhiAcx {
  display: contents;
}

.PUvy4wK9zJONuXCNvTsw {
  width: 100%;
}

/*!*****************************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/form/radio-group/RadioGroupInput/RadioGroupInput.module.css ***!
  \*****************************************************************************************************************************************************************************************************************************/
.zjGrzF1jzEvxZkVIBhgw {
  display: contents;
}

.W4usOsIcW3bnMQooHz8G {
  width: 100%;
}

/*!**********************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/form/rate/RateInput/RateInput.module.css ***!
  \**********************************************************************************************************************************************************************************************************/
.LKIKT7hfumZaGoLU2Gkw {
  display: contents;
}

.Oysz2j2cgXTztt8Sd7IM {
  width: 100%;
}

/*!************************************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/form/recorder/RecorderFieldWrapper/RecorderFieldWrapper.module.css ***!
  \************************************************************************************************************************************************************************************************************************************/
.boy9sy0421LtpwZB9_9H {
  display: inline-block;
}

.kkH7GwW1WHXmMOLHi56g {
  height: 100%;
}

.FDppDOebJADePFgsNdhE {
  width: 100%;
}

.UYdejNjUAwcI0HPSvc4a {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.y4Xll8TYI2Q_eNnyIWFy {
  padding-bottom: 16px;
}

.vDLlWYLL7iHf8XQZrSqz::before {
  color: #ff4d4f;
  content: '*';
  display: inline-block;
  font-family: SimSun, sans-serif;
  font-size: 14px;
  line-height: 1;
  margin-right: 4px;
}

.Rx31VdOGhLjDprViQ_JQ {
  font-size: 12px;
  line-height: 16px;
  min-height: 16px;
}

/*!*************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/presentation/media/DeckViewer/DeckElement/CurrentSlideDisplay/CurrentSlideViewer.module.css ***!
  \*************************************************************************************************************************************************************************************************************************************************************/
.t0b0L2THzKtH07yv_Qqy {
  box-sizing: border-box;
  height: 100%;
  object-fit: contain;
  padding-left: 8px;
  padding-right: 8px;
  width: calc(100% - 80px);
}

/*!**********************************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/presentation/media/DeckViewer/DeckElement/DeckElement.module.css ***!
  \**********************************************************************************************************************************************************************************************************************************/
.nNUFLMGykZFrh8d_oOP1 {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  position: relative;
}

.LV395DLXUjM6zHLvITin {
  width: 100%;
}

/*!****************************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/presentation/media/DeckViewer/DeckInfo/DeckInfo.module.css ***!
  \****************************************************************************************************************************************************************************************************************************/
.dn53thjjHw9SRVJnO_wP {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;
}

/*!***********************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/presentation/media/VideoViewer/VideoViewer.module.css ***!
  \***********************************************************************************************************************************************************************************************************************/
.BItOrbkQNf1qpI100BKa {
  align-items: center;
  background-color: #000000;
  display: flex;
  height: 128px;
  justify-content: center;
  width: 128px;
}

.ANlEKfRaOYFwc6eERDLp {
  color: #ffffff;
}

.DzMcwUhgp5_AvikYwPJb {
  height: 100%;
  width: 100%;
}

/*!************************************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/presentation/recorder/RecorderControls/RecorderControls.module.css ***!
  \************************************************************************************************************************************************************************************************************************************/
.q7_D7xdT_UlskM2hKsv8 {
  margin: 12px;
}

/*!****************************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/presentation/recorder/RecorderDeck/RecorderDeck.module.css ***!
  \****************************************************************************************************************************************************************************************************************************/
.OWIipBRUUQNuZNYtYxZL {
  height: calc(100% - 64px);
  min-height: 192px;
}

/*!******************************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/presentation/player/PlayerControls/PlayerControls.module.css ***!
  \******************************************************************************************************************************************************************************************************************************/
.IROpk4Xmj_hCUNvUXMnw {
  margin: 12px;
}

/*!**********************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/presentation/player/PlayerDeck/PlayerDeck.module.css ***!
  \**********************************************************************************************************************************************************************************************************************/
.WaBfkUVHJU6Rm3pUjsUv {
  height: calc(100% - 96px);
  min-height: 192px;
}

/*!**************************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/presentation/player/PlayerSlider/PlayerSlider.module.css ***!
  \**************************************************************************************************************************************************************************************************************************/
.SZqJiaPoNCyuJ3rCnfTZ {
  max-width: 768px;
  width: 100%;
}

/*!************************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/presentation/player/PlayerTimer/PlayerTimer.module.css ***!
  \************************************************************************************************************************************************************************************************************************/
.o8EQ2W1jWKmNhtOWNocv {
  font-size: 24px;
  font-weight: bold;
}

/*!*******************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/presentation/player/Player.module.css ***!
  \*******************************************************************************************************************************************************************************************************/
.g6cEukFiNn93lnXEDXKt {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;
}

.l7O8EJ6eYpQbT4z6gg2e {
  bottom: 96px;
  position: absolute;
  right: 32px;
}

.s3NVs848K_mmSEFeBVpN {
  align-items: center;
  display: flex;
}

/*!***************************************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/presentation/recorder/RecorderPreviewModal/RecorderPreview.module.css ***!
  \***************************************************************************************************************************************************************************************************************************************/
.TRQm7h5k_d5WowDJsg2V {
  height: 100%;
}

.mXFtCHTjg4DuOoXcmArm {
  height: calc(100% - 56px);
}

/*!********************************************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/presentation/recorder/RecorderPreviewModal/RecorderPreviewModal.module.css ***!
  \********************************************************************************************************************************************************************************************************************************************/
.Rai6TcEp0m0WRmIZcqlT {
  height: 100% !important;
  max-width: 100%;
  padding: 16px !important;
  width: 100% !important;
}

.MkCr4ZPdl_jp1_mtfiyw {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.mCcn8ICbiwMa0GyQO8qL {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.juaq4nMsLrFduwnXu3wy {
  margin: 8px 0;
  width: 100%;
}

/*!********************************************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/presentation/recorder/RecorderInitializing/RecorderInitializing.module.css ***!
  \********************************************************************************************************************************************************************************************************************************************/
.uRBEY6yT4Qfdp_U1O98i {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

/*!******************************************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/presentation/recorder/RecorderUnsupported/RecorderUnsupported.module.css ***!
  \******************************************************************************************************************************************************************************************************************************************/
.HPNEFbbzezd9JdLyqIYd {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

/*!************************************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/presentation/recorder/RecorderSettingsModal/AddressBarInputDevicesBlocked/AddressBarInputDevicesBlocked.module.css ***!
  \************************************************************************************************************************************************************************************************************************************************************************************/
.TNSWVg189nPjVIa3O_zd {
  align-items: center;
  border-color: #d9d9d9;
  border-radius: 24px;
  border-style: solid;
  border-width: 1px;
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
  max-width: 100%;
  padding: 8px 16px;
  width: 384px;
}

.TemDCZZTiTpO4PfMTQmM {
  font-size: 16px;
}

.qv4J_ACTs8DsnPgz4k4h {
  color: darkred;
  font-size: 12px;
}

/*!****************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/form/select/SelectInput/SelectInput.module.css ***!
  \****************************************************************************************************************************************************************************************************************/
.PbOiWTb0zPNMIsydBuA3 {
  display: contents;
}

.KjpxOKXLa6ovWA8rpfzg {
  width: 100%;
}

/*!**********************************************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/presentation/recorder/RecorderSettingsModal/RecorderSettingsModal.module.css ***!
  \**********************************************************************************************************************************************************************************************************************************************/
.bKjwkzU2PUkGPRRIojnt {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.QT67hvllgQQ4onB9UzcH {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.E6aedUdIqRjomxJzrsbi {
  margin: 8px 0;
  width: 100%;
}

/*!******************************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/presentation/recorder/RecorderTimer/RecorderTimer.module.css ***!
  \******************************************************************************************************************************************************************************************************************************/
.EuEaKKEljsbtTaLGxG4j {
  font-size: 24px;
  font-weight: bold;
}

/*!***********************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/presentation/recorder/Recorder.module.css ***!
  \***********************************************************************************************************************************************************************************************************/
.K8kM4YXmAV4mICcLyiog {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;
}

.pP4EcTbUIEUgX1A4ksoR {
  bottom: 64px;
  position: absolute;
  right: 32px;
}

.kc7w90FbBgmn_6dUB2I_ {
  align-items: center;
  display: flex;
}

/*!**********************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/form/recorder/RecorderInput/RecorderInput.module.css ***!
  \**********************************************************************************************************************************************************************************************************************/
.oEN_V4mifxt9rKc3eDZB {
  display: contents;
}

.lEjvXvOuU4cBPI1B_8Bu {
  height: 100%;
}

.EC7iXoJsrON25i4xXDpF {
  width: 100%;
}

/*!**********************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/form/recorder/RecorderField/RecorderField.module.css ***!
  \**********************************************************************************************************************************************************************************************************************/
.lymIlmYpD8fsTnlw3QVK {
  height: 100%;
}

/*!***********************************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/form/remote-select/RemoteSelectInput/RemoteSelectInput.module.css ***!
  \***********************************************************************************************************************************************************************************************************************************/
.en3d3MmkaeyiLulsewQb {
  display: contents;
}

.M1WeZny0yb1t7b3aP8hC {
  width: 100%;
}

/*!****************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/form/slider/SliderInput/SliderInput.module.css ***!
  \****************************************************************************************************************************************************************************************************************/
._INgYPIg0PjIQ03XAXOD {
  display: contents;
}

.GBCi165auVuj9nZRBzqJ {
  margin: var(--dimension-input-padding-vertical) 4px;
}

.qCKRaNqAeCSwueaIoAM4 {
  width: calc(100% - 8px);
}

/*!****************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/form/switch/SwitchInput/SwitchInput.module.css ***!
  \****************************************************************************************************************************************************************************************************************/
.a85YMU_sdMg6iFsc9vRY {
  display: contents;
}

.TsVnAgawzJOSHWUJ1vKX {
  align-items: center;
  display: flex;
}

.rTpjKsovM4uT72B5kZ9Q {
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.BPYxb9l6aGiRhVrzIAXU {
  margin-right: 10px;
}

.SP0wA4iXa2AHuLGIbXzN {
  margin-left: 10px;
}

.bAUMvLAulahW5bDxpiht {
  width: 100%;
}

/*!********************************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/form/switch-group/SwitchGroupInput/SwitchGroupInput.module.css ***!
  \********************************************************************************************************************************************************************************************************************************/
.AwKr_IE7UxbiBhrmfMAK {
  display: contents;
}

.AD9mcEGrnplXZdYSdKEC {
  display: inline-block;
}

.X4iInumf3IaJOdqbpVNv {
  width: 100%;
}

/*!**********************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/form/text/TextInput/TextInput.module.css ***!
  \**********************************************************************************************************************************************************************************************************/
.ABqLO1Wcx9A5OpX_JxhP {
  display: contents;
}

.dIDrHetCbYx0yv4SMkQ3 {
  width: 100%;
}

/*!***********************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/form/text-area/TextAreaInput/TextAreaInput.module.css ***!
  \***********************************************************************************************************************************************************************************************************************/
.nl7XMLWI6KpHvF5AN1Gq {
  display: contents;
}

.AEGaDsQVysCn7yj3HDf_ {
  width: 100%;
}

/*!*****************************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/form/time-picker/TimePickerInput/TimePickerInput.module.css ***!
  \*****************************************************************************************************************************************************************************************************************************/
.j_u974brCvDFHco4_lim {
  display: contents;
}

.QmmRocDuOhgTKhV0kCfI {
  width: 100%;
}

/*!****************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/form/upload/UploadInput/UploadInput.module.css ***!
  \****************************************************************************************************************************************************************************************************************/
.hLtDBbPR70onTybU26ZL {
  display: contents;
}

.aUdqmiPiaAbKs7Qa_65n {
  padding: 0;
}

.iqquoQFhTgVr4kanz3As {
  width: 100%;
}

.mODDn2HljsqWZAKfstuH {
  padding: var(--space-3x) !important;
}

.vvp5NmPKQiiR94SO_1py {
  padding: 0 !important;
}

.FRtekcLH32Q5yPVSyXzY {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.Cd4zMm0mIX5iYI8hcwFK {
  background-color: var(--color-backdrop-opaque);
  bottom: 0;
  left: 0;
  opacity: var(--opacity-backdrop);
  position: absolute;
  right: 0;
  top: 0;
}

.EQTi_M5gxGnFp07IAIOH {
  max-height: 100%;
  max-width: 100%;
  vertical-align: bottom;
}

.AKaY4bQf3Cmyu5pihuEy {
  bottom: var(--space-3x);
  position: absolute;
  right: var(--space-3x);
}

/*!************************************************************************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].use[2]!./src/navigation/sticky/Sticky/Sticky.module.css ***!
  \************************************************************************************************************************************************************************************************************/
.tALmpJim2H6Oe7n1IfLK {
  position: sticky;
}


/*# sourceMappingURL=index.css.map*/
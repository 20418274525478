.primary:hover {
  border-color: var(--color-primary-hover) !important;
}

.primary:focus {
  border-color: var(--color-primary-focus) !important;
}

.primary:active {
  border-color: var(--color-primary-press) !important;
}

.onPrimary:hover {
  border-color: var(--color-on-primary-hover) !important;
}

.onPrimary:focus {
  border-color: var(--color-on-primary-focus) !important;
}

.onPrimary:active {
  border-color: var(--color-on-primary-press) !important;
}

.primaryContainer:hover {
  border-color: var(--color-primary-container-hover) !important;
}

.primaryContainer:focus {
  border-color: var(--color-primary-container-focus) !important;
}

.primaryContainer:active {
  border-color: var(--color-primary-container-press) !important;
}

.onPrimaryContainer:hover {
  border-color: var(--color-on-primary-container-hover) !important;
}

.onPrimaryContainer:focus {
  border-color: var(--color-on-primary-container-focus) !important;
}

.onPrimaryContainer:active {
  border-color: var(--color-on-primary-container-press) !important;
}

.secondary:hover {
  border-color: var(--color-secondary-hover) !important;
}

.secondary:focus {
  border-color: var(--color-secondary-focus) !important;
}

.secondary:active {
  border-color: var(--color-secondary-press) !important;
}

.onSecondary:hover {
  border-color: var(--color-on-secondary-hover) !important;
}

.onSecondary:focus {
  border-color: var(--color-on-secondary-focus) !important;
}

.onSecondary:active {
  border-color: var(--color-on-secondary-press) !important;
}

.secondaryContainer:hover {
  border-color: var(--color-secondary-container-hover) !important;
}

.secondaryContainer:focus {
  border-color: var(--color-secondary-container-focus) !important;
}

.secondaryContainer:active {
  border-color: var(--color-secondary-container-press) !important;
}

.onSecondaryContainer:hover {
  border-color: var(--color-on-secondary-container-hover) !important;
}

.onSecondaryContainer:focus {
  border-color: var(--color-on-secondary-container-focus) !important;
}

.onSecondaryContainer:active {
  border-color: var(--color-on-secondary-container-press) !important;
}

.tertiary:hover {
  border-color: var(--color-tertiary-hover) !important;
}

.tertiary:focus {
  border-color: var(--color-tertiary-focus) !important;
}

.tertiary:active {
  border-color: var(--color-tertiary-press) !important;
}

.onTertiary:hover {
  border-color: var(--color-on-tertiary-hover) !important;
}

.onTertiary:focus {
  border-color: var(--color-on-tertiary-focus) !important;
}

.onTertiary:active {
  border-color: var(--color-on-tertiary-press) !important;
}

.tertiaryContainer:hover {
  border-color: var(--color-tertiary-container-hover) !important;
}

.tertiaryContainer:focus {
  border-color: var(--color-tertiary-container-focus) !important;
}

.tertiaryContainer:active {
  border-color: var(--color-tertiary-container-press) !important;
}

.onTertiaryContainer:hover {
  border-color: var(--color-on-tertiary-container-hover) !important;
}

.onTertiaryContainer:focus {
  border-color: var(--color-on-tertiary-container-focus) !important;
}

.onTertiaryContainer:active {
  border-color: var(--color-on-tertiary-container-press) !important;
}

.error:hover {
  border-color: var(--color-error-hover) !important;
}

.error:focus {
  border-color: var(--color-error-focus) !important;
}

.error:active {
  border-color: var(--color-error-press) !important;
}

.onError:hover {
  border-color: var(--color-on-error-hover) !important;
}

.onError:focus {
  border-color: var(--color-on-error-focus) !important;
}

.onError:active {
  border-color: var(--color-on-error-press) !important;
}

.errorContainer:hover {
  border-color: var(--color-error-container-hover) !important;
}

.errorContainer:focus {
  border-color: var(--color-error-container-focus) !important;
}

.errorContainer:active {
  border-color: var(--color-error-container-press) !important;
}

.onErrorContainer:hover {
  border-color: var(--color-on-error-container-hover) !important;
}

.onErrorContainer:focus {
  border-color: var(--color-on-error-container-focus) !important;
}

.onErrorContainer:active {
  border-color: var(--color-on-error-container-press) !important;
}

.background:hover {
  border-color: var(--color-background-hover) !important;
}

.background:focus {
  border-color: var(--color-background-focus) !important;
}

.background:active {
  border-color: var(--color-background-press) !important;
}

.onBackground:hover {
  border-color: var(--color-on-background-hover) !important;
}

.onBackground:focus {
  border-color: var(--color-on-background-focus) !important;
}

.onBackground:active {
  border-color: var(--color-on-background-press) !important;
}

.surface:hover {
  border-color: var(--color-surface-hover) !important;
}

.surface:focus {
  border-color: var(--color-surface-focus) !important;
}

.surface:active {
  border-color: var(--color-surface-press) !important;
}

.onSurface:hover {
  border-color: var(--color-on-surface-hover) !important;
}

.onSurface:focus {
  border-color: var(--color-on-surface-focus) !important;
}

.onSurface:active {
  border-color: var(--color-on-surface-press) !important;
}

.surface1:hover {
  border-color: var(--color-surface-1-hover) !important;
}

.surface1:focus {
  border-color: var(--color-surface-1-focus) !important;
}

.surface1:active {
  border-color: var(--color-surface-1-press) !important;
}

.onSurface1:hover {
  border-color: var(--color-on-surface-1-hover) !important;
}

.onSurface1:focus {
  border-color: var(--color-on-surface-1-focus) !important;
}

.onSurface1:active {
  border-color: var(--color-on-surface-1-press) !important;
}

.surface2:hover {
  border-color: var(--color-surface-2-hover) !important;
}

.surface2:focus {
  border-color: var(--color-surface-2-focus) !important;
}

.surface2:active {
  border-color: var(--color-surface-2-press) !important;
}

.onSurface2:hover {
  border-color: var(--color-on-surface-2-hover) !important;
}

.onSurface2:focus {
  border-color: var(--color-on-surface-2-focus) !important;
}

.onSurface2:active {
  border-color: var(--color-on-surface-2-press) !important;
}

.surface3:hover {
  border-color: var(--color-surface-3-hover) !important;
}

.surface3:focus {
  border-color: var(--color-surface-3-focus) !important;
}

.surface3:active {
  border-color: var(--color-surface-3-press) !important;
}

.onSurface3:hover {
  border-color: var(--color-on-surface-3-hover) !important;
}

.onSurface3:focus {
  border-color: var(--color-on-surface-3-focus) !important;
}

.onSurface3:active {
  border-color: var(--color-on-surface-3-press) !important;
}

.surface4:hover {
  border-color: var(--color-surface-4-hover) !important;
}

.surface4:focus {
  border-color: var(--color-surface-4-focus) !important;
}

.surface4:active {
  border-color: var(--color-surface-4-press) !important;
}

.onSurface4:hover {
  border-color: var(--color-on-surface-4-hover) !important;
}

.onSurface4:focus {
  border-color: var(--color-on-surface-4-focus) !important;
}

.onSurface4:active {
  border-color: var(--color-on-surface-4-press) !important;
}

.surface5:hover {
  border-color: var(--color-surface-5-hover) !important;
}

.surface5:focus {
  border-color: var(--color-surface-5-focus) !important;
}

.surface5:active {
  border-color: var(--color-surface-5-press) !important;
}

.onSurface5:hover {
  border-color: var(--color-on-surface-5-hover) !important;
}

.onSurface5:focus {
  border-color: var(--color-on-surface-5-focus) !important;
}

.onSurface5:active {
  border-color: var(--color-on-surface-5-press) !important;
}

.outline:hover {
  border-color: var(--color-outline-hover) !important;
}

.outline:focus {
  border-color: var(--color-outline-focus) !important;
}

.outline:active {
  border-color: var(--color-outline-press) !important;
}

.surfaceVariant:hover {
  border-color: var(--color-surface-variant-hover) !important;
}

.surfaceVariant:focus {
  border-color: var(--color-surface-variant-focus) !important;
}

.surfaceVariant:active {
  border-color: var(--color-surface-variant-press) !important;
}

.onSurfaceVariant:hover {
  border-color: var(--color-on-surface-variant-hover) !important;
}

.onSurfaceVariant:focus {
  border-color: var(--color-on-surface-variant-focus) !important;
}

.onSurfaceVariant:active {
  border-color: var(--color-on-surface-variant-press) !important;
}

.categorySuccess:hover {
  border-color: var(--color-category-success-hover) !important;
}

.categorySuccess:focus {
  border-color: var(--color-category-success-focus) !important;
}

.categorySuccess:active {
  border-color: var(--color-category-success-press) !important;
}

.onCategorySuccess:hover {
  border-color: var(--color-on-category-success-hover) !important;
}

.onCategorySuccess:focus {
  border-color: var(--color-on-category-success-focus) !important;
}

.onCategorySuccess:active {
  border-color: var(--color-on-category-success-press) !important;
}

.categoryInfo:hover {
  border-color: var(--color-category-info-hover) !important;
}

.categoryInfo:focus {
  border-color: var(--color-category-info-focus) !important;
}

.categoryInfo:active {
  border-color: var(--color-category-info-press) !important;
}

.onCategoryInfo:hover {
  border-color: var(--color-on-category-info-hover) !important;
}

.onCategoryInfo:focus {
  border-color: var(--color-on-category-info-focus) !important;
}

.onCategoryInfo:active {
  border-color: var(--color-on-category-info-press) !important;
}

.categoryWarning:hover {
  border-color: var(--color-category-warning-hover) !important;
}

.categoryWarning:focus {
  border-color: var(--color-category-warning-focus) !important;
}

.categoryWarning:active {
  border-color: var(--color-category-warning-press) !important;
}

.onCategoryWarning:hover {
  border-color: var(--color-on-category-warning-hover) !important;
}

.onCategoryWarning:focus {
  border-color: var(--color-on-category-warning-focus) !important;
}

.onCategoryWarning:active {
  border-color: var(--color-on-category-warning-press) !important;
}

.categoryError:hover {
  border-color: var(--color-category-error-hover) !important;
}

.categoryError:focus {
  border-color: var(--color-category-error-focus) !important;
}

.categoryError:active {
  border-color: var(--color-category-error-press) !important;
}

.onCategoryError:hover {
  border-color: var(--color-on-category-error-hover) !important;
}

.onCategoryError:focus {
  border-color: var(--color-on-category-error-focus) !important;
}

.onCategoryError:active {
  border-color: var(--color-on-category-error-press) !important;
}

.categoryA:hover {
  border-color: var(--color-category-a-hover) !important;
}

.categoryA:focus {
  border-color: var(--color-category-a-focus) !important;
}

.categoryA:active {
  border-color: var(--color-category-a-press) !important;
}

.onCategoryA:hover {
  border-color: var(--color-on-category-a-hover) !important;
}

.onCategoryA:focus {
  border-color: var(--color-on-category-a-focus) !important;
}

.onCategoryA:active {
  border-color: var(--color-on-category-a-press) !important;
}

.categoryB:hover {
  border-color: var(--color-category-b-hover) !important;
}

.categoryB:focus {
  border-color: var(--color-category-b-focus) !important;
}

.categoryB:active {
  border-color: var(--color-category-b-press) !important;
}

.onCategoryB:hover {
  border-color: var(--color-on-category-b-hover) !important;
}

.onCategoryB:focus {
  border-color: var(--color-on-category-b-focus) !important;
}

.onCategoryB:active {
  border-color: var(--color-on-category-b-press) !important;
}

.categoryC:hover {
  border-color: var(--color-category-c-hover) !important;
}

.categoryC:focus {
  border-color: var(--color-category-c-focus) !important;
}

.categoryC:active {
  border-color: var(--color-category-c-press) !important;
}

.onCategoryC:hover {
  border-color: var(--color-on-category-c-hover) !important;
}

.onCategoryC:focus {
  border-color: var(--color-on-category-c-focus) !important;
}

.onCategoryC:active {
  border-color: var(--color-on-category-c-press) !important;
}

.categoryD:hover {
  border-color: var(--color-category-d-hover) !important;
}

.categoryD:focus {
  border-color: var(--color-category-d-focus) !important;
}

.categoryD:active {
  border-color: var(--color-category-d-press) !important;
}

.onCategoryD:hover {
  border-color: var(--color-on-category-d-hover) !important;
}

.onCategoryD:focus {
  border-color: var(--color-on-category-d-focus) !important;
}

.onCategoryD:active {
  border-color: var(--color-on-category-d-press) !important;
}

.categoryE:hover {
  border-color: var(--color-category-e-hover) !important;
}

.categoryE:focus {
  border-color: var(--color-category-e-focus) !important;
}

.categoryE:active {
  border-color: var(--color-category-e-press) !important;
}

.onCategoryE:hover {
  border-color: var(--color-on-category-e-hover) !important;
}

.onCategoryE:focus {
  border-color: var(--color-on-category-e-focus) !important;
}

.onCategoryE:active {
  border-color: var(--color-on-category-e-press) !important;
}

.categoryF:hover {
  border-color: var(--color-category-f-hover) !important;
}

.categoryF:focus {
  border-color: var(--color-category-f-focus) !important;
}

.categoryF:active {
  border-color: var(--color-category-f-press) !important;
}

.onCategoryF:hover {
  border-color: var(--color-on-category-f-hover) !important;
}

.onCategoryF:focus {
  border-color: var(--color-on-category-f-focus) !important;
}

.onCategoryF:active {
  border-color: var(--color-on-category-f-press) !important;
}

.categoryG:hover {
  border-color: var(--color-category-g-hover) !important;
}

.categoryG:focus {
  border-color: var(--color-category-g-focus) !important;
}

.categoryG:active {
  border-color: var(--color-category-g-press) !important;
}

.onCategoryG:hover {
  border-color: var(--color-on-category-g-hover) !important;
}

.onCategoryG:focus {
  border-color: var(--color-on-category-g-focus) !important;
}

.onCategoryG:active {
  border-color: var(--color-on-category-g-press) !important;
}

.categoryH:hover {
  border-color: var(--color-category-h-hover) !important;
}

.categoryH:focus {
  border-color: var(--color-category-h-focus) !important;
}

.categoryH:active {
  border-color: var(--color-category-h-press) !important;
}

.onCategoryH:hover {
  border-color: var(--color-on-category-h-hover) !important;
}

.onCategoryH:focus {
  border-color: var(--color-on-category-h-focus) !important;
}

.onCategoryH:active {
  border-color: var(--color-on-category-h-press) !important;
}

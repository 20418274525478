.root {
  display: flex;
  flex-direction: row;
  min-height: 128px;
  max-height: 192px;
}

.imageContainer {
  overflow: hidden;
  position: relative;
  width: 256px;
}

.imageBackground {
  bottom: 0;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 0;
}

.image {
  aspect-ratio: 1024 / 160;
  backdrop-filter: blur(4px);
  height: 100%;
  object-fit: contain;
  position: relative;
}

.title {
  -moz-box-orient: vertical;
  -moz-line-clamp: 1;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -moz-box;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}

.summary {
  -moz-box-orient: vertical;
  -moz-line-clamp: 3;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -moz-box;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.cardNoticeContainer {
  align-items: center;
  display: flex;
  flex: 1;
  height: 32px;
  justify-content: flex-end;
  padding-left: var(--space-4x);
  padding-right: var(--space-4x);
}

.cardActionContainer {
  padding-top: var(--space-4x);
}

.surface {
  box-sizing: border-box;
  display: inline-block;
  overflow: clip;
  vertical-align: top;
}

.fullHeight {
  height: 100%;
}

.fullWidth {
  width: 100%;
}

.padded {
  padding: var(--dimension-surface-padding-horizontal)
    var(--dimension-surface-padding-vertical);
}

.rounded {
  border-radius: var(--dimension-surface-border-radius);
}

.outline {
  border-style: solid;
  border-width: 1px;
}

.outlineDashed {
  border-style: dashed;
}

.outlineDotted {
  border-style: dotted;
}

.anchor {
  cursor: pointer;
}

.button {
  border: none;
  cursor: pointer;
  padding: 0;
  text-align: unset;
}

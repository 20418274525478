.wrapper {
  display: inline-flex;
  padding-bottom: var(--typography-label-small-line-height);
}

.fullWidthWrapper {
  width: 100%;
}

.horizontal {
  flex-direction: row;
}

.fieldHorizontal:not(:first-of-type) {
  margin-left: -1px;
}

.fieldHorizontal .inputHorizontal {
  transition: all var(--transition-duration),
    z-index var(--transition-duration) cubic-bezier(0, 0, 1, 1);
  z-index: 0;
}

.fieldHorizontal .inputHorizontal:hover {
  transition: all var(--transition-duration), z-index 0s;
  z-index: 2;
}

.fieldHorizontal .inputHorizontal:active,
.fieldHorizontal .inputHorizontal:focus {
  transition: all var(--transition-duration), z-index 0s;
  z-index: 3;
}

.fieldHorizontal:not(:first-of-type) .inputHorizontal {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.fieldHorizontal:not(:last-of-type) .inputHorizontal {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.vertical {
  flex-direction: column;
}

.fieldVertical .ant-form-item-explain {
  display: none !important;
}

.fieldVertical {
  padding-bottom: 0 !important;
}

.fieldVertical:not(:first-of-type) {
  margin-top: -1px;
}

.fieldVertical .inputVertical {
  transition: all var(--transition-duration),
    z-index var(--transition-duration) cubic-bezier(0, 0, 1, 1);
  z-index: 0;
}

.fieldVertical .inputVertical:hover {
  transition: all var(--transition-duration), z-index 0s;
  z-index: 2;
}

.fieldVertical .inputVertical:active,
.fieldVertical .inputVertical:focus {
  transition: all var(--transition-duration), z-index 0s;
  z-index: 3;
}

.fieldVertical:not(:first-of-type) .inputVertical {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.fieldVertical:not(:last-of-type) .inputVertical {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.root {
  max-width: 384px;
  padding: 24px;
  width: 100%;
}

.backdrop {
  align-items: center;
  background: var(--color-backdrop);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
}

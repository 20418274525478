.ant-steps {
  color: inherit;
}

.ant-steps
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: inherit;
}

.ant-steps
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: var(--color-on-input-background);
}

.ant-steps .ant-steps-item-wait .ant-steps-item-icon {
  background-color: var(--color-input-background);
  border-color: var(--color-input-background);
}

.ant-steps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: var(--color-on-input-background);
}

.ant-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: inherit;
}

.ant-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: var(--color-on-input-background);
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: var(--color-input-active);
  border-color: var(--color-input-active);
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: var(--color-on-input-active);
}

.ant-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: var(--color-on-input-background);
}

.ant-steps.ant-steps-with-progress
  .ant-steps-item-process
  .ant-steps-item-container
  .ant-steps-item-icon
  .ant-steps-icon {
  color: var(--color-on-input-active);
}

.ant-steps
  .ant-steps-item-error
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: var(--color-category-error);
}

.ant-steps
  .ant-steps-item-error
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: var(--color-on-input-background);
}

.ant-steps .ant-steps-item-error .ant-steps-item-icon {
  background-color: var(--color-category-error);
  border-color: var(--color-category-error);
}

.ant-steps .ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon {
  color: var(--color-on-category-error);
}

.ant-steps
  .ant-steps-item-error
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: var(--color-on-input-background);
}

.ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: inherit;
}

.ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: var(--color-category-success);
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: var(--color-category-success);
  border-color: var(--color-category-success);
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: var(--color-on-category-success);
}

.ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: var(--color-category-success);
}

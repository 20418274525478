.ant-btn-default {
  background-color: var(--color-surface-variant);
  color: var(--color-on-surface-variant);
  border-color: var(--color-surface-variant);
}

.ant-btn-default:hover {
  background-color: var(--color-surface-variant-hover);
  color: var(--color-on-surface-variant-hover);
  border-color: var(--color-surface-variant-hover);
}

.ant-btn-default:focus {
  background-color: var(--color-surface-variant-focus);
  color: var(--color-on-surface-variant-focus);
  border-color: var(--color-surface-variant-focus);
}

.ant-btn-default:active {
  background-color: var(--color-surface-variant-press);
  color: var(--color-on-surface-variant-press);
  border-color: var(--color-surface-variant-press);
}

.ant-btn-primary {
  background-color: var(--color-primary);
  color: var(--color-on-primary);
  border-color: var(--color-primary);
}

.ant-btn-primary:hover {
  background-color: var(--color-primary-hover);
  color: var(--color-on-primary-hover);
  border-color: var(--color-primary-hover);
}

.ant-btn-primary:focus {
  background-color: var(--color-primary-focus);
  color: var(--color-on-primary-focus);
  border-color: var(--color-primary-focus);
}

.ant-btn-primary:active {
  background-color: var(--color-primary-press);
  color: var(--color-on-primary-press);
  border-color: var(--color-primary-press);
}

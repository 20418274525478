.root {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  gap: var(--space-gap);
}

.info {
  flex: 1;
}

.divider {
  height: 100%;
}

.status {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 208px;
}

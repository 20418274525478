.teamCard {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 256px;
  width: 100%;
}

.cardHeader {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 64px;
  justify-content: center;
  width: 100%;
}

.cardInfo {
  align-items: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--space-gap);
  justify-content: space-evenly;
  padding: var(--dimension-surface-padding-horizontal)
    var(--dimension-surface-padding-vertical);
}

.cardTagBox {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.cardAction {
  align-self: center;
}

.challengeText {
  text-align: center;
}

.membersText {
  margin-top: 10px;
  text-align: center;
}

.tagText {
  text-align: center;
}

.capitalized {
  text-transform: capitalize;
}

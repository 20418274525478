.root {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;
}

.audioVideo {
  bottom: 64px;
  position: absolute;
  right: 32px;
}

.controls {
  align-items: center;
  display: flex;
}

.root {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.content {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.deviceSelector {
  margin: 8px 0;
  width: 100%;
}

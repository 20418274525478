.ant-dropdown .ant-dropdown-menu,
.ant-dropdown-menu-submenu .ant-dropdown-menu {
  background-color: var(--color-input-background);
  padding: calc(var(--dimension-input-border-radius) / 2)
    var(--dimension-input-border-radius);
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title {
  color: var(--color-input-value);
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item:hover,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-submenu
  .ant-dropdown-menu
  .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-active,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item-active,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title-active,
.ant-dropdown-menu-submenu
  .ant-dropdown-menu
  .ant-dropdown-menu-submenu-title-active {
  background-color: var(--color-input-background-hover);
  color: var(--color-input-value-hover);
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:focus,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item:focus,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title:focus,
.ant-dropdown-menu-submenu
  .ant-dropdown-menu
  .ant-dropdown-menu-submenu-title:focus,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-active,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item-active,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title-active,
.ant-dropdown-menu-submenu
  .ant-dropdown-menu
  .ant-dropdown-menu-submenu-title-active {
  background-color: var(--color-input-background-focus);
  color: var(--color-input-value-focus);
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:active,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item:active,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title:active,
.ant-dropdown-menu-submenu
  .ant-dropdown-menu
  .ant-dropdown-menu-submenu-title:active,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-active,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item-active,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title-active,
.ant-dropdown-menu-submenu
  .ant-dropdown-menu
  .ant-dropdown-menu-submenu-title-active {
  background-color: var(--color-input-background-press);
  color: var(--color-input-value-press);
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-divider,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item-divider,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title-divider,
.ant-dropdown-menu-submenu
  .ant-dropdown-menu
  .ant-dropdown-menu-submenu-title-divider {
  background-color: var(--color-on-input-background);
}

.wrapper {
  display: contents;
}

.inline {
  display: inline-block;
}

.inputFullWidth {
  width: 100%;
}

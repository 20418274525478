:root {
  /* Button */
  --shape-button-border-radius: 4px;

  /* Small Button */
  --typography-button-small-font-family: var(
    --typography-label-medium-font-family
  );
  --typography-button-small-line-height: var(
    --typography-label-medium-line-height
  );
  --typography-button-small-size: var(--typography-label-medium-size);
  --typography-button-small-weight: var(--typography-label-medium-weight);
  --height-button-small: 32px;
  --padding-left-button-small: 24px;
  --padding-left-button-with-icon-small: 16px;
  --padding-right-button-small: 24px;
  --space-button-icon-small: 8px;

  /* Medium Button */
  --typography-button-medium-font-family: var(
    --typography-label-medium-font-family
  );
  --typography-button-medium-line-height: var(
    --typography-label-medium-line-height
  );
  --typography-button-medium-size: var(--typography-label-medium-size);
  --typography-button-medium-weight: var(--typography-label-medium-weight);
  --height-button-medium: 40px;
  --padding-left-button-medium: 24px;
  --padding-left-button-with-icon-medium: 16px;
  --padding-right-button-medium: 24px;
  --space-button-icon-medium: 8px;

  /* Large Button */
  --typography-button-large-font-family: var(
    --typography-label-medium-font-family
  );
  --typography-button-large-line-height: var(
    --typography-label-medium-line-height
  );
  --typography-button-large-size: var(--typography-label-medium-size);
  --typography-button-large-weight: var(--typography-label-medium-weight);
  --height-button-large: 56px;
  --padding-left-button-large: 24px;
  --padding-left-button-with-icon-large: 16px;
  --padding-right-button-large: 24px;
  --space-button-icon-large: 8px;

  /* Small Icon Button */
  --shape-icon-button-small-border-radius: 8px;
  --size-icon-button-small-icon: 20px;
  --size-icon-button-small: 32px;

  /* Medium Icon Button */
  --shape-icon-button-medium-border-radius: 12px;
  --size-icon-button-medium-icon: 24px;
  --size-icon-button-medium: 40px;

  /* Medium Icon Button */
  --shape-icon-button-large-border-radius: 16px;
  --size-icon-button-large-icon: 24px;
  --size-icon-button-large: 56px;

  /* Spacing */
  --space-1x: 2px;
  --space-2x: 4px;
  --space-3x: 8px;
  --space-4x: 12px;
  --space-5x: 16px;
  --space-6x: 24px;
  --space-7x: 32px;
  --space-8x: 40px;
  --space-9x: 48px;
  --space-10x: 64px;
  --space-11x: 96px;
  --space-12x: 128px;
  --space-gap: var(--space-3x);

  /* Transition */
  --transition-property: all;
  --transition-duration: 0.3s;
  --transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  --transition-delay: 0s;

  /* Backdrop */
  --color-backdrop: rgba(0, 0, 0, 0.56);
  --color-backdrop-opaque: rgb(0, 0, 0);
  --opacity-backdrop: 0.56;

  /* Surface */
  --padding-surface-horizontal: var(--space-4x);
  --padding-surface-vertical: var(--space-5x);
  --padding-surface: var(--padding-surface-horizontal)
    var(--padding-surface-vertical);

  --padding-surface-horizontal-small: var(--space-3x);
  --padding-surface-vertical-small: var(--space-4x);
  --padding-surface-small: var(--padding-surface-horizontal-small)
    var(--padding-surface-vertical-small);

  --padding-surface-horizontal-large: var(--space-5x);
  --padding-surface-vertical-large: var(--space-6x);
  --padding-surface-large: var(--padding-surface-horizontal-large)
    var(--padding-surface-vertical-large);

  /* List Item */
  --padding-list-item-horizontal: var(--space-4x);
  --padding-list-item-vertical: var(--space-4x);
  --padding-list-item: var(--padding-list-item-horizontal)
    var(--padding-list-item-vertical);

  /* Misc */
  --opacity-disabled: 0.38;
  --shape-card-border-radius: 4px;
  --shape-input-border-radius: 4px;
  --shape-input-border-style: none;
  --shape-input-border-width: 0;
  --size-container: 1280px;
}

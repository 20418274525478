.title {
  -moz-box-orient: vertical;
  -moz-line-clamp: 1;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -moz-box;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subtitle {
  -moz-box-orient: vertical;
  -moz-line-clamp: 1;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -moz-box;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}

.image {
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

.name {
  -moz-box-orient: vertical;
  -moz-line-clamp: 1;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -moz-box;
  display: -webkit-box;
  height: var(--typography-headline6-line-height);
  overflow: hidden;
  text-overflow: clip;
}

.summary {
  -moz-box-orient: vertical;
  -moz-line-clamp: 3;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -moz-box;
  display: -webkit-box;
  height: calc(var(--typography-body-large-line-height) * 3);
  overflow: hidden;
  text-overflow: clip;
}

.links {
  align-items: center;
  display: flex;
  gap: var(--space-gap);
  justify-content: center;
  margin-top: var(--space-gap);
}

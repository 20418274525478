.wrapper {
  display: contents;
}

.radio {
  align-items: center;
}

.inputFullWidth {
  width: 100%;
}

.padded {
  padding: var(--padding-surface);
}

.paddedSmall {
  padding: var(--padding-surface-small);
}

.paddedLarge {
  padding: var(--padding-surface-large);
}

/* discord: rgb(88, 101, 242); */
/* facebook: rgb(26, 124, 237); */
/* google: rgb(234, 67, 53); */
/* linkedin: rgb(14, 106, 190); */

:root {
  --color-discord-light: #3e52d5;
  --color-discord-hover-light: #5d6edc;
  --color-discord-focus-light: #6c7cdf;
  --color-discord-press-light: #6c7cdf;
  --color-discord-drag-light: #7c89e2;

  --color-on-discord-light: #ffffff;
  --color-on-discord-hover-light: #e0e3f8;
  --color-on-discord-focus-light: #d1d5f5;
  --color-on-discord-press-light: #d1d5f5;
  --color-on-discord-drag-light: #c1c8f2;

  --color-discord-container-light: #dfe1fd;
  --color-discord-container-hover-light: #bbbfe6;
  --color-discord-container-focus-light: #a9adda;
  --color-discord-container-press-light: #a9adda;
  --color-discord-container-drag-light: #989ccf;

  --color-on-discord-container-light: #000a6c;
  --color-on-discord-container-hover-light: #242c83;
  --color-on-discord-container-focus-light: #363e8f;
  --color-on-discord-container-press-light: #363e8f;
  --color-on-discord-container-drag-light: #474f9a;

  --color-discord-dark: #3e52d5;
  --color-discord-hover-dark: #5d6edc;
  --color-discord-focus-dark: #6c7cdf;
  --color-discord-press-dark: #6c7cdf;
  --color-discord-drag-dark: #7c89e2;

  --color-on-discord-dark: #ffffff;
  --color-on-discord-hover-dark: #e0e3f8;
  --color-on-discord-focus-dark: #d1d5f5;
  --color-on-discord-press-dark: #d1d5f5;
  --color-on-discord-drag-dark: #c1c8f2;

  --color-discord-container-dark: #dfe1fd;
  --color-discord-container-hover-dark: #bbbfe6;
  --color-discord-container-focus-dark: #a9adda;
  --color-discord-container-press-dark: #a9adda;
  --color-discord-container-drag-dark: #989ccf;

  --color-on-discord-container-dark: #000a6c;
  --color-on-discord-container-hover-dark: #242c83;
  --color-on-discord-container-focus-dark: #363e8f;
  --color-on-discord-container-press-dark: #363e8f;
  --color-on-discord-container-drag-dark: #474f9a;

  --color-facebook-light: #0360b7;
  --color-facebook-hover-light: #2b79c3;
  --color-facebook-focus-light: #3f86c8;
  --color-facebook-press-light: #3f86c8;
  --color-facebook-drag-light: #5493ce;

  --color-on-facebook-light: #ffffff;
  --color-on-facebook-hover-light: #d7e6f3;
  --color-on-facebook-focus-light: #c3d9ee;
  --color-on-facebook-press-light: #c3d9ee;
  --color-on-facebook-drag-light: #aecce8;

  --color-facebook-container-light: #d6e4fd;
  --color-facebook-container-hover-light: #b4c4de;
  --color-facebook-container-focus-light: #a3b4cf;
  --color-facebook-container-press-light: #a3b4cf;
  --color-facebook-container-drag-light: #92a4c0;

  --color-on-facebook-container-light: #001c3d;
  --color-on-facebook-container-hover-light: #223c5c;
  --color-on-facebook-container-focus-light: #334c6b;
  --color-on-facebook-container-press-light: #334c6b;
  --color-on-facebook-container-drag-light: #445c7a;

  --color-facebook-dark: #0360b7;
  --color-facebook-hover-dark: #2b79c3;
  --color-facebook-focus-dark: #3f86c8;
  --color-facebook-press-dark: #3f86c8;
  --color-facebook-drag-dark: #5493ce;

  --color-on-facebook-dark: #ffffff;
  --color-on-facebook-hover-dark: #d7e6f3;
  --color-on-facebook-focus-dark: #c3d9ee;
  --color-on-facebook-press-dark: #c3d9ee;
  --color-on-facebook-drag-dark: #aecce8;

  --color-facebook-container-dark: #d6e4fd;
  --color-facebook-container-hover-dark: #b4c4de;
  --color-facebook-container-focus-dark: #a3b4cf;
  --color-facebook-container-press-dark: #a3b4cf;
  --color-facebook-container-drag-dark: #92a4c0;

  --color-on-facebook-container-dark: #001c3d;
  --color-on-facebook-container-hover-dark: #223c5c;
  --color-on-facebook-container-focus-dark: #334c6b;
  --color-on-facebook-container-press-dark: #334c6b;
  --color-on-facebook-container-drag-dark: #445c7a;

  --color-google-light: #b60f1d;
  --color-google-hover-light: #c23541;
  --color-google-focus-light: #c84953;
  --color-google-press-light: #c84953;
  --color-google-drag-light: #cd5c65;

  --color-on-google-light: #ffffff;
  --color-on-google-hover-light: #f3d9db;
  --color-on-google-focus-light: #edc5c9;
  --color-on-google-press-light: #edc5c9;
  --color-on-google-drag-light: #e8b2b7;

  --color-google-container-light: #fed9d4;
  --color-google-container-hover-light: #e0b6b2;
  --color-google-container-focus-light: #d0a5a1;
  --color-google-container-press-light: #d0a5a1;
  --color-google-container-drag-light: #c19490;

  --color-on-google-container-light: #400001;
  --color-on-google-container-hover-light: #5e2323;
  --color-on-google-container-focus-light: #6e3434;
  --color-on-google-container-press-light: #6e3434;
  --color-on-google-container-drag-light: #7d4545;

  --color-google-dark: #b60f1d;
  --color-google-hover-dark: #c23541;
  --color-google-focus-dark: #c84953;
  --color-google-press-dark: #c84953;
  --color-google-drag-dark: #cd5c65;

  --color-on-google-dark: #ffffff;
  --color-on-google-hover-dark: #f3d9db;
  --color-on-google-focus-dark: #edc5c9;
  --color-on-google-press-dark: #edc5c9;
  --color-on-google-drag-dark: #e8b2b7;

  --color-google-container-dark: #fed9d4;
  --color-google-container-hover-dark: #e0b6b2;
  --color-google-container-focus-dark: #d0a5a1;
  --color-google-container-press-dark: #d0a5a1;
  --color-google-container-drag-dark: #c19490;

  --color-on-google-container-dark: #400001;
  --color-on-google-container-hover-dark: #5e2323;
  --color-on-google-container-focus-dark: #6e3434;
  --color-on-google-container-press-dark: #6e3434;
  --color-on-google-container-drag-dark: #7d4545;

  --color-linkedin-light: #0462ad;
  --color-linkedin-hover-light: #2c7bba;
  --color-linkedin-focus-light: #4088c1;
  --color-linkedin-press-light: #4088c1;
  --color-linkedin-drag-light: #5494c7;

  --color-on-linkedin-light: #ffffff;
  --color-on-linkedin-hover-light: #d7e6f2;
  --color-on-linkedin-focus-light: #c3d9eb;
  --color-on-linkedin-press-light: #c3d9eb;
  --color-on-linkedin-drag-light: #afcde5;

  --color-linkedin-container-light: #d3e4fd;
  --color-linkedin-container-hover-light: #b1c4de;
  --color-linkedin-container-focus-light: #a0b4ce;
  --color-linkedin-container-press-light: #a0b4ce;
  --color-linkedin-container-drag-light: #8fa4bf;

  --color-on-linkedin-container-light: #001d3a;
  --color-on-linkedin-container-hover-light: #223d59;
  --color-on-linkedin-container-focus-light: #334d69;
  --color-on-linkedin-container-press-light: #334d69;
  --color-on-linkedin-container-drag-light: #445d78;

  --color-linkedin-dark: #0462ad;
  --color-linkedin-hover-dark: #2c7bba;
  --color-linkedin-focus-dark: #4088c1;
  --color-linkedin-press-dark: #4088c1;
  --color-linkedin-drag-dark: #5494c7;

  --color-on-linkedin-dark: #ffffff;
  --color-on-linkedin-hover-dark: #d7e6f2;
  --color-on-linkedin-focus-dark: #c3d9eb;
  --color-on-linkedin-press-dark: #c3d9eb;
  --color-on-linkedin-drag-dark: #afcde5;

  --color-linkedin-container-dark: #d3e4fd;
  --color-linkedin-container-hover-dark: #b1c4de;
  --color-linkedin-container-focus-dark: #a0b4ce;
  --color-linkedin-container-press-dark: #a0b4ce;
  --color-linkedin-container-drag-dark: #8fa4bf;

  --color-on-linkedin-container-dark: #001d3a;
  --color-on-linkedin-container-hover-dark: #223d59;
  --color-on-linkedin-container-focus-dark: #334d69;
  --color-on-linkedin-container-press-dark: #334d69;
  --color-on-linkedin-container-drag-dark: #445d78;
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-discord: var(--color-discord-dark);
    --color-discord-hover: var(--color-discord-hover-dark);
    --color-discord-focus: var(--color-discord-focus-dark);
    --color-discord-press: var(--color-discord-press-dark);
    --color-discord-drag: var(--color-discord-drag-dark);
    --color-on-discord: var(--color-on-discord-dark);
    --color-on-discord-hover: var(--color-on-discord-hover-dark);
    --color-on-discord-focus: var(--color-on-discord-focus-dark);
    --color-on-discord-press: var(--color-on-discord-press-dark);
    --color-on-discord-drag: var(--color-on-discord-drag-dark);
    --color-discord-container: var(--color-discord-container-dark);
    --color-discord-container-hover: var(--color-discord-container-hover-dark);
    --color-discord-container-focus: var(--color-discord-container-focus-dark);
    --color-discord-container-press: var(--color-discord-container-press-dark);
    --color-discord-container-drag: var(--color-discord-container-drag-dark);
    --color-on-discord-container: var(--color-on-discord-container-dark);
    --color-on-discord-container-hover: var(
      --color-on-discord-container-hover-dark
    );
    --color-on-discord-container-focus: var(
      --color-on-discord-container-focus-dark
    );
    --color-on-discord-container-press: var(
      --color-on-discord-container-press-dark
    );
    --color-on-discord-container-drag: var(
      --color-on-discord-container-drag-dark
    );
    --color-facebook: var(--color-facebook-dark);
    --color-facebook-hover: var(--color-facebook-hover-dark);
    --color-facebook-focus: var(--color-facebook-focus-dark);
    --color-facebook-press: var(--color-facebook-press-dark);
    --color-facebook-drag: var(--color-facebook-drag-dark);
    --color-on-facebook: var(--color-on-facebook-dark);
    --color-on-facebook-hover: var(--color-on-facebook-hover-dark);
    --color-on-facebook-focus: var(--color-on-facebook-focus-dark);
    --color-on-facebook-press: var(--color-on-facebook-press-dark);
    --color-on-facebook-drag: var(--color-on-facebook-drag-dark);
    --color-facebook-container: var(--color-facebook-container-dark);
    --color-facebook-container-hover: var(
      --color-facebook-container-hover-dark
    );
    --color-facebook-container-focus: var(
      --color-facebook-container-focus-dark
    );
    --color-facebook-container-press: var(
      --color-facebook-container-press-dark
    );
    --color-facebook-container-drag: var(--color-facebook-container-drag-dark);
    --color-on-facebook-container: var(--color-on-facebook-container-dark);
    --color-on-facebook-container-hover: var(
      --color-on-facebook-container-hover-dark
    );
    --color-on-facebook-container-focus: var(
      --color-on-facebook-container-focus-dark
    );
    --color-on-facebook-container-press: var(
      --color-on-facebook-container-press-dark
    );
    --color-on-facebook-container-drag: var(
      --color-on-facebook-container-drag-dark
    );
    --color-google: var(--color-google-dark);
    --color-google-hover: var(--color-google-hover-dark);
    --color-google-focus: var(--color-google-focus-dark);
    --color-google-press: var(--color-google-press-dark);
    --color-google-drag: var(--color-google-drag-dark);
    --color-on-google: var(--color-on-google-dark);
    --color-on-google-hover: var(--color-on-google-hover-dark);
    --color-on-google-focus: var(--color-on-google-focus-dark);
    --color-on-google-press: var(--color-on-google-press-dark);
    --color-on-google-drag: var(--color-on-google-drag-dark);
    --color-google-container: var(--color-google-container-dark);
    --color-google-container-hover: var(--color-google-container-hover-dark);
    --color-google-container-focus: var(--color-google-container-focus-dark);
    --color-google-container-press: var(--color-google-container-press-dark);
    --color-google-container-drag: var(--color-google-container-drag-dark);
    --color-on-google-container: var(--color-on-google-container-dark);
    --color-on-google-container-hover: var(
      --color-on-google-container-hover-dark
    );
    --color-on-google-container-focus: var(
      --color-on-google-container-focus-dark
    );
    --color-on-google-container-press: var(
      --color-on-google-container-press-dark
    );
    --color-on-google-container-drag: var(
      --color-on-google-container-drag-dark
    );
    --color-linkedin: var(--color-linkedin-dark);
    --color-linkedin-hover: var(--color-linkedin-hover-dark);
    --color-linkedin-focus: var(--color-linkedin-focus-dark);
    --color-linkedin-press: var(--color-linkedin-press-dark);
    --color-linkedin-drag: var(--color-linkedin-drag-dark);
    --color-on-linkedin: var(--color-on-linkedin-dark);
    --color-on-linkedin-hover: var(--color-on-linkedin-hover-dark);
    --color-on-linkedin-focus: var(--color-on-linkedin-focus-dark);
    --color-on-linkedin-press: var(--color-on-linkedin-press-dark);
    --color-on-linkedin-drag: var(--color-on-linkedin-drag-dark);
    --color-linkedin-container: var(--color-linkedin-container-dark);
    --color-linkedin-container-hover: var(
      --color-linkedin-container-hover-dark
    );
    --color-linkedin-container-focus: var(
      --color-linkedin-container-focus-dark
    );
    --color-linkedin-container-press: var(
      --color-linkedin-container-press-dark
    );
    --color-linkedin-container-drag: var(--color-linkedin-container-drag-dark);
    --color-on-linkedin-container: var(--color-on-linkedin-container-dark);
    --color-on-linkedin-container-hover: var(
      --color-on-linkedin-container-hover-dark
    );
    --color-on-linkedin-container-focus: var(
      --color-on-linkedin-container-focus-dark
    );
    --color-on-linkedin-container-press: var(
      --color-on-linkedin-container-press-dark
    );
    --color-on-linkedin-container-drag: var(
      --color-on-linkedin-container-drag-dark
    );
  }

  .dark-scheme {
    --color-discord: var(--color-discord-dark);
    --color-discord-hover: var(--color-discord-hover-dark);
    --color-discord-focus: var(--color-discord-focus-dark);
    --color-discord-press: var(--color-discord-press-dark);
    --color-discord-drag: var(--color-discord-drag-dark);
    --color-on-discord: var(--color-on-discord-dark);
    --color-on-discord-hover: var(--color-on-discord-hover-dark);
    --color-on-discord-focus: var(--color-on-discord-focus-dark);
    --color-on-discord-press: var(--color-on-discord-press-dark);
    --color-on-discord-drag: var(--color-on-discord-drag-dark);
    --color-discord-container: var(--color-discord-container-dark);
    --color-discord-container-hover: var(--color-discord-container-hover-dark);
    --color-discord-container-focus: var(--color-discord-container-focus-dark);
    --color-discord-container-press: var(--color-discord-container-press-dark);
    --color-discord-container-drag: var(--color-discord-container-drag-dark);
    --color-on-discord-container: var(--color-on-discord-container-dark);
    --color-on-discord-container-hover: var(
      --color-on-discord-container-hover-dark
    );
    --color-on-discord-container-focus: var(
      --color-on-discord-container-focus-dark
    );
    --color-on-discord-container-press: var(
      --color-on-discord-container-press-dark
    );
    --color-on-discord-container-drag: var(
      --color-on-discord-container-drag-dark
    );
    --color-facebook: var(--color-facebook-dark);
    --color-facebook-hover: var(--color-facebook-hover-dark);
    --color-facebook-focus: var(--color-facebook-focus-dark);
    --color-facebook-press: var(--color-facebook-press-dark);
    --color-facebook-drag: var(--color-facebook-drag-dark);
    --color-on-facebook: var(--color-on-facebook-dark);
    --color-on-facebook-hover: var(--color-on-facebook-hover-dark);
    --color-on-facebook-focus: var(--color-on-facebook-focus-dark);
    --color-on-facebook-press: var(--color-on-facebook-press-dark);
    --color-on-facebook-drag: var(--color-on-facebook-drag-dark);
    --color-facebook-container: var(--color-facebook-container-dark);
    --color-facebook-container-hover: var(
      --color-facebook-container-hover-dark
    );
    --color-facebook-container-focus: var(
      --color-facebook-container-focus-dark
    );
    --color-facebook-container-press: var(
      --color-facebook-container-press-dark
    );
    --color-facebook-container-drag: var(--color-facebook-container-drag-dark);
    --color-on-facebook-container: var(--color-on-facebook-container-dark);
    --color-on-facebook-container-hover: var(
      --color-on-facebook-container-hover-dark
    );
    --color-on-facebook-container-focus: var(
      --color-on-facebook-container-focus-dark
    );
    --color-on-facebook-container-press: var(
      --color-on-facebook-container-press-dark
    );
    --color-on-facebook-container-drag: var(
      --color-on-facebook-container-drag-dark
    );
    --color-google: var(--color-google-dark);
    --color-google-hover: var(--color-google-hover-dark);
    --color-google-focus: var(--color-google-focus-dark);
    --color-google-press: var(--color-google-press-dark);
    --color-google-drag: var(--color-google-drag-dark);
    --color-on-google: var(--color-on-google-dark);
    --color-on-google-hover: var(--color-on-google-hover-dark);
    --color-on-google-focus: var(--color-on-google-focus-dark);
    --color-on-google-press: var(--color-on-google-press-dark);
    --color-on-google-drag: var(--color-on-google-drag-dark);
    --color-google-container: var(--color-google-container-dark);
    --color-google-container-hover: var(--color-google-container-hover-dark);
    --color-google-container-focus: var(--color-google-container-focus-dark);
    --color-google-container-press: var(--color-google-container-press-dark);
    --color-google-container-drag: var(--color-google-container-drag-dark);
    --color-on-google-container: var(--color-on-google-container-dark);
    --color-on-google-container-hover: var(
      --color-on-google-container-hover-dark
    );
    --color-on-google-container-focus: var(
      --color-on-google-container-focus-dark
    );
    --color-on-google-container-press: var(
      --color-on-google-container-press-dark
    );
    --color-on-google-container-drag: var(
      --color-on-google-container-drag-dark
    );
    --color-linkedin: var(--color-linkedin-dark);
    --color-linkedin-hover: var(--color-linkedin-hover-dark);
    --color-linkedin-focus: var(--color-linkedin-focus-dark);
    --color-linkedin-press: var(--color-linkedin-press-dark);
    --color-linkedin-drag: var(--color-linkedin-drag-dark);
    --color-on-linkedin: var(--color-on-linkedin-dark);
    --color-on-linkedin-hover: var(--color-on-linkedin-hover-dark);
    --color-on-linkedin-focus: var(--color-on-linkedin-focus-dark);
    --color-on-linkedin-press: var(--color-on-linkedin-press-dark);
    --color-on-linkedin-drag: var(--color-on-linkedin-drag-dark);
    --color-linkedin-container: var(--color-linkedin-container-dark);
    --color-linkedin-container-hover: var(
      --color-linkedin-container-hover-dark
    );
    --color-linkedin-container-focus: var(
      --color-linkedin-container-focus-dark
    );
    --color-linkedin-container-press: var(
      --color-linkedin-container-press-dark
    );
    --color-linkedin-container-drag: var(--color-linkedin-container-drag-dark);
    --color-on-linkedin-container: var(--color-on-linkedin-container-dark);
    --color-on-linkedin-container-hover: var(
      --color-on-linkedin-container-hover-dark
    );
    --color-on-linkedin-container-focus: var(
      --color-on-linkedin-container-focus-dark
    );
    --color-on-linkedin-container-press: var(
      --color-on-linkedin-container-press-dark
    );
    --color-on-linkedin-container-drag: var(
      --color-on-linkedin-container-drag-dark
    );
  }

  .light-scheme {
    --color-discord: var(--color-discord-light);
    --color-discord-hover: var(--color-discord-hover-light);
    --color-discord-focus: var(--color-discord-focus-light);
    --color-discord-press: var(--color-discord-press-light);
    --color-discord-drag: var(--color-discord-drag-light);
    --color-on-discord: var(--color-on-discord-light);
    --color-on-discord-hover: var(--color-on-discord-hover-light);
    --color-on-discord-focus: var(--color-on-discord-focus-light);
    --color-on-discord-press: var(--color-on-discord-press-light);
    --color-on-discord-drag: var(--color-on-discord-drag-light);
    --color-discord-container: var(--color-discord-container-light);
    --color-discord-container-hover: var(--color-discord-container-hover-light);
    --color-discord-container-focus: var(--color-discord-container-focus-light);
    --color-discord-container-press: var(--color-discord-container-press-light);
    --color-discord-container-drag: var(--color-discord-container-drag-light);
    --color-on-discord-container: var(--color-on-discord-container-light);
    --color-on-discord-container-hover: var(
      --color-on-discord-container-hover-light
    );
    --color-on-discord-container-focus: var(
      --color-on-discord-container-focus-light
    );
    --color-on-discord-container-press: var(
      --color-on-discord-container-press-light
    );
    --color-on-discord-container-drag: var(
      --color-on-discord-container-drag-light
    );
    --color-facebook: var(--color-facebook-light);
    --color-facebook-hover: var(--color-facebook-hover-light);
    --color-facebook-focus: var(--color-facebook-focus-light);
    --color-facebook-press: var(--color-facebook-press-light);
    --color-facebook-drag: var(--color-facebook-drag-light);
    --color-on-facebook: var(--color-on-facebook-light);
    --color-on-facebook-hover: var(--color-on-facebook-hover-light);
    --color-on-facebook-focus: var(--color-on-facebook-focus-light);
    --color-on-facebook-press: var(--color-on-facebook-press-light);
    --color-on-facebook-drag: var(--color-on-facebook-drag-light);
    --color-facebook-container: var(--color-facebook-container-light);
    --color-facebook-container-hover: var(
      --color-facebook-container-hover-light
    );
    --color-facebook-container-focus: var(
      --color-facebook-container-focus-light
    );
    --color-facebook-container-press: var(
      --color-facebook-container-press-light
    );
    --color-facebook-container-drag: var(--color-facebook-container-drag-light);
    --color-on-facebook-container: var(--color-on-facebook-container-light);
    --color-on-facebook-container-hover: var(
      --color-on-facebook-container-hover-light
    );
    --color-on-facebook-container-focus: var(
      --color-on-facebook-container-focus-light
    );
    --color-on-facebook-container-press: var(
      --color-on-facebook-container-press-light
    );
    --color-on-facebook-container-drag: var(
      --color-on-facebook-container-drag-light
    );
    --color-google: var(--color-google-light);
    --color-google-hover: var(--color-google-hover-light);
    --color-google-focus: var(--color-google-focus-light);
    --color-google-press: var(--color-google-press-light);
    --color-google-drag: var(--color-google-drag-light);
    --color-on-google: var(--color-on-google-light);
    --color-on-google-hover: var(--color-on-google-hover-light);
    --color-on-google-focus: var(--color-on-google-focus-light);
    --color-on-google-press: var(--color-on-google-press-light);
    --color-on-google-drag: var(--color-on-google-drag-light);
    --color-google-container: var(--color-google-container-light);
    --color-google-container-hover: var(--color-google-container-hover-light);
    --color-google-container-focus: var(--color-google-container-focus-light);
    --color-google-container-press: var(--color-google-container-press-light);
    --color-google-container-drag: var(--color-google-container-drag-light);
    --color-on-google-container: var(--color-on-google-container-light);
    --color-on-google-container-hover: var(
      --color-on-google-container-hover-light
    );
    --color-on-google-container-focus: var(
      --color-on-google-container-focus-light
    );
    --color-on-google-container-press: var(
      --color-on-google-container-press-light
    );
    --color-on-google-container-drag: var(
      --color-on-google-container-drag-light
    );
    --color-linkedin: var(--color-linkedin-light);
    --color-linkedin-hover: var(--color-linkedin-hover-light);
    --color-linkedin-focus: var(--color-linkedin-focus-light);
    --color-linkedin-press: var(--color-linkedin-press-light);
    --color-linkedin-drag: var(--color-linkedin-drag-light);
    --color-on-linkedin: var(--color-on-linkedin-light);
    --color-on-linkedin-hover: var(--color-on-linkedin-hover-light);
    --color-on-linkedin-focus: var(--color-on-linkedin-focus-light);
    --color-on-linkedin-press: var(--color-on-linkedin-press-light);
    --color-on-linkedin-drag: var(--color-on-linkedin-drag-light);
    --color-linkedin-container: var(--color-linkedin-container-light);
    --color-linkedin-container-hover: var(
      --color-linkedin-container-hover-light
    );
    --color-linkedin-container-focus: var(
      --color-linkedin-container-focus-light
    );
    --color-linkedin-container-press: var(
      --color-linkedin-container-press-light
    );
    --color-linkedin-container-drag: var(--color-linkedin-container-drag-light);
    --color-on-linkedin-container: var(--color-on-linkedin-container-light);
    --color-on-linkedin-container-hover: var(
      --color-on-linkedin-container-hover-light
    );
    --color-on-linkedin-container-focus: var(
      --color-on-linkedin-container-focus-light
    );
    --color-on-linkedin-container-press: var(
      --color-on-linkedin-container-press-light
    );
    --color-on-linkedin-container-drag: var(
      --color-on-linkedin-container-drag-light
    );
  }
}

@media (prefers-color-scheme: light) {
  :root {
    --color-discord: var(--color-discord-light);
    --color-discord-hover: var(--color-discord-hover-light);
    --color-discord-focus: var(--color-discord-focus-light);
    --color-discord-press: var(--color-discord-press-light);
    --color-discord-drag: var(--color-discord-drag-light);
    --color-on-discord: var(--color-on-discord-light);
    --color-on-discord-hover: var(--color-on-discord-hover-light);
    --color-on-discord-focus: var(--color-on-discord-focus-light);
    --color-on-discord-press: var(--color-on-discord-press-light);
    --color-on-discord-drag: var(--color-on-discord-drag-light);
    --color-discord-container: var(--color-discord-container-light);
    --color-discord-container-hover: var(--color-discord-container-hover-light);
    --color-discord-container-focus: var(--color-discord-container-focus-light);
    --color-discord-container-press: var(--color-discord-container-press-light);
    --color-discord-container-drag: var(--color-discord-container-drag-light);
    --color-on-discord-container: var(--color-on-discord-container-light);
    --color-on-discord-container-hover: var(
      --color-on-discord-container-hover-light
    );
    --color-on-discord-container-focus: var(
      --color-on-discord-container-focus-light
    );
    --color-on-discord-container-press: var(
      --color-on-discord-container-press-light
    );
    --color-on-discord-container-drag: var(
      --color-on-discord-container-drag-light
    );
    --color-facebook: var(--color-facebook-light);
    --color-facebook-hover: var(--color-facebook-hover-light);
    --color-facebook-focus: var(--color-facebook-focus-light);
    --color-facebook-press: var(--color-facebook-press-light);
    --color-facebook-drag: var(--color-facebook-drag-light);
    --color-on-facebook: var(--color-on-facebook-light);
    --color-on-facebook-hover: var(--color-on-facebook-hover-light);
    --color-on-facebook-focus: var(--color-on-facebook-focus-light);
    --color-on-facebook-press: var(--color-on-facebook-press-light);
    --color-on-facebook-drag: var(--color-on-facebook-drag-light);
    --color-facebook-container: var(--color-facebook-container-light);
    --color-facebook-container-hover: var(
      --color-facebook-container-hover-light
    );
    --color-facebook-container-focus: var(
      --color-facebook-container-focus-light
    );
    --color-facebook-container-press: var(
      --color-facebook-container-press-light
    );
    --color-facebook-container-drag: var(--color-facebook-container-drag-light);
    --color-on-facebook-container: var(--color-on-facebook-container-light);
    --color-on-facebook-container-hover: var(
      --color-on-facebook-container-hover-light
    );
    --color-on-facebook-container-focus: var(
      --color-on-facebook-container-focus-light
    );
    --color-on-facebook-container-press: var(
      --color-on-facebook-container-press-light
    );
    --color-on-facebook-container-drag: var(
      --color-on-facebook-container-drag-light
    );
    --color-google: var(--color-google-light);
    --color-google-hover: var(--color-google-hover-light);
    --color-google-focus: var(--color-google-focus-light);
    --color-google-press: var(--color-google-press-light);
    --color-google-drag: var(--color-google-drag-light);
    --color-on-google: var(--color-on-google-light);
    --color-on-google-hover: var(--color-on-google-hover-light);
    --color-on-google-focus: var(--color-on-google-focus-light);
    --color-on-google-press: var(--color-on-google-press-light);
    --color-on-google-drag: var(--color-on-google-drag-light);
    --color-google-container: var(--color-google-container-light);
    --color-google-container-hover: var(--color-google-container-hover-light);
    --color-google-container-focus: var(--color-google-container-focus-light);
    --color-google-container-press: var(--color-google-container-press-light);
    --color-google-container-drag: var(--color-google-container-drag-light);
    --color-on-google-container: var(--color-on-google-container-light);
    --color-on-google-container-hover: var(
      --color-on-google-container-hover-light
    );
    --color-on-google-container-focus: var(
      --color-on-google-container-focus-light
    );
    --color-on-google-container-press: var(
      --color-on-google-container-press-light
    );
    --color-on-google-container-drag: var(
      --color-on-google-container-drag-light
    );
    --color-linkedin: var(--color-linkedin-light);
    --color-linkedin-hover: var(--color-linkedin-hover-light);
    --color-linkedin-focus: var(--color-linkedin-focus-light);
    --color-linkedin-press: var(--color-linkedin-press-light);
    --color-linkedin-drag: var(--color-linkedin-drag-light);
    --color-on-linkedin: var(--color-on-linkedin-light);
    --color-on-linkedin-hover: var(--color-on-linkedin-hover-light);
    --color-on-linkedin-focus: var(--color-on-linkedin-focus-light);
    --color-on-linkedin-press: var(--color-on-linkedin-press-light);
    --color-on-linkedin-drag: var(--color-on-linkedin-drag-light);
    --color-linkedin-container: var(--color-linkedin-container-light);
    --color-linkedin-container-hover: var(
      --color-linkedin-container-hover-light
    );
    --color-linkedin-container-focus: var(
      --color-linkedin-container-focus-light
    );
    --color-linkedin-container-press: var(
      --color-linkedin-container-press-light
    );
    --color-linkedin-container-drag: var(--color-linkedin-container-drag-light);
    --color-on-linkedin-container: var(--color-on-linkedin-container-light);
    --color-on-linkedin-container-hover: var(
      --color-on-linkedin-container-hover-light
    );
    --color-on-linkedin-container-focus: var(
      --color-on-linkedin-container-focus-light
    );
    --color-on-linkedin-container-press: var(
      --color-on-linkedin-container-press-light
    );
    --color-on-linkedin-container-drag: var(
      --color-on-linkedin-container-drag-light
    );
  }

  .light-scheme {
    --color-discord: var(--color-discord-light);
    --color-discord-hover: var(--color-discord-hover-light);
    --color-discord-focus: var(--color-discord-focus-light);
    --color-discord-press: var(--color-discord-press-light);
    --color-discord-drag: var(--color-discord-drag-light);
    --color-on-discord: var(--color-on-discord-light);
    --color-on-discord-hover: var(--color-on-discord-hover-light);
    --color-on-discord-focus: var(--color-on-discord-focus-light);
    --color-on-discord-press: var(--color-on-discord-press-light);
    --color-on-discord-drag: var(--color-on-discord-drag-light);
    --color-discord-container: var(--color-discord-container-light);
    --color-discord-container-hover: var(--color-discord-container-hover-light);
    --color-discord-container-focus: var(--color-discord-container-focus-light);
    --color-discord-container-press: var(--color-discord-container-press-light);
    --color-discord-container-drag: var(--color-discord-container-drag-light);
    --color-on-discord-container: var(--color-on-discord-container-light);
    --color-on-discord-container-hover: var(
      --color-on-discord-container-hover-light
    );
    --color-on-discord-container-focus: var(
      --color-on-discord-container-focus-light
    );
    --color-on-discord-container-press: var(
      --color-on-discord-container-press-light
    );
    --color-on-discord-container-drag: var(
      --color-on-discord-container-drag-light
    );
    --color-facebook: var(--color-facebook-light);
    --color-facebook-hover: var(--color-facebook-hover-light);
    --color-facebook-focus: var(--color-facebook-focus-light);
    --color-facebook-press: var(--color-facebook-press-light);
    --color-facebook-drag: var(--color-facebook-drag-light);
    --color-on-facebook: var(--color-on-facebook-light);
    --color-on-facebook-hover: var(--color-on-facebook-hover-light);
    --color-on-facebook-focus: var(--color-on-facebook-focus-light);
    --color-on-facebook-press: var(--color-on-facebook-press-light);
    --color-on-facebook-drag: var(--color-on-facebook-drag-light);
    --color-facebook-container: var(--color-facebook-container-light);
    --color-facebook-container-hover: var(
      --color-facebook-container-hover-light
    );
    --color-facebook-container-focus: var(
      --color-facebook-container-focus-light
    );
    --color-facebook-container-press: var(
      --color-facebook-container-press-light
    );
    --color-facebook-container-drag: var(--color-facebook-container-drag-light);
    --color-on-facebook-container: var(--color-on-facebook-container-light);
    --color-on-facebook-container-hover: var(
      --color-on-facebook-container-hover-light
    );
    --color-on-facebook-container-focus: var(
      --color-on-facebook-container-focus-light
    );
    --color-on-facebook-container-press: var(
      --color-on-facebook-container-press-light
    );
    --color-on-facebook-container-drag: var(
      --color-on-facebook-container-drag-light
    );
    --color-google: var(--color-google-light);
    --color-google-hover: var(--color-google-hover-light);
    --color-google-focus: var(--color-google-focus-light);
    --color-google-press: var(--color-google-press-light);
    --color-google-drag: var(--color-google-drag-light);
    --color-on-google: var(--color-on-google-light);
    --color-on-google-hover: var(--color-on-google-hover-light);
    --color-on-google-focus: var(--color-on-google-focus-light);
    --color-on-google-press: var(--color-on-google-press-light);
    --color-on-google-drag: var(--color-on-google-drag-light);
    --color-google-container: var(--color-google-container-light);
    --color-google-container-hover: var(--color-google-container-hover-light);
    --color-google-container-focus: var(--color-google-container-focus-light);
    --color-google-container-press: var(--color-google-container-press-light);
    --color-google-container-drag: var(--color-google-container-drag-light);
    --color-on-google-container: var(--color-on-google-container-light);
    --color-on-google-container-hover: var(
      --color-on-google-container-hover-light
    );
    --color-on-google-container-focus: var(
      --color-on-google-container-focus-light
    );
    --color-on-google-container-press: var(
      --color-on-google-container-press-light
    );
    --color-on-google-container-drag: var(
      --color-on-google-container-drag-light
    );
    --color-linkedin: var(--color-linkedin-light);
    --color-linkedin-hover: var(--color-linkedin-hover-light);
    --color-linkedin-focus: var(--color-linkedin-focus-light);
    --color-linkedin-press: var(--color-linkedin-press-light);
    --color-linkedin-drag: var(--color-linkedin-drag-light);
    --color-on-linkedin: var(--color-on-linkedin-light);
    --color-on-linkedin-hover: var(--color-on-linkedin-hover-light);
    --color-on-linkedin-focus: var(--color-on-linkedin-focus-light);
    --color-on-linkedin-press: var(--color-on-linkedin-press-light);
    --color-on-linkedin-drag: var(--color-on-linkedin-drag-light);
    --color-linkedin-container: var(--color-linkedin-container-light);
    --color-linkedin-container-hover: var(
      --color-linkedin-container-hover-light
    );
    --color-linkedin-container-focus: var(
      --color-linkedin-container-focus-light
    );
    --color-linkedin-container-press: var(
      --color-linkedin-container-press-light
    );
    --color-linkedin-container-drag: var(--color-linkedin-container-drag-light);
    --color-on-linkedin-container: var(--color-on-linkedin-container-light);
    --color-on-linkedin-container-hover: var(
      --color-on-linkedin-container-hover-light
    );
    --color-on-linkedin-container-focus: var(
      --color-on-linkedin-container-focus-light
    );
    --color-on-linkedin-container-press: var(
      --color-on-linkedin-container-press-light
    );
    --color-on-linkedin-container-drag: var(
      --color-on-linkedin-container-drag-light
    );
  }

  .dark-scheme {
    --color-discord: var(--color-discord-dark);
    --color-discord-hover: var(--color-discord-hover-dark);
    --color-discord-focus: var(--color-discord-focus-dark);
    --color-discord-press: var(--color-discord-press-dark);
    --color-discord-drag: var(--color-discord-drag-dark);
    --color-on-discord: var(--color-on-discord-dark);
    --color-on-discord-hover: var(--color-on-discord-hover-dark);
    --color-on-discord-focus: var(--color-on-discord-focus-dark);
    --color-on-discord-press: var(--color-on-discord-press-dark);
    --color-on-discord-drag: var(--color-on-discord-drag-dark);
    --color-discord-container: var(--color-discord-container-dark);
    --color-discord-container-hover: var(--color-discord-container-hover-dark);
    --color-discord-container-focus: var(--color-discord-container-focus-dark);
    --color-discord-container-press: var(--color-discord-container-press-dark);
    --color-discord-container-drag: var(--color-discord-container-drag-dark);
    --color-on-discord-container: var(--color-on-discord-container-dark);
    --color-on-discord-container-hover: var(
      --color-on-discord-container-hover-dark
    );
    --color-on-discord-container-focus: var(
      --color-on-discord-container-focus-dark
    );
    --color-on-discord-container-press: var(
      --color-on-discord-container-press-dark
    );
    --color-on-discord-container-drag: var(
      --color-on-discord-container-drag-dark
    );
    --color-facebook: var(--color-facebook-dark);
    --color-facebook-hover: var(--color-facebook-hover-dark);
    --color-facebook-focus: var(--color-facebook-focus-dark);
    --color-facebook-press: var(--color-facebook-press-dark);
    --color-facebook-drag: var(--color-facebook-drag-dark);
    --color-on-facebook: var(--color-on-facebook-dark);
    --color-on-facebook-hover: var(--color-on-facebook-hover-dark);
    --color-on-facebook-focus: var(--color-on-facebook-focus-dark);
    --color-on-facebook-press: var(--color-on-facebook-press-dark);
    --color-on-facebook-drag: var(--color-on-facebook-drag-dark);
    --color-facebook-container: var(--color-facebook-container-dark);
    --color-facebook-container-hover: var(
      --color-facebook-container-hover-dark
    );
    --color-facebook-container-focus: var(
      --color-facebook-container-focus-dark
    );
    --color-facebook-container-press: var(
      --color-facebook-container-press-dark
    );
    --color-facebook-container-drag: var(--color-facebook-container-drag-dark);
    --color-on-facebook-container: var(--color-on-facebook-container-dark);
    --color-on-facebook-container-hover: var(
      --color-on-facebook-container-hover-dark
    );
    --color-on-facebook-container-focus: var(
      --color-on-facebook-container-focus-dark
    );
    --color-on-facebook-container-press: var(
      --color-on-facebook-container-press-dark
    );
    --color-on-facebook-container-drag: var(
      --color-on-facebook-container-drag-dark
    );
    --color-google: var(--color-google-dark);
    --color-google-hover: var(--color-google-hover-dark);
    --color-google-focus: var(--color-google-focus-dark);
    --color-google-press: var(--color-google-press-dark);
    --color-google-drag: var(--color-google-drag-dark);
    --color-on-google: var(--color-on-google-dark);
    --color-on-google-hover: var(--color-on-google-hover-dark);
    --color-on-google-focus: var(--color-on-google-focus-dark);
    --color-on-google-press: var(--color-on-google-press-dark);
    --color-on-google-drag: var(--color-on-google-drag-dark);
    --color-google-container: var(--color-google-container-dark);
    --color-google-container-hover: var(--color-google-container-hover-dark);
    --color-google-container-focus: var(--color-google-container-focus-dark);
    --color-google-container-press: var(--color-google-container-press-dark);
    --color-google-container-drag: var(--color-google-container-drag-dark);
    --color-on-google-container: var(--color-on-google-container-dark);
    --color-on-google-container-hover: var(
      --color-on-google-container-hover-dark
    );
    --color-on-google-container-focus: var(
      --color-on-google-container-focus-dark
    );
    --color-on-google-container-press: var(
      --color-on-google-container-press-dark
    );
    --color-on-google-container-drag: var(
      --color-on-google-container-drag-dark
    );
    --color-linkedin: var(--color-linkedin-dark);
    --color-linkedin-hover: var(--color-linkedin-hover-dark);
    --color-linkedin-focus: var(--color-linkedin-focus-dark);
    --color-linkedin-press: var(--color-linkedin-press-dark);
    --color-linkedin-drag: var(--color-linkedin-drag-dark);
    --color-on-linkedin: var(--color-on-linkedin-dark);
    --color-on-linkedin-hover: var(--color-on-linkedin-hover-dark);
    --color-on-linkedin-focus: var(--color-on-linkedin-focus-dark);
    --color-on-linkedin-press: var(--color-on-linkedin-press-dark);
    --color-on-linkedin-drag: var(--color-on-linkedin-drag-dark);
    --color-linkedin-container: var(--color-linkedin-container-dark);
    --color-linkedin-container-hover: var(
      --color-linkedin-container-hover-dark
    );
    --color-linkedin-container-focus: var(
      --color-linkedin-container-focus-dark
    );
    --color-linkedin-container-press: var(
      --color-linkedin-container-press-dark
    );
    --color-linkedin-container-drag: var(--color-linkedin-container-drag-dark);
    --color-on-linkedin-container: var(--color-on-linkedin-container-dark);
    --color-on-linkedin-container-hover: var(
      --color-on-linkedin-container-hover-dark
    );
    --color-on-linkedin-container-focus: var(
      --color-on-linkedin-container-focus-dark
    );
    --color-on-linkedin-container-press: var(
      --color-on-linkedin-container-press-dark
    );
    --color-on-linkedin-container-drag: var(
      --color-on-linkedin-container-drag-dark
    );
  }
}

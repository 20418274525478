.primary {
  border-color: var(--color-primary) !important;
}

.onPrimary {
  border-color: var(--color-on-primary) !important;
}

.primaryContainer {
  border-color: var(--color-primary-container) !important;
}

.onPrimaryContainer {
  border-color: var(--color-on-primary-container) !important;
}

.secondary {
  border-color: var(--color-secondary) !important;
}

.onSecondary {
  border-color: var(--color-on-secondary) !important;
}

.secondaryContainer {
  border-color: var(--color-secondary-container) !important;
}

.onSecondaryContainer {
  border-color: var(--color-on-secondary-container) !important;
}

.tertiary {
  border-color: var(--color-tertiary) !important;
}

.onTertiary {
  border-color: var(--color-on-tertiary) !important;
}

.tertiaryContainer {
  border-color: var(--color-tertiary-container) !important;
}

.onTertiaryContainer {
  border-color: var(--color-on-tertiary-container) !important;
}

.error {
  border-color: var(--color-error) !important;
}

.onError {
  border-color: var(--color-on-error) !important;
}

.errorContainer {
  border-color: var(--color-error-container) !important;
}

.onErrorContainer {
  border-color: var(--color-on-error-container) !important;
}

.background {
  border-color: var(--color-background) !important;
}

.onBackground {
  border-color: var(--color-on-background) !important;
}

.surface {
  border-color: var(--color-surface) !important;
}

.onSurface {
  border-color: var(--color-on-surface) !important;
}

.surface1 {
  border-color: var(--color-surface-1) !important;
}

.onSurface1 {
  border-color: var(--color-on-surface) !important;
}

.surface2 {
  border-color: var(--color-surface-2) !important;
}

.onSurface2 {
  border-color: var(--color-on-surface) !important;
}

.surface3 {
  border-color: var(--color-surface-3) !important;
}

.onSurface3 {
  border-color: var(--color-on-surface) !important;
}

.surface4 {
  border-color: var(--color-surface-4) !important;
}

.onSurface4 {
  border-color: var(--color-on-surface) !important;
}

.surface5 {
  border-color: var(--color-surface-5) !important;
}

.onSurface5 {
  border-color: var(--color-on-surface) !important;
}

.outline {
  border-color: var(--color-outline) !important;
}

.surfaceVariant {
  border-color: var(--color-surface-variant) !important;
}

.onSurfaceVariant {
  border-color: var(--color-on-surface-variant) !important;
}

.categorySuccess {
  border-color: var(--color-category-success) !important;
}

.onCategorySuccess {
  border-color: var(--color-on-category-success) !important;
}

.categoryInfo {
  border-color: var(--color-category-info) !important;
}

.onCategoryInfo {
  border-color: var(--color-on-category-info) !important;
}

.categoryWarning {
  border-color: var(--color-category-warning) !important;
}

.onCategoryWarning {
  border-color: var(--color-on-category-warning) !important;
}

.categoryError {
  border-color: var(--color-category-error) !important;
}

.onCategoryError {
  border-color: var(--color-on-category-error) !important;
}

.categoryA {
  border-color: var(--color-category-a) !important;
}

.onCategoryA {
  border-color: var(--color-on-category-a) !important;
}

.categoryB {
  border-color: var(--color-category-b) !important;
}

.onCategoryB {
  border-color: var(--color-on-category-b) !important;
}

.categoryC {
  border-color: var(--color-category-c) !important;
}

.onCategoryC {
  border-color: var(--color-on-category-c) !important;
}

.categoryD {
  border-color: var(--color-category-d) !important;
}

.onCategoryD {
  border-color: var(--color-on-category-d) !important;
}

.categoryE {
  border-color: var(--color-category-e) !important;
}

.onCategoryE {
  border-color: var(--color-on-category-e) !important;
}

.categoryF {
  border-color: var(--color-category-f) !important;
}

.onCategoryF {
  border-color: var(--color-on-category-f) !important;
}

.categoryG {
  border-color: var(--color-category-g) !important;
}

.onCategoryG {
  border-color: var(--color-on-category-g) !important;
}

.categoryH {
  border-color: var(--color-category-h) !important;
}

.onCategoryH {
  border-color: var(--color-on-category-h) !important;
}

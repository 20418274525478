.backgroundImage {
  height: 88px;
  object-fit: cover;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: row;
}

.profileImageContainer {
  border-radius: 50%;
  flex-shrink: 0;
  height: 152px;
  margin: -56px 16px 16px 16px;
  padding: 4px;
  width: 152px;
}

.profileImage {
  border-radius: 50%;
  height: 144px;
  object-fit: cover;
  width: 144px;
}

.links {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-gap);
  justify-content: flex-start;
  margin-top: var(--space-2x);
}

.tags {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-gap);
  justify-content: flex-start;
  margin-top: var(--space-4x);
}
